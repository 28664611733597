export default {
    flatchooser_1: {
        root: {
            'type': 'object',
            'hidden': true,
            'fields': {
                'slides': {
                    'type': 'object',
                    'hidden': true,
                    'autoFields': {
                        'except': {
                            'default': function({ data }) {
                                return !(data && data.type === 'flat');
                            }
                        },
                        'enable': {
                            'default': {
                                'type': 'object',
                                'fields': {
                                    'name': {
                                        'type': 'string',
                                        'validations': [
                                            {
                                                'name': 'min',
                                                'props': { 'value': 1, 'type': 'string' }
                                            }, {
                                                'name': 'type',
                                                'props': { 'value': ['string'] }
                                            }
                                        ]
                                    },
                                    'status': {
                                        'component': 'select',
                                        'type': 'string',
                                        'name': 'status',
                                        'default': 'available',
                                        'options': [
                                            { 'label': 'Available', 'value': 'available' },
                                            { 'label': 'Reserved', 'value': 'reserved' },
                                            { 'label': 'Sold', 'value': 'sold' }
                                        ],
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['object'] }
                                            }, {
                                                'name': 'arrayin',
                                                'props': { 'value': ['available', 'reserved', 'sold'] }
                                            }, {
                                                'name': 'min',
                                                'props': { 'value': 1, 'type': 'object' }
                                            }, {
                                                'name': 'max',
                                                'props': { 'value': 1, 'type': 'object' }
                                            }
                                        ]
                                    },
                                    'price': {
                                        'type': 'number',
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['number'] }
                                            }, {
                                                'name': 'min',
                                                'props': { 'value': 0, 'type': 'number' }
                                            }
                                        ]
                                    },
                                    'fee': {
                                        'type': 'number',
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['number'] }
                                            }, {
                                                'name': 'min',
                                                'props': { 'value': 0, 'type': 'number' }
                                            }
                                        ]
                                    },
                                    'floor': {
                                        'type': 'number',
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['number'] }
                                            }
                                        ]
                                    },
                                    'size': {
                                        'type': 'number',
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['number'] }
                                            }, {
                                                'name': 'min',
                                                'props': { 'value': 0, 'type': 'number' }
                                            }
                                        ]
                                    },
                                    'rooms': {
                                        'type': 'number',
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['number'] }
                                            }, {
                                                'name': 'min',
                                                'props': { 'value': 0, 'type': 'number' }
                                            }
                                        ]
                                    }
                                }
                            }
                        }
                    },
                    'fieldsName': 'name',
                    'fields': {}
                }
            }
        }
    },
    flatchooser_2: {
        root: {
            'type': 'object',
            'hidden': true,
            'fields': {
                'slides': {
                    'type': 'object',
                    'hidden': true,
                    'autoFields': {
                        'except': {
                            'default': function({ data }) {
                                return !(data && data.type === 'flat');
                            }
                        },
                        'enable': {
                            'default': {
                                'type': 'object',
                                'autoFields': {
                                    'except': {
                                        'SVG': 1,
                                        'views': 1,
                                        'autoIncrement': 1,
                                        'type': 1,
                                        'data': 1,
                                        'html': 1,
                                        'filename': 1,
                                        'pdf_download': 1,
                                        'pdf_background': 1,
                                        'flats_number': 1,
                                        'default': 1
                                    },
                                    'enable': {
                                        'default': {
                                            'type': 'string'
                                        }
                                    }
                                },
                                'fields': {
                                    'name': {
                                        'type': 'string',
                                        'validations': [
                                            {
                                                'name': 'min',
                                                'props': { 'value': 1, 'type': 'string' }
                                            }, {
                                                'name': 'type',
                                                'props': { 'value': ['string'] }
                                            }
                                        ]
                                    },
                                    'status': {
                                        'component': 'select',
                                        'type': 'string',
                                        'name': 'status',
                                        'default': 'available',
                                        'options': [
                                            { 'label': 'Available', 'value': 'available' },
                                            { 'label': 'Reserved', 'value': 'reserved' },
                                            { 'label': 'Sold', 'value': 'sold' }
                                        ],
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['object'] }
                                            }, {
                                                'name': 'arrayin',
                                                'props': { 'value': ['available', 'reserved', 'sold'] }
                                            }, {
                                                'name': 'min',
                                                'props': { 'value': 1, 'type': 'object' }
                                            }, {
                                                'name': 'max',
                                                'props': { 'value': 1, 'type': 'object' }
                                            }
                                        ]
                                    },
                                    'price': {
                                        'type': 'number',
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['number'] }
                                            }, {
                                                'name': 'min',
                                                'props': { 'value': 0, 'type': 'number' }
                                            }
                                        ]
                                    },
                                    'fee': {
                                        'type': 'number',
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['number'] }
                                            }, {
                                                'name': 'min',
                                                'props': { 'value': 0, 'type': 'number' }
                                            }
                                        ]
                                    },
                                    'floor': {
                                        'type': 'number',
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['number'] }
                                            }
                                        ]
                                    },
                                    'size': {
                                        'type': 'number',
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['number'] }
                                            }, {
                                                'name': 'min',
                                                'props': { 'value': 0, 'type': 'number' }
                                            }
                                        ]
                                    },
                                    'rooms': {
                                        'type': 'number',
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['number'] }
                                            }, {
                                                'name': 'min',
                                                'props': { 'value': 0, 'type': 'number' }
                                            }
                                        ]
                                    }
                                }
                            }
                        }
                    },
                    'fieldsName': 'name',
                    'fields': {}
                }
            }
        }
    }
}
