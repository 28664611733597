export default {
    'type': 'object',
    'hidden': true,
    'fields': {
        'props': {
            'type': 'object',
            'hidden': true,
            'fields': {
                'objects': {
                    'type': 'array',
                    'fieldsName': 'name',
                    'name': 'root',
                    'add': {
                        'name': 'Object',
                        'type': 'area',
                        'status': 'available',
                        'key': function() {
                            return 'object_' + new Date().getTime()
                        }
                    },
                    'fields': {},
                    'update': async function(p = {}) {
                        const { props = {} } = p
                        const root = props.root
                        await root.refElements.views.regenerateForm()
                        await root.refElements.galleries.regenerateForm()
                    },
                    'innerFormProps': {
                        'onOpen': function() {
                            return async function(p = {}) {
                                const { props = {} } = p
                                const root = props.root
                                const { refElements = {} } = root
                                refElements.views.close()
                                refElements.metas.close()
                                refElements.settings.close()
                                refElements.animations.close()
                                refElements.galleries.close()
                            }
                        }
                    },
                    'autoFields': {
                        'enable': {
                            'default': {
                                'type': 'object',
                                'remove': function({ data, key }) {
                                    return data[key].key !== 'area';
                                },
                                'fields': {}
                            },
                            'dinamic': function(p = {}) {

                                const data = p.data[p.key][p.i]
                                const metas = p.parentData.datas[p.deep - 2].metas.props.metas

                                if (metas && metas.length) {
                                    const r = {}
                                    metas.forEach(function(meta) {
                                        const key = meta.key
                                        const enabled = meta.enabled || []
                                        const go = (enabled.indexOf(data.type) > -1)
                                        if (go) {
                                            r[key] = {
                                                ...meta
                                            }

                                            if (key.match('custom')) {
                                                let validations = meta.validations || []
                                                let foundValidation = null

                                                if (validations && validations.length) {
                                                    validations.map(function(validation) {
                                                        if (validation.name === 'type') {
                                                            foundValidation = validation
                                                        }
                                                    })
                                                }

                                                if (!foundValidation) {
                                                    foundValidation = {
                                                        'name': 'type',
                                                        'props': { 'value': [] }
                                                    }
                                                }

                                                if (meta.type === 'number' || meta.type === 'string') {
                                                    foundValidation.props.value = [meta.type]
                                                }

                                                if (foundValidation.props.value && foundValidation.props.value.length) {
                                                    if (!validations) validations = []
                                                    validations.push(foundValidation)
                                                }

                                                if (validations && validations.length) {
                                                    r[key].validations = validations
                                                }

                                            }

                                        }
                                    })
                                    return { fields: r }
                                }
                                return {}
                            }
                        }
                    }
                }
            }
        }
    }
}
