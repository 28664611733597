import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

import CharliesPlayerOld from '../CharliesPlayerOld'
import cpos from '../CharliesPlayerOld/CharliesPlayerOld.css'

const Player = createReactClass({
    getInitialState() {

        this.refElements = {}
        const data = this.getData()
        const post = this.getPost()

        return {
            data: data,
            post: post,
            isMounted: true
        }
    },
    componentDidMount: function() {
    },
    componentWillUnmount: function() {
        this.setState({
            isMounted: false
        })
    },
    getStore: function() {
        const { construct } = this.props
        return (construct.props && construct.props.store) ? construct.props.store : null
    },
    getGlobalState: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct.props.state : teststate
    },
    getPost: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.currentpost && state.response.currentpost[0]) ? state.response.currentpost[0] : null
    },
    getData: function() {
        const { construct, data } = this.props
        if (data) return data
        return (construct.props && construct.props.data) ? construct.props.data : null
    },

    /*Refs*/

    getElement: function(e) {
        return (e) ? (e.nodeName) ? e : ReactDOM && ReactDOM.findDOMNode && ReactDOM.findDOMNode(e) : null
    },
    setRef: function(a, e) {
        if (e) {
            this.refElements[a] = e
        }
    },

    /*FlatChooser*/

    reload: function() {
        this.setState({
            time: new Date().getTime()
        })
    },

    render: function() {
        const { style, construct } = this.props
        const { post } = this.state

        return (
            <div className={style.container}>
                <CharliesPlayerOld construct={construct} post={post} style={cpos} />
            </div>
        )
    }
})

const Middle = createReactClass({
    setRef: function(a, e) {
        const { setRef } = this.props
        const { construct } = this.props
        const setRefConstruct = (construct.props) ? construct.props.setRef : null
        if (setRef) setRef(a, e)
        if (setRefConstruct) setRefConstruct(a, e)
    },
    render: function() {
        const setRef = this.setRef
        return (
            <Player {...this.props} ref={(setRef) ? function(e) {
                setRef('player', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})
