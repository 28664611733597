import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

import { resizeListen, resizeUnlisten } from 'dom-resize'

const App = createReactClass({
    getInitialState() {
        this.resizelisteners = {}
        return {
            isMounted: true
        }
    },

    componentDidMount: function() {
        const addResizeEvent = this.addResizeEvent

        setTimeout(function() {
            addResizeEvent()
        })
    },
    componentWillUnmount: function() {
        this.removeResizeEvent()
    },


    getGlobalState: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct.props.state : teststate
    },
    getMe: function() {
        const state = this.getGlobalState()
        const me = (state && state.response && state.response.me) ? state.response.me : null
        if (me) wapplravatarhelpers({ user: me })
        return me
    },
    getUser: function() {
        const post = this.getPost()
        if (post && post.author && post.author.id) {
            const author = post.author
            if (author) wapplravatarhelpers({ user: author })
            return author
        }
        return null
    },
    getPost: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.currentpost && state.response.currentpost[0]) ? state.response.currentpost[0] : null
    },
    getData: function(p = {}) {
        const { callback } = p
        const { construct, hooks } = this.props
        if (hooks && hooks.getData) return hooks.getData({ callback })
        const data = (construct.props && construct.props.data) ? construct.props.data : null
        if (callback) callback({ data })
        return data
    },
    getParentRoute: function() {
        const { construct, parentroute } = this.props
        return (construct.props && construct.props.parentroute) ? construct.props.parentroute : parentroute
    },
    getRootContainer: function() {
        const { construct } = this.props
        const { refElements = {} } = construct.props
        return refElements['charliescontainer']
    },


    /*Resize*/

    removeResizeListener: function(f, id) {
        const resizelisteners = this.resizelisteners
        const r = {}
        Object.keys(resizelisteners).map(function(key) {
            const listener = resizelisteners[key]
            if (listener !== f && id !== key) {
                r[key] = listener
            }
        })
        this.resizelisteners = r
        return r
    },
    addResizeListener: function(f) {
        const resizelisteners = this.resizelisteners
        const id = this.getResizeListenerID()
        resizelisteners[id] = f
        return { resizelisteners, id }
    },
    getResizeListenerID: function() {
        const lastresizelistenerid = this.lastresizelistenerid || 1
        this.lastresizelistenerid = lastresizelistenerid + 1
        return this.lastresizelistenerid
    },
    runResizeListeners: function(e) {
        const t = this
        const resizelisteners = t.resizelisteners
        t.disableRunResizeListeners = true

        Object.keys(resizelisteners).reverse().map(function(key) {
            const listener = resizelisteners[key]
            listener(e)
        })

        setTimeout(function() {
            t.disableRunResizeListeners = false
        }, 100)

    },
    resizeDelay: function(e) {
        if (!this.disableRunResizeListeners) {
            const t = this
            const d = new Date().getTime()
            const lt = t.lasttime || 0
            const delay = 500

            if ((d - lt) > delay) {
                if (t.delayTimeout) clearTimeout(t.delayTimeout)
                t.lasttime = d
                t.runResizeListeners(e)
            } else {
                if (t.delayTimeout) clearTimeout(t.delayTimeout)
                let wt = delay
                if (d - lt < delay) wt = (delay + 10) - (d - lt)
                this.delayTimeout = setTimeout(function() {
                    t.lasttime = new Date().getTime()
                    t.runResizeListeners(e)
                }, wt)
            }
        }
    },
    addResizeEvent: function() {
        const resizeElement = this.getRootContainer()

        if (resizeElement && resizeElement._dRTrigger && resizeElement._dRTrigger.contentDocument) {
            resizeUnlisten(resizeElement)
        }
        if (resizeElement) {
            resizeListen(resizeElement, this.resizeDelay)
        }
    },
    removeResizeEvent: function() {
        const resizeElement = this.getRootContainer()
        if (this.delayTimeout) clearTimeout(this.delayTimeout)

        if (resizeElement && resizeElement._dRTrigger && resizeElement._dRTrigger.contentDocument) {
            resizeUnlisten(resizeElement)
        }
        this.resizelisteners = {}
    },

    render: function() {
        const { children, style, construct } = this.props

        const getRootContainer = this.getRootContainer
        const rootRemoveResizeListener = this.removeResizeListener
        const rootAddResizeListener = this.addResizeListener

        return (
            <div className={style.app}>
                <div className={style.container}>
                    {React.cloneElement(children, {
                        getRootContainer,
                        rootRemoveResizeListener,
                        rootAddResizeListener
                    })}
                </div>
            </div>
        )
    }
})

const Middle = createReactClass({
    setRef: function(a, e) {
        const { setRef } = this.props
        const { construct } = this.props
        const setRefConstruct = (construct.props) ? construct.props.setRef : null
        if (setRef) setRef(a, e)
        if (setRefConstruct) setRefConstruct(a, e)
    },
    render: function() {
        const setRef = this.setRef
        return (
            <App {...this.props} ref={(setRef) ? function(e) {
                setRef('app', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})
