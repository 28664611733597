// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\+N6AF {\n    display: block;\n    color: rgba(0, 0, 0, 0.87);\n    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;\n    box-sizing: border-box;\n    font-family: Roboto, sans-serif;\n    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);\n    box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;\n    border-radius: 2px;\n    z-index: 1;\n    padding: 10px;\n    margin-bottom: 20px;\n}\n\n.MuYak {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    margin-bottom: 10px;\n}\n\n.GDOlD {\n    display: block;\n    width: auto;\n    vertical-align: middle;\n    padding: 5px 12px;\n}\n\n.OAIQ6 {\n    display: table-cell;\n    padding-left: 10px;\n    padding-right: 10px;\n    white-space: nowrap;\n    pointer-events: none;\n    -webkit-user-select: none;\n    -moz-user-select: none;\n    user-select: none;\n    color: rgba(0, 0, 0, 0.3);\n    font-size: 12px;\n}\n\n.pThEv {\n    display: table-cell;\n}\n\n.pThEv svg {\n    color: rgb(63, 69, 74);\n    fill: rgb(63, 69, 74);\n}\n\n.cnmYR {\n    position: relative;\n    letter-spacing: 0;\n    line-height: 36px;\n    font-family: \"Roboto\", \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    color: #3f454a;\n    font-weight: normal;\n    font-size: 16px;\n    display: block;\n    width: 100%;\n    vertical-align: middle;\n    padding-left: 12px;\n    padding-right: 12px;\n    white-space: normal;\n    max-width: 100%;\n    word-break: break-word;\n}\n\n.XOfpU {\n    padding: 10px;\n    background-color: #f5f6f7;\n    text-align: right;\n    margin-top: 10px;\n}\n\n.\\+N6AF {\n    box-shadow: none !important;\n    padding: 0;\n    margin-bottom: 0px;\n}\n\n.MuYak {\n    margin-bottom: 0;\n}\n\n.cnmYR {\n    line-height: 24px;\n    font-size: 13px !important;\n    padding: 0 !important;\n}\n\n.GDOlD {\n    padding: 0;\n}\n\n.OAIQ6 {\n    padding: 0;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "+N6AF",
	"row": "MuYak",
	"namecontainer": "GDOlD",
	"name": "OAIQ6",
	"icon": "pThEv",
	"value": "cnmYR",
	"innerButtons": "XOfpU"
};
module.exports = ___CSS_LOADER_EXPORT___;
