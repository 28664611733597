import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

import IconButton from 'material-ui/IconButton'
import CloseIcon from 'material-ui/svg-icons/action/highlight-off'
import VerticalAlignBottomIcon from 'material-ui/svg-icons/editor/vertical-align-bottom'
import SvgIcon from 'material-ui/SvgIcon'

import MobileDetect from 'mobile-detect'

const FloatingPalette = createReactClass({
    getInitialState() {
        this.refElements = {}

        const {
            open = false,
            pinned = false,
            pintype = 'right',
            palettePosX = -10,
            palettePosY = 10,
            palettePosMinY = 0,
            forcePinOnDownBreakPoint = 800,
            parentWidth = 0,
            visibility = 'visible',
            forcePinOnDownIsMobile = true,
            forcePinOnDownIsTouchable = false
        } = this.props

        const isTouchable = this.isTouchable()
        const isMobile = this.isMobile()
        const width = this.getWidth()

        return {
            open: open,
            pinned: pinned,
            pintype: pintype,
            isMounted: true,
            x: palettePosX,
            y: palettePosMinY + palettePosY,
            minY: palettePosMinY,
            width: parentWidth || width,
            forcePinOnDownBreakPoint: (forcePinOnDownBreakPoint > 0) ? forcePinOnDownBreakPoint : 800,
            forcePinOnDownIsMobile: forcePinOnDownIsMobile,
            forcePinOnDownIsTouchable: forcePinOnDownIsTouchable,
            visibility: visibility,
            isMobile: isMobile,
            isTouchable: isTouchable
        }
    },

    componentDidMount: function() {
        const { scrollTop = 0 } = this.props
        this.setWidthAndMobile()
        if (scrollTop) {
            this.refElements['content'].scrollTop = scrollTop
        }
    },
    componentWillUnmount: function() {
        this.setState({
            isMounted: false
        })
    },

    isChanged: function(a, b) {
        let changed = false
        const isChanged = this.isChanged
        if (a && b && typeof a == 'object' && typeof b == 'object') {
            const keys = (a.length == undefined) ? Object.keys(a) : [...a.keys()]
            keys.map(function(key) {
                if (typeof a[key] !== typeof b[key]) changed = true
                if (!changed) {
                    if (a[key] && typeof a[key] == 'object') {
                        changed = isChanged(a[key], b[key])
                    } else {
                        if (a[key] !== b[key]) {
                            changed = true
                        }
                    }
                }
            })
        } else {
            if (typeof a !== typeof b) changed = true
            if (a !== b) changed = true
        }
        return changed
    },
    shouldComponentUpdate: function(nextProps, nextState) {
        const state = this.state
        const changed1 = this.isChanged(state, nextState)
        const changed2 = (changed1) ? changed1 : this.isChanged(nextState, state)

        if (changed1 || changed2) {
            return true
        }
        return false
    },

    isMobile: function() {
        if (typeof window !== 'undefined') {
            const md = new MobileDetect(window.navigator.userAgent)
            return md.mobile() || md.tablet() || md.phone()
        }
        return false
    },
    isTouchable: function() {
        if (typeof window !== 'undefined') {
            return 'ontouchstart' in window || 'undefined' !== typeof navigator && navigator.maxTouchPoints
        }
        return false
    },
    getWidth: function() {
        const container = this.refElements['container']
        const state = this.state || {}
        const { width = 0 } = state
        if (container && container.parentElement) {
            const newWidth = container.parentElement.offsetWidth
            if (newWidth) return newWidth
        }
        return width
    },
    setWidthAndMobile: async function() {

        const isTouchable = this.isTouchable()
        const isMobile = this.isMobile()
        await this.setState({
            isTouchable: isTouchable,
            isMobile: isMobile
        })

        await this.setWidth()

    },
    setWidth: async function() {

        const { width, forcePinOnDownBreakPoint, isMounted } = this.state
        const { onForcePinOnDown } = this.props

        if (forcePinOnDownBreakPoint && isMounted) {

            const now = (!width || width < forcePinOnDownBreakPoint) ? 0 : 1
            const newWidth = this.getWidth()
            const change = (!newWidth || newWidth < forcePinOnDownBreakPoint) ? 0 : 1

            if (now !== change && width !== newWidth || !width && newWidth) {
                await this.setState({
                    width: newWidth
                })
                const { open, pinned, pintype } = this.getPinSettings()
                if (onForcePinOnDown) onForcePinOnDown({ open, pinned, pintype })
            }

        }
    },
    setRef: function(a, e, n) {
        if (e) {
            if (n) {
                this[n][a] = e
            } else {
                this.refElements[a] = e
            }
        }
    },

    open: function() {
        const { open, pinned, pintype } = this.getPinSettings()
        const { onOpen } = this.props
        if (!open) {
            this.setState({
                open: true
            })
            if (onOpen) onOpen({ open: true, pinned, pintype })
            if (!pinned) this.updatePosition({ forceUpdate: true })
        }
    },
    close: function() {
        const { open, pinned, pintype } = this.getPinSettings()
        const { onClose } = this.props
        if (open) {
            this.setState({
                open: false
            })
            if (onClose) onClose({ open: false, pinned, pintype })
        }
    },
    opentoggle: function() {
        const { open } = this.getPinSettings()
        if (open) {
            this.close()
        } else {
            this.open()
        }
    },


    pin: function() {
        const { open, pinned, pintype } = this.getPinSettings()
        const { onPin } = this.props
        if (!pinned) {
            this.setState({
                pinned: true
            })
            if (onPin) onPin({ open, pinned: true, pintype })
        }
    },
    unpin: function() {
        const { open, pinned, pintype } = this.getPinSettings()
        const { onUnpin } = this.props
        if (pinned) {
            this.setState({
                pinned: false
            })
            if (onUnpin) onUnpin({ open, pinned: false, pintype })
            if (open) this.updatePosition({ forceUpdate: true })
        }
    },
    pintoggle: function() {
        const { pinned } = this.getPinSettings()
        if (pinned) {
            this.unpin()
        } else {
            this.pin()
        }
    },

    pinright: function() {
        const { pintype, pinned } = this.getPinSettings()
        const { onPintype } = this.props
        if (pintype !== 'right') {
            this.setState({
                pintype: 'right'
            })
            if (onPintype) onPintype({ open, pintype: 'right', pinned })
        }
    },
    pindown: function() {
        const { open, pinned, pintype } = this.getPinSettings()
        const { onPintype } = this.props
        if (pintype !== 'down') {
            this.setState({
                pintype: 'down'
            })
            if (onPintype) onPintype({ open, pintype: 'down', pinned })
        }
    },
    pintypetoggle: function() {
        const { pintype } = this.getPinSettings()
        if (pintype == 'right') {
            this.pindown()
        } else if (pintype == 'down') {
            this.pinright()
        }
    },

    startMove: function(e) {

        const container = this.refElements['container']
        if (container && container.parentElement) {
            const rect = container.getBoundingClientRect()
            const parentrect = container.parentElement.getBoundingClientRect()

            this.mousePosition = {
                startx: e.pageX,
                starty: e.pageY,
                startpositionx: container.offsetLeft,
                startpositiony: container.offsetTop,
                startwidth: container.offsetWidth,
                startheight: container.offsetHeight,
                startscalex: rect.width / container.offsetWidth,
                startscaley: rect.height / container.offsetHeight,
                startrectwidth: rect.width,
                startrectheight: rect.height,

                parentrectwidth: parentrect.width,
                parentrectheight: parentrect.height,
                parentwidth: container.parentElement.offsetWidth,
                parentheight: container.parentElement.offsetHeight,
                parentscalex: parentrect.width / container.parentElement.offsetWidth,
                parentscaley: parentrect.height / container.parentElement.offsetHeight
            }

            window.removeEventListener('mouseup', this.endMove)
            window.removeEventListener('mousemove', this.move)

            window.addEventListener('mouseup', this.endMove, false)
            window.addEventListener('mousemove', this.move, false)
        }

        e.preventDefault()

    },
    endMove: function(e) {
        window.removeEventListener('mouseup', this.endMove)
        window.removeEventListener('mousemove', this.move)
        e.preventDefault()
    },
    move: function(e) {

        const { minY } = this.state
        let changex = e.pageX - this.mousePosition.startx
        let changey = e.pageY - this.mousePosition.starty

        let newx = this.mousePosition.startpositionx + (changex / this.mousePosition.parentscalex)
        let newy = this.mousePosition.startpositiony + (changey / this.mousePosition.parentscaley)

        if (newx < 0) newx = 0
        if (newy < minY) newy = minY

        if ((this.mousePosition.parentrectwidth - this.mousePosition.startrectwidth) < (newx)) newx = (this.mousePosition.parentrectwidth - this.mousePosition.startrectwidth)
        if ((this.mousePosition.parentrectheight - this.mousePosition.startrectheight) < (newy)) newy = (this.mousePosition.parentrectheight - this.mousePosition.startrectheight)

        const { x, y } = this.getPinSettings()
        if (x !== newx || y !== newy) {
            this.setState({
                x: newx,
                y: newy
            })
        }

        e.preventDefault()
    },
    updatePosition: async function({ forceUpdate, visibility }) {

        const t = this
        await this.setWidth()

        const { open, pinned, x, y } = t.getPinSettings()
        const { isMounted } = t.state
        let changed = false

        if (!pinned && open && isMounted || forceUpdate && isMounted) {

            const container = t.refElements['container']
            if (container && container.parentElement) {
                const rect = container.getBoundingClientRect()
                const parentrect = container.parentElement.getBoundingClientRect()

                let newx = x
                let newy = y

                if ((parentrect.width - rect.width) < (newx)) newx = (parentrect.width - rect.width)
                if ((parentrect.height - rect.height) < (newy)) newy = (parentrect.height - rect.height)

                if (x !== newx || y !== newy) {
                    changed = true
                    t.setState({
                        x: newx,
                        y: newy,
                        visibility: (visibility) ? visibility : t.state.visibility
                    })
                }
            }
        }

        if (!changed && visibility && this.state.visibility !== visibility) {
            this.setState({
                visibility: visibility
            })
        }

    },

    touchEnd: function(e, fn, pt, d) {
        if (e && e.type == 'mouseup' || e && e.type == 'touchend' || e && e.type == pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { type } = d
        if (type == 'opentoggle') {
            this.opentoggle()
        }
        if (type == 'pintoggle') {
            this.pintoggle()
        }
        if (type == 'pintypetoggle') {
            this.pintypetoggle()
        }
        if (type == 'startmove') {
            this.startmove()
        }
        e.preventDefault()
    },

    getClassName: function({ pinned, pintype }) {
        const { style, pinRightAndUnpinWidth = '350px', pintypeClasses } = this.props

        let className = style.floatingpalette
        let widthStyle = { width: '100%' }

        if (pinned && pintype == 'down' || pinned && pintype == 'right') {
            className = className + ' ' + style['pin' + pintype]
            if (pintypeClasses) {
                className = className + ' ' + pintypeClasses['pintype_' + pintype]
            }
        } else {
            className = className + ' ' + style['floating']
        }
        if (pinned && pintype == 'right' || !pinned) {
            widthStyle = { width: pinRightAndUnpinWidth }
        }
        return { className, widthStyle }
    },
    getStyle: function({ x, y, pinned, pintype, open }) {
        const { visibility, minY = 0 } = this.state
        if (!pinned) {

            const nx = (x >= 0) ? x + 'px' : 'calc(100% - ' + ((x * -1) + 280) + 'px)'

            return {
                left: nx,
                top: y + 'px',
                display: (open) ? 'flex' : 'none',
                visibility: visibility,
                maxHeight: 'calc(120% - ' + minY + 'px)'
            }
        }
        return {
            display: (open) ? (pintype == 'down') ? 'block' : 'inline-block' : 'none',
            visibility: visibility
        }
    },

    getPinSettings: function() {

        const {
            disableChangePin = false,
            disableChangePinType = false,
            disableMove = false,
            disableClose = false,
            disablCloseOnlyOneIconRemains = true,
            pinnedRelative
        } = this.props

        const {
            width,
            forcePinOnDownBreakPoint,
            open,
            pinned,
            pintype,
            x,
            y,
            forcePinOnDownIsMobile,
            forcePinOnDownIsTouchable,
            isMobile,
            isTouchable
        } = this.state

        let forcePinOnDown = (forcePinOnDownBreakPoint && width && width < forcePinOnDownBreakPoint) ? true : false
        if (forcePinOnDownIsMobile && isMobile || forcePinOnDownIsTouchable && isTouchable) forcePinOnDown = true

        const rDisableChangePin = (forcePinOnDown) ? true : disableChangePin
        const rDisableChangePinType = (forcePinOnDown) ? true : disableChangePinType
        const rDisableMove = (forcePinOnDown) ? true : disableMove
        let rDisableClose = disableClose
        if (disablCloseOnlyOneIconRemains && rDisableChangePin && rDisableChangePinType && rDisableMove) rDisableClose = true

        const rOpen = open
        const rPinned = (forcePinOnDown) ? true : pinned
        const rPintype = (forcePinOnDown) ? 'down' : pintype

        const hideHeader = !!(rDisableChangePin && rDisableChangePinType && rDisableMove && rDisableClose)

        const { className, widthStyle } = this.getClassName({
            open: rOpen,
            pinned: rPinned,
            pintype: rPintype,
            x: x,
            y: y
        })

        const containerStyle = this.getStyle({
            open: rOpen,
            pinned: rPinned,
            pintype: rPintype,
            x: x,
            y: y
        })

        return {
            disableChangePin: rDisableChangePin,
            disableChangePinType: rDisableChangePinType,
            disableMove: rDisableMove,
            disableClose: rDisableClose,
            hideHeader,
            open: rOpen,
            pinned: rPinned,
            pintype: rPintype,
            containerStyle,
            className,
            pinnedRelative,
            x,
            y,
            widthStyle
        }
    },
    getContainerClassName: function(p = this.getPinSettings()) {
        const { style } = this.props
        const { className, pinnedRelative } = p
        let rClassName = (pinnedRelative) ? className + ' ' + style.pinnedrelative : className
        if (this.props.className) {
            rClassName = this.props.className + ' ' + rClassName
        }
        return rClassName
    },
    render: function() {

        const { children, style, customStyle = {} } = this.props
        const cS = customStyle || {}
        if (!cS.backgroundColor) cS.backgroundColor = '#ffffff'

        const pinSettings = this.getPinSettings()

        const {
            disableChangePin, disableChangePinType, disableMove, disableClose,
            hideHeader, open, pinned, pintype, containerStyle,
            pinnedRelative, widthStyle
        } = pinSettings

        const className = this.getContainerClassName(pinSettings)

        const touchEnd = this.touchEnd
        const setRef = this.setRef
        const startMove = this.startMove

        return (
            <div className={className} ref={function(e) {
                setRef('container', e)
            }} style={{ ...containerStyle, ...cS, ...widthStyle }}>
                <div className={style.header} style={(hideHeader) ? { display: 'none' } : null}>
                    <div className={style.headerleft}>
                        {(!disableChangePin) ?
                            <div className={style.controlsicon}>
                                <IconButton
                                    onTouchTap={function(e) {
                                        touchEnd(e, 'click', 'click', { type: 'pintoggle' })
                                    }}
                                    onTouchStart={function(e) {

                                    }}
                                    className={(pinned) ? style.controlsiconbutton : style.controlsiconbutton + ' ' + style.pinnediconrotated}
                                >
                                    {(pinned) ?
                                        <SvgIcon viewBox={'-75,-75,662,662'}>
                                            <g xmlns='http://www.w3.org/2000/svg'>
                                                <path
                                                    d='M141.938,324.965L11.819,484.023c-3.387,4.146-3.517,10.24,0,14.535c4.002,4.918,11.247,5.646,16.147,1.625   l159.107-130.131c-8.13-7.24-15.955-14.598-23.244-21.893C156.199,340.533,148.909,332.758,141.938,324.965z' />
                                                <path
                                                    d='M447.89,64.1C403.4,19.598,353.134-2.246,335.581,15.254c-0.903,0.908-92.757,126.197-138.796,188.98   c-30.166-21.5-57.219-34.082-74.656-34.068c-6.374,0-11.485,1.662-14.971,5.18c-19.197,19.164,16.646,86.076,80.028,149.459   c51.797,51.797,105.971,85.223,134.488,85.223c6.387,0,11.472-1.68,14.971-5.178c13.084-13.084,0.548-48.41-28.858-89.658   c62.802-46.043,188.047-137.893,188.932-138.781C514.254,158.893,492.38,108.607,447.89,64.1z' />
                                            </g>
                                        </SvgIcon> :
                                        <SvgIcon viewBox={'-75,-75,662,662'}>
                                            <g xmlns='http://www.w3.org/2000/svg'>
                                                <path
                                                    d='M141.938,324.965L11.819,484.023c-3.387,4.146-3.517,10.24,0,14.535c4.002,4.918,11.247,5.646,16.147,1.625   l159.107-130.131c-8.13-7.24-15.955-14.598-23.244-21.893C156.199,340.533,148.909,332.758,141.938,324.965z' />
                                                <path
                                                    d='M447.89,64.1C403.4,19.598,353.134-2.246,335.581,15.254c-0.903,0.908-92.757,126.197-138.796,188.98   c-30.166-21.5-57.219-34.082-74.656-34.068c-6.374,0-11.485,1.662-14.971,5.18c-19.197,19.164,16.646,86.076,80.028,149.459   c51.797,51.797,105.971,85.223,134.488,85.223c6.387,0,11.472-1.68,14.971-5.178c13.084-13.084,0.548-48.41-28.858-89.658   c62.802-46.043,188.047-137.893,188.932-138.781C514.254,158.893,492.38,108.607,447.89,64.1z' />
                                            </g>
                                        </SvgIcon>
                                    }
                                </IconButton>
                            </div> : null
                        }
                        {
                            (!disableChangePinType && pinned && pintype == 'right' || !disableChangePinType && pinned && pintype == 'down') ?
                                <div className={style.controlsicon}>
                                    <IconButton
                                        onTouchTap={function(e) {
                                            touchEnd(e, 'click', 'click', { type: 'pintypetoggle' })
                                        }}
                                        onTouchStart={function(e) {

                                        }}
                                        className={(pintype == 'right') ? style.controlsiconbutton : style.controlsiconbutton + ' ' + style.pintypeiconrotated}
                                    >
                                        <VerticalAlignBottomIcon />
                                    </IconButton>
                                </div> : null

                        }
                        {
                            (!pinned && !disableMove) ?
                                <div className={style.controlsicon}>
                                    <IconButton
                                        onTouchStart={function(e) {
                                            startMove(e)
                                        }}
                                        onMouseDown={function(e) {
                                            startMove(e)
                                        }}
                                        ref={function(e) {
                                            setRef('movebutton', e)
                                        }}
                                        className={style.controlsiconbutton}>
                                        <SvgIcon viewBox={'-50 -50 510.988 510.988'}>
                                            <g xmlns='http://www.w3.org/2000/svg'>
                                                <path
                                                    d='M149.654,195.495c0-4.142-3.357-7.5-7.5-7.5l-78.262-0.001l12.527-12.528   c1.406-1.406,2.197-3.314,2.197-5.303c0-1.989-0.791-3.897-2.197-5.303l-14.143-14.143c-2.93-2.929-7.678-2.929-10.607,0   L2.197,200.191c-2.93,2.929-2.93,7.678,0,10.606l49.473,49.474c1.465,1.464,3.385,2.197,5.305,2.197   c1.918,0,3.838-0.732,5.303-2.197l14.143-14.143c1.406-1.406,2.197-3.314,2.197-5.303c0-1.989-0.791-3.897-2.197-5.303   l-12.527-12.527l78.262,0.001c1.99,0,3.896-0.79,5.305-2.197c1.406-1.406,2.195-3.314,2.195-5.303V195.495z' />
                                                <path
                                                    d='M408.791,200.191l-49.473-49.474c-2.93-2.929-7.678-2.929-10.607,0l-14.143,14.143   c-1.406,1.406-2.197,3.314-2.197,5.303c0,1.989,0.791,3.897,2.197,5.303l12.527,12.528l-78.262,0.001c-4.143,0-7.5,3.358-7.5,7.5   v20c0,1.989,0.789,3.897,2.195,5.303c1.408,1.407,3.314,2.197,5.305,2.197l78.262-0.001l-12.527,12.527   c-1.406,1.406-2.197,3.314-2.197,5.303c0,1.989,0.791,3.897,2.197,5.303l14.143,14.143c1.465,1.464,3.385,2.197,5.303,2.197   c1.92,0,3.84-0.732,5.305-2.197l49.473-49.474C411.721,207.869,411.721,203.12,408.791,200.191z' />
                                                <path
                                                    d='M164.859,76.42c1.406,1.406,3.314,2.197,5.305,2.197c1.988,0,3.896-0.791,5.303-2.197l12.527-12.527   v78.262c0,1.99,0.789,3.896,2.195,5.305c1.406,1.406,3.315,2.195,5.305,2.195h20c4.141,0,7.5-3.357,7.5-7.5V63.893l12.527,12.527   c1.406,1.406,3.314,2.197,5.305,2.197c1.988,0,3.896-0.791,5.303-2.197l14.143-14.143c2.928-2.93,2.928-7.678,0-10.607   L210.797,2.197c-2.928-2.93-7.678-2.93-10.605,0L150.717,51.67c-1.463,1.465-2.195,3.385-2.195,5.305   c0,1.918,0.733,3.838,2.195,5.303L164.859,76.42z' />
                                                <path
                                                    d='M246.129,334.568c-1.406-1.406-3.315-2.197-5.303-2.197c-1.99,0-3.898,0.791-5.305,2.197   l-12.527,12.527v-78.262c0-4.143-3.359-7.5-7.5-7.5h-20c-1.99,0-3.898,0.789-5.305,2.195c-1.406,1.408-2.195,3.314-2.195,5.305   v78.262l-12.527-12.527c-1.406-1.406-3.314-2.197-5.303-2.197c-1.99,0-3.898,0.791-5.305,2.197l-14.143,14.143   c-1.463,1.465-2.195,3.385-2.195,5.303c0,1.92,0.733,3.84,2.195,5.305l49.475,49.473c2.928,2.93,7.678,2.93,10.605,0l49.475-49.473   c2.928-2.93,2.928-7.678,0-10.607L246.129,334.568z' />
                                            </g>
                                        </SvgIcon>
                                    </IconButton>
                                </div> : null

                        }
                    </div>
                    <div className={style.headerright}>
                        {(!disableClose && open) ?
                            <div className={style.controlsicon}>
                                <IconButton
                                    onTouchTap={function(e) {
                                        touchEnd(e, 'click', 'click', { type: 'opentoggle' })
                                    }}
                                    onTouchStart={function(e) {

                                    }}
                                    className={style.controlsiconbutton}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            : null
                        }
                    </div>
                </div>
                <div ref={function(e) {
                    setRef('content', e)
                }} className={(hideHeader) ? style.container + ' ' + style.containerwithoutheader : style.container}>
                    {children}
                </div>
            </div>
        )

    }
})

const Middle = createReactClass({
    setRef: function(a, e) {
        const { setRef } = this.props
        if (setRef) setRef(a, e)
    },
    render: function() {
        const setRef = this.setRef
        return (
            <FloatingPalette {...this.props} ref={(setRef) ? function(e) {
                setRef('floatingpalette', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})
