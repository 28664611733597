import React from 'react'
import createReactClass from 'create-react-class'
import Snackbar from 'material-ui/Snackbar'

export default createReactClass({
    getInitialState: function() {
        return {
            autoHideDuration: 3000,
            message: <div></div>,
            open: false
        }
    },
    open: function(message) {
        this.setState({
            message: message,
            open: true
        })
    },
    handleRequestClose: function() {
        this.setState({
            open: false
        })
    },
    render: function() {
        const { style = {}, className } = this.props

        return (
            <div>
                <Snackbar
                    style={style}
                    open={this.state.open}
                    message={this.state.message}
                    autoHideDuration={this.state.autoHideDuration}
                    onRequestClose={this.handleRequestClose}
                    className={className}
                />
            </div>
        )
    }
})
