export function contents({ curlang, posttypeTemplates, templates, name, c = { storedConfig: {} } }) {

    const meta = c.storedConfig.meta

    const {
        sitenametitle = '',
        description = ''
    } = curlang

    const {
        siteurl = '',
        sitename = '',
        siteimage = ''
    } = meta

    const r = {}
    Object.keys(posttypeTemplates).map(function(t) {
        const tname = (t !== 'post') ? name + t : name

        r[tname] = {
            title: curlang[t + name + 'title'] + sitenametitle,
            component: 'content',
            content: templates[name + t],
            siteurl,
            sitename,
            siteimage,
            description
        }
    })
    return r
}
