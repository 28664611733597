var carco = {}
if (!carco.functions) carco.functions = {}
if (!carco.functions.ccsplayer) carco.functions.ccsplayer = {}

var dev = false
var domain = (dev) ? 'carco.hu' : 'reeltime.no'

carco.functions.ccsplayer.browser = {
    /**
     * @return {number}
     */
    IE: function() {
        var rv = -1
        if (navigator.appName == 'Microsoft Internet Explorer') {
            var ua = navigator.userAgent
            var re = new RegExp('MSIE ([0-9]{1,}[\.0-9]{0,})')
            if (re.exec(ua) != null)
                rv = parseFloat(RegExp.$1)
        } else if (navigator.appName == 'Netscape') {
            var ua = navigator.userAgent
            var re = new RegExp('Trident/.*rv:([0-9]{1,}[\.0-9]{0,})')
            if (re.exec(ua) != null)
                rv = parseFloat(RegExp.$1)
        }
        return rv
    }
}

carco.functions.isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i)
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i)
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i)
    },
    Windows: function() {
        var ieMobile = navigator.userAgent.match(/IEMobile/i)
        if (!ieMobile) ieMobile = navigator.userAgent.match(/Tablet PC/i)
        return ieMobile
    },
    any: function() {
        return (this.Android() || this.BlackBerry() || this.iOS() || this.Opera() || this.Windows())
    }
}

carco.functions.ccsplayer.createHTML = function(params) {
    var object = document.createElement(params.type)
    if (!object.carco) object.carco = {}
    for (var c in params.carco) {
        object.carco[c] = params.carco[c]
    }
    for (var x in params.attr) {
        object.setAttribute(x, params.attr[x])
    }
    for (var y in params.vars) {
        object[y] = params.vars[y]
    }
    object.carco.itemtype = params.type
    return object
}

carco.functions.ccsplayer.getPlayer = function(script, callback, editor) {

    if (script && script.parentElement) {

        const protocol = 'https:' //window.location.protocol;

        if (!carco.functions.ccsplayer.addedstyle) {
            carco.functions.ccsplayer.styletag = document.createElement('link')
            carco.functions.ccsplayer.styletag.type = 'text/css'
            carco.functions.ccsplayer.styletag.rel = 'stylesheet'
            carco.functions.ccsplayer.styletag.href = protocol + '//app-flatchooser.' + domain + '/assets/ccsplayer.css'
            document.getElementsByTagName('head')[0].appendChild(carco.functions.ccsplayer.styletag)
            carco.functions.ccsplayer.addedstyle = true
        }

        var parent = script.parentElement
        var root = carco.functions.ccsplayer.createHTML({ type: 'div', carco: script.carco })
        root.carco.root = root
        parent.appendChild(root)

        var attr = {}
        var location = protocol + '//app-flatchooser.' + domain + '/'
        var host = location + 'player/'
        if (editor) host = location + 'editor/'

        if (window.location.origin == 'file://') {
            location = window.location.href + '/'
            if (location.match('index.html')) {
                location = location.split('index.html')[0]
            }
            host = location + 'player/'
            if (editor) host = location + 'editor/'
            host = host + 'index.html'
        }

        var dataid = ''
        var plusfiles = ''
        var width = '100%'
        var height = '450'
        var style = 'background:transparent;margin-left:auto;margin-right:auto;'
        var ccsresize = true
        var autosize = 'contain'
        var token = false
        var getdata = false
        var exchangedefaultdata = false
        var d = new Date()
        var app = 'charliestestapp'
        var time = d.getTime()
        var atts = root.carco.paramsdata.user

        for (var k in atts) {
            var v = atts[k]
            if (k !== 'token' && k !== 'plusfiles' && k !== 'data-id' && k !== 'type' && k !== 'name' && k !== 'src' && k !== 'height' && k !== 'width' && k !== 'style' && k !== 'autosize' && k !== 'app') attr[k] = v
            if (k == 'width') width = v
            if (k == 'height') height = v
            if (k == 'ccsresize') ccsresize = v
            if (k == 'host') host = v
            if (k == 'data-id') dataid = v
            if (k == 'plusfiles') plusfiles = v
            if (k == 'token') token = v
            if (k == 'getdata') getdata = v
            if (k == 'exchangedefaultdata') exchangedefaultdata = v
            if (k == 'style') style = style + ';' + v
            if (k == 'autosize') autosize = v
            if (k == 'app') app = v
        }

        if (dataid && time && app) {
            var ihost = host + '?app=' + app + '&data-id=' + dataid + '&' + time
            if (token) ihost = host + '?app=' + app + '&data-id=' + dataid + '&token=' + token + '&' + time
            if (plusfiles) {
                ihost = host + '?app=' + app + '&data-id=' + dataid + '&plusfiles=' + plusfiles + '&' + time
                if (token) ihost = host + '?app=' + app + '&data-id=' + dataid + '&plusfiles=' + plusfiles + '&token=' + token + '&' + time
            }

            if (!width.match('%')) width = width + 'px'
            if (!height.match('%')) height = height + 'px'
            style = style + ';width:' + width
            if (autosize == 'contain') style = style + ';max-width:' + '100%'
            if (autosize == 'contain') style = style + ';max-height:' + height + ';height:' + height

            var ip = carco.functions.ccsplayer.createHTML({
                type: 'div',
                attr: { style: style },
                carco: { root: root }
            })

            var n = time

            attr.style = 'vertical-align:bottom;display:block;margin-left:auto;margin-right:auto'
            attr.width = width
            attr.height = height
            attr.autosize = autosize
            attr.ccsresize = '1'
            attr.frameborder = '0'
            attr.allowfullscreen = ''
            attr.msallowfullscreen = ''
            attr.mozallowfullscreen = ''
            attr.webkitallowfullscreen = ''
            attr.src = ihost
            attr.class = 'charlies-iframe'
            attr.id = n + '_charlies-iframe'

            var fr = carco.functions.ccsplayer.createHTML({ type: 'iframe', attr: attr, carco: { root: root } })
            root.appendChild(ip)
            ip.appendChild(fr)

            fr.carco.n = n
            fr.carco.nid = n + '_charlies-iframe'
            fr.carco.parent = ip
            fr.carco.callback = callback

            fr.carco.getData = function(callback) {
                var frameid = fr.carco.nid
                var sd = frameid + '|||' + 'getData' + '|||' + 'getData'
                fr.carco.getDataCallback = callback
                if (frameid) {
                    carco.functions.ccsplayer.functions.sendmessage(frameid, sd)
                }
            }

            if (!document.body.carco) document.body.carco = {}
            if (!document.body.carco.addedgloballiteners) {
                window.addEventListener('message', carco.functions.ccsplayer.resize.receivemessage, false)
                window.addEventListener('message', carco.functions.ccsplayer.resize.resizesmf, false)
                window.addEventListener('message', carco.functions.ccsplayer.functions.player.receivemessage, false)
                if (carco.functions.ccsplayer.functions.admin.receivemessage) {
                    window.addEventListener('message', carco.functions.ccsplayer.functions.admin.receivemessage, false)
                }

                window.addEventListener('keydown', carco.functions.ccsplayer.functions.player.keys, false)
                window.addEventListener('keyup', carco.functions.ccsplayer.functions.player.keys, false)

                document.addEventListener('webkitfullscreenchange', carco.functions.ccsplayer.functions.fullscreen, false)
                document.addEventListener('mozfullscreenchange', carco.functions.ccsplayer.functions.fullscreen, false)
                document.addEventListener('fullscreenchange', carco.functions.ccsplayer.functions.fullscreen, false)
                document.addEventListener('MSFullscreenChange', carco.functions.ccsplayer.functions.fullscreen, false)

                document.body.carco.addedgloballiteners = true
            }

            carco.functions.ccsplayer.resize.resize()
            return fr
        }
    }
}

if (!carco.functions.ccsplayer.resize) {
    carco.functions.ccsplayer.resize = {

        bh: function(f, hh) {
            if (f && typeof f == 'object' && f.toString() == '[object HTMLIFrameElement]') {
                if (!hh && f && f.carco && f.carco.height) {
                    var h = f.carco.height
                } else {
                    var h = hh
                }

                var asi = f.getAttribute('autosize')
                if (asi && h) {
                    if (asi == 'height') {
                        var hp = h + 'px'
                        f.style.height = hp
                        if (!f.carco) f.carco = {}
                        if (!f.carco.addedtransistion) {
                            f.carco.addedtransistion = true
                            f.style['-webkit-transition'] = 'height 100ms'
                            f.style['-moz-transition'] = 'height 100ms'
                            f.style['-o-transition'] = 'height 100ms'
                            f.style['transition'] = 'height 100ms'
                        }
                        f.setAttribute('height', h)
                        f.parentElement.style.maxWidth = '100%'
                    }
                    if (asi == 'contain') {

                        function setsize(owidth, oheight, maxwidth, maxheight, f) {
                            var rw = owidth / maxwidth
                            var rh = oheight / maxheight
                            var ma = false
                            var nw = owidth
                            var nh = oheight

                            if (rw < rh) {
                                nh = maxheight
                                nw = owidth / rh
                            }

                            if (rw > rh) {
                                nw = maxwidth
                                nh = oheight / rw
                                ma = true
                            }

                            nw = Math.floor(nw)
                            nh = Math.floor(nh)

                            var hp = nh + 'px'
                            f.setAttribute('height', nh)
                            f.style.height = hp

                            var wp = nw + 'px'
                            f.setAttribute('width', nw)
                            f.style.width = wp

                            if (ma) {
                                var mat = (maxheight - nh) / 2
                                f.parentElement.style.position = 'relative'
                                f.style.position = 'absolute'
                                f.style.marginTop = mat + 'px'
                            } else {
                                f.style.position = 'relative'
                                f.style.marginTop = 0 + 'px'
                            }

                        }

                        var maxheight = f.parentElement.offsetHeight
                        var maxwidth = f.parentElement.offsetWidth

                        var owidth = f.offsetWidth
                        var oheight = h

                        setsize(owidth, oheight, maxwidth, maxheight, f)
                    }
                }
            }
        },
        sendid: function(f) {
            if (f.target) f = f.target
            if (f.toString() !== '[object HTMLIFrameElement]' && f.path && f.path[0] && typeof f.path[0] == 'object' && f.path[0].toString() == '[object HTMLIFrameElement]') f = f.path[0]
            f.carco.loaded = true
            f.carco.item = f
            var nid = f.carco.nid
            f.contentWindow.postMessage(nid, f.src)
        },

        resizesmf: function(e) {
            if (e && e.origin == 'http://app-flatchooser.' + domain + '' ||
                e && e.origin == 'https://app-flatchooser.' + domain + '' ||
                !e || e && e.origin == window.location.origin) {
                window.removeEventListener('message', carco.functions.ccsplayer.resize.resizesmf, false)
                setTimeout(function() {
                    var fs = document.getElementsByTagName('iframe')
                    for (var i = 0; i < fs.length; i++) {
                        if (fs[i].getAttribute) {
                            if (fs[i].getAttribute('ccsresize') && fs[i].getAttribute('autosize') == 'contain') {
                                var f = fs[i]
                                if (f.offsetWidth < f.parentElement.offsetWidth - 5 &&
                                    f.offsetHeight < f.parentElement.offsetHeight - 5) {
                                    fs[i].style.width = '100%'
                                }
                            }
                        }
                    }
                    window.addEventListener('message', carco.functions.ccsplayer.resize.resizesmf, false)
                }, 500)
            }
        },

        resize: function(ff, hh) {
            if (ff && typeof ff == 'object' && ff.toString() == '[object HTMLIFrameElement]') {
                carco.functions.ccsplayer.resize.bh(ff, hh)
            } else {
                var fs = document.getElementsByTagName('iframe')
                for (var i = 0; i < fs.length; i++) {
                    if (fs[i].getAttribute) {
                        if (fs[i].getAttribute('ccsresize')) {
                            var f = fs[i]
                            if (!f.carco) f.carco = {}
                            if (!f.carco.addedonload) {
                                f.carco.addedonload = true
                                f.onload = function(a) {
                                    carco.functions.ccsplayer.resize.sendid(a)
                                }
                            }
                            if (f.carco.loaded) {
                                carco.functions.ccsplayer.resize.bh(f, hh)
                            }
                        }
                    }
                }
            }
        },
        receivemessage: function(e) {
            if (e && e.origin == 'http://app-flatchooser.' + domain + '' ||
                e && e.origin == 'https://app-flatchooser.' + domain + '' ||
                e && e.origin == window.location.origin) {
                var data = e.data
                var dataa = data.split('|||')
                var frameid = dataa[0]
                var type = dataa[1]
                var height = dataa[2]
                var frame = document.getElementById(frameid)
                if (!frame) {
                    var fs = document.getElementsByTagName('iframe')
                    for (var i = 0; i < fs.length; i++) {
                        if (fs[i].carco && fs[i].carco.nid == frameid) frame = fs[i]
                    }
                }

                if (frame && frame.carco && frame.carco.callback) frame.carco.callback()

                if (frame && type == 'height') {
                    if (!frame.carco) frame.carco = {}
                    frame.carco.height = height
                    carco.functions.ccsplayer.resize.bh(frame, height)
                }
            }
        }

    }

}

carco.functions.ccsplayer.functions = {
    sendmessage: function(frameid, data) {
        if (frameid) {
            var f = document.getElementById(frameid)
            if (f) {
                f.contentWindow.postMessage(data, f.src)
            }
        }
    },
    admin: {
        'ccs-wp-upload-image-button': function(frameid, type, data) {
            if (window.self !== window.top) {
                window.top.postMessage(frameid + '|||' + 'wpuploadimage' + '|||' + data, document.referrer)
            } else {
                var image_url = prompt('Please enter image url', 'http://')
                if (image_url) {
                    var sd = data + '|||' + type + '|||' + image_url
                    carco.functions.ccsplayer.functions.sendmessage(frameid, sd)
                }
            }
        },
        wpuploadimage: function(frameid, type, data, data2) {
            var sd = data + '|||' + 'ccs-wp-upload-image-button' + '|||' + data2
            carco.functions.ccsplayer.functions.sendmessage(frameid, sd)
        },
        receivemessage: function(e) {
            var edata = e.data
            var dataa = edata.split('|||')

            var frameid = dataa[0]
            var type = dataa[1]
            var data = dataa[2]
            var data2 = dataa[3]

            if (e && e.origin == 'http://app-flatchooser.' + domain + '' ||
                e && e.origin == 'https://app-flatchooser.' + domain + '' ||
                type == 'wpuploadimage' ||
                e && e.origin == window.location.origin) {
                var frame = document.getElementById(frameid)
                if (frame && type) {
                    if (carco.functions.ccsplayer.functions.admin[type]) {
                        carco.functions.ccsplayer.functions.admin[type](frameid, type, data, data2)
                    }
                }
            }
        },
        'data': function(frameid, type, data, e) {
            var f = document.getElementById(frameid)
            if (f) {
                if (f.carco.getDataCallback) f.carco.getDataCallback(data)
            }
        }
    },
    player: {
        keys: function(e) {
            var frames = document.getElementsByTagName('iframe')
            if (frames && frames[0]) {
                for (var x = 0; x < frames.length; x++) {
                    var frameid = false
                    if (frames[x] && frames[x].carco) {
                        frameid = frames[x].carco.nid
                        var sd = frameid + '|||' + e.type + 'Handler|||' + e.keyCode
                        if (frameid) {
                            carco.functions.ccsplayer.functions.sendmessage(frameid, sd)
                        }
                    }
                }
            }
        },
        popup: function(frameid, type, data) {
            var frame = document.getElementById(frameid)
            if (frame) {
                if (!frame.parentElement && frame.parentNode) frame.parentElement = frame.parentNode
                var parent = frame.parentElement
                if (!parent.carco) parent.carco = {}
                if (!parent.carco.popup) {
                    parent.style.position = 'relative'

                    parent.carco.popup = carco.functions.ccsplayer.createHTML({
                        type: 'div',
                        attr: { class: 'ccs-popup-bg' },
                        carco: { root: parent }
                    })
                    parent.carco.popupi = carco.functions.ccsplayer.createHTML({
                        type: 'div',
                        attr: { class: 'ccs-popup-block' },
                        carco: { root: parent }
                    })
                    parent.carco.popupit = carco.functions.ccsplayer.createHTML({
                        type: 'div',
                        attr: { class: 'ccs-popup-text' },
                        carco: { root: parent }
                    })
                    parent.carco.popupibt = carco.functions.ccsplayer.createHTML({
                        type: 'div',
                        attr: { class: 'ccs-popup-button-ok' },
                        vars: { innerHTML: 'Ok' },
                        carco: { root: parent }
                    })
                    parent.carco.popupibf = carco.functions.ccsplayer.createHTML({
                        type: 'div',
                        attr: { class: 'ccs-popup-button-cancel' },
                        vars: { innerHTML: 'Cancel' },
                        carco: { root: parent }
                    })

                    parent.appendChild(parent.carco.popup)
                    parent.carco.popup.appendChild(parent.carco.popupi)
                    parent.carco.popupi.appendChild(parent.carco.popupit)
                    parent.carco.popupi.appendChild(parent.carco.popupibf)
                    parent.carco.popupi.appendChild(parent.carco.popupibt)

                    parent.carco.popupibt.addEventListener('click', function(e) {
                        parent.carco.popup.style.display = 'none'
                        parent.carco.popupit.innerHTML = ''
                        carco.functions.ccsplayer.functions.player[type](parent.carco.popup.carco.data.frameid, parent.carco.popup.carco.data.type, parent.carco.popup.carco.data.data, e)
                    }, false)

                    parent.carco.popupibf.addEventListener('mousedown', function() {
                        parent.carco.popup.style.display = 'none'
                        parent.carco.popupit.innerHTML = ''
                    }, false)

                    parent.carco.popup.addEventListener('mousedown', function(e) {
                        if (e.target == parent.carco.popup) {
                            parent.carco.popup.style.display = 'none'
                            parent.carco.popupit.innerHTML = ''
                        }
                    }, false)
                }

                var message = ''
                if (type == 'ccs-fullscreen') message = 'Fullscreen'
                parent.carco.popupit.innerHTML = message
                parent.carco.popup.style.display = 'block'
                parent.carco.popup.carco.data = {
                    frameid: frameid,
                    type: type,
                    data: data
                }

            }

        },
        receivemessage: function(e, fn) {
            if (e && e.origin == 'http://app-flatchooser.' + domain + '' ||
                e && e.origin == 'https://app-flatchooser.' + domain + '' ||
                e && e.origin == window.location.origin) {
                var edata = e.data
                var dataa = edata.split('|||')

                var frameid = dataa[0]
                var type = dataa[1]
                var data = dataa[2]

                var frame = document.getElementById(frameid)
                if (frame && type) {
                    if (carco.functions.ccsplayer.functions.player[type]) {

                        var fs = false
                        if (document.fullscreenElement ||
                            document.webkitFullscreenElement ||
                            document.mozFullScreenElement ||
                            document.msFullscreenElement) {
                            fs = true
                        }

                        if (type == 'ccs-fullscreen' && !fs) {
                            carco.functions.ccsplayer.functions.player['popup'](frameid, type, data)
                        } else {
                            carco.functions.ccsplayer.functions.player[type](frameid, type, data)
                        }
                    }
                }
            }
        },
        'ccs-fullscreen': function(frameid, type, data, e) {

            var f = document.getElementById(frameid)
            if (!f.parentElement && f.parentNode) f.parentElement = f.parentNode

            if (f) {
                document.body.carco.globalfullscreendatas = {
                    frameid: frameid,
                    type: type,
                    data: data
                }
                var on = false
                if (
                    document.fullscreenElement ||
                    document.webkitFullscreenElement ||
                    document.mozFullScreenElement ||
                    document.msFullscreenElement
                ) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen()
                    } else if (document.webkitExitFullscreen) {
                        document.webkitExitFullscreen()
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen()
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen()
                    }
                } else if (
                    document.fullscreenEnabled ||
                    document.webkitFullscreenEnabled ||
                    document.mozFullScreenEnabled ||
                    document.msFullscreenEnabled
                ) {
                    on = true
                    var fsdiv = f.parentElement
                    if (fsdiv.requestFullscreen) {
                        fsdiv.requestFullscreen()
                    } else if (fsdiv.webkitRequestFullscreen) {
                        fsdiv.webkitRequestFullscreen()
                    } else if (fsdiv.mozRequestFullScreen) {
                        fsdiv.mozRequestFullScreen()
                    } else if (fsdiv.msRequestFullscreen) {
                        fsdiv.msRequestFullscreen()
                    }

                }

                if (!f.carco) f.carco = {}
                if (!f.parentElement.carco) f.parentElement.carco = {}

                if (f.carco.fsscrollbarint) clearInterval(f.carco.fsscrollbarint)

                if (on) {
                    f.carco.fsscrollbarint = setInterval(function() {
                        var oft = 'hidden'
                        if (f.parentElement.offsetHeight < f.offsetHeight) {
                            oft = 'scroll'
                        }

                        if (f.carco.lastheight > f.offsetHeight + 100 || f.carco.lastheight < f.offsetHeight - 100) {
                            f.carco.overflow = false
                        }
                        if (f.carco.overflow) {
                        } else {
                            if (f.carco.overflow !== oft) {
                                f.carco.lastheight = f.offsetHeight
                                f.carco.overflow = oft
                                f.parentElement.style.overflowY = oft
                                if (!f.parentElement.carco.tempwidth) f.parentElement.carco.tempwidth = f.parentElement.style.width
                                if (!f.parentElement.carco.tempwidth) f.parentElement.carco.tempwidth = '100%'
                                if (!f.carco.tempwidth) f.carco.tempwidth = f.getAttribute('width')
                                if (!f.carco.tempwidth) f.carco.tempwidth = '100%'
                                f.parentElement.style.width = '100%'
                                f.setAttribute('width', '100%')
                            }
                        }
                    }, 1000)
                } else {
                    if (f.carco.tempwidth) f.setAttribute('width', f.carco.tempwidth)
                    if (f.parentElement.carco.tempwidth) f.parentElement.style.width = f.parentElement.carco.tempwidth
                    f.parentElement.style.overflowY = 'hidden'
                }

            }
        }
    },
    fullscreen: function(e) {

        if (document.body.carco.globalfullscreendatas) {

            var frameid = document.body.carco.globalfullscreendatas.frameid
            var type = document.body.carco.globalfullscreendatas.type
            var data = document.body.carco.globalfullscreendatas.data

            if (document.fullscreenElement ||
                document.webkitFullscreenElement ||
                document.mozFullScreenElement ||
                document.msFullscreenElement) {
                var sd = data + '|||' + type + '|||' + 'on'
            } else {
                var sd = data + '|||' + type + '|||' + 'off'
            }
            carco.functions.ccsplayer.functions.sendmessage(frameid, sd)

        }
    }
}


export default carco

