import { backgroundColor, getObject, image } from './basic'

export default {
    'type': 'object',
    'hidden': true,
    'fields': {
        'props': {
            'type': 'object',
            'hidden': true,
            'fields': {
                'views': {
                    'type': 'array',
                    'fieldsName': function(data, props) {

                        const objects = props.datas[2].objects.props.objects

                        const view_name = data.name
                        const object_id = data.object_id
                        let foundObject = null
                        if (object_id) {
                            objects.map(function(o) {
                                if (object_id == o.key) foundObject = o
                            })
                        }
                        const name = (foundObject) ? foundObject.name : ''

                        let rName = data.key
                        if (view_name) rName = view_name
                        if (name) rName = rName + ' [' + name + ']'
                        return rName

                    },
                    'name': 'root',
                    'add': function() {
                        return function(props) {
                            const ID = 'view_' + new Date().getTime()
                            return {
                                'name': '',
                                'object_id': 'area',
                                'key': ID,
                                'elements': [{
                                    'component': 'Root',
                                    'props': {
                                        'id': 'root_' + ID,
                                        'scale': false,
                                        'width': 1920,
                                        'height': 1080,
                                        'customStyle': {
                                            'backgroundColor': 'rgba(0,0,0,0)'
                                        },
                                        'innerClassName': null,
                                        'enableComponentsAddProps': ['SvgImage', 'Hotspots']
                                    },
                                    'children': [
                                        {
                                            'component': 'Container',
                                            'props': {
                                                'autosize': 'contain',
                                                'scale': false,
                                                'width': 1920,
                                                'height': 1080,
                                                'autopositionx': 'center',
                                                'autopositiony': 'center',
                                                'boundingx': 'center',
                                                'boundingy': 'center',
                                                'customStyle': {
                                                    'backgroundColor': 'rgba(0,0,0,0)'
                                                },
                                                'innerClassName': null,
                                                'enableComponentsAddProps': ['SvgImage']
                                            },
                                            'children': [
                                                {
                                                    'component': 'SvgImage',
                                                    'jsoneditor': {
                                                        'schema': 'image',
                                                        'name': 'Background',
                                                        'update': [{
                                                            id: 'root_' + ID + '_background',
                                                            data: { 'deep': 1, 'key': 'props' }
                                                        }]
                                                    },
                                                    'props': {
                                                        'id': 'root_' + ID + '_background',
                                                        'enableSetDimensions': [
                                                            { 'id': 'parent', 'keepwidth': false },
                                                            { 'id': 'root', 'keepwidth': true }
                                                        ],
                                                        'image': '',
                                                        'filters': {},
                                                        'width': 1920,
                                                        'height': 1080,
                                                        'containerStyle': {
                                                            'backgroundColor': 'rgba(0,0,0,1)'
                                                        }
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            'component': 'Container',
                                            'props': {
                                                'autosize': 'contain',
                                                'scale': true,
                                                'relativewidth': '100%',
                                                'relativeheight': '100%',
                                                'autopositionx': 'center',
                                                'autopositiony': 'center',
                                                'boundingx': 'center',
                                                'boundingy': 'center',
                                                'customStyle': {
                                                    'backgroundColor': 'rgba(0,0,0,0)'
                                                },
                                                'innerClassName': null,
                                                'enableComponentsAddProps': ['Hotspots']
                                            },
                                            'children': [
                                                {
                                                    'component': 'Hotspots',
                                                    'jsoneditor': {
                                                        'schema': 'hotspot',
                                                        'name': 'Hotspots',
                                                        'update': [{
                                                            id: 'hotspots_' + ID,
                                                            data: { 'deep': 2, 'key': 'hotspots' }
                                                        }]
                                                    },
                                                    'props': {
                                                        'id': 'hotspots_' + ID,
                                                        'editable': {
                                                            'resize': 1,
                                                            'position': 1
                                                        },
                                                        'hotspots': []
                                                    }
                                                }
                                            ]
                                        }
                                    ]
                                }]
                            }
                        }
                    },
                    'fields': {},
                    'update': async function(p = {}) {
                        const { props = {}, data = {} } = p
                        const root = props.root
                        await root.refElements.settings.regenerateForm()
                        await root.refElements.animations.regenerateForm()

                        const rootProps = root.props
                        await rootProps.setCurrentView({ view_id: data.views[data.views.length - 1].key })
                        rootProps.updateCompass(data)
                    },
                    'innerFormProps': {
                        'onOpen': function() {
                            return async function(p = {}) {
                                const { props = {} } = p
                                const root = props.root
                                const rootProps = root.props
                                const { refElements = {} } = root
                                refElements.metas.close()
                                refElements.objects.close()
                                refElements.settings.close()
                                refElements.animations.close()
                                refElements.galleries.close()
                                await rootProps.setPlayerTypeToEditor()
                            }
                        },
                        'onClose': function() {
                            return async function(p = {}) {
                                const { props = {} } = p
                                const root = props.root
                                const rootProps = root.props
                                await rootProps.setPlayerTypeToPlayer()
                            }
                        }
                    },
                    'autoFields': {
                        'enable': {
                            'default': {
                                'type': 'object',
                                'remove': function({ data, key }) {
                                    if (data[key].key == 'area') return false
                                    return true
                                },
                                'innerFormProps': {
                                    'onOpen': function() {
                                        return async function(p = {}) {
                                            const { props = {}, data = {}, key = 0 } = p
                                            const close = []
                                            const d = (data[key]) ? data[key] : data
                                            const { __jsoneditorId } = d
                                            Object.keys(props.parent.refElements).map(function(k) {
                                                if (k !== __jsoneditorId && props.parent.refElements[k] && props.parent.refElements[k].close) {
                                                    close.push(props.parent.refElements[k].close)
                                                }
                                            })
                                            await Promise.all(close.map(async function(c) {
                                                await c()
                                            }))

                                            const root = props.root
                                            const rootProps = root.props
                                            await rootProps.setCurrentView({ view_id: data[key].key })
                                        }
                                    },
                                    'onClickTitle': function() {
                                        return async function(p = {}) {
                                            const { props = {}, data = {}, key = 0 } = p
                                            const root = props.root
                                            const rootProps = root.props
                                            await rootProps.setCurrentView({ view_id: data[key].key })
                                        }
                                    }
                                },
                                'fields': {
                                    'name': {
                                        'type': 'string',
                                        'name': 'View name (optional)',
                                        'default': '',
                                        'required': false,
                                        'validations': [
                                            {
                                                'name': 'max',
                                                'props': { 'value': 250, 'type': 'string' }
                                            }, {
                                                'name': 'type',
                                                'props': { 'value': ['string'] }
                                            }
                                        ],
                                        'update': async function(p = {}) {
                                            const { props = {}, data = {} } = p

                                            const parent = props.parent
                                            await parent.regenerateForm()

                                            const root = props.root
                                            const rootProps = root.props
                                            await rootProps.setCurrentView({ view_id: data.key })
                                            await root.refElements.settings.regenerateForm()
                                            await root.refElements.animations.regenerateForm()
                                        }
                                    },
                                    'object_id': {},
                                    'key': {
                                        'type': 'string',
                                        'default': 'view_key',
                                        'disabled': true,
                                        'validations': [
                                            {
                                                'name': 'min',
                                                'props': { 'value': 2, 'type': 'string' }
                                            }, {
                                                'name': 'max',
                                                'props': { 'value': 250, 'type': 'string' }
                                            }, {
                                                'name': 'type',
                                                'props': { 'value': ['string'] }
                                            }
                                        ]
                                    },
                                    'compass': {
                                        'component': 'select',
                                        'type': 'string',
                                        'name': 'Compass',
                                        'default': null,
                                        'options': [
                                            { 'label': 'Not set', 'value': null },
                                            { 'label': 'North', 'value': 'north' },
                                            { 'label': 'North East', 'value': 'northeast' },
                                            { 'label': 'East', 'value': 'east' },
                                            { 'label': 'South East', 'value': 'southeast' },
                                            { 'label': 'South', 'value': 'south' },
                                            { 'label': 'South West', 'value': 'southwest' },
                                            { 'label': 'West', 'value': 'west' },
                                            { 'label': 'North West', 'value': 'nortwest' }
                                        ],
                                        'multiple': false,
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['object'] }
                                            }, {
                                                'name': 'arrayin',
                                                'props': { 'value': [null, 'north', 'northeast', 'east', 'southeast', 'south', 'southwest', 'west', 'nortwest'] }
                                            }
                                        ],
                                        'update': async function(p = {}) {
                                            const { props = {}, data = {}, datas = {} } = p

                                            const parent = props.parent
                                            await parent.regenerateForm()

                                            const root = props.root
                                            const rootProps = root.props

                                            rootProps.updateCompass(data)
                                        }
                                    },
                                    'hidden': {
                                        'type': 'boolean',
                                        'default': false,
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['boolean'] }
                                            }
                                        ]
                                    },
                                    'elements': {
                                        'type': 'object',
                                        'hidden': true
                                    },
                                    'gallery': {},
                                    'thumbnail': {
                                        'type': 'object',
                                        'name': 'Thumbnail (v3)',
                                        'fields': {
                                            'props': {
                                                'type': 'object',
                                                'hidden': true,
                                                'fields': {
                                                    'image': image,
                                                    'width': {
                                                        'type': 'number',
                                                        'validations': [
                                                            {
                                                                'name': 'type',
                                                                'props': { 'value': ['number'] }
                                                            }, {
                                                                'name': 'min',
                                                                'props': { 'value': 0, 'type': 'number' }
                                                            }
                                                        ]
                                                    },
                                                    'height': {
                                                        'type': 'number',
                                                        'validations': [
                                                            {
                                                                'name': 'type',
                                                                'props': { 'value': ['number'] }
                                                            }, {
                                                                'name': 'min',
                                                                'props': { 'value': 0, 'type': 'number' }
                                                            }
                                                        ]
                                                    },
                                                    'containerStyle': getObject({
                                                        'hidden': true,
                                                        'fields': {
                                                            'backgroundColor': backgroundColor
                                                        }
                                                    })
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            'dinamic': function(p = {}) {

                                const r = {
                                    'object_id': {
                                        'component': 'select',
                                        'type': 'string',
                                        'name': 'Object ID',
                                        'default': null,
                                        'options': [
                                            { 'label': 'No object', 'value': null },
                                            { 'label': 'Area', 'value': 'area' }
                                        ],
                                        'multiple': false,
                                        'disabled': function({ data, key }) {
                                            if (data['key'] == 'area') return true
                                            return false
                                        },
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['object'] }
                                            }, {
                                                'name': 'arrayin',
                                                'props': { 'value': [null, 'area'] }
                                            }
                                        ],
                                        'update': async function(p = {}) {
                                            const { props = {}, data = {} } = p

                                            const parent = props.parent
                                            await parent.regenerateForm()

                                            const root = props.root
                                            const rootProps = root.props
                                            await rootProps.setCurrentView({ view_id: data.key })
                                            await root.refElements.settings.regenerateForm()
                                            await root.refElements.animations.regenerateForm()
                                            rootProps.updateCompass(data)

                                        }
                                    },
                                    'gallery': {
                                        'type': 'object',
                                        'name': 'Gallery',
                                        'fields': {
                                            'gallery_id': {
                                                'component': 'select',
                                                'type': 'string',
                                                'name': 'Gallery ID',
                                                'default': null,
                                                'options': [
                                                    { 'label': 'No object', 'value': null }
                                                ],
                                                'multiple': false,
                                                'disabled': function({ data, key }) {
                                                    return false
                                                },
                                                'validations': [
                                                    {
                                                        'name': 'type',
                                                        'props': { 'value': ['object'] }
                                                    }, {
                                                        'name': 'arrayin',
                                                        'props': { 'value': [null] }
                                                    }
                                                ]
                                            }
                                        }
                                    }
                                }

                                const data = p.data[p.key][p.i]
                                const objects = p.parentData.datas[p.deep - 2].objects.props.objects

                                if (objects && objects.length) {
                                    objects.map(function(object) {
                                        const key = object.key
                                        const name = object.name
                                        if (key !== 'area') {
                                            r['object_id'].options.push({
                                                label: name,
                                                value: key
                                            })
                                            r['object_id'].validations[1].props.value.push(key)
                                        } else {
                                            r['object_id'].options[1].label = name
                                        }
                                    })
                                }

                                const galleries = (p.parentData.datas[p.deep - 3].props.galleries) ? p.parentData.datas[p.deep - 3].props.galleries.props.galleries : null
                                if (galleries && galleries.length) {
                                    galleries.map(function(gallery) {

                                        const key = gallery.key
                                        const gallery_name = gallery.name

                                        const object_id = gallery.object_id
                                        let foundObject = null
                                        if (object_id) {
                                            objects.map(function(o) {
                                                if (object_id == o.key) foundObject = o
                                            })
                                        }

                                        const name = (foundObject) ? foundObject.name : ''

                                        let rName = key
                                        if (gallery_name) rName = gallery_name
                                        if (name) rName = rName + ' [' + name + ']'

                                        r.gallery.fields['gallery_id'].options.push({
                                            label: rName,
                                            value: key
                                        })

                                        r.gallery.fields['gallery_id'].validations[1].props.value.push(key)
                                    })
                                }

                                return { fields: r }
                            }
                        }
                    }
                }
            }
        }
    }
}
