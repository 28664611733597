import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

const images = {}

const SvgImage = createReactClass({
    getInitialState: function() {

        this.refElements = {}
        const data = this.getStateData()

        return {
            isMounted: false,
            loaded: false,
            ...data
        }
    },
    getStateData: function(data) {

        const p = (data) ? data : this.props
        const state = this.state || {}
        const currentimage = state.image

        const {
            width = 0,
            height = 0,
            image,
            filters = {}
        } = p

        const {
            colorise = 'rgba(0, 0, 0, 0)',
            hue = 0,            //-100 - 100
            saturation = 0,     //-100 - 100
            contrast = 0,       //-100 - 100
            blur = 0,           //0 - 30
            red = 255,          //1 - 255
            blue = 255,         //1 - 255
            green = 255,        //1 - 255
            opacity = 1,        //0 - 1
            blendMode = null
        } = filters

        const i = this.getImageUrl(image)
        const loaded = (currentimage !== i) ? false : true

        return {
            width: width,
            height: height,
            image: i,
            hue: hue,
            colorise: colorise,
            saturation: saturation,
            contrast: contrast,
            blur: blur,
            red: red,
            blue: blue,
            green: green,
            opacity: opacity,
            blendMode: blendMode,
            loaded: loaded
        }
    },
    updateContainer: function(data) {
        const { parent } = this.props
        if (parent && data) {
            parent.setChildProps({
                ...data
            })
        }
    },
    isChanged: function(a, b) {
        let changed = false
        const isChanged = this.isChanged
        if (a && b && typeof a == 'object' && typeof b == 'object') {
            const keys = (a.length == undefined) ? Object.keys(a) : [...a.keys()]
            keys.map(function(key) {
                if (typeof a[key] !== typeof b[key]) changed = true
                if (!changed) {
                    if (a[key] && typeof a[key] == 'object') {
                        changed = isChanged(a[key], b[key])
                    } else {
                        if (a[key] !== b[key]) changed = true
                    }
                }
            })
        } else {
            if (typeof a !== typeof b) changed = true
            if (a !== b) changed = true
        }
        return changed
    },
    shouldComponentUpdate: function(nextProps, nextState) {
        const state = this.state
        const changed1 = this.isChanged(state, nextState)
        const changed2 = (changed1) ? changed1 : this.isChanged(nextState, state)
        if (changed1 || changed2) {
            return true
        }
        return false
    },

    getImageUrl: function(image) {
        if (image && image.match('url')) {
            return image.match(/\((.*?)\)/)[1].replace(/('|")/g, '')
        }
        if (image) return image
        return ''
    },
    componentDidMount: function() {
        this.state.isMounted = true
        this.setState({
            isMounted: true
        })
        this.setUrlToSvgImages()
        const setDimensions = this.setDimensions
        setTimeout(function() {
            setDimensions()
        })
    },
    setUrlToSvgImages: function() {
        const { image } = this.state
        if (image) {
            if (this.refElements['svg']) this.refElements['svg'].setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink')
            if (this.refElements['image']) this.refElements['image'].setAttributeNS('http://www.w3.org/1999/xlink', 'href', image)
            if (this.refElements['mask']) this.refElements['mask'].setAttributeNS('http://www.w3.org/1999/xlink', 'href', image)
        }
    },
    componentWillUnmount: function() {
        this.state.isMounted = false
        this.setState({
            isMounted: false
        })
    },
    setImageDimensions: function({ callback }) {

        const container = this.refElements['container']
        const { width, height, image } = this.state

        if (width && height) {

            if (callback) {
                callback({
                    width: width,
                    height: height
                })
            }

        } else if (image) {
            if (images[image]) {
                const imageelement = images[image]
                const width = imageelement.width
                const height = imageelement.height

                if (callback) {
                    callback({
                        width: width,
                        height: height
                    })
                }
            } else {
                let imageelement = new Image()
                images[image] = imageelement
                imageelement.onload = function() {

                    const width = imageelement.width
                    const height = imageelement.height
                    images[image] = { width: width, height: height }

                    if (callback) {
                        callback({
                            width: width,
                            height: height
                        })
                    }


                    imageelement.src = ''
                    imageelement = null
                }
                imageelement.src = image
            }
        }
    },
    setDimensions: async function() {

        const t = this
        const { disableSetDimensions, parent = {}, enableSetDimensions = [] } = t.props
        const container = t.refElements['container']

        const parentProps = parent.props || {}
        const { root } = parentProps
        const rootRefElements = (root) ? root.refElements : {}

        if (container && container.parentElement) {

            t.setImageDimensions({
                callback: async function(newdimensions = {}) {

                    const { width, height, loaded, isMounted, image } = t.state
                    const cwidth = newdimensions.width
                    const cheight = newdimensions.height

                    if (isMounted && cwidth && cwidth !== width ||
                        isMounted && cwidth && cwidth !== height ||
                        isMounted && !loaded ||
                        isMounted && loaded !== image) {

                        t.setState({
                            width: cwidth,
                            height: cheight,
                            loaded: image
                        })

                        if (enableSetDimensions && enableSetDimensions.length && rootRefElements) {
                            const elements = { ...rootRefElements, root: root, parent: parent }
                            await Promise.all(enableSetDimensions.map(async function(props = {}) {
                                const { id } = props
                                if (elements[id] && elements[id].setDimensions) {

                                    await elements[id].setDimensions({
                                        dimensions: {
                                            width: cwidth,
                                            height: cheight
                                        },
                                        ...props
                                    })
                                }
                            }))
                        }

                    }

                }
            })
        }

    },
    setRef: function(a, e, n) {
        if (e) {
            if (n) {
                this[n][a] = e
            } else {
                this.refElements[a] = e
            }
        }
    },
    getFilters: function() {

        const {
            colorise,
            hue,
            saturation,
            contrast,
            blur,
            red,
            blue,
            green,
            opacity,
            blendMode
        } = this.state

        const rcolorise = colorise
        const rhue = 210 / 200 * (hue + 100) - 105
        const rsaturation = (saturation < 14.28) ? saturation / 100 + 1 : Math.round((saturation / 14.28) * 100) / 100
        const rcontrast = (contrast < 20) ? (contrast / 100 + 1) : Math.round((contrast / 20) * 100) / 100
        const rblur = blur / 7
        const rred = red / 255
        const rgreen = green / 255
        const rblue = blue / 255
        const ropacity = opacity
        const rblendMode = (blendMode) ? { mixBlendMode: blendMode } : {}

        return {
            colorise: rcolorise,
            hue: rhue,
            saturation: rsaturation,
            contrast: rcontrast,
            blur: rblur,
            red: rred,
            blue: rblue,
            green: rgreen,
            opacity: ropacity,
            blendMode: rblendMode
        }
    },
    render: function() {
        const {
            style,
            containerStyle = {},
            disableSvgBgImageStyle = {},
            preserveAspectRatio = 'none',
            svgStyle = {},
            forceSvg
        } = this.props
        const { image, width, height } = this.state
        const setRef = this.setRef

        const {
            colorise,
            hue,
            saturation,
            contrast,
            blur,
            red,
            blue,
            green,
            opacity,
            blendMode
        } = this.getFilters()

        const disableSvg = (colorise == 'rgba(0,0,0,0)' && hue == 0 && saturation == 1 && contrast == 1 && blur == 0 && red == 1 && blue == 1 && green == 1 && !forceSvg) ? true : false

        const rcontainerStyle = { ...containerStyle }
        const rsvgStyle = { opacity: opacity, ...svgStyle }

        const ID = this._reactInternalInstance._debugID

        return (
            <div ref={function(e) {
                setRef('container', e)
            }} className={style.svgimage} style={rcontainerStyle}>
                {(image && disableSvg) ? <div className={style.backgroundimage} style={{
                    backgroundImage: 'url(' + image + ')',
                    opacity: opacity, ...disableSvgBgImageStyle
                }}></div> : null}
                {(image && !disableSvg) ?
                    <svg
                        ref={function(e) {
                            setRef('svg', e)
                        }}
                        className={style.svg}
                        width={width}
                        height={height}
                        preserveAspectRatio={preserveAspectRatio}
                        viewBox={'0 0 ' + width + ' ' + height}
                        style={rsvgStyle}
                    >
                        <filter id={'filter_' + ID}>
                            <feGaussianBlur in={'SourceGraphic'} stdDeviation={blur} />
                            <feColorMatrix type={'hueRotate'} values={hue} />
                            <feColorMatrix type={'saturate'} values={saturation} />
                            <feComponentTransfer>
                                <feFuncR type={'linear'} slope={contrast} />
                                <feFuncG type={'linear'} slope={contrast} />
                                <feFuncB type={'linear'} slope={contrast} />
                            </feComponentTransfer>
                            <feColorMatrix type={'matrix'}
                                           values={red + ' 0 0 0 0 0 ' + green + ' 0 0 0 0 0 ' + blue + ' 0 0 0 0 0 1 0'} />
                        </filter>
                        <filter id={'filtercolorise_' + ID}>
                            <feColorMatrix type={'matrix'}
                                           values={5 + ' 5 5 0 0 0 ' + 5 + ' 5 5 0 0 0 ' + 5 + ' 5 5 0 0 0 ' + 1 + ' 0'} />
                            <feColorMatrix type={'saturate'} values={0} />
                            <feComponentTransfer>
                                <feFuncR type={'linear'} slope={10} />
                                <feFuncG type={'linear'} slope={10} />
                                <feFuncB type={'linear'} slope={10} />
                            </feComponentTransfer>
                        </filter>
                        <defs>
                            <mask id={'mask_' + ID}>
                                <image ref={function(e) {
                                    setRef('mask', e)
                                }} width={width} height={height}
                                       style={{ filter: 'url(#filtercolorise_' + ID + ')' }}></image>
                            </mask>
                        </defs>
                        <image ref={function(e) {
                            setRef('image', e)
                        }} width={width} height={height} style={{ filter: 'url(#filter_' + ID + ')' }}></image>
                        <rect
                            width={width + 8}
                            height={height + 8}
                            x={'-4'}
                            y={'-4'}
                            style={{ mask: 'url(#mask_' + ID + ')', fill: colorise, ...blendMode }}
                        ></rect>
                    </svg> : null
                }
            </div>
        )
    }
})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <SvgImage {...this.props} ref={(setRef) ? function(e) {
                setRef('svgimage', e)
            } : null} />
        )
    }
})

export default createReactClass({
    getInitialState: function() {
        this.refElements = {}
        return {}
    },
    setRef: function(a, e) {
        const { setRef } = this.props
        if (a == 'svgimage') {
            this.refElements['Container'] = e
        }
        if (setRef) setRef(a, e)
    },
    displayName: 'SvgImage',
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const setRef = this.setRef
        const input = { ...this.props, style, setRef }
        return (
            <R {...input} />
        )
    }
})
