export default {
    'type': 'object',
    'hidden': true,
    'fields': {
        'props': {
            'type': 'object',
            'hidden': true,
            'fields': {
                'animations': {
                    'type': 'array',
                    'fieldsName': 'name',
                    'name': 'Animations',
                    'add': {
                        'name': 'Animation',
                        'key': function() {
                            return 'animation_' + new Date().getTime()
                        }
                    },
                    'fields': {},
                    'update': async function(p = {}) {
                    },
                    'innerFormProps': {
                        'onOpen': function() {
                            return async function(p = {}) {
                                const { props = {} } = p
                                const root = props.root
                                const rootProps = root.props
                                const { refElements = {} } = root
                                refElements.views.close()
                                refElements.objects.close()
                                refElements.settings.close()
                                refElements.metas.close()
                                refElements.galleries.close()
                            }
                        }
                    },
                    'autoFields': {
                        'enable': {
                            'default': {
                                'type': 'object',
                                'remove': function({ data, key }) {
                                    return true
                                },
                                'fields': {
                                    'name': {
                                        'type': 'string',
                                        'name': 'Animation name (optional)',
                                        'default': '',
                                        'required': false,
                                        'validations': [
                                            {
                                                'name': 'max',
                                                'props': { 'value': 250, 'type': 'string' }
                                            }, {
                                                'name': 'type',
                                                'props': { 'value': ['string'] }
                                            }
                                        ],
                                        'update': async function(p = {}) {
                                            const { props = {}, data = {} } = p

                                            const parent = props.parent
                                            await parent.regenerateForm()

                                            const root = props.root
                                            await root.refElements.settings.regenerateForm()
                                        }
                                    },
                                    'start_view_id': {},
                                    'end_view_id': {},
                                    'first_image': {
                                        'component': 'imageurl',
                                        'type': 'string',
                                        'name': 'first image url (https://...) [jpg, png]',
                                        'default': '',
                                        'required': true,
                                        'validations': [{
                                            'name': 'url'
                                        }, {
                                            'name': 'path',
                                            'props': {
                                                'value': ['jpg', 'jpeg', 'png']
                                            }
                                        }, {
                                            'name': 'max',
                                            'props': { 'value': 1000, 'type': 'string' }
                                        }, {
                                            'name': 'type',
                                            'props': { 'value': ['string'] }
                                        }]
                                    },
                                    'last_image': {
                                        'component': 'imageurl',
                                        'type': 'string',
                                        'name': 'last image url (https://...) [jpg, png]',
                                        'default': '',
                                        'required': true,
                                        'validations': [{
                                            'name': 'url'
                                        }, {
                                            'name': 'path',
                                            'props': {
                                                'value': ['jpg', 'jpeg', 'png']
                                            }
                                        }, {
                                            'name': 'max',
                                            'props': { 'value': 1000, 'type': 'string' }
                                        }, {
                                            'name': 'type',
                                            'props': { 'value': ['string'] }
                                        }]
                                    },
                                    'key': {
                                        'type': 'string',
                                        'default': 'animation_key',
                                        'disabled': true,
                                        'validations': [
                                            {
                                                'name': 'min',
                                                'props': { 'value': 2, 'type': 'string' }
                                            }, {
                                                'name': 'max',
                                                'props': { 'value': 250, 'type': 'string' }
                                            }, {
                                                'name': 'type',
                                                'props': { 'value': ['string'] }
                                            }
                                        ]
                                    }
                                }
                            },
                            'dinamic': function(p = {}) {

                                const r = {
                                    'start_view_id': {
                                        'component': 'select',
                                        'type': 'string',
                                        'name': 'Start view',
                                        'default': null,
                                        'options': [
                                            { 'label': 'No object', 'value': null }
                                        ],
                                        'multiple': false,
                                        'disabled': function({ data, key }) {
                                            return false
                                        },
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['object'] }
                                            }, {
                                                'name': 'arrayin',
                                                'props': { 'value': [null] }
                                            }
                                        ],
                                        'update': async function(p = {}) {
                                            const { props = {}, data = {} } = p
                                            const parent = props.parent
                                            await parent.regenerateForm()
                                        }
                                    },
                                    'end_view_id': {
                                        'component': 'select',
                                        'type': 'string',
                                        'name': 'End view',
                                        'default': null,
                                        'options': [
                                            { 'label': 'No object', 'value': null }
                                        ],
                                        'multiple': false,
                                        'disabled': function({ data, key }) {
                                            return false
                                        },
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['object'] }
                                            }, {
                                                'name': 'arrayin',
                                                'props': { 'value': [null] }
                                            }
                                        ],
                                        'update': async function(p = {}) {
                                            const { props = {}, data = {} } = p
                                            const parent = props.parent
                                            await parent.regenerateForm()
                                        }
                                    }
                                }

                                const data = p.data[p.key][p.i]
                                const views = p.parentData.parentData.datas[2].views.props.views
                                const objects = p.parentData.parentData.datas[2].objects.props.objects

                                function getName(data) {
                                    const view_name = data.name
                                    const object_id = data.object_id
                                    let foundObject = null
                                    if (object_id) {
                                        objects.map(function(o) {
                                            if (object_id == o.key) foundObject = o
                                        })
                                    }
                                    const name = (foundObject) ? foundObject.name : ''

                                    if (view_name && name) return name + ' [' + view_name + ']'
                                    if (view_name) return 'No selected view' + ' [' + view_name + ']'
                                    if (name) return name
                                    return data.key
                                }

                                if (views && views.length) {
                                    views.map(function(object) {
                                        const key = object.key
                                        const name = getName(object)
                                        r['start_view_id'].options.push({
                                            label: name,
                                            value: key
                                        })
                                        r['start_view_id'].validations[1].props.value.push(key)
                                        r['end_view_id'].options.push({
                                            label: name,
                                            value: key
                                        })
                                        r['end_view_id'].validations[1].props.value.push(key)
                                    })
                                }

                                return { fields: r }

                            }
                        }
                    }
                }
            }
        }
    }
}
