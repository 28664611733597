import { backgroundColor } from './basic.js'

export default {
    'type': 'object',
    'hidden': true,
    'fields': {
        'props': {
            'type': 'object',
            'hidden': true,
            'fields': {
                'settings': {
                    'type': 'array',
                    'fieldsName': 'name',
                    'name': 'root',
                    'fields': {},
                    'innerFormProps': {
                        'onOpen': function() {
                            return async function(p = {}) {
                                const { props = {} } = p
                                const root = props.root
                                const rootProps = root.props
                                const { refElements = {} } = root
                                refElements.views.close()
                                refElements.metas.close()
                                refElements.objects.close()
                                refElements.animations.close()
                                refElements.galleries.close()
                            }
                        }
                    },
                    'autoFields': {
                        'enable': {
                            'default': {
                                'type': 'object',
                                'hidden': true,
                                'fields': {
                                    'title': {
                                        'type': 'string',
                                        'validations': [
                                            {
                                                'name': 'min',
                                                'props': { 'value': 2, 'type': 'string' }
                                            }, {
                                                'name': 'max',
                                                'props': { 'value': 250, 'type': 'string' }
                                            }, {
                                                'name': 'type',
                                                'props': { 'value': ['string'] }
                                            }
                                        ]
                                    },
                                    'view_id': {},
                                    'default_effect': {
                                        'component': 'select',
                                        'type': 'string',
                                        'name': 'Default effect',
                                        'default': null,
                                        'options': [
                                            { 'label': 'Fade', 'value': 'fade' },
                                            { 'label': 'Zoom out', 'value': 'zoom' },
                                            { 'label': 'Zoom in', 'value': 'zoomIn' },
                                            { 'label': 'Slide left', 'value': 'slide' },
                                            { 'label': 'Slide right', 'value': 'slideRight' },
                                            { 'label': 'Slide bottom', 'value': 'slideBottom' }
                                        ],
                                        'multiple': false,
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['object'] }
                                            }, {
                                                'name': 'arrayin',
                                                'props': { 'value': ['fade', 'zoom', 'zoomIn', 'slide', 'slideRight', 'slideBottom'] }
                                            }
                                        ]
                                    },
                                    'translations': {
                                        'type': 'array',
                                        'name': 'Translations',
                                        'fieldsName': 'text',
                                        'fields': {},
                                        'add': {
                                            'text': 'original text',
                                            'translate': 'translated text'
                                        },
                                        'autoFields': {
                                            'enable': {
                                                'default': {
                                                    'type': 'object',
                                                    'fields': {
                                                        'text': {
                                                            'name': 'Text',
                                                            'type': 'string',
                                                            'required': false,
                                                            'validations': [
                                                                {
                                                                    'name': 'max',
                                                                    'props': { 'value': 250, 'type': 'string' }
                                                                }, {
                                                                    'name': 'type',
                                                                    'props': { 'value': ['string'] }
                                                                }
                                                            ],
                                                            'update': async function(p = {}) {
                                                                const { props = {}, data = {} } = p
                                                                const parent = props.parent
                                                                await parent.regenerateForm()
                                                            }
                                                        },
                                                        'translate': {
                                                            'name': 'Translate',
                                                            'type': 'string',
                                                            'required': false,
                                                            'validations': [
                                                                {
                                                                    'name': 'max',
                                                                    'props': { 'value': 250, 'type': 'string' }
                                                                }, {
                                                                    'name': 'type',
                                                                    'props': { 'value': ['string'] }
                                                                }
                                                            ]
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    'colors': {
                                        'type': 'object',
                                        'name': 'Colors',
                                        'fields': {
                                            'area': {
                                                'type': 'object',
                                                'name': 'Area type colors',
                                                'fields': {
                                                    'available': {
                                                        'type': 'object',
                                                        'name': 'Available status colors',
                                                        'fields': {
                                                            'defaultColor': {
                                                                ...backgroundColor,
                                                                name: 'Shape default color'
                                                            },
                                                            'defaultStrokeColor': {
                                                                ...backgroundColor,
                                                                name: 'Stoke default color'
                                                            },
                                                            'hoverColor': {
                                                                ...backgroundColor,
                                                                name: 'Shape hover color'
                                                            },
                                                            'hoverStrokeColor': {
                                                                ...backgroundColor,
                                                                name: 'Stroke hover color'
                                                            },
                                                            'mobileDefaultColor': {
                                                                ...backgroundColor,
                                                                name: '[Mobile] Shape default color'
                                                            },
                                                            'mobileDefaultStrokeColor': {
                                                                ...backgroundColor,
                                                                name: '[Mobile] Stoke default color'
                                                            }
                                                        }
                                                    },
                                                    'reserved': {
                                                        'type': 'object',
                                                        'name': 'Reserved status colors',
                                                        'fields': {
                                                            'defaultColor': {
                                                                ...backgroundColor,
                                                                name: 'Shape default color'
                                                            },
                                                            'defaultStrokeColor': {
                                                                ...backgroundColor,
                                                                name: 'Stoke default color'
                                                            },
                                                            'hoverColor': {
                                                                ...backgroundColor,
                                                                name: 'Shape hover color'
                                                            },
                                                            'hoverStrokeColor': {
                                                                ...backgroundColor,
                                                                name: 'Stroke hover color'
                                                            },
                                                            'mobileDefaultColor': {
                                                                ...backgroundColor,
                                                                name: '[Mobile] Shape default color'
                                                            },
                                                            'mobileDefaultStrokeColor': {
                                                                ...backgroundColor,
                                                                name: '[Mobile] Stoke default color'
                                                            }
                                                        }
                                                    },
                                                    'sold': {
                                                        'type': 'object',
                                                        'name': 'Sold status colors',
                                                        'fields': {
                                                            'defaultColor': {
                                                                ...backgroundColor,
                                                                name: 'Shape default color'
                                                            },
                                                            'defaultStrokeColor': {
                                                                ...backgroundColor,
                                                                name: 'Stoke default color'
                                                            },
                                                            'hoverColor': {
                                                                ...backgroundColor,
                                                                name: 'Shape hover color'
                                                            },
                                                            'hoverStrokeColor': {
                                                                ...backgroundColor,
                                                                name: 'Stroke hover color'
                                                            },
                                                            'mobileDefaultColor': {
                                                                ...backgroundColor,
                                                                name: '[Mobile] Shape default color'
                                                            },
                                                            'mobileDefaultStrokeColor': {
                                                                ...backgroundColor,
                                                                name: '[Mobile] Stoke default color'
                                                            }
                                                        }
                                                    }
                                                }
                                            },
                                            'building': {
                                                'type': 'object',
                                                'name': 'Building type colors',
                                                'fields': {
                                                    'available': {
                                                        'type': 'object',
                                                        'name': 'Available status colors',
                                                        'fields': {
                                                            'defaultColor': {
                                                                ...backgroundColor,
                                                                name: 'Shape default color'
                                                            },
                                                            'defaultStrokeColor': {
                                                                ...backgroundColor,
                                                                name: 'Stoke default color'
                                                            },
                                                            'hoverColor': {
                                                                ...backgroundColor,
                                                                name: 'Shape hover color'
                                                            },
                                                            'hoverStrokeColor': {
                                                                ...backgroundColor,
                                                                name: 'Stroke hover color'
                                                            },
                                                            'mobileDefaultColor': {
                                                                ...backgroundColor,
                                                                name: '[Mobile] Shape default color'
                                                            },
                                                            'mobileDefaultStrokeColor': {
                                                                ...backgroundColor,
                                                                name: '[Mobile] Stoke default color'
                                                            }
                                                        }
                                                    },
                                                    'reserved': {
                                                        'type': 'object',
                                                        'name': 'Reserved status colors',
                                                        'fields': {
                                                            'defaultColor': {
                                                                ...backgroundColor,
                                                                name: 'Shape default color'
                                                            },
                                                            'defaultStrokeColor': {
                                                                ...backgroundColor,
                                                                name: 'Stoke default color'
                                                            },
                                                            'hoverColor': {
                                                                ...backgroundColor,
                                                                name: 'Shape hover color'
                                                            },
                                                            'hoverStrokeColor': {
                                                                ...backgroundColor,
                                                                name: 'Stroke hover color'
                                                            },
                                                            'mobileDefaultColor': {
                                                                ...backgroundColor,
                                                                name: '[Mobile] Shape default color'
                                                            },
                                                            'mobileDefaultStrokeColor': {
                                                                ...backgroundColor,
                                                                name: '[Mobile] Stoke default color'
                                                            }
                                                        }
                                                    },
                                                    'sold': {
                                                        'type': 'object',
                                                        'name': 'Sold status colors',
                                                        'fields': {
                                                            'defaultColor': {
                                                                ...backgroundColor,
                                                                name: 'Shape default color'
                                                            },
                                                            'defaultStrokeColor': {
                                                                ...backgroundColor,
                                                                name: 'Stoke default color'
                                                            },
                                                            'hoverColor': {
                                                                ...backgroundColor,
                                                                name: 'Shape hover color'
                                                            },
                                                            'hoverStrokeColor': {
                                                                ...backgroundColor,
                                                                name: 'Stroke hover color'
                                                            },
                                                            'mobileDefaultColor': {
                                                                ...backgroundColor,
                                                                name: '[Mobile] Shape default color'
                                                            },
                                                            'mobileDefaultStrokeColor': {
                                                                ...backgroundColor,
                                                                name: '[Mobile] Stoke default color'
                                                            }
                                                        }
                                                    }
                                                }
                                            },
                                            'flat': {
                                                'type': 'object',
                                                'name': 'Flat type colors',
                                                'fields': {
                                                    'available': {
                                                        'type': 'object',
                                                        'name': 'Available status colors',
                                                        'fields': {
                                                            'defaultColor': {
                                                                ...backgroundColor,
                                                                name: 'Shape default color'
                                                            },
                                                            'defaultStrokeColor': {
                                                                ...backgroundColor,
                                                                name: 'Stoke default color'
                                                            },
                                                            'hoverColor': {
                                                                ...backgroundColor,
                                                                name: 'Shape hover color'
                                                            },
                                                            'hoverStrokeColor': {
                                                                ...backgroundColor,
                                                                name: 'Stroke hover color'
                                                            },
                                                            'mobileDefaultColor': {
                                                                ...backgroundColor,
                                                                name: '[Mobile] Shape default color'
                                                            },
                                                            'mobileDefaultStrokeColor': {
                                                                ...backgroundColor,
                                                                name: '[Mobile] Stoke default color'
                                                            }
                                                        }
                                                    },
                                                    'reserved': {
                                                        'type': 'object',
                                                        'name': 'Reserved status colors',
                                                        'fields': {
                                                            'defaultColor': {
                                                                ...backgroundColor,
                                                                name: 'Shape default color'
                                                            },
                                                            'defaultStrokeColor': {
                                                                ...backgroundColor,
                                                                name: 'Stoke default color'
                                                            },
                                                            'hoverColor': {
                                                                ...backgroundColor,
                                                                name: 'Shape hover color'
                                                            },
                                                            'hoverStrokeColor': {
                                                                ...backgroundColor,
                                                                name: 'Stroke hover color'
                                                            },
                                                            'mobileDefaultColor': {
                                                                ...backgroundColor,
                                                                name: '[Mobile] Shape default color'
                                                            },
                                                            'mobileDefaultStrokeColor': {
                                                                ...backgroundColor,
                                                                name: '[Mobile] Stoke default color'
                                                            }
                                                        }
                                                    },
                                                    'sold': {
                                                        'type': 'object',
                                                        'name': 'Sold status colors',
                                                        'fields': {
                                                            'defaultColor': {
                                                                ...backgroundColor,
                                                                name: 'Shape default color'
                                                            },
                                                            'defaultStrokeColor': {
                                                                ...backgroundColor,
                                                                name: 'Stoke default color'
                                                            },
                                                            'hoverColor': {
                                                                ...backgroundColor,
                                                                name: 'Shape hover color'
                                                            },
                                                            'hoverStrokeColor': {
                                                                ...backgroundColor,
                                                                name: 'Stroke hover color'
                                                            },
                                                            'mobileDefaultColor': {
                                                                ...backgroundColor,
                                                                name: '[Mobile] Shape default color'
                                                            },
                                                            'mobileDefaultStrokeColor': {
                                                                ...backgroundColor,
                                                                name: '[Mobile] Stoke default color'
                                                            }
                                                        }
                                                    }
                                                }
                                            },
                                            'gallery': {
                                                'type': 'object',
                                                'name': 'Gallery colors',
                                                'fields': {
                                                    'gallery1': {
                                                        'type': 'object',
                                                        'name': 'Default gallery colors',
                                                        'fields': {
                                                            'defaultColor': {
                                                                ...backgroundColor,
                                                                'default': 'rgba(59,61,65,0.8)',
                                                                name: 'Shape default color'
                                                            },
                                                            'defaultStrokeColor': {
                                                                ...backgroundColor,
                                                                name: 'Stoke default color'
                                                            },
                                                            'hoverColor': {
                                                                ...backgroundColor,
                                                                'default': 'rgba(59,61,65,1)',
                                                                name: 'Shape hover color'
                                                            },
                                                            'hoverStrokeColor': {
                                                                ...backgroundColor,
                                                                name: 'Stroke hover color'
                                                            },
                                                            'mobileDefaultColor': {
                                                                ...backgroundColor,
                                                                name: '[Mobile] Shape default color'
                                                            },
                                                            'mobileDefaultStrokeColor': {
                                                                ...backgroundColor,
                                                                name: '[Mobile] Stoke default color'
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            'dinamic': function(p = {}) {

                                const r = {
                                    'view_id': {
                                        'component': 'select',
                                        'type': 'string',
                                        'name': 'Start view',
                                        'default': null,
                                        'options': [
                                            { 'label': 'Area', 'value': 'area' }
                                        ],
                                        'multiple': false,
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['object'] }
                                            }, {
                                                'name': 'arrayin',
                                                'props': { 'value': ['area'] }
                                            }
                                        ]
                                    }
                                }

                                const data = p.data[p.key][p.i]
                                const views = p.parentData.datas[p.deep - 3].props.views.props.views
                                const objects = p.parentData.datas[p.deep - 3].props.objects.props.objects

                                function getName(data) {
                                    const view_name = data.name
                                    const object_id = data.object_id
                                    let foundObject = null
                                    if (object_id) {
                                        objects.map(function(o) {
                                            if (object_id == o.key) foundObject = o
                                        })
                                    }
                                    const name = (foundObject) ? foundObject.name : ''

                                    if (view_name && name) return name + ' [' + view_name + ']'
                                    if (view_name) return 'No selected object' + ' [' + view_name + ']'
                                    if (name) return name
                                    return data.key
                                }

                                if (views && views.length) {
                                    views.map(function(view) {
                                        const key = view.key
                                        const name = getName(view)
                                        if (key !== 'area') {
                                            r['view_id'].options.push({
                                                label: name,
                                                value: key
                                            })
                                            r['view_id'].validations[1].props.value.push(key)
                                        } else {
                                            r['view_id'].options[0].label = name
                                        }
                                    })
                                }
                                return { fields: r }
                            }
                        }
                    }
                }
            }
        }
    }
}
