import React from 'react'

import usergalleryhelpers from '../../../components/Usergallery/helpers'
import fetchs from '../../../services/fetchs'
import SelectGroup from '../../../components/SelectGroup/formsy'
import sgs from '../../../components/SelectGroup/SelectGroup.css'

import MenuItem from 'material-ui/MenuItem'
import MultiSelect from '../../../components/MultiSelect/formsy'
import mss from '../../../components/MultiSelect/MultiSelect.css'
import CloseIcon from 'material-ui/svg-icons/navigation/close'

import Wapplrdialog from '../../../components/Wapplrdialog'
import Formsy from '../../../components/Formsy'

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

const step1min = 1

export function reqursiveSearchTaxonomy({ tax, id }) {
    let foundtax = null
    if (tax && tax.items) {
        tax.items.map(function(t) {
            if (!foundtax && t.id === id) foundtax = t
            if (!foundtax && t.items) {
                foundtax = reqursiveSearchTaxonomy({ tax: t, id })
            }
        })
    }
    return foundtax
}


export function getSelectDataFromTaxonomy({
                                              name,
                                              taxonomies = {},
                                              taxonomyname,
                                              parent,
                                              selectattr,
                                              disableAddEmpty
                                          }) {

    const rname = (name) ? name : taxonomyname
    const r = {
        name: rname,
        selectattr: selectattr,
        data: []
    }
    let foundtax = null
    Object.keys(taxonomies).map(function(key) {
        const tax = taxonomies[key]
        if (!foundtax && tax.name === taxonomyname) foundtax = tax
    })
    if (parent) {
        foundtax = reqursiveSearchTaxonomy({ tax: foundtax, id: parent })
    }
    const data = []
    if (foundtax && foundtax.items) {
        r.id = foundtax.id
        if (!disableAddEmpty) data.push({ name: '', value: null })
        foundtax.items.map(function(t) {
            data.push({ name: t.name, value: t.id })
        })
    }
    r.data = data
    return r
}

export function getSubSelectFunctionBySubCategory({
                                                      name = 'Sub category',
                                                      parentcolumn = 0,
                                                      parentdata = { id: 0, data: [] },
                                                      taxonomies = {},
                                                      taxonomyname
                                                  }) {
    return function({ state, currentrow, currentcolumn }) {

        const { values = [] } = state
        const currentvalue = (values[currentrow] && values[currentrow][parentcolumn]) ? values[currentrow][parentcolumn] : null
        let value = (values[currentrow] && values[currentrow][currentcolumn]) ? values[currentrow][currentcolumn] : null
        let found = 0
        const r = {
            name: name,
            data: []
        }

        parentdata.data.map(function(d) {
            if (!found && d.value && currentvalue === d.value) {
                r.data = [
                    ...getSelectDataFromTaxonomy({ name, taxonomies, taxonomyname, parent: currentvalue }).data
                ]
                let foundvalue = false
                r.data.map(function(rd) {
                    if (value === rd.value) foundvalue = true
                })
                if (!foundvalue) value = null
                found = 1
            }
        })

        if (!found) {
            if (values[currentrow][currentcolumn] !== null) values[currentrow][currentcolumn] = null
        } else {
            if (values[currentrow][currentcolumn] !== value) values[currentrow][currentcolumn] = value
        }
        return r

    }
}

function selectGroupValidation({ refs, onValid, onFail, validations = {}, data }) {

    let valid = true
    let errorMessage = null
    let invalidKey = null
    let failElem = null
    let values = null

    if (refs.elements) {
        Object.keys(refs.elements).map(function(key) {
            if (refs.elements[key] && refs.elements[key].props && refs.elements[key].props.endvalidation && valid) {
                const sg = refs.elements[key]
                const name = sg.props.name
                if (sg.getValues && name) {

                    const sgs = sg.getValues()
                    if (!values) values = {}
                    values[name] = sgs

                    Object.keys(validations).map(function(vkey) {
                        if (valid) {
                            const validanddata = validations[vkey].validation({
                                ...validations[vkey].props,
                                value: values[name]
                            })
                            valid = validanddata[0]
                            if (valid) values[name] = validanddata[1]
                            if (!valid) {
                                errorMessage = validations[vkey].errorMessage
                                invalidKey = key
                                failElem = sg
                            }
                        }
                    })
                }
            }
        })
    }

    if (valid) {
        if (!values) values = data
        if (onValid) onValid(values)
    } else {
        if (onFail) onFail({ element: failElem, errorMessage, invalidKey })
    }
}

export function endValidationHelpers({ stepIndex, onValid, onFail, data, resetForm, invalidateForm, refs, curlang }) {



    let startendvalidation = false

    Object.keys(refs.elements).map(function(key) {
        if (refs.elements[key] && refs.elements[key].props && refs.elements[key].props.endvalidation) {
            startendvalidation = true
        }
    })

    if (startendvalidation) {

        selectGroupValidation({
            refs: refs,
            data: data,
            onValid: function(data) {
                onValid(data, resetForm, invalidateForm, refs)
            },
            onFail: function({ errorMessage, invalidKey }) {
                setTimeout(function() {
                    onFail({ [invalidKey]: errorMessage })
                })
            },
            validations: {
                step1: {
                    validation: function({ value, n}) {
                        if (stepIndex === 0) {
                            const v = []
                            let hm = 0
                            value.map(function(row) {
                                if (row[0]) {
                                    hm = hm + 1
                                    v.push(row)
                                }
                            })
                            return [(hm > n), v]
                        }
                        return [true, value]
                    },
                    props: {
                        n: step1min - 1
                    },
                    errorMessage: curlang.minselectnumber(step1min)
                }
            }
        })

    } else {
        if (onValid) onValid(data)
    }

}

export function editdata({ curlang, history, searchData, posttypesData, routes, uploadconfig, getthumb, taxonomies }) {

    const {
        wordcategory = 'Category'
    } = curlang

    const categorydata = getSelectDataFromTaxonomy({
        taxonomies,
        taxonomyname: 'Tag',
        name: capitalizeFirstLetter(wordcategory)
    })

    const data = {
        0: [
            {
                type: 'text',
                attr: {
                    name: 'content_extended',
                    hintText: curlang.edithintdescription,
                    floatingLabelText: curlang.edithintdescription,
                    validations: { maxLength: 1500 },
                    validationError: curlang.maxcharerror(1500),
                    multiLine: true,
                    rows: 5,
                    rowsMax: 100,
                    style: function(state, props) {
                        const { hiddencontent } = props
                        if (hiddencontent) return { display: 'none', width: '100%' }
                        return { width: '100%' }
                    },
                    onChange: function(state, props) {
                        return function(e, value) {
                            const { post } = props
                            post['content_extended'] = value
                        }
                    },
                    value: function(state, props) {
                        const { post } = props
                        return (post && post['content_extended']) ? post['content_extended'] : ''
                    }
                }
            }, {
                type: 'selectgroup',
                component: SelectGroup,
                style: function(state, props) {
                    const { hiddencontent } = props
                    if (hiddencontent) return { display: 'none' }
                    return { marginTop: '32px' }
                },
                attr: {
                    title: wordcategory,
                    name: 'category',
                    endvalidation: false,
                    style: function() {
                        return sgs
                    },
                    validations: {
                        selectgroup: function(values, value) {
                            return !!(value && value[0] && value[0][0]);

                        }
                    },
                    validationErrors: {
                        selectgroup: curlang.minselectnumber(step1min)
                    },
                    value: function(state, props) {
                        const { post } = props
                        return (post && post.category) ? post.category : []
                    },
                    required: true,
                    onChange: function(state, props) {
                        const { post } = props
                        return function({ data, setValue, setState, state, compSetState }) {
                            const values = (data && data.length) ? data : []
                            const save = []
                            values.map(function(v) {
                                if (v[0]) save.push(v)
                            })
                            if (post) {
                                if (save.length > (step1min - 1)) {
                                    post.category = save
                                } else {
                                    post.category = values
                                }
                            }
                            compSetState({
                                values: values
                            })
                            setValue(save)
                            if (save.length > (step1min - 1) && !state._isValid) {
                                setState({
                                    _isValid: true,
                                    _externalError: [],
                                    _value: values
                                })
                            }
                        }
                    },
                    column: function(state, props) {
                        const { me } = props
                        const admin = !!(me && me.isEditor || me && me.isAdmin)
                        if (admin) return [categorydata]
                        const disableTaxs = ['59a2d2071fd9fd140cc665bf']
                        const data = []
                        if (categorydata.data) {
                            categorydata.data.map(function(tax) {
                                const taxid = tax.value
                                if (disableTaxs.indexOf(taxid) === -1) data.push(tax)
                            })
                        }
                        return [{ ...categorydata, data: data }]
                    },
                    row: [
                        { name: 'Most important 1' },
                        { name: 'Most important 2' },
                        { name: 'Most important 3' }
                    ],
                    responsiveColumns: {
                        800: 1,
                        480: 1
                    }
                }
            },
            usergalleryhelpers({
                fetchs, curlang, searchData, posttypesData, history, routes, uploadconfig, getthumb,
                style: function(state, props) {
                    const { hiddencontent } = props
                    if (hiddencontent) return { display: 'none' }
                    return { marginTop: '32px' }
                },
                config: {
                    formname: 'coverrel',
                    validations: {
                        maxLength: 1
                    },
                    validationErrors: {
                        maxLength: curlang.maxcharerror(1)
                    },
                    value: function(state, props) {
                        const { post } = props
                        return (post && post.coverrel) ? [post.coverrel] : []
                    },
                    selectsimple: true,
                    title: curlang.edithintcover,
                    getSelectedPosts: function({ post }) {
                        return (post && post.coverrel) ? [post.coverrel] : []
                    },
                    afterSelect: function({ e, selectedposts, setPosts, onChange, post }) {
                        const cover = (selectedposts && selectedposts.length) ? selectedposts[selectedposts.length - 1] : null
                        if (cover) {
                            post.coverrel = cover
                            post.cover = cover.cover
                            setPosts({ posts: [cover], modalclose: true })
                            if (onChange) onChange(e, [cover])
                        } else {
                            post.coverrel = null
                            post.cover = null
                            setPosts({ posts: [], modalclose: true })
                            if (onChange) onChange(e, [])
                        }
                    },
                    afterUpload: function({ response, setPosts, onChange, post }) {

                        let cover = null

                        if (response) {
                            if (response.length) {
                                let wassucc = false
                                response.map(function(i) {
                                    if (i.success) {
                                        cover = i
                                        if (i.post) cover = i.post
                                        wassucc = true
                                    }
                                })
                            } else {
                                if (response.success) {
                                    cover = response
                                    if (response.post) cover = response.post
                                }
                            }
                        }

                        if (cover) {
                            post.coverrel = cover
                            post.cover = cover.cover
                            setPosts({ posts: [cover], modalclose: true })
                            if (onChange) onChange(null, [cover])
                        }

                    },
                    deleteFunction: function({ newposts, post }) {
                        const cover = (newposts && newposts.length) ? newposts[newposts.length - 1] : null
                        if (cover) {
                            post.coverrel = cover
                            post.cover = cover.cover
                        } else {
                            post.coverrel = null
                            post.cover = null
                        }
                    },
                    disableupload: function({ props }) {
                        const { me, post } = props
                        if (me && me.id && post && post.id && post.author && post.author.id === me.id) return false
                        return !(post && post.id === 1)

                    }
                }
            }),
            {
                type: 'multiselect',
                component: MultiSelect,
                attr: {
                    name: 'tokens',
                    validations: {
                        minLength: 0
                    },
                    required: false,
                    value: function(state, props) {
                        const { post } = props
                        if (post.tokens) {
                            const tokens = (typeof post.tokens == 'string') ? JSON.parse(post.tokens) : post.tokens
                            if (tokens.map) {
                                const r = []
                                tokens.map(function(token) {
                                    r.push(JSON.stringify(token))
                                })
                                return r
                            }
                        }
                        return []
                    },
                    hintText: curlang.edithinttokens,
                    floatingLabelText: curlang.edithinttokens,
                    style: mss,
                    componentStyle: function(state, props) {
                        const { hiddencontent } = props
                        if (hiddencontent) return { display: 'none', width: '100%' }
                        return { width: '100%' }
                    },
                    selectedMenuItemStyle: { color: '#ffffff', backgroundColor: '#3f454a' },
                    children: function(state, props) {

                        const { post } = props

                        const values = []
                        if (post.tokens) {
                            const tokens = (typeof post.tokens == 'string') ? JSON.parse(post.tokens) : post.tokens
                            if (tokens.map) {
                                tokens.map(function(token) {
                                    if (token.token) {
                                        values.push({
                                            name: token.token + '(' + token.data + ')',
                                            value: JSON.stringify(token)
                                        })
                                    }
                                })
                            }
                        }

                        const data = []

                        if (values && typeof values == 'object' && values.length) {
                            values.map(function(token, i) {
                                data[i] = {
                                    label: token.name,
                                    value: token.value
                                }
                            })
                        }


                        if (!data.length) {
                            data.push({
                                value: null,
                                label: curlang.editnotokens,
                                disabled: true
                            })
                        }

                        return data.map(function(o, i) {
                            const name = o.label
                            const value = o.value
                            const disabled = o.disabled

                            return <MenuItem
                                key={i}
                                insetChildren={true}
                                checked={!!(values && values.indexOf(value) > -1)}
                                value={value}
                                primaryText={name}
                                disabled={disabled}
                                leftIcon={(disabled) ? null : <CloseIcon />}
                            />
                        })

                    },
                    addRender: function() {

                        return function(props = {}) {

                            const { setRef } = props

                            return (
                                <Wapplrdialog setRef={setRef} />
                            )
                        }
                    },
                    addButtonClick: function(state, props) {

                        const { post } = props

                        return function({ refElements, onChange }) {
                            const dialog = refElements['dialog']

                            console.log(dialog);

                            if (dialog) {

                                dialog.dialogOpen({
                                    submittext: curlang.submittext,
                                    canceltext: curlang.canceltext,
                                    content: Formsy,
                                    action: function() {
                                        const formsy = refElements['formsy']
                                        formsy.submit()
                                    },
                                    contentprops: {
                                        submitForm: function(data) {
                                            const formsy = refElements['formsy']
                                            const isValid = formsy.state.isValid
                                            if (isValid) {
                                                if (!post.tokens) post.tokens = []
                                                let tokens = (typeof post.tokens == 'string') ? JSON.parse(post.tokens) : post.tokens
                                                if (!tokens || !tokens.push) tokens = []
                                                tokens.push(data)
                                                post.tokens = JSON.stringify(tokens)

                                                const r = []
                                                tokens.map(function(token) {
                                                    r.push(JSON.stringify(token))
                                                })
                                                onChange(null, null, r)
                                                dialog.dialogClose()
                                            }
                                        },
                                        setRef: function(a, e) {
                                            if (e) refElements[a] = e
                                        },
                                        post: {
                                            token: new Date().getTime().toString(),
                                            data: 'flatchooser_1'
                                        },
                                        schemas: [
                                            { name: 'Flatchooser default settings', value: 'flatchooser_1' },
                                            { name: 'Flatchooser all meta', value: 'flatchooser_2' }
                                        ],
                                        formdata: [
                                            {
                                                type: 'text',
                                                attr: {
                                                    name: 'token',
                                                    hintText: curlang.edithinttoken,
                                                    floatingLabelText: curlang.edithinttoken,
                                                    validations: {
                                                        minLength: 5,
                                                        maxLength: 200
                                                    },
                                                    validationErrors: {
                                                        minLength: curlang.mincharerror(5),
                                                        maxLength: curlang.maxcharerror(200)
                                                    },
                                                    multiLine: false,
                                                    style: function() {
                                                        return { width: '100%' }
                                                    },
                                                    onChange: function(state, props) {
                                                        return function(e, value) {
                                                            const { post } = props
                                                            post['token'] = value
                                                        }
                                                    },
                                                    value: function(state, props) {
                                                        const { post } = props
                                                        return (post && post['token']) ? post['token'] : ''
                                                    }
                                                }
                                            },
                                            {
                                                type: 'select',
                                                attr: {
                                                    name: 'data',
                                                    floatingLabelText: curlang.edithintselectschema,
                                                    validations: {
                                                        minLength: 1
                                                    },
                                                    style: function() {
                                                        return { width: '100%' }
                                                    },
                                                    onChange: function(state, props) {
                                                        return function(e, value) {
                                                            const { post } = props
                                                            post.data = value
                                                        }
                                                    },
                                                    children: function(state, props) {

                                                        const { post, schemas } = props
                                                        let currentdata = (post && post.data) ? post.data : ''

                                                        const data = [...schemas]
                                                        const options = []

                                                        data.map(function(d) {
                                                            if (d.value) {
                                                                options.push({
                                                                    value: d.value,
                                                                    label: d.name
                                                                })
                                                            }
                                                        })

                                                        return options.map(function(o, i) {
                                                            const name = o.label
                                                            const value = o.value
                                                            return <MenuItem
                                                                key={i}
                                                                insetChildren={true}
                                                                checked={!!(currentdata && currentdata === value)}
                                                                value={value}
                                                                primaryText={name}
                                                            />
                                                        })

                                                    },
                                                    selectedMenuItemStyle: {
                                                        color: '#ffffff',
                                                        backgroundColor: '#3f454a'
                                                    },
                                                    required: true,
                                                    value: function(state, props) {
                                                        const { post } = props
                                                        return (post && post.data) ? post.data : ''
                                                    }
                                                }
                                            }
                                        ]
                                    }
                                })
                            }
                        }
                    },
                    onChange: function(state, props) {
                        const { post } = props
                        return function(a, b, values) {
                            if (values && values.length) {
                                const tokens = []
                                values.map(function(token) {
                                    tokens.push(JSON.parse(token))
                                })
                                post.tokens = JSON.stringify(tokens)
                            } else {
                                post.tokens = []
                            }
                        }
                    }

                }
            },
            {
                type: 'text',
                attr: {
                    name: 'plusdata',
                    hintText: curlang.edithintplusdata,
                    floatingLabelText: curlang.edithintplusdata,
                    validations: {
                        maxLength: 200
                    },
                    validationErrors: {
                        maxLength: curlang.maxcharerror(200)
                    },
                    style: function(state, props) {
                        const { hiddencontent } = props
                        if (hiddencontent) return { display: 'none', width: '100%' }
                        return { width: '100%' }
                    },
                    required: false,
                    onChange: function(state, props) {
                        return function(e, value) {
                            const { post } = props
                            post.plusdata = value
                        }
                    },
                    value: function(state, props) {
                        const { post } = props
                        return (post && post.plusdata) ? post.plusdata : ''
                    }
                }
            },
            {
                type: 'text',
                attr: {
                    name: 'disabledChangesScript',
                    hintText: curlang.edithintDisabledChangesScript,
                    floatingLabelText: curlang.edithintDisabledChangesScript,
                    validations: {
                        parsableArray: (values, value)=>{
                            if (!value){
                                return true;
                            }
                            let valid;
                            try {
                                const array = JSON.parse(value);
                                if (Array.isArray(array)){
                                    valid = true;
                                }
                            } catch (e){}
                            return valid;
                        }
                    },
                    validationErrors: {
                        parsableArray: curlang.parsableArray
                    },
                    style: function(state, props) {
                        const { hiddencontent } = props
                        if (hiddencontent) return { display: 'none', width: '100%' }
                        return { width: '100%' }
                    },
                    required: false,
                    onChange: function(state, props) {
                        return function(e, value) {
                            const { post } = props
                            post.disabledChangesScript = value
                        }
                    },
                    value: function(state, props) {
                        const { post } = props
                        return (post && post.disabledChangesScript) ? post.disabledChangesScript : ''
                    }
                }
            },
            {
                type: 'toggle',
                attr: {
                    name: 'share',
                    label: curlang.editlabelpubliccontent,
                    style: function(state, props) {
                        const { hiddencontent } = props
                        if (hiddencontent) return { display: 'none', width: '100%' }
                        return { width: '100%' }
                    },
                    onChange: function(state, props) {
                        return function(e, value) {
                            const { post } = props
                            post['share'] = (value) ? 'public' : 'private'
                        }
                    },
                    defaultToggled: function(state, props) {
                        const { post } = props
                        return (post && post['share'] === 'public' || post && !post['share'])
                    }
                }
            },
            {
                type: 'text',
                attr: {
                    name: 'coproducers',
                    hintText: curlang.edithintcoproducers,
                    floatingLabelText: curlang.edithintcoproducers,
                    multiLine: true,
                    rows: 1,
                    rowsMax: 3,
                    style: function(state, props) {
                        const { hiddencontent } = props
                        if (hiddencontent) return { display: 'none', width: '100%' }
                        return { width: '100%' }
                    },
                    onChange: function(state, props) {
                        return function(e, value) {
                            const { post } = props
                            post['coproducers'] = value
                        }
                    },
                    value: function(state, props) {
                        const { post } = props
                        return (post && post['coproducers']) ? post['coproducers'] : ''
                    }
                }
            },
            {
                type: 'select',
                attr: {
                    name: 'workstate',
                    floatingLabelText: curlang.editlabelworkstate,
                    style: function(state, props) {
                        const { hiddencontent } = props
                        if (hiddencontent) return { display: 'none', width: '100%' }
                        return { width: '100%' }
                    },
                    onChange: function(state, props) {
                        return function(e, value) {
                            const { post } = props
                            post.workstate = value
                        }
                    },
                    children: function(state, props) {

                        const { post } = props
                        const currentdata = (post && post.workstate) ? post.workstate : 0
                        const options = [{ value: 0, label: curlang.workstatecreated }, {
                            value: 10,
                            label: curlang.workstateprogress
                        }, { value: 20, label: curlang.workstatefinished }]

                        return options.map(function(o, i) {
                            const name = o.label
                            const value = o.value
                            return <MenuItem
                                key={i}
                                insetChildren={true}
                                checked={!!(currentdata && currentdata === value)}
                                value={value}
                                primaryText={name}
                            />
                        })

                    },
                    selectedMenuItemStyle: { color: '#ffffff', backgroundColor: '#3f454a' },
                    value: function(state, props) {
                        const { post } = props
                        return (post && post.workstate) ? post.workstate : 0
                    }
                }
            },

            /*admin*/
            {
                type: 'toggle',
                style: function(state, props) {
                    const { hiddencontent, me, isnew } = props
                    if (isnew) return { display: 'none' }
                    const admin = !!(me && me.isEditor || me && me.isAdmin)
                    if (admin) {
                        if (hiddencontent) return { display: 'none', width: '100%' }
                        return { width: '100%' }
                    }
                    return { display: 'none' }
                },
                attr: {
                    name: 'appjsonenableadmin',
                    label: curlang.editlabelappjsonenableadmin,
                    onChange: function(state, props) {
                        return function(e, value) {
                            const { post } = props
                            post['appjsonenableadmin'] = !!(value)
                        }
                    },
                    defaultToggled: function() {

                        return false
                    }
                }
            }, {
                type: 'text',
                attr: {
                    name: 'appjson',
                    hintText: curlang.edithintappjson,
                    floatingLabelText: curlang.edithintappjson,
                    multiLine: true,
                    rows: 5,
                    rowsMax: 100,
                    style: function(state, props) {
                        const { hiddencontent, me } = props
                        const admin = !!(me && me.isEditor || me && me.isAdmin)
                        if (admin) {
                            if (hiddencontent) return { display: 'none', width: '100%' }
                            return { width: '100%' }
                        }
                        return { display: 'none' }
                    },
                    onChange: function(state, props) {
                        return function(e, value) {
                            const { post } = props
                            post['appjson'] = value
                        }
                    },
                    value: function(state, props) {
                        const { post, me } = props
                        const admin = !!(me && me.isEditor || me && me.isAdmin)
                        return (post && post['appjson'] && admin) ? post['appjson'] : ''
                    }
                }
            }, {
                type: 'button',
                style: function(state, props) {
                    const { hiddencontent } = props
                    if (hiddencontent && !state.canSubmit) return { display: 'block' }
                    return { display: 'none' }
                },
                attr: {
                    name: 'open',
                    style: function(state, props) {
                        const { hiddencontent } = props
                        if (hiddencontent) return { marginTop: 0 }
                        return { marginTop: 32 }
                    },
                    label: function(state, props) {
                        const { isnew } = props
                        const savepost = (isnew) ? curlang.saveplayernew : curlang.saveplayer
                        return (state.processing) ? savepost : curlang.addmoredatabutton
                    },
                    onClick: function(state, props) {
                        return function() {
                            const { open, hiddencontent } = props
                            open(!hiddencontent)
                        }
                    },
                    disabled: function(state = {}, props) {
                        const { ready, hiddencontent } = props
                        if (!ready) return true
                        if (hiddencontent && !state.canSubmit) {
                            return false
                        } else {
                            return !state.canSubmit
                        }
                    }
                }
            }, {
                type: 'button',
                style: function(state, props) {
                    const { hiddencontent } = props
                    if (hiddencontent && !state.canSubmit) return { display: 'none' }
                    return { display: 'block' }
                },
                attr: {
                    type: 'submit',
                    name: 'submit',
                    style: function(state, props) {
                        const { hiddencontent } = props
                        if (hiddencontent) return { marginTop: 0 }
                        return { marginTop: 32 }
                    },
                    label: function(state, props) {
                        const { restoretext, isnew } = props
                        const savepost = (isnew) ? curlang.saveplayernew : curlang.saveplayer
                        return (state.processing) ? savepost : (restoretext) ? restoretext : savepost
                    },
                    disabled: function(state = {}, props) {
                        const { ready, hiddencontent } = props
                        if (!ready) return true
                        if (hiddencontent && !state.canSubmit) {
                            return false
                        } else {
                            return !state.canSubmit
                        }
                    }
                }
            }
        ]
    }

    return {
        'new': {
            0: [
                {
                    type: 'text',
                    attr: {
                        name: 'title',
                        hintText: curlang.edithinttitle,
                        floatingLabelText: curlang.edithinttitle,
                        validations: {
                            minLength: 2,
                            maxLength: 200
                        },
                        validationErrors: {
                            minLength: curlang.mincharerror(2),
                            maxLength: curlang.maxcharerror(200)
                        },
                        style: function(state, props) {
                            const { hiddencontent } = props
                            if (hiddencontent) return { display: 'none', width: '100%' }
                            return { width: '100%' }
                        },
                        required: true,
                        onChange: function(state, props) {
                            return function(e, value) {
                                const { post } = props
                                post.title = value
                            }
                        },
                        value: function(state, props) {
                            const { post } = props
                            return (post && post.title) ? post.title : ''
                        }
                    }
                },
                {
                    type: 'select',
                    attr: {
                        name: 'appver',
                        floatingLabelText: curlang.edithintappver,
                        validations: {
                            minLength: 1
                        },
                        style: function() {
                            return { width: '100%' }
                        },
                        onChange: function(state, props) {
                            return function(e, value) {
                                const { post } = props
                                post.appver = (typeof value == 'object' && value.length) ? value[0] : value
                            }
                        },
                        children: function(state, props) {

                            const { post} = props
                            let currentdata = (post && post.appver) ? post.appver : '2.0'

                            const options = [{ value: '1.0', label: '1.0' }, { value: '2.0', label: '2.0' }]

                            return options.map(function(o, i) {
                                const name = o.label
                                const value = o.value
                                return <MenuItem
                                    key={i}
                                    insetChildren={true}
                                    checked={!!(currentdata && currentdata === value)}
                                    value={value}
                                    primaryText={name}
                                />
                            })

                        },
                        selectedMenuItemStyle: { color: '#ffffff', backgroundColor: '#3f454a' },
                        required: true,
                        value: function(state, props) {
                            const { post } = props
                            return (post && post.value) ? post.value : '2.0'
                        }
                    }
                },
                {
                    type: 'text',
                    attr: {
                        name: 'content_extended',
                        hintText: curlang.edithintdescription,
                        floatingLabelText: curlang.edithintdescription,
                        validations: { maxLength: 1500 },
                        validationError: curlang.maxcharerror(1500),
                        multiLine: true,
                        rows: 5,
                        rowsMax: 100,
                        style: function(state, props) {
                            const { hiddencontent } = props
                            if (hiddencontent) return { display: 'none', width: '100%' }
                            return { width: '100%' }
                        },
                        onChange: function(state, props) {
                            return function(e, value) {
                                const { post } = props
                                post['content_extended'] = value
                            }
                        },
                        value: function(state, props) {
                            const { post } = props
                            return (post && post['content_extended']) ? post['content_extended'] : ''
                        }
                    }
                }, {
                    type: 'button',
                    style: function(state, props) {
                        const { hiddencontent } = props
                        if (hiddencontent && !state.canSubmit) return { display: 'none' }
                        return { display: 'block' }
                    },
                    attr: {
                        type: 'submit',
                        name: 'submit',
                        style: function(state, props) {
                            const { hiddencontent } = props
                            if (hiddencontent) return { marginTop: 0 }
                            return { marginTop: 32 }
                        },
                        label: function(state, props) {
                            const { restoretext, isnew } = props
                            const savepost = (isnew) ? curlang.saveplayernew : curlang.saveplayer
                            return (state.processing) ? savepost : (restoretext) ? restoretext : savepost
                        },
                        disabled: function(state = {}, props) {
                            const { ready, hiddencontent } = props
                            if (!ready) return true
                            if (hiddencontent && !state.canSubmit) {
                                return false
                            } else {
                                return !state.canSubmit
                            }
                        }
                    }
                }
            ]
        },
        'edit': {
            ...data
        },
        'json': {
            0: [
                {
                    type: 'button',
                    attr: {
                        type: 'submit',
                        name: 'submit',
                        label: function(state, props) {
                            const { restoretext } = props
                            return (state.processing) ? curlang.saveplayer : (restoretext) ? restoretext : curlang.saveplayer
                        },
                        style: function() {
                            return { marginTop: 0 }
                        },
                        disabled: function(state = {}, props) {
                            const { ready} = props
                            return !ready;

                        }
                    }
                }
            ]
        }

    }
}
