export const filters = {
    'type': 'object',
    'name': 'image filters',
    'fields': {
        'colorise': {
            'component': 'colorpicker',
            'type': 'string',
            'name': 'colorise (rgba(0,0,0,1))',
            'default': 'rgba(0,0,0,0)',
            'validations': [
                {
                    'name': 'color',
                    'props': {
                        'value': ['rgba']
                    }
                }
            ]
        },
        'hue': {
            'component': 'slider',
            'type': 'number',
            'name': 'hue (-100 - 100)',
            'default': 0,
            'min': -100,
            'max': 100,
            'step': 1,
            'validations': [
                {
                    'name': 'type',
                    'props': { 'value': ['number'] }
                }, {
                    'name': 'min',
                    'props': { 'value': -100, 'type': 'number' }
                }, {
                    'name': 'max',
                    'props': { 'value': 100, 'type': 'number' }
                }
            ]
        },
        'saturation': {
            'component': 'slider',
            'type': 'number',
            'name': 'saturation (-100 - 100)',
            'default': 0,
            'min': -100,
            'max': 100,
            'step': 1,
            'validations': [
                {
                    'name': 'type',
                    'props': { 'value': ['number'] }
                }, {
                    'name': 'min',
                    'props': { 'value': -100, 'type': 'number' }
                }, {
                    'name': 'max',
                    'props': { 'value': 100, 'type': 'number' }
                }
            ]
        },
        'contrast': {
            'component': 'slider',
            'type': 'number',
            'name': 'contrast (-100 - 100)',
            'default': 0,
            'min': -100,
            'max': 100,
            'step': 1,
            'validations': [
                {
                    'name': 'type',
                    'props': { 'value': ['number'] }
                }, {
                    'name': 'min',
                    'props': { 'value': -100, 'type': 'number' }
                }, {
                    'name': 'max',
                    'props': { 'value': 100, 'type': 'number' }
                }
            ]
        },
        'blur': {
            'component': 'slider',
            'type': 'number',
            'name': 'blur (0 - 30)',
            'default': 0,
            'min': 0,
            'max': 30,
            'step': 1,
            'validations': [
                {
                    'name': 'type',
                    'props': { 'value': ['number'] }
                }, {
                    'name': 'min',
                    'props': { 'value': 0, 'type': 'number' }
                }, {
                    'name': 'max',
                    'props': { 'value': 30, 'type': 'number' }
                }
            ]
        },
        'red': {
            'component': 'slider',
            'type': 'number',
            'name': 'red (0 - 255)',
            'default': 255,
            'min': 0,
            'max': 255,
            'step': 1,
            'validations': [
                {
                    'name': 'type',
                    'props': { 'value': ['number'] }
                }, {
                    'name': 'min',
                    'props': { 'value': 0, 'type': 'number' }
                }, {
                    'name': 'max',
                    'props': { 'value': 255, 'type': 'number' }
                }
            ]
        },
        'blue': {
            'component': 'slider',
            'type': 'number',
            'name': 'blue (0 - 255)',
            'default': 255,
            'min': 0,
            'max': 255,
            'step': 1,
            'validations': [
                {
                    'name': 'type',
                    'props': { 'value': ['number'] }
                }, {
                    'name': 'min',
                    'props': { 'value': 0, 'type': 'number' }
                }, {
                    'name': 'max',
                    'props': { 'value': 255, 'type': 'number' }
                }
            ]
        },
        'green': {
            'component': 'slider',
            'type': 'number',
            'name': 'green (0 - 255)',
            'default': 255,
            'min': 0,
            'max': 255,
            'step': 1,
            'validations': [
                {
                    'name': 'type',
                    'props': { 'value': ['number'] }
                }, {
                    'name': 'min',
                    'props': { 'value': 0, 'type': 'number' }
                }, {
                    'name': 'max',
                    'props': { 'value': 255, 'type': 'number' }
                }
            ]
        },
        'opacity': {
            'component': 'slider',
            'type': 'number',
            'name': 'opacity (0 - 1)',
            'default': 1,
            'min': 0,
            'max': 1,
            'step': 0.05,
            'validations': [
                {
                    'name': 'type',
                    'props': { 'value': ['number'] }
                }, {
                    'name': 'min',
                    'props': { 'value': 0, 'type': 'number' }
                }, {
                    'name': 'max',
                    'props': { 'value': 1, 'type': 'number' }
                }
            ]
        }
    }
}

export const image = {
    'component': 'imageurl',
    'type': 'string',
    'name': 'image url (https://...) [jpg, png]',
    'validations': [{
        'name': 'urlorempty',
        'props': {}
    }, {
        'name': 'pathorempty',
        'props': {
            'value': ['jpg', 'jpeg', 'png']
        }
    }]
}

export const backgroundColor = {
    'component': 'colorpicker',
    'type': 'string',
    'name': 'background color (rgba(0,0,0,1))',
    'default': 'rgba(0,0,0,0)',
    'validations': [
        {
            'name': 'color',
            'props': {
                'value': ['rgba']
            }
        }
    ]
}

export const shadow = {
    'component': 'shadowpicker',
    'type': 'string',
    'name': 'shadow',
    'validations': [
        {
            'name': 'shadow',
            'props': {
                'value': ['[color(rgba) offsetX(px) offsetY(px) blurRadius(px)]']
            }
        }
    ]
}

export const defaultObject = {
    'type': 'object',
    'hidden': false
}

export const getObject = function(p = {}) {
    return {
        ...defaultObject,
        ...p
    }
}

export const getContainer = function(p = {}) {
    const { fields = {} } = p
    return getObject({
        'fields': {
            'props': getObject({
                'hidden': true,
                'fields': {
                    ...fields
                }
            })
        }
    })
}
