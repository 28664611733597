import path from 'path'

export const defaultUpdate = async function(p = {}) {
    const { props, schemaProps, data, datas, field } = p

    //update editor;
    if (field && field.update) await field.update(p)

    //update player;
    const { root, updatePlayer } = props
    const update = (updatePlayer) ? updatePlayer : root.props.updatePlayer
    const updateids = (schemaProps && schemaProps.update) ? schemaProps.update : null
    if (update) update({ update: updateids, data, datas })

}

export const defaultValidUpdate = async function(p = {}) {
    const { value, data, key, props, schemaProps, datas, setValue, hasChanged, beforeSetValue, onValid, onInvalid } = p
    const changed = (hasChanged) ? hasChanged({
        oldValud: data[key],
        newValue: value
    }) : (value !== data[key]) ? true : false
    if (changed) {
        data[key] = value
        if (beforeSetValue) beforeSetValue()
        await setValue(value)

        const t = props.parent.refElements[key]
        const isValid = t.isValid()
        const isValidRoot = props.root.isValid()

        if (isValid && isValidRoot) {
            if (onValid) onValid()
            defaultUpdate(p)
        } else {
            if (onInvalid) onInvalid()
        }

    }
}

export const validations = {
    required: {
        validation: function(props = {}) {
            const required = props.required
            return function(values, value) {
                if (required == true) {
                    if (value == 'undefined' ||
                        value == null ||
                        value == '' && value !== 0 ||
                        typeof value == 'undefined'
                    ) {
                        return false
                    }
                }
                return true
            }
        },
        error: function(props = {}) {
            const required = props.required
            if (required == true) {
                return 'required value'
            } else {
                return 'bad configuration for required [' + required.toString() + ']'
            }
        }
    },
    min: {
        validation: function(props = {}) {
            const n = props.value || 0
            const t = props.type
            return function(values, value) {
                const type = typeof value
                if (t) {
                    if (value && type == 'object' && t == type && value.length > n - 1) return true
                    if (value && type == 'string' && t == type && value.length > n - 1) return true
                    if (value && type == 'number' && t == type && value > n - 1 || value == 0 && type == 'number' && value > n - 1) return true
                    if (t == 'number' && type == 'string' && !isNaN(Number(value))) {
                        if (Number(value) > n - 1) return true
                    }
                } else {
                    if (value && type == 'object' && value.length > n - 1) return true
                    if (value && type == 'string' && value.length > n - 1) return true
                    if (value && type == 'number' && value > n - 1 || value == 0 && type == 'number' && value > n - 1) return true
                }
                return false
            }
        },
        error: function(props = {}) {
            const n = props.value || 0
            return 'minimum ' + n
        }
    },
    max: {
        validation: function(props = {}) {
            const n = props.value || 0
            const t = props.type
            return function(values, value) {
                const type = typeof value
                if (t) {
                    if (value && type == 'object' && t == type && value.length < n + 1) return true
                    if (value && type == 'string' && t == type && value.length < n + 1) return true
                    if (value && type == 'number' && t == type && value < n + 1 || value == 0 && type == 'number' && value < n + 1) return true
                    if (t == 'number' && type == 'string' && !isNaN(Number(value))) {
                        if (Number(value) < n + 1) return true
                    }
                } else {
                    if (value && type == 'object' && value.length < n + 1) return true
                    if (value && type == 'string' && value.length < n + 1) return true
                    if (value && type == 'number' && value < n + 1 || value == 0 && type == 'number' && value < n + 1) return true
                }
                return false
            }
        },
        error: function(props = {}) {
            const n = props.value || 0
            return 'maximum ' + n
        }
    },
    url: {
        validation: function(props = {}) {
            return function(values, value) {
                const matcher = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/
                const isUrl = matcher.test(value)
                if (isUrl) return true
                return false
            }
        },
        error: function(props = {}) {
            return 'invalid url'
        }
    },
    urlorempty: {
        validation: function(props = {}) {
            return function(values, value) {
                if (!value) return true
                const matcher = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/
                const isUrl = matcher.test(value)
                if (isUrl) return true
                return false
            }
        },
        error: function(props = {}) {
            return 'invalid url'
        }
    },
    path: {
        validation: function(props = {}) {
            const t = props.value || []
            return function(values, value) {
                if (t && t.length && value) {
                    const basename = path.basename(value)
                    const ext = (basename && basename.split) ? basename.split('.').pop() : null
                    if (ext) {
                        if (t.indexOf(ext) > -1) return true
                    }
                }
                return false
            }
        },
        error: function(props = {}) {
            const t = props.value || 0
            if (t && t.length) {
                return 'invalid file type, please specify a value of this type [' + t.toString() + ']'
            } else {
                return 'bad configuration [' + t.toString() + ']'
            }
        }
    },
    pathorempty: {
        validation: function(props = {}) {
            const t = props.value || []
            return function(values, value) {
                if (!value) return true
                if (t && t.length && value) {
                    const basename = path.basename(value)
                    const ext = (basename && basename.split) ? basename.split('.').pop() : null
                    if (ext) {
                        if (t.indexOf(ext) > -1) return true
                    }
                }
                return false
            }
        },
        error: function(props = {}) {
            const t = props.value || 0
            if (t && t.length) {
                return 'invalid file type, please specify a value of this type [' + t.toString() + ']'
            } else {
                return 'bad configuration [' + t.toString() + ']'
            }
        }
    },
    type: {
        validation: function(props = {}) {
            const t = props.value || []
            return function(values, value) {
                const type = typeof value
                if (!t) return true
                if (typeof t !== 'object') return true
                if (!t.length) return true
                if (t.indexOf(type) > -1) return true
                if (t.indexOf('number') > -1 && type == 'string' && !isNaN(Number(value))) {
                    return true
                }
                return false
            }
        },
        error: function(props = {}) {
            const t = props.value
            if (t && t.length) {
                return 'invalid type, please specify a value of this type [' + t.toString() + ']'
            } else {
                return 'bad configuration [' + t.toString() + ']'
            }
        }
    },
    color: {
        validation: function(props = {}) {
            const t = props.value || []
            return function(values, value) {
                const type = typeof value
                if (!t) return false
                if (type == 'string') {
                    if (t && t.indexOf('rgba') > -1) {
                        if (value && value.match('rgba') && value.slice(0, 5) == 'rgba(' && value.slice(-1) == ')') {
                            const n = value.slice(5, -1).replace(/ /g, '').split(',')
                            const r = Number(n[0])
                            const g = Number(n[1])
                            const b = Number(n[2])
                            const a = Number(n[3])
                            if (r >= 0 && r <= 255 &&
                                g >= 0 && g <= 255 &&
                                b >= 0 && b <= 255 &&
                                a >= 0 && a <= 1) {
                                return true
                            }
                        }
                    }
                }
                return false
            }
        },
        error: function(props = {}) {
            const t = props.value
            if (t && t.length) {
                return 'invalid type, please specify a value of this type [' + t.toString() + ']'
            } else {
                return 'bad configuration [' + 'missing type' + ']'
            }
        }
    },
    arrayin: {
        validation: function(props = {}) {
            const a = props.value || []
            return function(values, value) {
                const type = typeof value
                if (type == 'object') {
                    if (value && value.length && a && a.length) {
                        let foundinvalid = false
                        value.map(function(v) {
                            if (a.indexOf(v) == -1) foundinvalid = true
                        })
                        if (foundinvalid) return false
                    }
                } else {
                    if (a && a.length && a.indexOf(value) == -1) return false
                }
                return true
            }
        },
        error: function(props = {}) {
            const a = props.value
            if (a && a.length) {
                return 'invalid type, please choose one of them [' + a.toString() + ']'
            } else {
                return 'bad configuration [' + a.toString() + ']'
            }
        }
    },
    parsable: {
        validation: function(props = {}) {
            const enableEmpty = props['enableEmpty']
            return function(values, value) {
                try {
                    if (enableEmpty && !value) return true
                    const p = JSON.parse(value)
                    return true
                } catch (err) {
                    return false
                }
            }
        },
        error: function(props = {}) {
            return 'invalid type, please add a parsable string'
        }
    }
}

export const getValidations = function(v, required) {

    const r = {}

    if (required == true) {
        if (!r.validations) r.validations = {}
        if (!r.validationErrors) r.validationErrors = {}
        r.validations['required'] = validations['required'].validation({ required })
        r.validationErrors['required'] = validations['required'].error({ required })
    }

    const requiredHook = function(callback) {
        return function(values, value) {
            if (required == false && value == '') return true
            if (required == false && value == null) return true
            if (required == false && value == 'undefined') return true
            if (required == false && typeof value == 'undefined') return true
            return callback(values, value)
        }
    }

    if (v && v.length) {
        v.map(function(validation) {
            if (validation.name && validations[validation.name]) {
                if (!r.validations) r.validations = {}
                if (!r.validationErrors) r.validationErrors = {}
                r.validations[validation.name] = requiredHook(validations[validation.name].validation(validation.props))
                r.validationErrors[validation.name] = validations[validation.name].error(validation.props)
            }
        })
    }

    r.isValid = function() {
        return function(value) {
            let isValid = true
            let errorMessage = ''
            const validationsObject = r
            if (validationsObject.validations) {
                Object.keys(validationsObject.validations).map(function(key) {
                    if (isValid) {
                        const validation = validationsObject.validations[key]
                        const currentIsValid = validation(null, value)
                        if (!currentIsValid) {
                            isValid = false
                            errorMessage = validationsObject.validationErrors[key]
                        }
                    }
                })
            }
            return { isValid, errorMessage }
        }
    }

    r.getErrorMessage = function(value) {
        const isValid = r.isValid()
        return isValid(value).errorMessage
    }

    return r
}
