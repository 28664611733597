import React from 'react'
import createReactClass from 'create-react-class'
import ContentEmbed from './content_embed'
import { decode } from 'querystring'

export default createReactClass({
    getInitialState: function() {
        return {
            isMounted: false,
            query: {}
        }
    },
    componentDidMount: function() {

        const keys = [
            'autoheight',
            'appClassName',
            'height',
            'autoheight',
            'headertypeatplayer',
            'headertitlecolor',
            'headerbgcolor',
            'headericoncolor',
            'headericonsize',
            'breadcrumbstyle',
            'bgcolor',
            'layoutpadding',
            'layouttype',
            'sidebarwidth',
            'disabledescription',
            'disableshare',
            'disablefullscreen',
            'paletteenabledtypes',
            'paletteopen',
            'palettepinned',
            'palettepintype',
            'paletteposx',
            'paletteposy',
            'palettedisablemove',
            'palettedisableclose',
            'paletteforcepinondownbreakpoint',
            'paletteforcepinondownismobile',
            'paletteforcepinondownistouchable',
            'palettedisablcloseonlyoneiconremains',
            'palettebg',
            'palettepinnedrelative',
            'id',
            'enableshareobjectwithhash',
            'enableshareembeddedlocation',
            'enablega',
            'enableclosebox',
            'closeboxicon',
            'showboxestitle',
            'enableboxstyle',
            'enablefilter',
            'enablesharebox',
            'enablepreviouslybox',
            'enableplayerboxstyle',
            'enablecontrolstoplayerheader',
            'translations',
            'template',
            'headerlinkscolor',
            'addmenus',
            'enableclicktosoldobject',
            'prevnextnavposition',
            'infoheaderposition',
            'tooltipscale',
            'prevnextbgcolor',
            'logostyle',
            'logoimage',
            'logowidth',
            'currentviewid',
            'liststatuscolorwidth',
            'boxcontpadding',
            'webfontloaderfamilies',
            'webfontloadercustom',
            'webfontloaderurls',
            'forcefontfamily',
            'splitcontainer',
            'playerratio'
        ]

        let search = window.location.search
        if (search.slice(0, 1) === '?') search = search.slice(1)
        search = search.replace(/%20data-/g, '&data-')
        const query = decode(search)

        const r = {}
        Object.keys(query).map(function(key) {

            let k = (key.slice(0, 3) === '%20') ? key.slice(3) : key
            k = (k.slice(0, 1) === ' ') ? k.slice(1) : k
            k = (k.slice(0, 5) === 'data-') ? k.slice(5) : k

            if (keys.indexOf(k) > -1) {
                let value = query[key]
                if (value.slice(0, 1) === '"') value = value.slice(1)
                if (value.slice(-1) === '"') value = value.slice(0, -1)
                if (value.slice(0, 1) === '\'') value = value.slice(1)
                if (value.slice(-1) === '\'') value = value.slice(0, -1)
                if (value === '0' || value === 'false') value = 0
                r[k] = value
            }
        })

        this.setState({
            isMounted: true,
            query: r
        })

    },
    render: function() {
        if (this.state.isMounted) {
            return <ContentEmbed {...this.props} {...this.state.query} />
        } else {
            return null
        }
    }
})
