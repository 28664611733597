import { getRoot } from './components'

export default {
    /*jsoneditor: {
        "schema":"root",
        "name": "Root",
    },
    title: "Basic example",
    elements: [
        getRoot({
            id: "root",
            jsoneditor: {
                "schema":"image",
                "name": "Background",
                "update": [{id:"root"+"_background", data:{"deep":1, "key":"props"}}]
            },
            backgroundImage: "https://app-flatchooser.carco.hu/demo/zoomselect_dummy_building1.jpg",
            backgroundFilters: {},
            backgroundWidth: 1280,
            backgroundHeight: 720,
            backgroundStyle: {},
            //children: []
        })
    ]*/
    'jsoneditor': {
        'schema': 'root',
        'name': 'Root'
    },
    'props': {
        'settings': {
            'jsoneditor': {
                'schema': 'settings',
                'name': 'Settings'
            },
            'props': {
                'settings': [
                    {
                        'title': 'Basic example',
                        'view_id': 'area',
                        'default_effect': 'zoom',
                        'translations': [
                            {
                                'text': 'available',
                                'translate': 'Available'
                            },
                            {
                                'text': 'reserved',
                                'translate': 'Reserved'
                            },
                            {
                                'text': 'sold',
                                'translate': 'Sold'
                            }
                        ],
                        'colors': {
                            'player': {},
                            'area': {
                                'available': {
                                    defaultColor: 'rgba(0,0,0,0)',
                                    defaultStrokeColor: 'rgba(0,0,0,0)',
                                    hoverColor: 'rgba(76,175,80,0.7)',
                                    hoverStrokeColor: 'rgba(255,255,255,1)'
                                },
                                'reserved': {
                                    defaultColor: 'rgba(0,0,0,0)',
                                    defaultStrokeColor: 'rgba(0,0,0,0)',
                                    hoverColor: 'rgba(230,207,52,0.7)',
                                    hoverStrokeColor: 'rgba(255,255,255,1)'
                                },
                                'sold': {
                                    defaultColor: 'rgba(0,0,0,0)',
                                    defaultStrokeColor: 'rgba(0,0,0,0)',
                                    hoverColor: 'rgba(204,89,60,0.7)',
                                    hoverStrokeColor: 'rgba(255,255,255,1)'
                                }
                            },
                            'building': {
                                'available': {
                                    defaultColor: 'rgba(0,0,0,0)',
                                    defaultStrokeColor: 'rgba(0,0,0,0)',
                                    hoverColor: 'rgba(52,86,230,0.7)',
                                    hoverStrokeColor: 'rgba(255,255,255,1)'
                                },
                                'reserved': {
                                    defaultColor: 'rgba(0,0,0,0)',
                                    defaultStrokeColor: 'rgba(0,0,0,0)',
                                    hoverColor: 'rgba(230,207,52,0.7)',
                                    hoverStrokeColor: 'rgba(255,255,255,1)'
                                },
                                'sold': {
                                    defaultColor: 'rgba(0,0,0,0)',
                                    defaultStrokeColor: 'rgba(0,0,0,0)',
                                    hoverColor: 'rgba(204,89,60,0.7)',
                                    hoverStrokeColor: 'rgba(255,255,255,1)'
                                }
                            },
                            'flat': {
                                'available': {
                                    defaultColor: 'rgba(0,0,0,0)',
                                    defaultStrokeColor: 'rgba(0,0,0,0)',
                                    hoverColor: 'rgba(76,182,194,0.7)',
                                    hoverStrokeColor: 'rgba(255,255,255,1)'
                                },
                                'reserved': {
                                    defaultColor: 'rgba(0,0,0,0)',
                                    defaultStrokeColor: 'rgba(0,0,0,0)',
                                    hoverColor: 'rgba(230,207,52,0.7)',
                                    hoverStrokeColor: 'rgba(255,255,255,1)'
                                },
                                'sold': {
                                    defaultColor: 'rgba(0,0,0,0)',
                                    defaultStrokeColor: 'rgba(0,0,0,0)',
                                    hoverColor: 'rgba(204,89,60,0.7)',
                                    hoverStrokeColor: 'rgba(255,255,255,1)'
                                }
                            }
                        }
                    }
                ]
            }
        },
        'metas': {
            'jsoneditor': {
                'schema': 'meta',
                'name': 'Meta editor'
            },
            'props': {
                'metas': [
                    {
                        'key': 'key'
                    },
                    {
                        'name': 'Object type',
                        'show': [],
                        'order': 0,
                        'key': 'type'
                    },
                    {
                        'name': 'Object name',
                        'order': 0,
                        'key': 'name'
                    },
                    {
                        'name': 'Status',
                        'show': ['tooltip', 'popup'],
                        'order': 0,
                        'key': 'status'
                    },
                    {
                        'name': 'Number of flats',
                        'show': ['table', 'tooltip', 'popup'],
                        'order': 2,
                        'required': false,
                        'key': 'flats_number'
                    },

                    {
                        'name': 'Price',
                        'show': ['table', 'tooltip', 'popup'],
                        'order': 2,
                        'required': false,
                        'key': 'price',
                        'endfix': 'EUR',
                        'format': 'price'
                    },
                    {
                        'name': 'Floor',
                        'show': ['tooltip', 'popup'],
                        'order': 4,
                        'required': false,
                        'key': 'floor'
                    },
                    {
                        'name': 'Size',
                        'show': ['tooltip', 'popup'],
                        'order': 6,
                        'required': false,
                        'key': 'size'
                    },
                    {
                        'name': 'Rooms',
                        'show': ['tooltip', 'popup'],
                        'order': 8,
                        'required': false,
                        'key': 'rooms'
                    },

                    {
                        'name': 'PDF download',
                        'show': ['popup'],
                        'order': 102,
                        'required': false,
                        'key': 'pdf_download',
                        'format': 'download'
                    },
                    {
                        'name': 'Custom meta',
                        'show': ['table', 'tooltip', 'popup'],
                        'order': 40,
                        'required': false,
                        'type': 'string',
                        'enabled': ['building'],
                        'key': 'custom_meta_1'
                    },
                    {
                        'name': 'Custom meta',
                        'show': ['table', 'tooltip', 'popup'],
                        'order': 41,
                        'required': false,
                        'type': 'string',
                        'enabled': ['flat'],
                        'key': 'custom_meta_2'
                    }
                ]
            }
        },
        'objects': {
            'jsoneditor': {
                'schema': 'objects',
                'name': 'Object editor'
            },
            'props': {
                'objects': [
                    {
                        'key': 'area',
                        'name': 'Area',
                        'type': 'area',
                        'status': 'available'
                    },
                    {
                        'key': 'object_1',
                        'name': 'Building 1',
                        'type': 'building',
                        'status': 'available',
                        'flats_number': 6,
                        'custom_meta_1': 'Buiding custom data'
                    },
                    {
                        'key': 'object_2',
                        'name': 'Flat 1-1',
                        'type': 'flat',
                        'status': 'available',
                        'price': 100000,
                        'floor': 1,
                        'size': 72,
                        'rooms': 3,
                        'pdf_background': 'https://a.com/a.jpg',
                        'pdf_download': 'http://unec.edu.az/application/uploads/2014/12/pdf-sample.pdf',
                        'custom_meta_2': 'Flat 1 custom data'
                    },
                    {
                        'key': 'object_3',
                        'name': 'Flat 1-2',
                        'type': 'flat',
                        'status': 'reserved',
                        'price': 100000,
                        'floor': 2,
                        'size': 72,
                        'rooms': 3,
                        'pdf_background': 'https://a.com/a.jpg',
                        'pdf_download': 'http://unec.edu.az/application/uploads/2014/12/pdf-sample.pdf',
                        'custom_meta_2': 'Flat 2 custom data'
                    },
                    {
                        'key': 'object_4',
                        'name': 'Flat 1-3',
                        'type': 'flat',
                        'status': 'sold',
                        'price': 100000,
                        'floor': 3,
                        'size': 72,
                        'rooms': 3,
                        'pdf_background': 'https://a.com/a.jpg',
                        'pdf_download': 'http://unec.edu.az/application/uploads/2014/12/pdf-sample.pdf',
                        'custom_meta_2': 'Flat 3 custom data'
                    }

                ]
            }
        },
        'views': {
            'jsoneditor': {
                'schema': 'views',
                'name': 'View editor'
            },
            'props': {
                'views': [
                    {
                        'name': '',
                        'object_id': 'area',
                        'key': 'area',
                        'elements': [
                            getRoot({
                                id: 'root_area',
                                jsoneditor: {
                                    'schema': 'image',
                                    'name': 'Background',
                                    'update': [{ id: 'root_area' + '_background', data: { 'deep': 1, 'key': 'props' } }]
                                },
                                backgroundImage: 'https://app-flatchooser.carco.hu/demo/zoomselect_dummy_start.jpg',
                                backgroundFilters: {},
                                backgroundWidth: 1920,
                                backgroundHeight: 1080,
                                backgroundStyle: {},
                                children: [
                                    {
                                        'component': 'Hotspots',
                                        'jsoneditor': {
                                            'schema': 'hotspot',
                                            'name': 'Hotspots',
                                            'update': [{ id: 'hotspots_area', data: { 'deep': 1, 'key': 'props' } }]
                                        },
                                        'props': {
                                            'id': 'hotspots_area',
                                            'editable': {
                                                'resize': 1,
                                                'position': 1
                                            },
                                            'hotspots': [
                                                {
                                                    'props': {
                                                        view_id: 'view_1',
                                                        points: [[527.972027972028, 10.97978227060652], [977.0629370629371, 348.74834659870476], [977.0629370629371, 933.9968895800934], [528.6713286713286, 977.1228615863142], [134.2657342657343, 921.6951788491448], [133.56643356643357, 480.49766718507]]
                                                    }
                                                }
                                            ]
                                        }
                                    }
                                ]
                            })
                        ]
                    },
                    {
                        'name': '',
                        'object_id': 'object_1',
                        'key': 'view_1',
                        'elements': [
                            getRoot({
                                id: 'root_view_1',
                                jsoneditor: {
                                    'schema': 'image',
                                    'name': 'Background',
                                    'update': [{
                                        id: 'root_view_1' + '_background',
                                        data: { 'deep': 1, 'key': 'props' }
                                    }]
                                },
                                backgroundImage: 'https://app-flatchooser.carco.hu/demo/zoomselect_dummy_building1.jpg',
                                backgroundFilters: {},
                                backgroundWidth: 1920,
                                backgroundHeight: 1080,
                                backgroundStyle: {},
                                children: [
                                    {
                                        'component': 'Hotspots',
                                        'jsoneditor': {
                                            'schema': 'hotspot',
                                            'name': 'Hotspots',
                                            'update': [{
                                                id: 'hotspots_view_1',
                                                data: { 'deep': 2, 'key': 'hotspots' }
                                            }]
                                        },
                                        'props': {
                                            'id': 'hotspots_view_1',
                                            'editable': {
                                                'resize': 1,
                                                'position': 1
                                            },
                                            'hotspots': [
                                                {
                                                    'props': {
                                                        view_id: 'view_3',
                                                        points: [[907.1337579617834, 844.8697621744055], [1350.3312101910828, 863.6625141562854], [1351.5541401273886, 956.1007927519819], [907.3662197674937, 1003.8406922533637], [507.73885350318477, 947.2933182332956], [506.2929936305734, 876.3533408833523]]

                                                    }
                                                },
                                                {
                                                    'props': {
                                                        view_id: 'view_4',
                                                        points: [[903.1847133757962, 683.1257078142696], [1349.2738853503183, 765.0736126840318], [1350.2738853503183, 860.6274065685164], [905.4076433121019, 844.5039637599094], [507.5159235668791, 876.3533408833523], [506.2929936305734, 801.7440543601359]]
                                                    }
                                                },
                                                {
                                                    'props': {
                                                        view_id: 'view_5',
                                                        points: [[505.68132009177106, 726.264998061824], [903.6076984065729, 518.2765979190515], [1350.355179771856, 663.5788077557443], [1348.966662526835, 764.1003330231099], [903.0612813370473, 681.238285323436], [506.3004567683813, 801.7077546680842]]
                                                    }
                                                }
                                            ]
                                        }
                                    }
                                ]
                            })
                        ]
                    },
                    {
                        'name': '',
                        'object_id': 'object_2',
                        'key': 'view_3',
                        'elements': [
                            getRoot({
                                id: 'root_view_3',
                                jsoneditor: {
                                    'schema': 'image',
                                    'name': 'Background',
                                    'update': [{
                                        id: 'root_view_3' + '_background',
                                        data: { 'deep': 1, 'key': 'props' }
                                    }]
                                },
                                backgroundImage: 'https://app-flatchooser.carco.hu/demo/zoomselect_flat.png',
                                backgroundFilters: {},
                                backgroundWidth: 850,
                                backgroundHeight: 480,
                                backgroundStyle: {},
                                children: [
                                    {
                                        'component': 'Hotspots',
                                        'jsoneditor': {
                                            'schema': 'hotspot',
                                            'name': 'Hotspots',
                                            'update': [{
                                                id: 'hotspots_view_3',
                                                data: { 'deep': 2, 'key': 'hotspots' }
                                            }]
                                        },
                                        'props': {
                                            'id': 'hotspots_view_3',
                                            'editable': {
                                                'resize': 1,
                                                'position': 1
                                            },
                                            'hotspots': []
                                        }
                                    }
                                ]
                            })
                        ]
                    },
                    {
                        'name': '',
                        'object_id': 'object_3',
                        'key': 'view_4',
                        'elements': [
                            getRoot({
                                id: 'root_view_4',
                                jsoneditor: {
                                    'schema': 'image',
                                    'name': 'Background',
                                    'update': [{
                                        id: 'root_view_4' + '_background',
                                        data: { 'deep': 1, 'key': 'props' }
                                    }]
                                },
                                backgroundImage: 'https://app-flatchooser.carco.hu/demo/zoomselect_flat.png',
                                backgroundFilters: {},
                                backgroundWidth: 850,
                                backgroundHeight: 480,
                                backgroundStyle: {},
                                children: [
                                    {
                                        'component': 'Hotspots',
                                        'jsoneditor': {
                                            'schema': 'hotspot',
                                            'name': 'Hotspots',
                                            'update': [{
                                                id: 'hotspots_view_4',
                                                data: { 'deep': 2, 'key': 'hotspots' }
                                            }]
                                        },
                                        'props': {
                                            'id': 'hotspots_view_4',
                                            'editable': {
                                                'resize': 1,
                                                'position': 1
                                            },
                                            'hotspots': []
                                        }
                                    }
                                ]
                            })
                        ]
                    },
                    {
                        'name': '',
                        'object_id': 'object_4',
                        'key': 'view_5',
                        'elements': [
                            getRoot({
                                id: 'root_view_5',
                                jsoneditor: {
                                    'schema': 'image',
                                    'name': 'Background',
                                    'update': [{
                                        id: 'root_view_5' + '_background',
                                        data: { 'deep': 1, 'key': 'props' }
                                    }]
                                },
                                backgroundImage: 'https://app-flatchooser.carco.hu/demo/zoomselect_flat.png',
                                backgroundFilters: {},
                                backgroundWidth: 850,
                                backgroundHeight: 480,
                                backgroundStyle: {},
                                children: [
                                    {
                                        'component': 'Hotspots',
                                        'jsoneditor': {
                                            'schema': 'hotspot',
                                            'name': 'Hotspots',
                                            'update': [{
                                                id: 'hotspots_view_5',
                                                data: { 'deep': 2, 'key': 'hotspots' }
                                            }]
                                        },
                                        'props': {
                                            'id': 'hotspots_view_5',
                                            'editable': {
                                                'resize': 1,
                                                'position': 1
                                            },
                                            'hotspots': []
                                        }
                                    }
                                ]
                            })
                        ]
                    }

                ]
            }
        }
    }
}
