import { backgroundColor } from './basic'

export default {
    'type': 'object',
    'hidden': true,
    'fields': {
        'props': {
            'type': 'object',
            'hidden': true,
            'fields': {
                'galleries': {
                    'type': 'array',
                    'fieldsName': function(data, props) {
                        const objects = props.datas[2].objects.props.objects

                        const gallery_name = data.name
                        const object_id = data.object_id
                        let foundObject = null
                        if (object_id) {
                            objects.map(function(o) {
                                if (object_id == o.key) foundObject = o
                            })
                        }
                        const name = (foundObject) ? foundObject.name : ''

                        let rName = data.key
                        if (gallery_name) rName = gallery_name
                        if (name) rName = rName + ' [' + name + ']'
                        return rName

                    },
                    'name': 'Galleries',
                    'add': {
                        'name': '',
                        'key': function() {
                            return 'gallery_' + new Date().getTime()
                        }
                    },
                    'fields': {},
                    'update': async function(p = {}) {
                        const { props = {} } = p
                        const root = props.root
                        await root.refElements.views.regenerateForm()
                    },
                    'innerFormProps': {
                        'onOpen': function() {
                            return async function(p = {}) {
                                const { props = {} } = p
                                const root = props.root
                                const rootProps = root.props
                                const { refElements = {} } = root
                                refElements.views.close()
                                refElements.objects.close()
                                refElements.settings.close()
                                refElements.metas.close()
                                refElements.animations.close()
                            }
                        }
                    },
                    'autoFields': {
                        'enable': {
                            'default': {
                                'type': 'object',
                                'remove': function({ data, key }) {
                                    return true
                                },
                                'fields': {
                                    'name': {
                                        'type': 'string',
                                        'name': 'Gallery name (optional)',
                                        'default': '',
                                        'required': false,
                                        'validations': [
                                            {
                                                'name': 'max',
                                                'props': { 'value': 250, 'type': 'string' }
                                            }, {
                                                'name': 'type',
                                                'props': { 'value': ['string'] }
                                            }
                                        ],
                                        'update': async function(p = {}) {
                                            const { props = {}, data = {} } = p

                                            const parent = props.parent
                                            await parent.regenerateForm()
                                        }
                                    },
                                    'images': {
                                        'type': 'array',
                                        'fieldsName': 'name',
                                        'name': 'Images',
                                        'add': {
                                            'name': 'Image'
                                        },
                                        'fields': {},
                                        'autoFields': {
                                            'enable': {
                                                'default': {
                                                    'type': 'object',
                                                    'remove': function({ data, key }) {
                                                        return true
                                                    },
                                                    'fields': {
                                                        'image_url': {
                                                            'component': 'imageurl',
                                                            'type': 'string',
                                                            'name': 'image url (https://...) [jpg, png]',
                                                            'default': '',
                                                            'required': true,
                                                            'validations': [{
                                                                'name': 'url'
                                                            }, {
                                                                'name': 'path',
                                                                'props': {
                                                                    'value': ['jpg', 'jpeg', 'png']
                                                                }
                                                            }, {
                                                                'name': 'max',
                                                                'props': { 'value': 1000, 'type': 'string' }
                                                            }, {
                                                                'name': 'type',
                                                                'props': { 'value': ['string'] }
                                                            }]
                                                        },
                                                        'name': {
                                                            'type': 'string',
                                                            'name': 'Image name (optional)',
                                                            'default': '',
                                                            'required': false,
                                                            'validations': [
                                                                {
                                                                    'name': 'max',
                                                                    'props': { 'value': 250, 'type': 'string' }
                                                                }, {
                                                                    'name': 'type',
                                                                    'props': { 'value': ['string'] }
                                                                }
                                                            ],
                                                            'update': async function(p = {}) {
                                                                const { props = {}, data = {} } = p
                                                                const parent = props.parent
                                                                await parent.regenerateForm()
                                                            }
                                                        },
                                                        'description': {
                                                            'type': 'string',
                                                            'name': 'Image description (optional)',
                                                            'default': '',
                                                            'required': false,
                                                            'validations': [
                                                                {
                                                                    'name': 'max',
                                                                    'props': { 'value': 2500, 'type': 'string' }
                                                                }, {
                                                                    'name': 'type',
                                                                    'props': { 'value': ['string'] }
                                                                }
                                                            ]
                                                        },
                                                        'backgroundColor': backgroundColor
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    'object_id': {},
                                    'add_view_image': {
                                        'type': 'boolean',
                                        'name': 'Add view image',
                                        'default': false,
                                        'disabled': function({ data, key }) {
                                            return false
                                        },
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['boolean'] }
                                            }
                                        ]
                                    },
                                    'view_image_backgroundColor': backgroundColor,
                                    'key': {
                                        'type': 'string',
                                        'default': 'gallery_key',
                                        'disabled': true,
                                        'validations': [
                                            {
                                                'name': 'min',
                                                'props': { 'value': 2, 'type': 'string' }
                                            }, {
                                                'name': 'max',
                                                'props': { 'value': 250, 'type': 'string' }
                                            }, {
                                                'name': 'type',
                                                'props': { 'value': ['string'] }
                                            }
                                        ]
                                    }
                                }
                            },
                            'dinamic': function(p = {}) {

                                const r = {
                                    'object_id': {
                                        'component': 'select',
                                        'type': 'string',
                                        'name': 'Connect an object',
                                        'default': null,
                                        'options': [
                                            { 'label': 'No object', 'value': null }
                                        ],
                                        'multiple': false,
                                        'disabled': function({ data, key }) {
                                            return false
                                        },
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['object'] }
                                            }, {
                                                'name': 'arrayin',
                                                'props': { 'value': [null] }
                                            }
                                        ],
                                        'update': async function(p = {}) {
                                            const { props = {}, data = {} } = p
                                            const parent = props.parent
                                            await parent.regenerateForm()
                                            const root = props.root
                                            await root.refElements.views.regenerateForm()
                                        }
                                    }
                                }

                                const data = p.data[p.key][p.i]
                                const objects = p.parentData.parentData.datas[2].objects.props.objects

                                if (objects && objects.length) {
                                    objects.map(function(object) {
                                        const key = object.key
                                        const name = object.name
                                        r['object_id'].options.push({
                                            label: name,
                                            value: key
                                        })
                                        r['object_id'].validations[1].props.value.push(key)
                                    })
                                }

                                return { fields: r }

                            }
                        }
                    }
                }
            }
        }
    }
}
