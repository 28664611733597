import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

import CharliesPlayerOld from '../CharliesPlayerOld'
import cpos from '../CharliesPlayerOld/CharliesPlayerOld.css'

const Editor = createReactClass({
    getInitialState() {

        this.refElements = {}
        const data = this.getData()
        const post = this.getPost()

        return {
            data: data,
            post: post,
            isMounted: true
        }
    },
    componentDidMount: function() {
        const { hooks } = this.props
        const save = this.save
        hooks.getData = function(p = {}) {
            const { callback } = p
            return save({ callback })
        }
    },
    componentWillUnmount: function() {
        this.setState({
            isMounted: false
        })
    },
    getStore: function() {
        const { construct } = this.props
        return (construct.props && construct.props.store) ? construct.props.store : null
    },
    getGlobalState: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct.props.state : teststate
    },
    getPost: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.currentpost && state.response.currentpost[0]) ? state.response.currentpost[0] : null
    },
    getData: function() {
        const { construct } = this.props
        return (construct.props && construct.props.data) ? construct.props.data : null
    },

    /*Refs*/

    getElement: function(e) {
        return (e) ? (e.nodeName) ? e : ReactDOM && ReactDOM.findDOMNode && ReactDOM.findDOMNode(e) : null
    },
    setRef: function(a, e) {
        const { setRef } = this.props
        if (e) {
            this.refElements[a] = e
            if (setRef) setRef(a, e)
        }
    },

    /*Form*/

    save: function(p = {}) {
        const { callback } = p
        const editor = this.refElements['charliesplayerold']
        const { data } = this.state
        const { construct, reloadPlayerAfterSave } = this.props
        const reload = this.reload

        if (editor && editor.getData) {
            editor.getData(function(d) {
                if (d) {
                    const dataObject = JSON.parse(d)
                    Object.keys(dataObject.data).map(function(key) {
                        construct.props.data[key] = dataObject.data[key]
                    })
                    const r = { data: construct.props.data, form: dataObject.form }
                    if (callback) callback(r)

                    if (reloadPlayerAfterSave) {
                        setTimeout(function() {
                            reload()
                        }, 100)
                    }

                    return r
                }
            })
        } else {
            if (callback) callback(data)

            if (reloadPlayerAfterSave) {
                setTimeout(function() {
                    reload()
                }, 100)
            }

        }
    },
    reload: function() {
        this.setState({
            time: new Date().getTime()
        })
    },

    render: function() {
        const { style, construct } = this.props
        const { post } = this.state
        const setRef = this.setRef

        return (
            <div className={style.editor}>
                <CharliesPlayerOld construct={construct} post={post} setRef={setRef} style={cpos} editor={true} />
            </div>
        )
    }
})

const Middle = createReactClass({
    setRef: function(a, e) {
        const { setRef } = this.props
        const { construct } = this.props
        const setRefConstruct = (construct.props) ? construct.props.setRef : null
        if (setRef) setRef(a, e)
        if (setRefConstruct) setRefConstruct(a, e)
    },
    render: function() {
        const setRef = this.setRef
        return (
            <Editor {...this.props} ref={(setRef) ? function(e) {
                setRef('editor', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})
