import Text from '../../../components/Text/formsy'
import tes from '../../../components/Text/Text.css'

import NumberComponent from '../../../components/Number/formsy'
import nus from '../../../components/Number/Number.css'

import Select from '../../../components/Select/formsy'
import ses from '../../../components/Select/Select.css'

import Toggle from '../../../components/Toggle/formsy'
import tos from '../../../components/Toggle/Toggle.css'

import Slider from '../../../components/Slider/formsy'
import sls from '../../../components/Slider/Slider.css'

import ColorPicker from '../../../components/ColorPicker/formsy'
import cps from '../../../components/ColorPicker/ColorPicker.css'

import { defaultUpdate, defaultValidUpdate, getValidations } from '../validations'

export default {
    innerform: function(p = {}) {
        const { attr = {}, data, key, field, schemaProps, schema, datas, deep } = p

        const id = (key && typeof key == 'string' && typeof data[key] == 'object') ?
            key :
            (data && data.props && data.props.id) ?
                data.props.id :
                schemaProps.schema + '_' + deep + '_' + key

        const dataObject = (data && key && data[key] && typeof data[key] == 'object') ? data[key] : (data && typeof data == 'object') ? data : null

        if (dataObject) {
            Object.defineProperty(dataObject, '__jsoneditorId', {
                value: id,
                enumerable: false,
                configurable: true
            })
        }

        return {
            type: 'innerform',
            attr: {
                name: id,
                deep: deep,
                ...attr,
                onChange: function(state, props) {
                    return function({ value }) {
                        defaultUpdate({ ...p, props })
                    }
                }
            }
        }
    },
    number: function(p = {}) {
        const { label, data, key, field, schemaProps, datas, deep } = p

        const { validations } = field
        const required = (field.required == false || field.required == true) ? field.required : null
        const requiredUseWithDisableSubmit = (required && field.requiredUseWithDisableSubmit) ? required : null
        const validationsObject = getValidations(validations, required)

        const disabled = (typeof field.disabled == 'function') ? field.disabled(p) : field.disabled

        return {
            component: NumberComponent,
            type: 'text',
            attr: {
                ...validationsObject,
                name: key,
                hintText: label,
                floatingLabelText: label,
                required: requiredUseWithDisableSubmit,
                onChange: function(state, props) {
                    return function({ setValue, value }) {
                    }
                },
                onBlur: function(state, props) {
                    return function({ setValue, value }) {
                        const v = (!isNaN(Number(value))) ? Number(value) : (value) ? value : 0
                        defaultValidUpdate({ ...p, value: v, props, setValue })
                    }
                },
                style: function() {
                    return nus
                },
                value: function(state, props) {
                    if (data[key] == 0 || !data[key]) return '0'
                    return data[key]
                },
                disabled: disabled
            }
        }
    },
    string: function(p = {}) {
        const { label, data, key, field = {}, schemaProps, datas, deep } = p

        const { validations, multiline = false } = field
        const required = (field.required == false || field.required == true) ? field.required : null
        const requiredUseWithDisableSubmit = (required && field.requiredUseWithDisableSubmit) ? required : null
        const validationsObject = getValidations(validations, required)

        const disabled = (typeof field.disabled == 'function') ? field.disabled(p) : field.disabled

        return {
            component: Text,
            type: 'text',
            attr: {
                ...validationsObject,
                name: key,
                hintText: label,
                floatingLabelText: label,
                required: requiredUseWithDisableSubmit,
                multiLine: multiline,
                onChange: function(state, props) {
                    return function({ setValue, value }) {
                    }
                },
                onBlur: function(state, props) {
                    return function({ setValue, value }) {
                        defaultValidUpdate({ ...p, value, props, setValue })
                    }
                },
                style: function() {
                    return tes
                },
                value: function(state, props) {
                    return data[key]
                },
                disabled: disabled
            }
        }
    },
    select: function(p = {}) {
        const { label, data, key, field, schemaProps, datas, deep } = p

        const options = (field.options && field.options.length) ? field.options : null
        const multiple = field.multiple

        const { validations } = field
        const required = (field.required == false || field.required == true) ? field.required : null
        const requiredUseWithDisableSubmit = (required && field.requiredUseWithDisableSubmit) ? required : null
        const validationsObject = getValidations(validations, required)

        const disabled = (typeof field.disabled == 'function') ? field.disabled(p) : field.disabled

        return {
            component: Select,
            type: 'select',
            attr: {
                ...validationsObject,
                name: key,
                hintText: label,
                floatingLabelText: label,
                required: requiredUseWithDisableSubmit,
                dropDownMenuProps: function(state, props) {
                    const { root = {} } = props
                    const { getRootContainer } = root.props
                    return {
                        parentContainer: getRootContainer()
                    }
                },
                onChange: function(state, props) {
                    return function({ setValue, setState, value }) {

                        const transFormValue = function(value) {
                            let v = null
                            if (multiple) {
                                if (value && value.length) {
                                    v = [...value]
                                } else {
                                    v = []
                                }
                            } else {
                                if (value && value[0] || value && value[0] == false) {
                                    v = value[0]
                                } else {
                                    v = ''
                                }
                            }
                            return v
                        }

                        defaultValidUpdate({
                            ...p, value, props, setValue,
                            hasChanged: function() {
                                const oldValue = data[key]
                                const v = transFormValue(value)
                                if (multiple) {
                                    if (oldValue && oldValue.length == v.length) {
                                        let found = 0
                                        v.map(function(vi) {
                                            if (oldValue.indexOf(v[vi]) > -1) found = found + 1
                                        })
                                        if (found !== oldValue.length) return true
                                    } else {
                                        return true
                                    }
                                } else {
                                    if (oldValue !== v) return true
                                }
                                return false
                            },
                            beforeSetValue: function() {
                                const v = transFormValue(value)
                                data[key] = v
                            }
                        })

                    }
                },
                options: options,
                disabled: (!options) ? true : disabled,
                multiple: multiple,
                selectedMenuItemStyle: { color: '#ffffff', backgroundColor: '#3f454a' },
                style: function() {
                    return ses
                },
                value: function() {
                    if (!data[key]) {
                        if (options) {
                            let foundnull = false
                            let foundnullnumber = false
                            let foundemptystring = false
                            let foundfalse = false
                            Object.keys(options).map(function(key) {
                                if (options.value == null) foundnull = true
                                if (options.value == 0) foundnullnumber = true
                                if (options.value == '') foundemptystring = true
                                if (options.value == false) foundfalse = true
                            })
                            if (foundnull) return [null]
                            if (foundnullnumber) return [0]
                            if (foundemptystring) return ['']
                            if (foundfalse) return [false]
                        }
                        return []
                    }
                    if (typeof data[key] == 'object') return [...data[key]]
                    return [data[key]]
                }
            }
        }
    },
    boolean: function(p = {}) {

        const { label, data, key, field, schemaProps, datas, deep } = p

        const { validations } = field
        const required = (field.required == false || field.required == true) ? field.required : null
        const requiredUseWithDisableSubmit = (required && field.requiredUseWithDisableSubmit) ? required : null
        const validationsObject = getValidations(validations, required)

        const disabled = (typeof field.disabled == 'function') ? field.disabled(p) : field.disabled

        return {
            component: Toggle,
            type: 'toggle',
            attr: {
                ...validationsObject,
                required: requiredUseWithDisableSubmit,
                name: key,
                label: label,
                style: function(state, props) {
                    return tos
                },
                onChange: function(state, props) {
                    return function({ setValue, value }) {
                        const v = (value == true || value == false) ? value : false
                        defaultValidUpdate({ ...p, value: v, props, setValue })
                    }
                },
                value: function(state, props) {
                    return (data[key] == false || data[key] == true) ? data[key] : false
                },
                defaultToggled: function(state, props) {
                    return (data[key] == false || data[key] == true) ? data[key] : false
                },
                disabled: disabled
            }
        }

    },
    slider: function(p = {}) {
        const { label, data, key, field, schemaProps, datas, deep } = p

        const min = (field.min) ? field.min : 0
        const max = (field.max) ? field.max : 100
        const step = (field.step) ? field.step : 1

        const { validations } = field
        const required = (field.required == false || field.required == true) ? field.required : null
        const requiredUseWithDisableSubmit = (required && field.requiredUseWithDisableSubmit) ? required : null
        const validationsObject = getValidations(validations, required)
        const getIsValid = validationsObject.isValid()

        let lastSavedData = data[key]

        return {
            component: Slider,
            type: 'slider',
            attr: {
                ...validationsObject,
                required: requiredUseWithDisableSubmit,
                name: key,
                floatingLabelText: label,
                onChange: function(state, props) {
                    return function({ setValue, value }) {
                        const v = (!isNaN(Number(value))) ? Number(value) : value
                        const { isValid } = getIsValid(v)
                        if (isValid) {
                            data[key] = v
                            defaultUpdate({ ...p, props })
                        }
                    }
                },
                onBlur: function(state, props) {
                    return function({ setValue, value }) {

                        let v = (!isNaN(Number(value))) ? Number(value) : value
                        defaultValidUpdate({
                            ...p, value: v, props, setValue,
                            hasChanged: function() {
                                if (v !== lastSavedData) {
                                    lastSavedData = v
                                    return true
                                }
                                return false
                            }
                        })
                    }
                },
                style: function(state, props) {
                    return sls
                },
                sliderClassName: function(state, props) {
                    const { root = {} } = props
                    const { floatingStyle } = root.props
                    return floatingStyle.disablescale
                },
                min: min,
                max: max,
                step: step,
                value: function() {
                    return data[key]
                }
            }
        }
    },
    colorpicker: function(p = {}) {
        const { label, data, key, field = {}, schemaProps, datas, deep } = p

        const { validations } = field
        const required = (field.required == false || field.required == true) ? field.required : null
        const requiredUseWithDisableSubmit = (required && field.requiredUseWithDisableSubmit) ? required : null
        const validationsObject = getValidations(validations, required)

        return {
            component: ColorPicker,
            type: 'colorpicker',
            attr: {
                ...validationsObject,
                required: requiredUseWithDisableSubmit,
                name: key,
                hintText: label,
                floatingLabelText: label,
                style: function(state, props) {
                    return cps
                },
                onChange: function(state, props) {
                    return function({ setValue, value }) {
                    }
                },
                onBlur: function(state, props) {
                    return function({ setValue, value }) {
                        defaultValidUpdate({ ...p, value, props, setValue })
                    }
                },
                pickersClassName: function(state, props) {
                    const { root = {} } = props
                    const { floatingStyle } = root.props
                    return floatingStyle.disablescale
                },
                value: function(state, props) {
                    return data[key]
                }
            }
        }
    },
    imageurl: function(p = {}) {
        const { label, data, key, field, schemaProps, datas, deep } = p

        const { validations } = field
        const required = (field.required == false || field.required == true) ? field.required : null
        const requiredUseWithDisableSubmit = (required && field.requiredUseWithDisableSubmit) ? required : null
        const validationsObject = getValidations(validations, required)
        const getIsValid = validationsObject.isValid()

        return {
            component: Text,
            type: 'text',
            attr: {
                ...validationsObject,
                required: requiredUseWithDisableSubmit,
                name: key,
                hintText: label,
                floatingLabelText: label,
                onChange: function(state, props) {
                    return function({ setValue, value }) {
                    }
                },
                onBlur: function(state, props) {
                    return async function({ setValue, value }) {

                        const { isValid } = getIsValid(value)
                        data[key] = value

                        if (value) {
                            if (isValid) {
                                const image = new Image()
                                image.onload = async function() {
                                    data.width = image.width
                                    data.height = image.height
                                    await setValue(value)
                                    defaultUpdate({ ...p, props })
                                }

                                function error(e) {
                                    const { parent = {} } = props
                                    const t = parent.refElements[key]
                                    t.setExternalError({ value, error: 'unloadable image' })
                                }
                                image.onabort = function(e) {
                                    error(e)
                                }
                                image.onerror = function(e) {
                                    error(e)
                                }
                                image.src = value

                            } else {
                                setValue(value)
                            }
                        } else {
                            await setValue(value)
                            if (isValid) {
                                defaultUpdate({ ...p, props })
                            }

                        }
                    }
                },
                style: function() {
                    return tes
                },
                value: function(state, props) {
                    return data[key]
                }
            }
        }
    }
}
