import React from 'react'
import createReactClass from 'create-react-class'
import ReactDOM from 'react-dom'
import TemplateDefault from '../templates/template_default'

import { wapplrhelpers } from '../../../../../../../components'
import Player from '../components/Player'
import PlayerControls from '../components/Player/controls'
import pls from '../components/Player/Player.css'

import Editor from '../components/Editor'
import EditorControls from '../components/Editor/controls'
import eds from '../components/Editor/Editor.css'

import PlayerOld from '../components/PlayerOld'
import plso from '../components/PlayerOld/PlayerOld.css'

import EditorOld from '../components/EditorOld'
import edso from '../components/EditorOld/EditorOld.css'

import { addDefaultMetas } from '../services/editdata'

import { getGA } from './helpers'
import ContentShare from './content_share'

import Controls from '../components/Controls'
import cts from '../components/Controls/Controls.css'

import BackIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-left'

const wapplravatarhelpers = wapplrhelpers.avatar

export default createReactClass({

    getInitialState: function() {
        this.refElements = {}
        const data = this.getData()
        const appver = this.getAppver()
        if (appver !== '1.0') addDefaultMetas({ data })
        return {
            isMounted: true
        }
    },
    getGlobalState: function() {
        const { construct } = this.props
        return (construct.props && construct.props.state) ? construct.props.state : null
    },
    getMe: function() {
        const state = this.getGlobalState()
        const me = (state && state.response && state.response.me) ? state.response.me : null
        if (me) wapplravatarhelpers({ user: me })
        return me
    },
    getUser: function() {
        const post = this.getPost()
        if (post && post.author && post.author.id) {
            const author = post.author
            if (author) wapplravatarhelpers({ user: author })
            return author
        }
        return null
    },
    getPost: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.currentpost && state.response.currentpost[0]) ? state.response.currentpost[0] : null
    },
    getData: function() {
        const { construct } = this.props
        return (construct.props && construct.props.data) ? construct.props.data : null
    },
    getSchema: function() {
        const { construct } = this.props
        return (construct.props && construct.props.schema) ? construct.props.schema : null
    },
    getPaletteProps: function() {

        const { construct } = this.props
        const schema = this.getSchema()
        const appver = this.getAppver()

        const paletteProps = (construct.props && construct.props.paletteprops) ? construct.props.paletteprops : {}

        return (schema) ? {
            pinned: true,
            pintype: 'down',
            disableChangePin: true,
            disableChangePinType: true,
            disableMove: true,
            disableClose: true, ...paletteProps
        } : { ...paletteProps }
    },
    getSomeAnotherData: function() {
        const { construct } = this.props
        const props = (construct.props && construct.props) ? construct.props : {}
        return props
    },
    getAppver: function() {
        const post = this.getPost()
        return (post && post.appver && post.appver == '1.0' || post && post.appver && post.appver == '2.0') ? post.appver : '1.0'
    },
    getIsEditor: function() {
        const { construct } = this.props
        return (construct.props && construct.props.iseditor) ? construct.props.iseditor : null
    },
    resize: function() {
        const player = this.refElements['player']
        const editor = this.refElements['editor']
        if (player && player.resize) {
            player.resize()
        }
        if (editor && editor.resize) {
            editor.resize()
        }
    },
    onFullScreen: function() {
        const resize = this.resize
        setTimeout(function() {
            resize()
        }, 200)
    },


    getElement: function(e) {
        return (e) ? (e.nodeName) ? e : ReactDOM && ReactDOM.findDOMNode && ReactDOM.findDOMNode(e) : null
    },
    setRef: function(a, e) {
        if (e) {
            this.refElements[a] = e
        }
    },

    /*For contols*/
    onOpenEditor: function() {
        const editor = this.refElements['editor']
        if (editor) {
            editor.open()
        }
    },
    onCloseEditor: function() {
        const editor = this.refElements['editor']
        if (editor) {
            editor.close()
        }
    },

    onOpenPlayerControls: function() {
        const player = this.refElements['player']
        if (player) {
            player.open()
        }
    },
    onClosePlayerControls: function() {
        const player = this.refElements['player']
        if (player) {
            player.close()
        }
    },
    setCurrentView: function(p) {
        const player = this.refElements['player']
        if (player) {
            player.setCurrentView(p)
        }
    },
    getControls: function() {
        const iseditor = this.getIsEditor()
        const appver = this.getAppver()
        const setRef = this.setRef
        const schema = this.getSchema()
        const { open = true } = this.getPaletteProps()
        const { construct } = this.props
        const refElements = this.refElements
        const setCurrentView = this.setCurrentView
        const { disableopentoggleicon } = this.getSomeAnotherData()

        if (iseditor && appver == '1.0') {
            return null
        }

        if (iseditor) {
            return (
                <EditorControls
                    setCurrentView={setCurrentView}
                    construct={construct}
                    refElements={refElements}
                    setRef={setRef}
                    style={eds}
                    open={open}
                    onOpen={this.onOpenEditor}
                    onClose={this.onCloseEditor}
                />
            )
        }

        if (!iseditor && appver == '1.0') {
            return null
        }

        if (!iseditor) {

            const { enablecontrolstoplayerheader, enableplayerboxstyle } = this.getSomeAnotherData()

            if (enablecontrolstoplayerheader && enableplayerboxstyle) {

                const onFullScreen = this.onFullScreen

                return <Controls {...this.props} style={cts} onFullScreen={onFullScreen}
                                 containerStyle={{ height: '48px', marginRight: '-8px' }}
                >
                    <PlayerControls
                        setCurrentView={setCurrentView}
                        construct={construct}
                        refElements={refElements}
                        setRef={setRef}
                        style={pls}
                        open={open}
                        onOpen={this.onOpenPlayerControls}
                        onClose={this.onClosePlayerControls}
                        disableopentoggleicon={disableopentoggleicon}
                    />
                </Controls>

            }

            return (
                <PlayerControls
                    setCurrentView={setCurrentView}
                    construct={construct}
                    refElements={refElements}
                    setRef={setRef}
                    style={pls}
                    open={open}
                    onOpen={this.onOpenPlayerControls}
                    onClose={this.onClosePlayerControls}
                    disableopentoggleicon={disableopentoggleicon}
                />
            )
        }

        return null
    },
    onChangePin: function({ open, pinned, pintype }) {
        const { containerType } = this.getContainerType()
        const headerContainer = this.refElements['headercontainer']

        if (headerContainer && containerType == 'fullpage') {
            if (open && pinned && pintype == 'right') {
                headerContainer.style.maxWidth = 'calc(100% - 350px)'
            } else {
                headerContainer.style.maxWidth = '100%'
            }
        }

    },
    getContainerType: function() {

        const appver = this.getAppver()
        const iseditor = this.getIsEditor()
        const {
            headertypeatplayer,
            alignitems,
            headertitlecolor,
            headerlinkscolor,
            headerbgcolor,
            headericoncolor,
            headericonsize,
            customcss,
            breadcrumbstyle,
            bgcolor = '#f5f6f7'
        } = this.getSomeAnotherData()

        let containerType = 'fullpagewithoutpaddingswithheader'
        let containerStyle = { backgroundColor: bgcolor }
        let floatingPalettePosMinY = 0
        let alignItems = null

        switch (alignitems) {
            case 'center':
                alignItems = 'center'
                break
            case 'start':
                alignItems = 'start'
                break
            default:
        }

        switch (headertypeatplayer) {
            case 'normal':
                containerType = 'normal'
                containerStyle = { backgroundColor: bgcolor }
                floatingPalettePosMinY = 0
                break
            case 'fullpage':
                containerType = 'fullpage'
                containerStyle = { backgroundColor: bgcolor }
                floatingPalettePosMinY = 64
                break
            case 'video':
                containerType = 'video'
                containerStyle = { backgroundColor: bgcolor }
                floatingPalettePosMinY = 64
                break
            case 'fullpagewithheader':
                containerType = 'fullpagewithoutpaddingswithheader'
                containerStyle = { backgroundColor: bgcolor }
                floatingPalettePosMinY = 0
                break
            default:
        }

        if (appver == '1.0') containerType = 'fullpage'
        if (appver == '1.0') containerStyle = { backgroundColor: '#ffffff' }

        return {
            containerType,
            containerStyle,
            floatingPalettePosMinY,
            headertitlecolor,
            headerlinkscolor,
            headerbgcolor,
            headericoncolor,
            headericonsize,
            customcss,
            breadcrumbstyle,
            alignItems
        }

    },

    getViews: function() {
        const data = this.getData()
        const views = (data) ? data.props.views.props.views : []
        return views
    },
    getSettings: function() {
        const data = this.getData()
        const settings = (data) ? data.props.settings.props.settings[0] : {}
        return settings
    },
    getStartView: function() {
        const settings = this.getSettings()
        const startView = settings.view_id
        const views = this.getViews()

        let foundStartView = false
        views.map(function(view) {
            const key = view.key
            if (startView && key == startView) foundStartView = true
        })

        if (foundStartView) return startView
        return views[0].key

    },
    translate: function({ text }) {
        const settings = this.getSettings() || {}
        const { construct } = this.props
        const { translations = [] } = (construct && construct.props && construct.props.translations) ? { translations: construct.props.translations } : settings
        let translatedText = null
        if (typeof text == 'string' && text) {
            translations.map(function(translate) {
                if (!translatedText && translate.text && translate.translate && translate.text == text) {
                    translatedText = translate.translate
                }
            })
        }
        if (translatedText) return translatedText
        return text
    },
    sendToTracker: function(p) {
        const { view_id } = p
        const post = this.getPost()
        const startView = this.getStartView()
        let url = '/player/' + post.id
        if (view_id && startView !== view_id) url = url + '#' + post.id + view_id
        const ga = new getGA({ ...this.props })
        ga.addGA({
            url: url
        })
    },
    onChangeView: function(p) {
        this.sendToTracker(p)
    },
    getAddMenus: function() {

        const { addmenus } = this.getSomeAnotherData()
        if (!addmenus) return undefined

        const translate = this.translate
        const r = (typeof addmenus == 'string') ? JSON.parse(addmenus) : addmenus

        r.map(function(menu) {
            if (menu.link || menu.onClick) {
                const url = menu.link
                const onClick = menu.onClick
                const target = menu.target
                if (target == '_blank' && url) {
                    menu.onClick = undefined
                } else {
                    menu.onClick = function(props, e) {
                        e.preventDefault()
                        setTimeout(function() {
                            if (onClick && onClick == 'historyBack') {
                                window.history.back()
                            } else if (url) {
                                window.location.href = url
                            } else {
                                console.log('Missing link')
                            }
                        })
                        return true
                    }
                    menu.link = undefined
                }
            }

            if (menu.name) menu.name = translate({ text: menu.name })
            menu.disableParentRoute = true

            if (typeof menu.lefticon == 'string') {
                if (menu.lefticon == 'BackIcon') menu.lefticon = <BackIcon />
            }
        })

        return r
    },
    getCenterChildren: function() {
        return null
    },
    render: function() {

        const { style, history, getthumb } = this.props

        const me = this.getMe()
        const post = this.getPost()
        const user = this.getUser()
        const schema = this.getSchema()
        const iseditor = this.getIsEditor()
        const appver = this.getAppver()
        const setRef = this.setRef
        const refElements = this.refElements
        const onFullScreen = this.onFullScreen
        const controlsChildren = this.getControls()
        const onChangePin = this.onChangePin
        const onChangeView = this.onChangeView
        const addMenus = this.getAddMenus()
        const centerChildren = this.getCenterChildren()

        const {
            containerType,
            containerStyle,
            floatingPalettePosMinY,
            headertitlecolor,
            headerlinkscolor,
            headerbgcolor,
            headericoncolor,
            headericonsize,
            customcss,
            breadcrumbstyle,
            alignItems
        } = this.getContainerType()

        const floatingPaletteProps = { ...this.getPaletteProps(), palettePosMinY: floatingPalettePosMinY }

        const {
            enablepreviouslybox,
            showboxestitle,
            enableboxstyle,
            enableclosebox,
            enablefilter,
            enablesharebox,
            closeboxicon,
            enableplayerboxstyle,
            enablecontrolstoplayerheader,
            layoutpadding,
            layouttype,
            boxcontpadding,
            sidebarwidth,
            disableInternalHistory,
            logostyle,
            logoimage,
            logowidth,
            forcefontfamily,
            playerbgcolor,
            boxesorder,
            prevnextnavhidetitle,
            playerratio,
            setfixheight,
            splitcontainer
        } = this.getSomeAnotherData()

        return (
            <TemplateDefault {...this.props}
                             setRef={setRef}
                             containerType={containerType}
                             setfixheight={(setfixheight) ? pls.setfixheight : null}
                             alignItems={alignItems}
                             onFullScreen={onFullScreen}
                             containerStyle={containerStyle}
                             controlsChildren={(!iseditor && appver !== '1.0' && enablecontrolstoplayerheader && enableplayerboxstyle) ? null : controlsChildren}
                             disableControls={(!iseditor && appver !== '1.0' && enablecontrolstoplayerheader && enableplayerboxstyle) ? true : false}
                             logotext={''}
                             logospan={''}
                             logostyle={logostyle}
                             logoimage={logoimage}
                             logowidth={logowidth}
                             headertitlecolor={headertitlecolor}
                             headerlinkscolor={headerlinkscolor}
                             headerbgcolor={headerbgcolor}
                             headericoncolor={headericoncolor}
                             headericonsize={headericonsize}
                             customcss={customcss}
                             breadcrumbstyle={breadcrumbstyle}
                             disablecoltrolsandbreadcrumstyle={(enableplayerboxstyle && enablecontrolstoplayerheader) ? true : false}
                             playerstyle={(iseditor) ? eds : pls}
                             path={''}
                             addMenus={addMenus}
                             centerChildren={(enableplayerboxstyle && enablecontrolstoplayerheader) ? centerChildren : null}
                             forcefontfamily={forcefontfamily}
                             hideHeaderClass={pls.hideheader}
            >
                {(iseditor) ?
                    (appver == '1.0' && !schema) ?
                        <EditorOld {...this.props} setRef={setRef} style={edso} reloadPlayerAfterSave={false} /> :
                        <Editor {...this.props}
                                Player={(appver == '1.0') ? PlayerOld : undefined}
                                playerProps={(appver == '1.0') ? { style: plso } : (schema) ? { editor: false } : undefined}
                                reloadPlayerAfterSave={(appver == '1.0') ? true : (schema) ? true : false}
                                floatingPaletteProps={floatingPaletteProps}
                                setRef={setRef} style={eds}
                                getControls={function(e) {
                                    return refElements['editorcontrols']
                                }}
                                rootClassName={style.playerroot}
                                onChangePin={onChangePin}
                        /> :
                    (appver == '1.0') ?
                        <PlayerOld {...this.props} setRef={setRef} style={plso} /> :
                        <Player {...this.props}
                                setRef={setRef}
                                style={pls}
                                getControls={function(e) {
                                    return refElements['playercontrols']
                                }}
                                rootClassName={style.playerroot}
                                floatingPaletteProps={floatingPaletteProps}
                                onChangePin={onChangePin}
                                onChangeView={onChangeView}

                                enablePreviouslyBox={enablepreviouslybox}
                                showBoxesTitle={showboxestitle}
                                enableCloseBox={enableclosebox}
                                closeBoxIcon={closeboxicon}
                                enableBoxStyle={enableboxstyle}
                                boxContPadding={boxcontpadding}
                                enableFilter={enablefilter}
                                enableShareBox={enablesharebox}
                                boxesorder={boxesorder}
                                prevnextnavhidetitle={prevnextnavhidetitle}
                                ShareComponent={ContentShare}
                                enablePlayerBoxStyle={enableplayerboxstyle}
                                enableControlsToPlayerHeader={enablecontrolstoplayerheader}
                                playerHeaderChildren={(enablecontrolstoplayerheader && enableplayerboxstyle) ? controlsChildren : null}
                                layoutType={layouttype}
                                layoutPadding={layoutpadding}
                                sidebarWidth={sidebarwidth}
                                playerbgcolor={playerbgcolor}
                                playerratio={playerratio}
                                splitcontainer={splitcontainer}

                        />
                }
            </TemplateDefault>
        )

    }
})
