export function userpageroutes({
                                   requests,
                                   configroutes,
                                   poststatuses,
                                   name,
                                   type,
                                   userRequest,
                                   posttypes,
                                   taxonomies,
                                   storedSearchData,
                                   defaultSearchPostType
                               }) {

    const userRequestTrash = requests.user({
        posttypes,
        taxonomies,
        storedSearchData,
        defaultSearchPostType,
        replacequery: { posttype: type, slt: (poststatuses['deleted itself'] + 1) }
    })

    return [
        {
            path: configroutes['wapplruserapp' + name + 'slug'],
            data: {
                content: 'user' + name,
                fetchreq: userRequest
            }
        },
        {
            path: configroutes['wapplruserapp' + name + 'slug'] + '/trash',
            data: {
                content: 'user' + name,
                fetchreq: userRequestTrash
            }
        }
    ]

}
