import React from 'react'
import createReactClass from 'create-react-class'

import TextField from 'material-ui/TextField'
import { CustomPicker } from 'react-color'
import { Alpha, Hue, Saturation } from 'react-color/lib/components/common'

import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

import IconButton from 'material-ui/IconButton'
import CloseIcon from 'material-ui/svg-icons/navigation/close'
import DoneIcon from 'material-ui/svg-icons/action/done'

const Pointer = createReactClass({
    render: function() {
        const { style, pointerClassName } = this.props
        return (
            <div className={style.pointer + ' ' + pointerClassName}></div>
        )
    }
})

const Picker = createReactClass({
    getInitialState: function() {
        return {
            changed: false
        }
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type == 'mouseup' || e && e.type == 'touchend' || e && e.type == pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { onClose } = this.props
        const { type } = d
        if (type == 'add') {
            onClose(e)
        }
        e.preventDefault()
    },
    getPointer: function(props = {}) {
        const { style } = this.props
        return <Pointer style={style} />
    },
    getSaturationPointer: function(props = {}) {
        const { style } = this.props
        return <Pointer style={style} pointerClassName={style.saturationPointer} />
    },
    handleChange: function(a, b, c) {
        const { onChange } = this.props
        this.setState({
            changed: true
        })
        onChange(a, b, c)
    },
    render: function() {
        const {
            style,
            pickersClassName = '',
            ...rest
        } = this.props

        const touchEnd = this.touchEnd
        const Pointer = this.getPointer
        const SaturationPointer = this.getSaturationPointer
        const onChange = this.handleChange
        const handleChange = function(data) {
            onChange({ a: 1, h: data.h, l: 0.5, s: 1 })
        }
        const rgba = 'rgba(' + rest.rgb.r + ',' + rest.rgb.g + ',' + rest.rgb.b + ',' + rest.rgb.a + ')'

        return (
            <div className={style.pickerboxcontainer + ' ' + pickersClassName}>
                <div className={style.pickerheader}>
                    <div className={style.pickervalue}>
                        <div style={{ backgroundColor: rgba }}></div>
                    </div>
                    <IconButton
                        className={style.pickerapplybutton}
                        onTouchTap={function(e) {
                            touchEnd(e, 'click', 'click', { type: 'add' })
                        }}
                        onTouchStart={function(e) {

                        }}
                    >
                        {(this.state.changed) ? <DoneIcon /> : <CloseIcon />}
                    </IconButton>
                </div>
                <div className={style.pickersaturation}>
                    <Saturation
                        {...rest}
                        onChange={onChange}
                        pointer={SaturationPointer}
                    />
                </div>
                <div className={style.pickerbox}>
                    <div className={style.pickerhue}>
                        <Hue
                            {...rest}
                            onChange={handleChange}
                            pointer={Pointer}
                        />
                    </div>
                    <div className={style.pickeralpha}>
                        <Alpha
                            {...rest}
                            onChange={onChange}
                            pointer={Pointer}
                        />
                    </div>
                </div>
            </div>
        )
    }
})

const CustomColorPicker = CustomPicker(Picker)

const ColorPicker = createReactClass({
    getInitialState: function() {

        const { value = 0 } = this.props
        this.refElements = {}

        return {
            value: value,
            open: false,
            changed: false,
            isMounted: true
        }
    },
    setRef: function(a, e) {
        const { setRef } = this.props
        this.refElements[a] = e
    },
    componentDidMount: function() {
        this.addListeners()
    },
    componentWillUnmount: function() {
        this.removeListeners()
    },


    open: function() {
        const { open } = this.state
        if (!open) {
            this.setState({
                open: true
            })
        }
    },
    close: function() {
        const { open } = this.state
        if (open) {
            this.setState({
                open: false
            })
        }
    },
    focus: function() {
        this.open()
    },
    blur: function(e) {
        this.close()
        const { onBlur } = this.props
        if (onBlur) {
            this.onBlur(e)
        } else {
            this.setState({
                changed: false
            })
        }
    },
    handleUpdateText: function(e, nextValue = '') {
        const { value } = this.state
        if (value !== nextValue) {
            this.setState({
                value: nextValue,
                changed: true
            })
        }
    },
    handleUpdate: function(nextValue = { rgb: { r: 0, g: 0, b: 0, a: 0 } }, e) {
        const { onChange } = this.props
        const value = 'rgba(' + nextValue.rgb.r + ',' + nextValue.rgb.g + ',' + nextValue.rgb.b + ',' + nextValue.rgb.a + ')'
        this.setState({
            value: value,
            changed: true
        })
        if (onChange) onChange(e, value)
    },
    onBlur: function(e) {
        const { value } = this.state
        const { onBlur } = this.props
        if (onBlur) onBlur(e, value)
    },

    mouseDown: function(e) {
        const { container } = this.refElements
        if (container) {
            if (container && container.contains(e.target)) {
                return null
            } else {
                this.blur(e)
            }
        }
    },
    addListeners: function() {
        document.removeEventListener('mousedown', this.mouseDown)
        document.addEventListener('mousedown', this.mouseDown, false)
    },
    removeListeners: function() {
        document.removeEventListener('mousedown', this.mouseDown)
    },
    render() {

        const {
            name = '',
            floatingLabelText,
            className = '',
            pickersClassName = '',
            errorText = '',
            style,
            ...rest
        } = this.props

        const value = this.state.value
        const v = (value) ? value : 'rgba(0,0,0,0)'
        const setRef = this.setRef

        return (
            <div ref={function(e) {
                setRef('container', e)
            }} className={style.colorpickercontainer + ' ' + className}>
                <div className={style.colorpickerinput}>
                    <div className={style.text}>
                        <TextField
                            type={'text'}
                            name={name}
                            floatingLabelText={floatingLabelText}
                            errorText={errorText}
                            onFocus={this.focus}
                            onChange={this.handleUpdateText}
                            value={v}
                        />
                    </div>
                </div>
                {(this.state.open) ?
                    <CustomColorPicker
                        style={style}
                        color={v}
                        onChange={this.handleUpdate}
                        onClose={this.blur}
                        pickersClassName={pickersClassName}
                    />
                    : null
                }
            </div>
        )
    }

})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <ColorPicker {...this.props} ref={(setRef) ? function(e) {
                setRef('colorpicker', e)
            } : null} />
        )
    }
})

export default createReactClass({
    displayName: 'ColorPicker',
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})
