// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StDS- {\n    position: relative;\n    display: block;\n    width: 100%;\n}\n\n._7EmSX {\n    visibility: hidden;\n    opacity: 0;\n    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;\n}\n\n._7EmSX._33Cp2 {\n    opacity: 1;\n    visibility: visible;\n}\n\n.KCOtw {\n    width: 100%;\n    height: 100%;\n    position: absolute;\n    background-color: #575c64;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n}\n\n.-\\+hpt {\n    display: table;\n    width: 60px;\n}\n\n.-\\+hpt svg circle {\n    stroke: #ffffff !important;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"charliesplayerbg": "StDS-",
	"charliesplayer": "_7EmSX",
	"show": "_33Cp2",
	"processbox": "KCOtw",
	"process": "-+hpt"
};
module.exports = ___CSS_LOADER_EXPORT___;
