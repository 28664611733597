export default {
    'type': 'object',
    'hidden': true,
    'fields': {
        'props': {
            'type': 'object',
            'hidden': true,
            'fields': {
                'metas': {
                    'type': 'array',
                    'fieldsName': 'name',
                    'name': 'root',
                    'add': {
                        'type': 'string',
                        'name': 'Custom meta name',
                        'key': function() {
                            return 'custom_meta_' + new Date().getTime()
                        },
                        'show': ['table', 'tooltip', 'popup'],
                        'enabled': ['area', 'flat', 'building'],
                        'required': false,
                        'order': 50
                    },
                    'fields': {},
                    'update': async function(p = {}) {
                        const { props = {} } = p
                        const root = props.root
                        await root.refElements.objects.regenerateForm()
                    },
                    'innerFormProps': {
                        'onOpen': function() {
                            return async function(p = {}) {
                                const { props = {} } = p
                                const root = props.root
                                const { refElements = {} } = root
                                refElements.views.close()
                                refElements.objects.close()
                                refElements.settings.close()
                                refElements.animations.close()
                                refElements.galleries.close()
                            }
                        }
                    },
                    'autoFields': {
                        'enable': {
                            'default': {
                                'type': 'object',
                                'remove': function({ data, key }) {
                                    return !(data[key].key === 'name' ||
                                        data[key].key === 'type' ||
                                        data[key].key === 'status' ||
                                        data[key].key === 'flats_number' ||
                                        data[key].key === 'description' ||
                                        data[key].key === 'price' ||
                                        data[key].key === 'floor' ||
                                        data[key].key === 'size' ||
                                        data[key].key === 'rooms' ||
                                        data[key].key === 'pdf_background' ||
                                        data[key].key === 'pdf_download' ||
                                        data[key].key === 'key');
                                },
                                'fields': {
                                    'name': {
                                        'type': 'string',
                                        'name': 'Meta name',
                                        'default': 'Meta data name',
                                        'validations': [
                                            {
                                                'name': 'min',
                                                'props': { 'value': 2, 'type': 'string' }
                                            }, {
                                                'name': 'max',
                                                'props': { 'value': 250, 'type': 'string' }
                                            }, {
                                                'name': 'type',
                                                'props': { 'value': ['string'] }
                                            }
                                        ],
                                        'disabled': function({ data }) {
                                            return data['key'] === 'description' ||
                                                data['key'] === 'pdf_background' ||
                                                data['key'] === 'key';
                                        },
                                        'update': async function(p = {}) {
                                            const { props = {} } = p

                                            const parent = props.parent
                                            await parent.regenerateForm()

                                            const root = props.root
                                            await root.refElements.objects.regenerateForm()
                                        }
                                    },
                                    'order': {
                                        'type': 'number',
                                        'name': 'Order',
                                        'default': 0,
                                        'disabled': function({ data }) {
                                            return data['key'] === 'description' ||
                                                data['key'] === 'pdf_background' ||
                                                data['key'] === 'key';
                                        },
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['number'] }
                                            }, {
                                                'name': 'min',
                                                'props': { 'value': 0, 'type': 'number' }
                                            }
                                        ]
                                    },
                                    'show': {
                                        'component': 'select',
                                        'type': 'string',
                                        'name': 'Show',
                                        'default': ['table', 'tooltip', 'popup'],
                                        'options': [
                                            { 'label': 'Table', 'value': 'table' }, //list
                                            { 'label': 'Tooltip', 'value': 'tooltip' }, //hotspot and box
                                            { 'label': 'Info', 'value': 'popup' }, //info on the top (meta)
                                            { 'label': 'Filter', 'value': 'filter' }
                                        ],
                                        'multiple': true,
                                        'disabled': function({ data }) {
                                            return data['key'] === 'name' ||
                                                data['key'] === 'description' ||
                                                data['key'] === 'pdf_background' ||
                                                data['key'] === 'key';
                                        },
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['object'] }
                                            }, {
                                                'name': 'arrayin',
                                                'props': { 'value': ['table', 'tooltip', 'popup', 'filter'] }
                                            }, {
                                                'name': 'min',
                                                'props': { 'value': 0, 'type': 'object' }
                                            }, {
                                                'name': 'max',
                                                'props': { 'value': 4, 'type': 'object' }
                                            }
                                        ]
                                    },
                                    'showdata': {
                                        'type': 'string',
                                        'name': 'Show data object',
                                        'default': '',
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['string'] }
                                            },
                                            {
                                                'name': 'parsable',
                                                'props': { 'enableEmpty': true }
                                            }
                                        ],
                                        'disabled': function({ data }) {
                                            return data['key'] === 'name' ||
                                                data['key'] === 'description' ||
                                                data['key'] === 'pdf_background' ||
                                                data['key'] === 'key';
                                        }
                                    },
                                    'required': {
                                        'type': 'boolean',
                                        'name': 'Required',
                                        'default': false,
                                        'disabled': function({ data }) {
                                            return data['key'] === 'name' ||
                                                data['key'] === 'type' ||
                                                data['key'] === 'status' ||
                                                data['key'] === 'description' ||
                                                data['key'] === 'pdf_background' ||
                                                data['key'] === 'key';

                                        },
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['boolean'] }
                                            }
                                        ],
                                        'update': async function(p = {}) {
                                            const { props = {} } = p
                                            const root = props.root
                                            await root.refElements.objects.regenerateForm()
                                        }
                                    },
                                    'endfix': {
                                        'type': 'string',
                                        'name': 'Endfix',
                                        'default': '',
                                        'required': false,
                                        'validations': [
                                            {
                                                'name': 'max',
                                                'props': { 'value': 20, 'type': 'string' }
                                            }, {
                                                'name': 'type',
                                                'props': { 'value': ['string'] }
                                            }
                                        ],
                                        'disabled': function({ data }) {
                                            return data['key'] === 'name' ||
                                                data['key'] === 'type' ||
                                                data['key'] === 'status' ||
                                                data['key'] === 'description' ||
                                                data['key'] === 'pdf_background' ||
                                                data['key'] === 'pdf_download' ||
                                                data['key'] === 'key';
                                        }
                                    },
                                    'format': {
                                        'component': 'select',
                                        'type': 'string',
                                        'name': 'Format',
                                        'default': [],
                                        'options': [
                                            { 'label': 'Price', 'value': 'price' },
                                            { 'label': 'Replace', 'value': 'replace' },
                                            { 'label': 'Count', 'value': 'count' },
                                            { 'label': 'Downloadable', 'value': 'download' },
                                            { 'label': 'Open new tab', 'value': 'open' },
                                            { 'label': 'Form and request', 'value': 'formAndRequest' }
                                        ],
                                        'multiple': true,
                                        'disabled': function({ data }) {
                                            return data['key'] === 'name' ||
                                                data['key'] === 'type' ||
                                                data['key'] === 'status' ||
                                                data['key'] === 'description' ||
                                                data['key'] === 'pdf_background' ||
                                                data['key'] === 'pdf_download' ||
                                                data['key'] === 'key';
                                        },
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': 'object' }
                                            }, {
                                                'name': 'arrayin',
                                                'props': { 'value': ['price', 'download', 'replace', 'open', 'formAndRequest', 'count'] }
                                            }
                                        ]
                                    },
                                    'formatdata': {
                                        'type': 'string',
                                        'name': 'Format data object',
                                        'default': '',
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['string'] }
                                            },
                                            {
                                                'name': 'parsable',
                                                'props': { 'enableEmpty': true }
                                            }
                                        ],
                                        'disabled': function({ data }) {
                                            return data['key'] === 'name' ||
                                                data['key'] === 'type' ||
                                                data['key'] === 'status' ||
                                                data['key'] === 'description' ||
                                                data['key'] === 'pdf_background' ||
                                                data['key'] === 'pdf_download' ||
                                                data['key'] === 'key';
                                        }
                                    },
                                    'type': {
                                        'component': 'select',
                                        'type': 'string',
                                        'name': 'Type',
                                        'default': 'string',
                                        'options': [
                                            { 'label': 'String', 'value': 'string' },
                                            { 'label': 'Number', 'value': 'number' }
                                        ],
                                        'disabled': function({ data }) {
                                            return data['key'] === 'name' ||
                                                data['key'] === 'type' ||
                                                data['key'] === 'status' ||
                                                data['key'] === 'description' ||
                                                data['key'] === 'flats_number' ||
                                                data['key'] === 'price' ||
                                                data['key'] === 'floor' ||
                                                data['key'] === 'size' ||
                                                data['key'] === 'rooms' ||
                                                data['key'] === 'pdf_background' ||
                                                data['key'] === 'pdf_download' ||
                                                data['key'] === 'key';
                                        },
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['object'] }
                                            }, {
                                                'name': 'arrayin',
                                                'props': { 'value': ['string', 'number'] }
                                            }, {
                                                'name': 'min',
                                                'props': { 'value': 1, 'type': 'object' }
                                            }, {
                                                'name': 'max',
                                                'props': { 'value': 1, 'type': 'object' }
                                            }
                                        ],
                                        'update': async function(p = {}) {
                                            const { props = {} } = p
                                            const root = props.root
                                            await root.refElements.objects.regenerateForm()
                                        }
                                    },
                                    'enabled': {
                                        'component': 'select',
                                        'type': 'string',
                                        'name': 'Enabled objects',
                                        'default': [],
                                        'options': [
                                            { 'label': 'Area', 'value': 'area' },
                                            { 'label': 'Building', 'value': 'building' },
                                            { 'label': 'Flat', 'value': 'flat' }
                                        ],
                                        'multiple': true,
                                        'disabled': function({ data }) {
                                            return data['key'] === 'name' ||
                                                data['key'] === 'type' ||
                                                data['key'] === 'status' ||
                                                data['key'] === 'flats_number' ||
                                                data['key'] === 'price' ||
                                                data['key'] === 'floor' ||
                                                data['key'] === 'size' ||
                                                data['key'] === 'rooms' ||
                                                data['key'] === 'pdf_background' ||
                                                data['key'] === 'pdf_download' ||
                                                data['key'] === 'key';

                                        },
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['object'] }
                                            }, {
                                                'name': 'arrayin',
                                                'props': { 'value': ['area', 'building', 'flat'] }
                                            }, {
                                                'name': 'min',
                                                'props': { 'value': 0, 'type': 'object' }
                                            }, {
                                                'name': 'max',
                                                'props': { 'value': 3, 'type': 'object' }
                                            }
                                        ],
                                        'update': async function(p = {}) {
                                            const { props = {} } = p
                                            const root = props.root
                                            await root.refElements.objects.regenerateForm()
                                        }
                                    },
                                    'hidetitle': {
                                        'component': 'select',
                                        'type': 'string',
                                        'name': 'Hide title',
                                        'default': [],
                                        'options': [
                                            { 'label': 'Tooltip', 'value': 'tooltip' },
                                            { 'label': 'Info', 'value': 'popup' }
                                        ],
                                        'multiple': true,
                                        'disabled': function({data}) {
                                            return (data['key'] === 'name' || data['key'] === 'description')
                                        },
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['object'] }
                                            }, {
                                                'name': 'arrayin',
                                                'props': { 'value': ['tooltip', 'popup'] }
                                            }, {
                                                'name': 'min',
                                                'props': { 'value': 0, 'type': 'object' }
                                            }, {
                                                'name': 'max',
                                                'props': { 'value': 2, 'type': 'object' }
                                            }
                                        ]
                                    },
                                    'key': {
                                        'type': 'string',
                                        'name': 'Key',
                                        'default': 'custom_meta_key',
                                        'disabled': function({ data, key }) {
                                            if (data[key] === 'name' ||
                                                data[key] === 'type' ||
                                                data[key] === 'status' ||
                                                data[key] === 'flats_number' ||
                                                data[key] === 'price' ||
                                                data[key] === 'floor' ||
                                                data[key] === 'size' ||
                                                data[key] === 'rooms' ||
                                                data[key] === 'pdf_background' ||
                                                data[key] === 'pdf_download' ||
                                                data[key] === 'key'
                                            ) return true
                                            return true
                                        },
                                        'validations': [
                                            {
                                                'name': 'min',
                                                'props': { 'value': 2, 'type': 'string' }
                                            }, {
                                                'name': 'max',
                                                'props': { 'value': 250, 'type': 'string' }
                                            }, {
                                                'name': 'type',
                                                'props': { 'value': ['string'] }
                                            }
                                        ]
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
