export default function(helpers) {

    return [
        {
            path: '/',
            data: {
                content: 'home'
            }
        },
        {
            path: '/home',
            data: {
                content: 'home'
            }
        }, {
            path: '/share',
            data: {
                content: 'share'
            }
        }, {
            path: '/description',
            data: {
                content: 'description'
            }
        },
        {
            path: '*',
            data: {
                content: 'home',
                callback: async function({ r, universalPath }) {
                    if (universalPath == '/') return r
                    r.status = 404
                    r.title = 'Not found'
                    return r
                }
            }
        }
    ]
}

