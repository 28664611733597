import React from 'react'

import flatchooser from './oldApp'

export default function({ curlang, history, getthumb, parentroute }) {

    const flatchooserapp = flatchooser({
        disableStoredConfig: true,
        name: 'flatchooser',
        parentroute: parentroute,
        curlang: curlang,
        history: history,
        getthumb: getthumb,
        asaWidget: true

    })

    return { flatchooserapp }
}
