import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

const Share = createReactClass({
    getInitialState() {
        const { post } = this.props
        return {
            post: post,
            isMounted: true
        }
    },
    componentDidMount: function() {
    },
    componentWillUnmount: function() {
    },

    touchEnd: function(e, fn, pt, d) {
        if (e && e.type == 'mouseup' || e && e.type == 'touchend' || e && e.type == pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { type } = d
        e.preventDefault()
    },
    getText: function() {

        const { staticscripturl } = this.props
        const { post } = this.state

        const id = (post.dataid) ? post.dataid : (post.author && post.author.id) ? post.author.id + '/' + post.id : post.id

        if (!post.appver || post.appver == '1.0') {

            return `
            <div
                data-app="flatchooser"
                data-id="${id}"

                data-autoload="1"
                data-disablegraphql="1"
                data-loadfromstatic="0"
                data-disabledescription="1"
                data-disableshare="1"
                data-disablefullscreen="1"

                style="
                    width:100%;
                    height:auto;
                    display:block;
                    position:relative;
                    margin-left:auto;
                    margin-right:auto;
                    margin-top:0;
                ">
            </div>
            <script src="${staticscripturl}"></script>`

        }

        return `
            <div
                id="flatchooser-unique-id"
                data-app="flatchooser"
                data-id="${id}"

                data-autoload="1"
                data-disablegraphql="1"
                data-loadfromstatic="1"

                data-headertypeatplayer="0"
                data-headertitlecolor="rgba(14,217,157,1)"
                data-headerbgcolor="rgba(245, 246, 247,1)"
                data-headericoncolor="rgba(0,0,0,0.87)"

                data-disabledescription="0"
                data-disableshare="0"
                data-disablefullscreen="0"

                data-paletteenabledtypes='["floatingPalette", "pinnedOnRight", "pinnedOnDown"]'
                data-paletteopen="1"
                data-palettepinned="0"
                data-palettepintype="right"
                data-paletteposx="-10"
                data-paletteposy="10"
                data-palettedisablemove="0"
                data-palettedisableclose="0"
                data-paletteforcepinondownbreakpoint="800"
                data-paletteforcepinondownistouchable="0"
                data-paletteforcepinondownismobile="1"
                data-palettedisablcloseonlyoneiconremains="1"
                data-enableshareobjectwithhash="1"
                data-enablega="1"

                style="
                    width:100%;
                    max-width:100%;
                    height:auto;
                    display:block;
                    position:relative;
                    margin-left:auto;
                    margin-right:auto;
                    margin-top:0;
                ">
            </div>
            <script src="${staticscripturl}"></script>`

    },
    render: function() {
        const { style } = this.props
        const { post } = this.state
        const touchEnd = this.touchEnd
        const t = this.getText()

        return (
            <div className={style.share}>
                {t}
            </div>
        )
    }
})

const Middle = createReactClass({
    setRef: function(a, e) {
        const { setRef } = this.props
        if (setRef) setRef(a, e)
    },
    render: function() {
        const setRef = this.setRef
        return (
            <Share {...this.props} ref={(setRef) ? function(e) {
                setRef('share', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})
