// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oi8ik {\n    position: relative;\n    display: block;\n    width: 100%;\n    overflow: hidden;\n}\n\n.VMFzK {\n    position: relative;\n    display: block;\n    width: 100%;\n}\n\n.ALera {\n    display: table-cell;\n    vertical-align: middle;\n}\n\n.DqLrz {\n    display: table-cell;\n    vertical-align: middle;\n}\n\n.ofKD5 {\n    display: block !important;\n}\n\n.ofKD5 svg {\n    display: block !important;\n}\n\n/*breadcrumb*/\n\n.tsgd5 {\n    position: absolute;\n    top: 0;\n    left: 0;\n    height: 64px;\n    max-width: calc(100% - 96px);\n    z-index: 1300;\n    margin-left: -16px;\n    overflow: hidden;\n    width: 100%;\n}\n\n.tsgd5 > div {\n    height: 64px;\n    flex-wrap: wrap;\n    align-content: flex-start !important;\n    justify-content: flex-start !important;\n}\n\n.EtXur {\n    cursor: pointer;\n    max-width: 100%;\n}\n\n.EtXur > span {\n    color: #0ED99D !important;\n    font-size: 16px !important;\n    text-transform: uppercase;\n    padding-left: 8px !important;\n    padding-right: 8px !important;\n    font-weight: 500 !important;\n\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    overflow: hidden;\n    display: block !important;\n    height: 64px !important;\n}\n\n.tsgd5 > div > svg {\n    color: #0ED99D !important;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editor": "oi8ik",
	"player": "VMFzK",
	"headercenter": "ALera",
	"controlsicon": "DqLrz",
	"controlsiconbutton": "ofKD5",
	"breadcrumb": "tsgd5",
	"breadstep": "EtXur"
};
module.exports = ___CSS_LOADER_EXPORT___;
