import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

import IconButton from 'material-ui/IconButton'
import FullscreenIcon from 'material-ui/svg-icons/navigation/fullscreen'
import FullscreenOffIcon from 'material-ui/svg-icons/navigation/fullscreen-exit'

const Controls = createReactClass({
    getInitialState() {

        const store = this.getStore()
        const state = (store && store.getState) ? store.getState() : null
        const fullscreen = (state && state.fullscreen) ? state.fullscreen : false

        return {
            isMounted: false,
            fullscreen: fullscreen
        }

    },
    componentDidMount: function() {
        this.setState({
            isMounted: true
        })
    },
    componentWillUnmount: function() {
        this.setState({
            isMounted: false
        })
    },
    getStore: function() {
        const { construct } = this.props
        return (construct.props && construct.props.store) ? construct.props.store : null
    },
    getGlobalState: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct.props.state : teststate
    },
    getPost: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.currentpost && state.response.currentpost[0]) ? state.response.currentpost[0] : null
    },
    getData: function() {
        const { construct } = this.props
        return (construct.props && construct.props.data) ? construct.props.data : null
    },
    getParentRoute: function() {
        const { construct, parentroute } = this.props
        return (construct.props && construct.props.parentroute) ? construct.props.parentroute : parentroute
    },

    touchEnd: function(e, fn, pt, d) {
        if (e && e.type == 'mouseup' || e && e.type == 'touchend' || e && e.type == pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { type } = d
        const { style, construct } = this.props
        const { onFullScreen } = construct.props
        const state = this.state
        const { fullscreen } = state
        if (type == 'fullscreen') {
            if (onFullScreen) onFullScreen(e, state)
        }
        e.preventDefault()
    },
    setData: function(data = {}) {
        const { isMounted } = this.state
        const { onFullScreen } = this.props

        if (isMounted) {
            const state = this.state
            let changed = false
            Object.keys(data).map(function(key) {
                if (data[key] !== state[key]) changed = true
            })
            if (changed) {
                this.setState(data)

                if (typeof data.fullscreen !== undefined) {

                    const store = this.getStore()
                    if (store && store.setState) {
                        store.setState({ fullscreen: data.fullscreen })
                    }
                    if (onFullScreen) onFullScreen()
                }
            }
        }
    },

    render: function() {
        const { children, style, construct, containerStyle = {}, className, disableFullscreenFromProps } = this.props
        const { fullscreen, isMounted } = this.state
        const { disablefullscreen } = construct.props
        const touchEnd = this.touchEnd

        const disableFullscreen = (disablefullscreen || disableFullscreenFromProps)

        const hidecontrols = (disableFullscreen && !children || !isMounted) ? true : false
        let cN = (hidecontrols) ? style.controls + ' ' + style.hidden : style.controls
        if (className) {
            if (style[className]) {
                cN = cN + ' ' + style[className]
            } else {
                cN = cN + ' ' + className
            }
        }

        return (
            <div style={containerStyle} className={cN}>
                <div className={style.controlsinner}>
                    {(children) ? children : null}
                    {(!disableFullscreen && isMounted) ?
                        <div className={style.icon}>
                            <IconButton
                                onTouchTap={function(e) {
                                    touchEnd(e, 'click', 'click', { type: 'fullscreen' })
                                }}
                                onTouchStart={function(e) {

                                }}
                                className={style.iconbutton}
                            >
                                {(fullscreen) ? <FullscreenOffIcon /> : <FullscreenIcon />}
                            </IconButton>
                        </div>
                        : null}
                </div>
            </div>
        )
    }
})

const Middle = createReactClass({
    setRef: function(a, e) {
        const { setRef } = this.props
        const { construct } = this.props
        const setRefConstruct = (construct.props) ? construct.props.setRef : null
        if (setRef) setRef(a, e)
        if (setRefConstruct) setRefConstruct(a, e)
    },
    render: function() {
        const setRef = this.setRef
        return (
            <Controls {...this.props} ref={(setRef) ? function(e) {
                setRef('controls', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})
