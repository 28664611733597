/*Import dependencies*/
import React from 'react'
import createReactClass from 'create-react-class'

/*Components*/
import { wapplrhelpers } from '../../../../../../../components'

/*Templates*/
import ContentHome from '../contents/content_home'
import ContentShare from '../contents/content_share'
import ContentDescription from '../contents/content_details'

import App from '../components/CharliesAppWrapper'
import ast from '../components/CharliesAppWrapper/CharliesAppWrapper.css'

/*Styles*/
//noinspection JSUnresolvedVariable
import s from '../../themes/default/FlatChooserApp.css'
//noinspection JSUnresolvedVariable
import ms from '../../themes/default/Wapplrmenu.css'
//noinspection JSUnresolvedVariable
import avs from '../../themes/default/Wapplravatar.css'

/*Icons and Material theme*/
import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme'

import PlayerIcon from 'material-ui/svg-icons/av/play-circle-filled'
import ShareIcon from 'material-ui/svg-icons/social/share'
import DetailsIcon from 'material-ui/svg-icons/action/description'

import test from './test/index.js'
import geteditdata from './editdata'

//const isDebug = true;
const isDebug = false

export default function(c) {

    const modulename = c.name || 'flatchooser'

    const history = c.history || {}
    const parentroute = c.parentroute
    const getthumb = c.getthumb
    const asaWidget = c.asaWidget
    const curlang = c.curlang || {
        mincharerror: function() {
            return ''
        },
        maxcharerror: function() {
            return ''
        }
    }

    const style = c.style || s
    const headerstyle = c.headerstyle || ms
    const avatarstyle = c.avatarstyle || avs

    if (lightBaseTheme && lightBaseTheme.palette) {
        lightBaseTheme.palette.primary1Color = c.palette && c.palette.primary1Color || '#3f454a'
        lightBaseTheme.palette.primary2Color = c.palette && c.palette.primary2Color || '#3f454a'
        lightBaseTheme.zIndex = {}
        lightBaseTheme.zIndex.popover = 2147483647
    }

    const { pageRefs } = wapplrhelpers.page()
    const { menuRefs, getMenuDataObject } = wapplrhelpers.menu({ localstorename: modulename })
    const menuData = getMenuDataObject(modulename)

    const menus = c.menus || {
        [modulename]: [],
        [modulename + '_fixeddrawer']: [
            {
                name: 'Player', link: '/home', lefticon: <PlayerIcon />,
                role: function(props) {
                    const { sendprops = {} } = props
                    const { construct = { props: {} } } = sendprops
                    const { disableshare, disabledescription } = construct.props
                    return !!(!disableshare || !disabledescription)
                }
            },
            {
                name: 'Share', link: '/share', lefticon: <ShareIcon />,
                role: function(props) {
                    const { sendprops = {} } = props
                    const { construct = { props: {} } } = sendprops
                    const { disableshare } = construct.props
                    return !disableshare
                }
            },
            {
                name: 'Description', link: '/description', lefticon: <DetailsIcon />,
                role: function(props) {
                    const { sendprops = {} } = props
                    const { construct = { props: {} } } = sendprops
                    const { disabledescription } = construct.props
                    return !disabledescription
                }
            }
        ]
    }

    const { editdata } = geteditdata({ curlang, history, parentroute, getthumb })

    const hooks = {}

    const input = {

        logostyle: null,
        logohref: null,
        logotext: 'FlatChooser',
        logospan: '',
        menus: menus,

        style: style,
        headerstyle: headerstyle,
        avatarstyle: avatarstyle,

        history: history,
        parentroute: parentroute,
        muitheme: lightBaseTheme,
        modulename: modulename,
        getthumb: getthumb,

        menuRefs,
        menuData,
        pageRefs,
        asaWidget,

        editdata,
        hooks

    }

    function getHistory({ construct }) {
        const constructPush = (construct.push && construct.props && construct.props.disableHistory) ? construct.push : null
        return (constructPush) ? {
            push: function(a) {
                const r = constructPush(a)
                if (!r) {
                    history.push(a)
                }
            },
            location: { ...history.location }
        } : history
    }

    function getConstruct({ construct }) {
        if (construct && construct.props && construct.props.state) {
            if (test && isDebug) {
                const r = {
                    ...construct,
                    props: {
                        ...construct.props
                    }
                }
                r.props.data = test.props.data
                r.props.state.response.currentpost[0].appjson = JSON.stringify(test.props.data)
                return r
            }
            return construct
        }

        return test
    }

    const templates = {
        home: createReactClass({
            render() {

                const { construct } = this.props

                const h = getHistory({ construct })
                const c = getConstruct({ construct })

                return (
                    <App {...input} construct={c} history={h} originalhistory={history} style={ast}>
                        <ContentHome {...input} construct={c} history={h} originalhistory={history} />
                    </App>
                )
            }
        }),
        share: createReactClass({
            render() {
                const { construct } = this.props
                const h = getHistory({ construct })
                const c = getConstruct({ construct })

                return (
                    <App {...input} construct={c} history={h} originalhistory={history} style={ast}>
                        <ContentShare {...input} construct={c} history={h} originalhistory={history} />
                    </App>
                )
            }
        }),
        description: createReactClass({
            render() {
                const { construct } = this.props
                const h = getHistory({ construct })
                const c = getConstruct({ construct })

                const { disabledescription } = construct.props

                return (
                    <App {...input} construct={c} history={h} originalhistory={history} style={ast}>
                        {(disabledescription) ?
                            <ContentHome {...input} construct={c} history={h} originalhistory={history} /> :
                            <ContentDescription {...input} construct={c} history={h} originalhistory={history} />
                        }
                    </App>
                )
            }
        })
    }

    if (c.pages) {
        Object.keys(c.pages).map(function(k) {

            const name = c.pages[k].name
            const template = c.pages[k].template
            const parentprops = c.pages[k].parentprops

            if (template && name && !templates[name]) {
                templates[name] = createReactClass({
                    render() {
                        const { construct } = this.props
                        const h = getHistory({ construct })
                        const c = getConstruct({ construct })

                        return (
                            <App {...input} construct={c} history={h} originalhistory={history} style={ast}>
                                <ContentCustom {...input} construct={c} CustomPage={template} parentprops={parentprops}
                                               history={h} originalhistory={history} />
                            </App>
                        )
                    }
                })
            }

        })
    }

    return templates
}
