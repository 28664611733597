import config from '../config'

const lowername = config.name.toLowerCase()

export default {
    ['post' + lowername + 'title']: 'Lejátszó',
    ['menu' + lowername + 'title']: 'Lejátszó',
    ['edit' + lowername + 'title']: 'Lejátszó szerkesztése',
    ['json' + lowername + 'title']: 'Lejátszó szerkesztése',
    ['new' + lowername + 'title']: 'Új lejátszó',
    ['user' + config.path + 'subtitle']: 'Lejátszók',
    ['user' + config.path + 'trashsubtitle']: 'Törölt lejátszók',
    ['wapplruserapp' + config.path + 'name']: 'Lejátszók',
    ['wapplruserapp' + config.path + 'title']: 'Felhasználó lejátszói',
    ['wapplruserapp' + config.path + 'trash']: 'Törölt lejátszók',

    [lowername + 'description']: 'Lejátszó leírása',

    ['all' + config.path]: 'Összes lejátszó',
    ['my' + config.path]: 'Lejátszóim',
    ['filter' + config.path]: 'Kategóriák',

    [lowername + 'searchhint']: function({ user, taxs }) {
        const username = (user && user.name && user.name.first) ? user.name.first : null
        let r = 'Keresés a lejátszók között'
        if (username && !taxs) r = 'Keresés ' + username + ' lejátszói között'
        if (!username && taxs) r = 'Keresés a lejátszói között a ' + taxs + ' kategóriában'
        if (username && taxs) r = 'Keresés ' + username + ' lejátszói között, a ' + taxs + ' kategóriában'
        return r
    },

    stepsetbasicdata: 'Alap adatok',
    stepchooseacover: 'Cover',
    stepaddsomeimage: 'Képek',
    stepcategory: 'Kategória',

    edithintappjson: 'App adat (json)',
    addmoredatabutton: 'További adatok megadása',
    edithintdescription: 'Leírás',
    saveplayer: 'Lejátszó mentése',
    saveplayernew: 'Lejátszó létrehozása',

    edithintbackgroundimageurl: 'Background image url',
    edithintdataid: 'Data id',
    editlabelappjsonenableadmin: 'Enable edit for json',
    edithintappver: 'App version',
    edithinttitle: 'Title',
    editlabelaccesstoplayer: 'Access to player',
    editlabelrunscript: 'Run script',
    editlabeldisablescriptnotification: 'Disable script notifications',
    edithinttokens: 'Tokenek',
    editnotokens: 'Nincsenek tokenek',
    edithinttoken: 'Token hash',
    edithintselectschema: 'Séma kiválasztása',
    edithintplusdata: 'Plusz adat',

    mincharerror: function(a) {
        return 'Minimum ' + a + ' karakter'
    },
    maxcharerror: function(a) {
        return 'Maximum ' + a + ' karakter'
    },
    minarrerror: function(a) {
        return 'Minimum ' + a
    },
    maxarrerror: function(a) {
        return 'Maximum ' + a
    },

    editlabelworkstate: 'Work state',

    workstatecreated: 'Created',
    workstateprogress: 'Progress',
    workstatefinished: 'Finished',

    edithintcoproducers: 'Coproducers',

    edithintDisabledChangesScript: 'Disabled from changes by script, eg: ["123465789", "123456788"]',
    parsableArray: "Can't parse the string represents array"

}
