export default {
    'type': 'object',
    'hidden': true,
    'fields': {
        'props': {
            'type': 'object',
            'hidden': true,
            'fields': {
                'hotspots': {
                    'type': 'array',
                    'name': 'root',
                    'add': {
                        'props': {
                            view_id: null,
                            effect: '',
                            points: [[0, 0], [100, 0], [100, 100], [0, 100]]
                        }
                    },
                    'update': async function(p = {}) {
                        const { props = {}, data = {} } = p
                        const parent = p.attr.recursiveData.parentData.parentData.parentData
                        const key = parent.key
                        const currentViewObject = parent.data[key]

                        const root = props.root
                        const rootProps = root.props
                        await rootProps.setCurrentView({ view_id: currentViewObject.key })
                    },
                    'fieldsName': function(data, props) {

                        const views = props.parentData.parentData.datas[2].views.props.views
                        const galleries = (props.parentData.parentData.datas[1].props.galleries) ? props.parentData.parentData.datas[1].props.galleries.props.galleries : null
                        const objects = props.parentData.parentData.datas[2].objects.props.objects

                        const view_id = data.props.view_id

                        let foundView = null
                        let foundGallery = null
                        let foundObject = null

                        if (view_id && !view_id.match('gallery')) {
                            views.map(function(o) {
                                if (view_id == o.key) foundView = o
                            })
                            if (foundView && foundView.object_id) {
                                objects.map(function(o) {
                                    if (foundView.object_id == o.key) foundObject = o
                                })
                            }
                        }

                        if (view_id && view_id.match('gallery') && galleries) {
                            galleries.map(function(o) {
                                if (view_id == o.key) foundGallery = o
                            })
                            if (foundGallery && foundGallery.object_id) {
                                objects.map(function(o) {
                                    if (foundGallery.object_id == o.key) foundObject = o
                                })
                            }
                        }

                        if (foundGallery) {

                            const gallery_name = foundGallery.name
                            const name = (foundObject && foundObject.name) ? foundObject.name : (foundObject) ? foundObject.key : ''

                            let rName = foundGallery.key
                            if (gallery_name) rName = gallery_name
                            if (name) rName = rName + ' [' + name + ']'
                            return 'G: ' + rName

                        } else if (foundView) {

                            const view_name = foundView.name
                            const name = (foundObject && foundObject.name) ? foundObject.name : (foundObject) ? foundObject.key : ''

                            let rName = foundView.key
                            if (view_name) rName = view_name
                            if (name) rName = rName + ' [' + name + ']'
                            return 'V: ' + rName

                        }


                        return 'Empty'

                    },
                    'autoFields': {
                        'enable': {
                            'dinamic': function(p = {}) {

                                const field = {
                                    'type': 'object',
                                    'remove': true,
                                    'innerFormProps': {
                                        'onOpen': function() {
                                            return async function(p = {}) {
                                                const { props = {}, data = {}, key = 0 } = p
                                                const close = []
                                                const d = (data[key]) ? data[key] : data
                                                const { __jsoneditorId } = d
                                                Object.keys(props.parent.refElements).map(function(k) {
                                                    if (k !== __jsoneditorId && props.parent.refElements[k] && props.parent.refElements[k].close) {
                                                        close.push(props.parent.refElements[k].close)
                                                    }
                                                })
                                                await Promise.all(close.map(async function(c) {
                                                    await c()
                                                }))
                                            }
                                        }
                                    },
                                    'fields': {
                                        'props': {
                                            'type': 'object',
                                            'hidden': true,
                                            'fields': {
                                                'effect': {
                                                    'component': 'select',
                                                    'type': 'string',
                                                    'name': 'Effect',
                                                    'default': null,
                                                    'options': [
                                                        { 'label': 'Default effect', 'value': null },
                                                        { 'label': 'Fade', 'value': 'fade' },
                                                        { 'label': 'Zoom out', 'value': 'zoom' },
                                                        { 'label': 'Zoom in', 'value': 'zoomIn' },
                                                        { 'label': 'Slide left', 'value': 'slide' },
                                                        { 'label': 'Slide right', 'value': 'slideRight' },
                                                        { 'label': 'Slide bottom', 'value': 'slideBottom' },
                                                        { 'label': 'Open url', 'value': 'openUrl' }
                                                    ],
                                                    'multiple': false,
                                                    'validations': [
                                                        {
                                                            'name': 'type',
                                                            'props': { 'value': ['object'] }
                                                        }, {
                                                            'name': 'arrayin',
                                                            'props': { 'value': [null, 'fade', 'zoom', 'zoomIn', 'slide', 'slideRight', 'slideBottom', 'openUrl'] }
                                                        }
                                                    ],
                                                    'update': async function(p = {}) {
                                                        const { props = {}, data = {} } = p
                                                        const parent = props.parent
                                                        await parent.regenerateForm()
                                                    }
                                                },
                                                'view_id': {},
                                                'effect_data': {
                                                    'type': 'string',
                                                    'name': 'Effect data object',
                                                    'default': '',
                                                    'validations': [
                                                        {
                                                            'name': 'type',
                                                            'props': { 'value': ['string'] }
                                                        },
                                                        {
                                                            'name': 'parsable',
                                                            'props': { 'enableEmpty': true }
                                                        }
                                                    ],
                                                    'disabled': function({ data, key }) {
                                                        if (data['effect'] == 'openUrl') return false
                                                        return true
                                                    }
                                                },
                                                'enable_icon': {
                                                    'type': 'boolean',
                                                    'name': 'Enable gallery icon',
                                                    'default': false,
                                                    'disabled': function({ data, key }) {
                                                        if (data['view_id'] && data['view_id'].match('gallery')) return false
                                                        return true
                                                    },
                                                    'validations': [
                                                        {
                                                            'name': 'type',
                                                            'props': { 'value': ['boolean'] }
                                                        }
                                                    ]
                                                }
                                            }
                                        }
                                    }
                                }

                                const r = {
                                    'view_id': {
                                        'component': 'select',
                                        'type': 'string',
                                        'name': 'Object ID',
                                        'default': null,
                                        'options': [
                                            { 'label': 'No object', 'value': null }
                                        ],
                                        'multiple': false,
                                        'disabled': function({ data, key }) {
                                            return false
                                        },
                                        'validations': [
                                            {
                                                'name': 'type',
                                                'props': { 'value': ['object'] }
                                            }, {
                                                'name': 'arrayin',
                                                'props': { 'value': [null] }
                                            }
                                        ],
                                        'update': async function(p = {}) {
                                            const { props = {}, data = {} } = p
                                            const parent = props.parent
                                            await parent.regenerateForm()
                                        }
                                    }
                                }

                                const data = p.data[p.key][p.i]
                                const views = p.parentData.parentData.datas[2].views.props.views
                                const galleries = (p.parentData.parentData.datas[1].props.galleries) ? p.parentData.parentData.datas[1].props.galleries.props.galleries : null
                                const objects = p.parentData.parentData.datas[2].objects.props.objects

                                function getName(view_id) {

                                    let foundView = null
                                    let foundGallery = null
                                    let foundObject = null

                                    if (view_id && !view_id.match('gallery')) {
                                        views.map(function(o) {
                                            if (view_id == o.key) foundView = o
                                        })
                                        if (foundView && foundView.object_id) {
                                            objects.map(function(o) {
                                                if (foundView.object_id == o.key) foundObject = o
                                            })
                                        }
                                    }

                                    if (view_id && view_id.match('gallery') && galleries) {
                                        galleries.map(function(o) {
                                            if (view_id == o.key) foundGallery = o
                                        })
                                        if (foundGallery && foundGallery.object_id) {
                                            objects.map(function(o) {
                                                if (foundGallery.object_id == o.key) foundObject = o
                                            })
                                        }
                                    }

                                    if (foundGallery) {

                                        const gallery_name = foundGallery.name
                                        const name = (foundObject && foundObject.name) ? foundObject.name : (foundObject) ? foundObject.key : ''

                                        let rName = foundGallery.key
                                        if (gallery_name) rName = gallery_name
                                        if (name) rName = rName + ' [' + name + ']'
                                        return 'G: ' + rName

                                    } else if (foundView) {

                                        const view_name = foundView.name
                                        const name = (foundObject && foundObject.name) ? foundObject.name : (foundObject) ? foundObject.key : ''

                                        let rName = foundView.key
                                        if (view_name) rName = view_name
                                        if (name) rName = rName + ' [' + name + ']'
                                        return 'V: ' + rName

                                    }

                                    return 'Empty'
                                }


                                if (views && views.length) {
                                    views.map(function(object) {
                                        const key = object.key
                                        const name = getName(key)
                                        r['view_id'].options.push({
                                            label: name,
                                            value: key
                                        })
                                        r['view_id'].validations[1].props.value.push(key)
                                    })
                                }

                                if (galleries && galleries.length) {
                                    galleries.map(function(gallery) {
                                        const key = gallery.key
                                        const name = getName(key)

                                        r['view_id'].options.push({
                                            label: name,
                                            value: key
                                        })
                                        r['view_id'].validations[1].props.value.push(key)

                                    })
                                }

                                field.fields.props.fields.view_id = r.view_id

                                return field
                            }
                        }
                    },
                    'fields': {}
                }
            }
        }
    }
}
