// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oQpeo {\n    width: 100%;\n}\n\n.oQpeo > div {\n    width: 100% !important;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"number": "oQpeo"
};
module.exports = ___CSS_LOADER_EXPORT___;
