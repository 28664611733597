import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

import { Container } from '../Container'

import { Manager, Popper, Target } from 'react-popper'
import Portal from 'react-travel'
import Paper from 'material-ui/Paper'

import getMatrix from './perspective-transform'

import { Table, TableBody, TableFooter, TableHeader, TableRow, TableRowColumn } from 'material-ui/Table'

import MobileDetect from 'mobile-detect'

import FormAndRequest from '../Player/FormAndRequest'
import FlatButton from 'material-ui/FlatButton'
import DownloadIcon from 'material-ui/svg-icons/file/file-download'
import OpenIcon from 'material-ui/svg-icons/action/open-in-new'

//let oi = 0;

const HotSpotEditor = createReactClass({
    getInitialState: function() {

        this.refElements = {}
        this.points = {}
        this.lines = {}

        const {
            points = [[0, 0], [100, 0], [100, 100], [0, 100]],
            selected
        } = this.props

        return {
            points: [...points],
            selected: selected,
            currentPointIndex: null
        }
    },
    setSelected: async function() {
        const { selected } = this.state
        const { id, parent } = this.props
        if (!selected) {
            await this.setState({
                selected: true
            })
            if (parent) await parent.setSelected({ id })
        }
    },
    removeSelected: function() {
        const { selected } = this.state
        if (selected) {
            this.setState({
                selected: false,
                currentPointIndex: 0
            })

            this.currentTarget = null
            this.currentPointIndex = 0
        }
    },
    componentDidMount: function() {
        this.addListeners()
    },
    componentWillUnmount: function() {

        this.refElements = {}
        this.points = {}
        this.lines = {}

        this.currentTarget = null
        this.currentPointIndex = null

        this.removeListeners()
    },
    getD: function() {

        const {
            points = [[0, 0], [100, 0], [100, 100], [0, 100]]
        } = this.state

        let d = 'M '
        points.map(function(point, pi) {
            if (pi == 0) {
                d = d + point[0] + ',' + point[1]
            } else {
                d = d + ' L ' + point[0] + ',' + point[1]
            }
        })
        d = d + ' Z'
        return d
    },
    setRef: function(a, e, n) {
        if (e) {
            if (n) {
                this[n][a] = e
            } else {
                this.refElements[a] = e
            }
        }
    },

    touchMove: function(e) {

        if (this.enableMouseMove == 'path') {

            window.getSelection().removeAllRanges()

            let changex = e.pageX - this.mousePosition.startx
            let changey = e.pageY - this.mousePosition.starty

            changex = changex / this.mousePosition.startscalex
            changey = changey / this.mousePosition.startscaley

            this.setPointsPositionWithoutState({ changex, changey })

        } else if (this.enableMouseMove) {

            window.getSelection().removeAllRanges()

            const { points = [[0, 0], [100, 0], [100, 100], [0, 100]] } = this.state
            let changex = e.pageX - this.mousePosition.startx
            let changey = e.pageY - this.mousePosition.starty

            changex = changex / this.mousePosition.startscalex
            changey = changey / this.mousePosition.startscaley

            const target = this.currentTarget
            const currentPointIndex = this.currentPointIndex
            const currentPoint = points[currentPointIndex]

            if (currentPoint) {
                this.setPointPositionWithoutState({ target, currentPoint, changex, changey })
            }

        }

    },
    setPointsPositionWithoutState: function({ changex, changey }) {

        const { points = [[0, 0], [100, 0], [100, 100], [0, 100]] } = this.state
        const refPoints = this.points
        points.map(function(currentPoint, i) {
            const target = refPoints[i]
            target.setAttribute('cx', currentPoint[0] + changex)
            target.setAttribute('cy', currentPoint[1] + changey)
        })

    },
    setPointPositionWithoutState: function({ target, currentPoint, changex, changey }) {

        target.setAttribute('cx', currentPoint[0] + changex)
        target.setAttribute('cy', currentPoint[1] + changey)
    },

    setPointsPosition: function({ changex, changey }) {

        const { onPointsChange, store } = this.props
        const { points = [[0, 0], [100, 0], [100, 100], [0, 100]] } = this.state

        const newPoints = [...points]

        points.map(function(currentPoint, i) {
            const newx = currentPoint[0] + changex
            const newy = currentPoint[1] + changey
            newPoints[i][0] = newx
            newPoints[i][1] = newy
        })

        this.setState({
            points: newPoints
        })

        onPointsChange({ points: newPoints })
        store.props.points = newPoints
    },

    setPointPosition: function({ target, currentPoint, changex, changey, currentPointIndex }) {

        const { onPointsChange, store } = this.props
        const { points = [[0, 0], [100, 0], [100, 100], [0, 100]] } = this.state
        const newx = currentPoint[0] + changex
        const newy = currentPoint[1] + changey
        const newPoints = [...points]

        newPoints[currentPointIndex][0] = newx
        newPoints[currentPointIndex][1] = newy

        this.setState({
            points: newPoints
        })

        onPointsChange({ points: newPoints })
        store.props.points = newPoints
    },

    onKeyDown: function(e) {

        const { points = [[0, 0], [100, 0], [100, 100], [0, 100]], selected } = this.state
        const target = this.currentTarget
        const currentPointIndex = this.currentPointIndex
        const currentPoint = points[currentPointIndex]

        if (currentPoint && selected) {

            e = e || window.event

            let changex = 0
            let changey = 0

            if (e.keyCode == '38') {
                changey = -1
            } else if (e.keyCode == '40') {
                changey = 1
            } else if (e.keyCode == '37') {
                changex = -1
            } else if (e.keyCode == '39') {
                changex = 1
            }

            if (currentPoint && changex || currentPoint && changey) {
                this.setPointPosition({ target, currentPoint, changex, changey, currentPointIndex })
                e.preventDefault()
            }

        }

    },
    touchEnd: function(e) {

        this.enableMouseMove = false

        if (this.enableMouseUp == 'path') {

            this.enableMouseUp = false

            const { points = [[0, 0], [100, 0], [100, 100], [0, 100]] } = this.state

            let changex = e.pageX - this.mousePosition.startx
            let changey = e.pageY - this.mousePosition.starty

            changex = changex / this.mousePosition.startscalex
            changey = changey / this.mousePosition.startscaley

            this.setPointsPosition({ changex, changey })

            this.setState({
                currentPointIndex: null
            })

            this.currentTarget = null
            this.currentPointIndex = null

            window.getSelection().removeAllRanges()

        } else if (this.enableMouseUp) {

            this.enableMouseUp = false

            const { points = [[0, 0], [100, 0], [100, 100], [0, 100]] } = this.state

            let changex = e.pageX - this.mousePosition.startx
            let changey = e.pageY - this.mousePosition.starty

            changex = changex / this.mousePosition.startscalex
            changey = changey / this.mousePosition.startscaley

            const target = this.currentTarget
            const currentPointIndex = this.currentPointIndex
            const currentPoint = points[currentPointIndex]

            if (currentPoint && changex || currentPoint && changey) {
                this.setPointPosition({ target, currentPoint, changex, changey, currentPointIndex })
            }

            this.setState({
                currentPointIndex: this.currentPointIndex
            })

            //this.currentTarget = null;
            //this.currentPointIndex = 0;

            //oi = oi + 1;
            //console.log(oi)

            window.getSelection().removeAllRanges()

        }

    },
    touchStart: function(e) {

        if (e.button > 0) {

        } else {

            this.setSelected()

            const { parent } = this.props
            const points = this.points
            const t = this

            Object.keys(points).map(function(key) {
                if (points[key] == e.target) {
                    t.currentTarget = e.target
                    t.currentPointIndex = key
                }
            })

            if (e.target == this.refElements['path']) {
                this.currentTarget = this.refElements['path']
                this.currentPointIndex = 'path'
            }


            if (this.currentTarget) {
                const containerElement = parent.refElements['container']
                const container = parent.refElements['container'].refElements['container']
                const rect = container.getBoundingClientRect()

                this.mousePosition = {
                    startx: e.pageX,
                    starty: e.pageY,
                    startscalex: rect.width / containerElement.state.width,
                    startscaley: rect.height / containerElement.state.height
                }

                window.getSelection().removeAllRanges()

                if (this.currentPointIndex == 'path') {
                    this.enableMouseMove = 'path'
                    this.enableMouseUp = 'path'
                } else {
                    this.enableMouseMove = true
                    this.enableMouseUp = true
                }
            }

        }
    },
    addPoint: async function(e) {

        const { parent, onPointsChange } = this.props
        const pointsRef = this.lines
        const t = this

        Object.keys(pointsRef).map(function(key) {
            if (pointsRef[key] == e.target) {
                t.currentTarget = e.target
                t.currentPointIndex = key
            }
        })

        const { points = [[0, 0], [100, 0], [100, 100], [0, 100]] } = this.state

        if (this.currentTarget) {

            this.setSelected()

            const containerElement = parent.refElements['container']
            const container = parent.refElements['container'].refElements['container']
            const rect = container.getBoundingClientRect()

            this.mousePosition = {
                startx: e.pageX,
                starty: e.pageY,
                startscalex: rect.width / containerElement.state.width,
                startscaley: rect.height / containerElement.state.height
            }

            const target = this.currentTarget
            const currentPointIndex = this.currentPointIndex
            const currentPoint = points[currentPointIndex]

            if (currentPoint) {

                let newPoints = [...points]

                let newx = e.pageX
                let newy = e.pageY

                newx = newx - (rect.left + window.scrollX)
                newy = newy - (rect.top + window.scrollY)

                newx = newx / this.mousePosition.startscalex
                newy = newy / this.mousePosition.startscaley

                newPoints.splice(Number(currentPointIndex) + 1, 0, [newx, newy])

                this.currentPointIndex = Number(currentPointIndex) + 1

                await this.setState({
                    points: newPoints,
                    currentPointIndex: this.currentPointIndex
                })

                await onPointsChange({ points: newPoints })

                this.addListeners()

            }
        }

        //this.currentTarget = null;
        //this.currentPointIndex = null;

        window.getSelection().removeAllRanges()
        e.preventDefault()

    },
    clearPoint: function(e) {

        const { parent, onPointsChange } = this.props
        const pointsRef = this.points
        const t = this

        Object.keys(pointsRef).map(function(key) {
            if (pointsRef[key] == e.target) {
                t.currentTarget = e.target
                t.currentPointIndex = key
            }
        })

        const { points = [[0, 0], [100, 0], [100, 100], [0, 100]] } = this.state

        if (this.currentTarget && points.length > 3) {

            this.setSelected()

            const target = this.currentTarget
            const currentPointIndex = this.currentPointIndex
            const currentPoint = points[currentPointIndex]

            if (currentPoint) {

                let newPoints = [...points]

                newPoints.splice(currentPointIndex, 1)

                this.setState({
                    points: newPoints,
                    currentPointIndex: null
                })

                onPointsChange({ points: newPoints })

                this.currentTarget = null
                this.currentPointIndex = null

            }
        }

        window.getSelection().removeAllRanges()
        e.preventDefault()

    },
    addListeners: function() {

        this.removeListeners()

        const { style } = this.props
        const { points = [[0, 0], [100, 0], [100, 100], [0, 100]] } = this.state
        const path = this.refElements['path']
        const refElements = this.points
        const refElementsLine = this.lines
        const touchStart = this.touchStart
        const clearPoint = this.clearPoint
        const setSelected = this.setSelected
        const addPoint = this.addPoint

        points.map(function(point, pi) {
            const e = refElements[pi]
            if (e) {
                e.addEventListener('mousedown', touchStart, false)
                e.addEventListener('dblclick', clearPoint, false)
            }
            const l = refElementsLine[pi]
            if (l) {
                l.addEventListener('mousedown', setSelected, false)
                l.addEventListener('dblclick', addPoint, false)
            }

        })

        if (path) {
            path.addEventListener('mousedown', touchStart, false)
        }

        window.addEventListener('mouseup', this.touchEnd, false)
        window.addEventListener('mousemove', this.touchMove, false)
        window.addEventListener('keydown', this.onKeyDown, false)

    },
    removeListeners: function() {
        const { style } = this.props
        const { points = [[0, 0], [100, 0], [100, 100], [0, 100]] } = this.state
        const path = this.refElements['path']
        const refElements = this.points
        const refElementsLine = this.lines
        const touchStart = this.touchStart
        const clearPoint = this.clearPoint
        const setSelected = this.setSelected
        const addPoint = this.addPoint

        points.map(function(point, pi) {
            const e = refElements[pi]
            if (e) {
                e.removeEventListener('mousedown', touchStart)
                e.removeEventListener('dblclick', clearPoint)
            }
            const l = refElementsLine[pi]
            if (l) {
                l.removeEventListener('mousedown', setSelected)
                l.removeEventListener('dblclick', addPoint)
            }
        })

        if (path) {
            path.removeEventListener('mousedown', touchStart)
        }

        window.removeEventListener('onkeydown', this.onKeyDown)
        window.removeEventListener('mouseup', this.touchEnd)
        window.removeEventListener('mousemove', this.touchMove)
    },
    render: function() {

        const { id } = this.props
        this.refElements = {}
        this.points = {}
        this.lines = {}

        const { style, name = 'HotSpot' } = this.props
        const { points = [[0, 0], [100, 0], [100, 100], [0, 100]] } = this.state

        const d = this.getD()
        const setRef = this.setRef
        const { selected, currentPointIndex } = this.state

        const ID = 'svgeditor_' + id

        return (
            <g className={(selected) ? style.svgeditor + ' ' + style.selected : style.svgeditor}>
                <g>
                    <path
                        className={style.svgeditorpath}
                        ref={function(e) {
                            setRef('path', e)
                        }}
                        d={d}
                    >
                    </path>
                    <defs>
                        <path id={'path_' + ID} d={d} fill='#ddd' stroke='#ddd'></path>
                    </defs>
                    <text className={style.svgeditortitle}>
                        <textPath xlinkHref={'#path_' + ID} startOffset='0'>{name}</textPath>
                    </text>
                </g>
                <g>
                    {
                        points.map(function(point, pi) {
                            const x1 = point[0]
                            const y1 = point[1]
                            const x2 = (points[pi + 1]) ? points[pi + 1][0] : points[0][0]
                            const y2 = (points[pi + 1]) ? points[pi + 1][1] : points[0][1]
                            return <line
                                ref={function(e) {
                                    setRef(pi, e, 'lines')
                                }}
                                className={style.svgeditorline} key={pi} x1={x1} y1={y1} x2={x2} y2={y2}
                                strokeWidth={'4'} />
                        })
                    }
                </g>
                <g>
                    {
                        points.map(function(point, pi) {
                            const selected = (pi == currentPointIndex) ? true : false
                            return <circle
                                ref={function(e) {
                                    setRef(pi, e, 'points')
                                }}
                                className={(selected) ? style.selectedpoint + ' ' + style.svgeditorcircle : style.svgeditorcircle}
                                key={pi} cx={point[0]} cy={point[1]} r='10'></circle>
                        })
                    }
                </g>
            </g>
        )

    }
})

const HotSpot = createReactClass({
    getInitialState: function() {
        this.refElements = {}
        const isMobile = this.isMobile()

        const {
            points = [[0, 0], [100, 0], [100, 100], [0, 100]]
        } = this.props

        return {
            popupOpen: false,
            isMounted: false,
            isMobile: isMobile,
            points
        }
    },
    removeSelected: function() {
        this.refElements['editor'].removeSelected()
    },
    setPoints: async function({ points }) {
        const { isMounted } = this.state
        if (isMounted && points && points.length > 2) {
            await this.setState({
                points: points
            })
        }
    },
    isMobile: function() {
        if (typeof window !== 'undefined') {
            const md = new MobileDetect(window.navigator.userAgent)
            return md.mobile() || md.tablet() || md.phone()
        }
        return false
    },
    componentDidMount: async function() {
        const isMobile = this.isMobile()
        await this.setState({
            isMounted: true,
            isMobile: isMobile
        })
        this.createStyle()
        if (!isMobile) this.addListeners()
    },
    isEditable: function() {
        const { isEditable } = this.props
        return (isEditable) ? isEditable : false
    },
    getDataObject: function() {
        const { root = {} } = this.props
        const dataObject = root.props.dataObject
        return dataObject
    },
    getSettings: function() {
        const dataObject = this.getDataObject()
        const settings = dataObject.props.settings.props.settings[0]
        return settings
    },
    getViews: function() {
        const dataObject = this.getDataObject()
        const views = dataObject.props.views.props.views
        return views
    },
    getGalleries: function() {
        const dataObject = this.getDataObject()
        const views = dataObject.props.galleries.props.galleries
        return views
    },
    getObjects: function() {
        const dataObject = this.getDataObject()
        const objects = dataObject.props.objects.props.objects
        return objects
    },
    getMetas: function() {
        const dataObject = this.getDataObject()
        const metas = dataObject.props.metas.props.metas
        return metas
    },
    getViewData: function(p = {}) {
        const { view_id } = p
        if (view_id) {
            const views = (view_id.match('gallery')) ? this.getGalleries() : this.getViews()
            if (views && views.length) {
                let foundView = null
                views.map(function(view) {
                    if (view.key == view_id) foundView = view
                })
                return foundView
            }
        }
        return null
    },
    translate: function({ text }) {
        const settings = this.getSettings() || {}
        const { construct } = this.props
        const { translations = [] } = (construct && construct.props && construct.props.translations) ? { translations: construct.props.translations } : settings
        let translatedText = null
        if (typeof text == 'string' && text) {
            translations.map(function(translate) {
                if (!translatedText && translate.text && translate.translate && translate.text == text) {
                    translatedText = translate.translate
                }
            })
        }
        if (translatedText) return translatedText
        return text
    },
    getObjectData: function(p = {}) {
        const { object_id } = p
        if (object_id) {
            const objects = this.getObjects()
            if (objects && objects.length) {
                let foundObject = null
                objects.map(function(object) {
                    if (object.key == object_id) foundObject = object
                })
                return foundObject
            }
        }
        return null
    },
    getViewObjectData: function({ view_id }) {
        const viewData = this.getViewData({ view_id })
        if (viewData && viewData.object_id) {
            return this.getObjectData({ object_id: viewData.object_id })
        }
        if (viewData && view_id && view_id.match('gallery')) {
            return {
                name: viewData.name || 'Gallery',
                type: 'gallery',
                status: 'gallery1'
            }
        }
        return null
    },
    getViewsByObjectId: function(p = {}) {
        const { object_id } = p
        if (object_id) {
            const views = this.getViews()
            if (views && views.length) {
                const foundViews = []
                views.map(function(view) {
                    if (view.object_id == object_id) foundViews.push(view)
                })
                return foundViews
            }
        }
        return []
    },
    getHotSpotsData: function(p = {}) {
        const { view_id, style } = this.props
        const vid = (p.view_id) ? p.view_id : view_id
        const viewData = this.getViewData({ view_id: vid })
        const hotspots = viewData.elements[0].children[1].children[0].props.hotspots
        return hotspots
    },
    getGalleryColors: function() {

        const r = {
            defaultColor: 'rgba(0,0,0,0)',
            defaultStrokeColor: 'rgba(0,0,0,0)',
            hoverColor: 'rgba(255,255,255,1)',
            hoverStrokeColor: 'rgba(255,255,255,1)'
        }

        const settings = this.getSettings() || {}
        const { colors = {} } = settings

        const type = 'gallery'
        const status = 'gallery1'

        if (colors && colors[type] && colors[type][status]) {
            return { ...colors[type][status] }
        }

        return r

    },
    getHotSpotColors: function() {
        const { view_id } = this.props

        const r = {
            defaultColor: 'rgba(0,0,0,0)',
            defaultStrokeColor: 'rgba(0,0,0,0)',
            hoverColor: 'rgba(255,255,255,1)',
            hoverStrokeColor: 'rgba(255,255,255,1)'
        }

        if (view_id) {
            const settings = this.getSettings() || {}
            const { colors = {} } = settings

            const objectData = this.getViewObjectData({ view_id })
            if (objectData && objectData.type) {
                const type = objectData.type
                const status = objectData.status
                if (colors && colors[type] && colors[type][status]) {
                    return { ...colors[type][status] }
                }
            }
        }

        return r

    },
    getPopupBackgroundColor: function() {
        const settings = this.getSettings() || {}
        const { colors = {} } = settings
        const { player = {} } = colors
        const { tooltip = {} } = player
        return tooltip.backgroundColor
    },
    showOrNot: function(show, showdata, type, objectData) {
        if (showdata) {
            let sd = showdata
            if (typeof sd == 'string') sd = JSON.parse(sd)
            if (sd && typeof sd == 'object') {
                const { disable = {} } = sd
                if (typeof disable == 'object') {
                    let foundAll = true
                    Object.keys(sd.disable).map(function(key) {
                        const disableDatas = sd.disable[key]
                        if (disableDatas.indexOf(objectData[key]) == -1) {
                            foundAll = false
                        }
                    })
                    if (foundAll) return false
                }
            }
        }
        return (show.indexOf(type) > -1) ? true : false
    },
    getDataToView: function(p = {}) {

        const { style, getRootContainer } = this.props
        const { type = 'popup', forceKeys = [] } = p
        const { view_id } = this.props
        const objectData = this.getViewObjectData({ view_id })
        const metas = this.getMetas()
        const translate = this.translate

        const getViewsByObjectId = this.getViewsByObjectId
        const getHotSpotsData = this.getHotSpotsData
        const getViewObjectData = this.getViewObjectData
        const showOrNot = this.showOrNot

        if (objectData) {

            const enabled = {}
            const orders = []
            const r = []

            function getFormatDataObject(type, data) {
                const dataObject = (data && typeof data == 'string') ? JSON.parse(data) : (data && typeof data == 'object') ? data : {}
                return (dataObject && type && dataObject[type]) ? dataObject[type] : {}
            }

            const formats = {
                price: function(value, data) {
                    const n = 0
                    const x = 0
                    const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')'
                    return (value && value.toFixed || value == 0) ? value.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$& ') : value
                },
                endFix: function(value, data) {
                    const endFix = getFormatDataObject('endFix', data)
                    if (endFix && typeof endFix.not !== 'undefined') {
                        if (typeof endFix.not == 'string' && endFix.not == value) {
                            return value
                        } else if (typeof endFix.not == 'object' && endFix.not.length) {
                            let foundEqual = false
                            endFix.not.forEach(function(not) {
                                if (value == not) {
                                    foundEqual = true
                                }
                            })
                            if (foundEqual) {
                                return value
                            }
                        }
                    }
                    return (value && endFix.value) ? value + ' ' + endFix.value : value
                },
                replace: function(value, data) {
                    const replace = getFormatDataObject('replace', data)
                    const { status = [], type = [] } = replace
                    if (status.indexOf(objectData.status) > -1 && type.indexOf(objectData.type) > -1) {
                        if (replace.value || replace.value == '' || replace.value == 0) return replace.value
                    }
                    return value
                },
                count: function(value, data) {
                    const count = getFormatDataObject('count', data)
                    const { status = [], type = [] } = count
                    const viewsForObject = getViewsByObjectId({ object_id: objectData.key })
                    const aViewForObject = (viewsForObject) ? viewsForObject[0] : null
                    if (aViewForObject) {
                        let ct = 0
                        const hotspots = getHotSpotsData({ view_id: aViewForObject.key })
                        hotspots.map(function(hotspot) {
                            const view_id = hotspot.props.view_id
                            const objectData = getViewObjectData({ view_id })
                            if (objectData && status.indexOf(objectData.status) > -1 && type.indexOf(objectData.type) > -1) {
                                ct = ct + 1
                            }
                        })
                        if (typeof count.value == 'object' && count.value[ct.toString()]) {
                            return count.value[ct.toString()]
                        }
                        return ct
                    }
                    return value
                },
                download: function(value, data) {
                    const download = getFormatDataObject('download', data)
                    const disableIcon = (download && download.disableIcon) ? true : false
                    return (
                        <FlatButton
                            label={translate({ text: 'download' })}
                            href={value}
                            icon={(disableIcon) ? null : <DownloadIcon />}
                            target={'_blank'}
                            download={true}
                        />
                    )

                },
                open: function(value, data) {
                    const open = getFormatDataObject('open', data)
                    const disableIcon = (open && open.disableIcon) ? true : false
                    return (
                        <FlatButton
                            label={translate({ text: 'open' })}
                            href={value}
                            icon={(disableIcon) ? null : <OpenIcon />}
                            target={'_blank'}
                            download={true}
                        />
                    )

                },
                formAndRequest: function(value, data) {
                    const formAndRequestData = getFormatDataObject('formAndRequest', data)
                    const savedPost = {}

                    return (<FormAndRequest
                        value={value}
                        data={formAndRequestData}
                        translate={translate}
                        style={style}
                        post={savedPost}
                        getRootContainer={getRootContainer}
                    />)

                }
            }

            Object.keys(objectData).map(function(key) {
                metas.map(function(meta) {

                    const show = meta.show || []
                    const showdata = meta.showdata

                    let go = (showdata) ? showOrNot(show, showdata, type, objectData) : (show.indexOf(type) > -1) ? true : false

                    if (go && meta.key == key || meta && meta.key == key && forceKeys.indexOf(key) > -1) {

                        if (meta.enabled && meta.enabled.indexOf(objectData.type) > -1) {

                            const endFix = meta.endfix
                            let value = objectData[key]

                            if (key == 'status') {
                                value = translate({ text: value })
                            }

                            let format = meta.format || []
                            let formatdata = meta.formatdata || {}

                            if (endFix) {
                                if (typeof format == 'string') {
                                    format = [format, 'endfix']
                                } else {
                                    format = [...format]
                                    format.push('endFix')
                                }
                                if (typeof formatdata == 'string') {
                                    formatdata = JSON.parse(formatdata)
                                } else {
                                    formatdata = { ...formatdata }
                                }
                                if (!formatdata.endFix) {
                                    formatdata.endFix = { value: endFix }
                                }
                                if (typeof formatdata.endFix.value == 'undefined') {
                                    formatdata.endFix.value = endFix
                                }
                            }

                            if (format) {

                                if (format && typeof format == 'string' && formats[format]) {
                                    value = formats[format](value, formatdata)
                                }
                                if (format && typeof format == 'object' && format.length) {
                                    if (formatdata && typeof formatdata.order == 'object' && formatdata.order.length) {
                                        formatdata.order.map(function(fkey) {
                                            if (format.indexOf(fkey) > -1) {
                                                value = formats[fkey](value, formatdata)
                                            }
                                        })
                                    } else {
                                        Object.keys(formats).map(function(fkey) {
                                            if (format.indexOf(fkey) > -1) {
                                                value = formats[fkey](value, formatdata)
                                            }
                                        })
                                    }
                                }
                            }

                            enabled[key] = ({ value: value, meta: meta })
                            orders.push([meta.order, key])

                        }
                    }
                })
            })
            if (objectData && objectData.type == 'gallery') {
                Object.keys(objectData).map(function(key) {
                    if (key == 'name') {
                        const value = objectData[key]
                        enabled[key] = ({ value: value, meta: { key: 'name', name: 'Object name' } })
                        orders.push([1, key])
                    }
                })
            }
            orders.sort(function(a, b) {
                const aorder = a[0] || 0
                const border = b[0] || 0
                return (aorder > border) ? 1 : -1
            })
            orders.map(function(a) {
                const key = a[1]
                r.push(enabled[key])
            })

            return r
        }
        return null
    },
    getD: function() {

        const {
            points = [[0, 0], [100, 0], [100, 100], [0, 100]]
        } = this.state

        let d = 'M '
        points.map(function(point, pi) {
            if (pi == 0) {
                d = d + point[0] + ',' + point[1]
            } else {
                d = d + ' L ' + point[0] + ',' + point[1]
            }
        })
        d = d + ' Z'
        return d
    },
    setRef: function(a, e, n) {
        if (e) {
            if (n) {
                this[n][a] = e
            } else {
                this.refElements[a] = e
            }
        }
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type == 'mouseup' || e && e.type == 'touchend' || e && e.type == pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { onClose } = this.props
        const { type } = d
        if (type == 'onClick') {
            this.onClick()
        }
        e.preventDefault()
    },
    onClick: function() {
        const { root = {}, view_id, effect, effect_data, construct } = this.props
        const { popupOpen, isMobile } = this.state

        const enableclicktosoldobject = (construct && construct.props && construct.props.enableclicktosoldobject) ? true : false
        const objectData = this.getViewObjectData({ view_id })
        const disabledClick = (!enableclicktosoldobject && objectData && objectData.status == 'sold') ? true : false

        if (disabledClick) {

        } else {

            if (root.props.setCurrentView) {

                if (isMobile) {
                    if (popupOpen) {
                        root.props.setCurrentView({ view_id, effect, effect_data })
                    } else {
                        this.setActive()
                        this.runListFunction({ func: 'setActive' })
                    }
                } else {
                    root.props.setCurrentView({ view_id, effect, effect_data })
                }

            }

        }

    },
    createStyle: function() {

        const { view_id } = this.props
        const className = view_id + '_classes'
        const popupScale = this.getPopupScale()

        const head = document.head || document.getElementsByTagName('head')[0]
        if (document.getElementById(className)) head.removeChild(document.getElementById(className))

        const hotSpotColors = this.getHotSpotColors({ view_id })
        const galleryColors = this.getGalleryColors()
        const { isMobile } = this.state
        const isGallery = (view_id.match('gallery')) ? true : false

        const defaultColor = (isMobile && hotSpotColors.mobileDefaultColor && hotSpotColors.mobileDefaultColor !== 'rgba(0,0,0,0)') ? hotSpotColors.mobileDefaultColor : hotSpotColors.defaultColor
        const defaultStrokeColor = (isMobile && hotSpotColors.mobileDefaultStrokeColor && hotSpotColors.mobileDefaultStrokeColor !== 'rgba(0,0,0,0)') ? hotSpotColors.mobileDefaultStrokeColor : hotSpotColors.defaultStrokeColor

        const defaultGalleryColor = (isMobile && galleryColors.mobileDefaultColor && galleryColors.mobileDefaultColor !== 'rgba(0,0,0,0)') ? galleryColors.mobileDefaultColor : galleryColors.defaultColor
        const defaultGalleryStrokeColor = (isMobile && galleryColors.mobileDefaultStrokeColor && galleryColors.mobileDefaultStrokeColor !== 'rgba(0,0,0,0)') ? galleryColors.mobileDefaultStrokeColor : galleryColors.defaultStrokeColor

        const getDefaultColor = function() {
            return (isGallery) ? defaultGalleryColor : defaultColor
        }

        const getDefaultStrokeColor = function() {
            return (isGallery) ? defaultGalleryStrokeColor : defaultStrokeColor
        }

        const getColor = function(key) {
            return (isGallery) ? galleryColors[key] : hotSpotColors[key]
        }

        const getPathColorIfGalleryIconBg = function(color) {
            if (isGallery) {
                const def = color
                const a = def.split(',')
                const nT = a[3].slice(0, -1)
                if (!isNaN(Number(nT))) {
                    let n = Number(nT)
                    n = n / 3
                    const dc = a[0] + ',' + a[1] + ',' + a[2] + ',' + n + ')'
                    return dc
                }
            }
            return 'rgba(0,0,0,0)'
        }

        let css = '.' + className + ' path { fill:' + getPathColorIfGalleryIconBg(getDefaultColor()) + ' ; }'
        css = css + ' .' + className + ' path:first-child { fill:' + getDefaultColor() + ' ; }'
        css = css + ' .' + className + ' line { stroke:' + getDefaultStrokeColor() + ' ; }'

        css = css + ' .' + className + ':hover path { fill:' + getPathColorIfGalleryIconBg(getColor('hoverColor')) + ' ; }'
        css = css + ' .' + className + ':hover path:first-child { fill:' + getColor('hoverColor') + ' ; }'
        css = css + ' .' + className + ':hover line { stroke:' + getColor('hoverStrokeColor') + ' ; }'

        css = css + ' .' + className + '.active path { fill:' + getPathColorIfGalleryIconBg(getColor('hoverColor')) + ' ; }'
        css = css + ' .' + className + '.active path:first-child { fill:' + getColor('hoverColor') + ' ; }'
        css = css + ' .' + className + '.active line { stroke:' + getColor('hoverStrokeColor') + ' ; }'

        css = css + ' .' + className + ' .popupheader { background-color:' + hotSpotColors.hoverColor + ' ; color:#ffffff ; text-align:center ; font-weight:bold  }'
        css = css + ' .' + className + ' .popupfooter { color:' + hotSpotColors.hoverColor + ' ; text-align:center ; font-weight:bold  }'

        css = css + ' @keyframes ' + className + '_path { from {fill: ' + getDefaultColor() + ';} to {fill: ' + getColor('hoverColor') + ';}}'
        css = css + ' @keyframes ' + className + '_line { from {stroke: ' + getDefaultStrokeColor() + ';} to {stroke: ' + getColor('hoverStrokeColor') + ';}}'

        css = css + ' .' + className + '.anim path:first-child { animation-duration: 1.5s ; animation-name: ' + className + '_path ; animation-iteration-count: infinite ; animation-direction: alternate ; }'
        css = css + ' .' + className + '.anim line { animation-duration: 1.5s ; animation-name: ' + className + '_line ; animation-iteration-count: infinite ; animation-direction: alternate ; }'

        if (popupScale !== 0.8) {
            const marginRight = (300 * (1 - popupScale)) * -1
            css = css + ' .' + className + ' { transform:scale(' + popupScale + ') ; -ms-transform:scale(' + popupScale + ') ; -webkit-transform:scale(' + popupScale + ') ; margin-right:' + marginRight + 'px; }'
        }

        const style = document.createElement('style')

        style.type = 'text/css'

        if (style.styleSheet) {
            style.styleSheet.cssText = css
        } else {
            style.appendChild(document.createTextNode(css))
        }

        style.setAttribute('id', className)

        head.appendChild(style)
    },
    setActive: function() {
        const { popupOpen } = this.state
        if (!popupOpen) {
            this.setState({
                popupOpen: true
            })
        }
    },
    removeActive: function() {
        const { popupOpen } = this.state
        if (popupOpen) {
            this.setState({
                popupOpen: false
            })
        }
    },
    mouseOver: function() {
        this.setActive()
        this.runListFunction({ func: 'setActive' })
    },
    mouseLeave: function() {
        this.removeActive()
        this.runListFunction({ func: 'removeActive' })
    },

    runListFunction({ func }) {
        const { view_id } = this.props
        let foundList = this.getList()
        if (foundList && foundList.refElements) {
            Object.keys(foundList.refElements).map(function(key) {
                const element = foundList.refElements[key]
                if (element.props && element.props.view_id == view_id && element[func]) {
                    element[func]()
                }
            })
        }
    },
    getList: function() {
        const { parent } = this.props
        const { getListElement } = parent.props
        if (getListElement) return getListElement()
        return null
    },

    removeListeners: function() {
        const e = this.refElements['hotspot']
        if (e) {
            e.removeEventListener('mouseenter', this.mouseOver)
            e.removeEventListener('mouseleave', this.mouseLeave)
        }
    },
    addListeners: function() {
        const e = this.refElements['hotspot']
        if (e) {
            this.removeListeners()
            e.addEventListener('mouseenter', this.mouseOver)
            e.addEventListener('mouseleave', this.mouseLeave)
        }
    },
    disablePopup: function() {
        const { root, popupMinWidth = 780 } = this.props
        const { refElements = {} } = root
        const { container } = refElements
        if (container && container.refElements && container.refElements.container) {
            const width = container.refElements.container.offsetWidth
            if (popupMinWidth && width && popupMinWidth > width) return true
        }
        return false
    },
    getPopupScale: function() {
        const { construct = {} } = this.props
        const { props = {} } = construct
        const defaultScale = 0.8
        const tooltipScale = props.tooltipscale || defaultScale
        if (isNaN(Number(tooltipScale))) return defaultScale
        return Number(tooltipScale)
    },
    Children: function(props) {
        const { style, show } = props
        const { view_id } = this.props
        const { isMobile } = this.state
        const popupBackgroundColor = this.getPopupBackgroundColor() || '#ffffff'
        const disablePopup = this.disablePopup()

        const setRef = this.setRef
        const paperstyle = {
            display: 'flex',
            position: 'relative',
            width: 'auto',
            flexDirection: 'column',
            padding: '0px 0px',
            backgroundColor: 'rgba(0,0,0,0)'
        }

        let popupData = this.getDataToView({ type: 'tooltip', forceKeys: ['name'] })
        const viewData = this.getViewData({ view_id })
        const viewName = (viewData) ? viewData.name : ''
        const translate = this.translate

        if (!isMobile && show && popupData && popupData.length && !disablePopup) {

            let name = ''
            let status = ''

            popupData.map(function(data, i) {
                if (data.meta.key == 'name') {
                    name = data.value
                }
                if (data.meta.key == 'status') {
                    status = data.value
                }
            })

            if (viewName) {
                if (name && viewName !== name) {
                    name = name + ' [' + viewName + ']'
                } else {
                    name = viewName
                }
            }

            const className = view_id + '_classes'

            return (
                <div ref={function(e) {
                    setRef('popper', e)
                }} className={style.popup + ' ' + className}>
                    <Paper zDepth={1} style={paperstyle}>
                        <Table fixedHeader={true}>
                            {(name) ?
                                <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                                    <TableRow className={style.popupheader + ' popupheader'}>
                                        <TableRowColumn style={{ textAlign: 'center' }}>{name}</TableRowColumn>
                                    </TableRow>
                                </TableHeader> : null
                            }
                            <TableBody displayRowCheckbox={false} className={style.popupbody}>
                                {popupData.map(function(data, i) {
                                    if (data.meta.key !== 'name' && data.meta.key !== 'status') {

                                        const hideTitleObject = data.meta.hidetitle || []
                                        const hideTitle = (hideTitleObject.indexOf('tooltip') > -1) ? true : false

                                        if (hideTitle) {
                                            return (
                                                <TableRow className={style.popuprow + ' ' + style.tableRowFullWidth}
                                                          selectable={false} key={i}>
                                                    <TableRowColumn
                                                        className={style.popuprow + ' ' + style.tableRowColumnFullWidth}
                                                        key={i + '_2'}>{data.value}</TableRowColumn>
                                                </TableRow>
                                            )
                                        }


                                        return (
                                            <TableRow className={style.popuprow} selectable={false} key={i}>
                                                <TableRowColumn className={style.popupcol}
                                                                key={i + '_1'}>{translate({ text: data.meta.name })}</TableRowColumn>
                                                <TableRowColumn className={style.popupcol}
                                                                key={i + '_2'}>{data.value}</TableRowColumn>
                                            </TableRow>
                                        )
                                    }
                                    return null
                                })}
                            </TableBody>
                            {(status) ?
                                <TableFooter adjustForCheckbox={false}>
                                    <TableRow selectable={false}>
                                        <TableRowColumn
                                            className={style.popupfooter + ' popupfooter'}>{status}</TableRowColumn>
                                    </TableRow>
                                </TableFooter>
                                : null
                            }
                        </Table>
                    </Paper>
                </div>
            )
        }
        return null

    },
    getParentElement: function() {
        const { parent } = this.props
        const innerContainer = parent.refElements['container']
        return (innerContainer) ? innerContainer.refElements['container'].parentElement.parentElement : null
    },
    getName: function({ view_id }) {

        const viewData = (view_id) ? this.getViewData({ view_id }) : null
        const objectData = (viewData && viewData.object_id) ? this.getViewObjectData({ view_id }) : null
        let name = (objectData && objectData.name) ? objectData.name : ''
        const viewName = (viewData && viewData.name) ? viewData.name : ''
        if (viewName) {
            if (name && name !== viewName) {
                name = name + ' [' + viewName + ']'
            } else {
                name = viewName
            }
        }
        return name || view_id || ''

    },
    getMatrix: function({ points }) {

        let minX = null
        let minY = null
        let maxX = null
        let maxY = null

        points.map(function(point) {
            const x = point[0]
            const y = point[1]
            if (x > maxX || maxX == null) maxX = x
            if (x < minX || minX == null) minX = x
            if (y > maxY || maxY == null) maxY = y
            if (y < minY || minY == null) minY = y
        })

        const boxHeight = maxY - minY
        const boxWidth = maxX - minX

        const cOpt = [0, 1, 2, 3]
        const shiftX = -points[cOpt[0]][0]
        const shiftY = -points[cOpt[0]][1]
        const shiftDivX = points[cOpt[0]][0] - minX
        const shiftDivY = points[cOpt[0]][1] - minY

        let transformIcon = {
            'transformOrigin': '0px 0px',
            'position': 'relative',
            'width': boxWidth + 'px',
            'height': boxHeight + 'px',
            'top': shiftDivY + 'px',
            'left': shiftDivX + 'px',
            'padding': '8px'
        }

        if (points.length > 3) {

            const matrix = getMatrix(
                boxWidth, boxHeight,
                points[cOpt[0]][0] + shiftX, points[cOpt[0]][1] + shiftY,
                points[cOpt[1]][0] + shiftX, points[cOpt[1]][1] + shiftY,
                points[cOpt[2]][0] + shiftX, points[cOpt[2]][1] + shiftY,
                points[cOpt[3]][0] + shiftX, points[cOpt[3]][1] + shiftY
            )

            transformIcon = {
                'transform': matrix,
                'WebkitTransform': matrix,
                'MozTransform': matrix,
                'msTransform': matrix,
                'OTransform': matrix,
                'transformOrigin': '0px 0px',
                'position': 'relative',
                'width': boxWidth + 'px',
                'height': boxHeight + 'px',
                'top': shiftDivY + 'px',
                'left': shiftDivX + 'px',
                'padding': '8px'
            }

        }

        return { boxWidth, boxHeight, minX, minY, transformIcon }

    },
    render: function() {

        const {
            style,
            points = [[0, 0], [100, 0], [100, 100], [0, 100]],
            view_id,
            popupRenderTo,
            enable_icon = false
        } = this.props

        const isEditable = this.isEditable()
        let strokeWidth = '4'
        const d = this.getD()
        const touchEnd = this.touchEnd
        const setRef = this.setRef
        const { popupOpen, isMounted, isMobile } = this.state
        const Children = this.Children

        const parentElement = this.getParentElement()
        let className = (popupOpen) ? (isMobile) ? view_id + '_classes anim' : view_id + '_classes active' : view_id + '_classes'
        const isGallery = view_id.match('gallery') ? true : false
        const name = this.getName({ view_id })

        const { boxWidth, boxHeight, minX, minY, transformIcon } = this.getMatrix({ points })

        if (isMobile && isGallery) return null

        return (
            <g>
                <g ref={function(e) {
                    setRef('hotspot', e)
                }}
                   className={className + ' ' + style.hotspotpath}>
                    {(isGallery) ?
                        <foreignObject x={minX} y={minY} width={boxWidth} height={boxHeight}>
                            <div style={transformIcon} xmlns='http://www.w3.org/1999/xhtml'>
                                {(enable_icon) ?
                                    <svg width='100%' height='100%' viewBox='0 0 24 24'
                                         xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                            d='M22 16V4c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2zm-11-4l2.03 2.71L16 11l4 5H8l3-4zM2 6v14c0 1.1.9 2 2 2h14v-2H4V6H2z'></path>
                                    </svg>
                                    : null
                                }
                            </div>
                        </foreignObject>
                        : null
                    }
                    <path
                        onTouchTap={function(e) {
                            touchEnd(e, 'click', 'click', { type: 'onClick' })
                        }}
                        onTouchStart={function(e) {

                        }}
                        d={d}></path>
                    {(!isEditable) ?
                        points.map(function(point, pi) {
                            const x1 = point[0]
                            const y1 = point[1]
                            const x2 = (points[pi + 1]) ? points[pi + 1][0] : points[0][0]
                            const y2 = (points[pi + 1]) ? points[pi + 1][1] : points[0][1]
                            return <line key={pi} x1={x1} y1={y1} x2={x2} y2={y2} strokeWidth={strokeWidth} />
                        }) : null
                    }
                </g>
                {(isMounted && parentElement) ?
                    <Manager tag={'g'} ref={function(e) {
                        setRef('poppermanager', e)
                    }}>
                        <Target component={'path'} d={d} style={{ visibility: 'hidden' }}></Target>
                        <Portal isOpened={true} renderTo={parentElement}>
                            <Popper
                                style={{ zIndex: 2147483647 }}
                                placement={'left-start'}
                                eventsEnabled={false}
                                modifiers={{
                                    flip: {
                                        behavior: ['left', 'right', 'top', 'bottom']
                                    },
                                    preventOverflow: {
                                        boundariesElement: parentElement
                                    }
                                }}
                            >
                                <Children show={popupOpen} style={style} />
                            </Popper>
                        </Portal>
                    </Manager> : null}
                {(isEditable) ? <HotSpotEditor ref={function(e) {
                    setRef('editor', e)
                }} {...this.props} onPointsChange={this.setPoints} name={name} /> : null}
            </g>
        )

    }

})

const Hotspots = createReactClass({
    getInitialState: function() {

        this.refElements = {}
        const data = this.getStateData()
        return {
            ...data
        }

    },
    componentDidMount: function() {
        const setDimensions = this.setDimensions
        setTimeout(function() {
            setDimensions()
        }, 100)
    },
    getStateData: function(data) {
        const p = (data) ? data : this.props

        const { root } = p

        const width = (root) ? root.state.width : 1920
        const height = (root) ? root.state.height : 1080

        const {
            hotspots = [],
            selected = null
        } = p

        return {
            hotspots: hotspots,
            selected: selected,
            width: width,
            height: height
        }

    },
    setSelected: async function({ id }) {
        const { selected } = this.state
        if (selected && this.refElements['hotspot_' + selected] ||
            selected == 0 && this.refElements['hotspot_' + selected]) {
            await this.refElements['hotspot_' + selected].removeSelected()
        }
        await this.setState({
            selected: id
        })
        this.updateContainer({ selected: id })
    },
    updateContainer: function(data) {
        const { parent } = this.props
        if (parent && data) {
            parent.setChildProps({
                ...data
            })
        }
    },
    setDimensions: async function() {
        const { root } = this.props

        const width = (root) ? root.state.width : 1920
        const height = (root) ? root.state.height : 1080

        if (width !== this.state.width || height !== this.state.height) {

            await this.setState({
                width,
                height
            })

            const container = this.refElements['container']
            if (container) {
                await container.setDimensions({ dimensions: { width, height }, keepwidth: true })
            }


        }
    },
    getClassName: function() {
        const { style, className } = this.props
        const cn = (className) ? (style[className]) ? style[className] : className : null
        return (cn) ? style.container + ' ' + cn : style.container
    },
    setRef: function(a, e, n) {
        if (e) {
            if (n) {
                this[n][a] = e
            } else {
                this.refElements[a] = e
            }
        }
    },
    getHotPosts: function() {

        const { style, editable, root, store, disableHotspots, construct, getRootContainer } = this.props
        const { hotspots = [], selected } = this.state
        const isEditable = (root.props.editor && editable) ? editable : false
        const t = this
        const setRef = this.setRef

        if (hotspots && hotspots.length && !disableHotspots) {
            const r = []

            hotspots.map(function(hotspot, i) {

                if (selected !== i) {

                    r.push(<HotSpot
                        construct={construct}
                        {...hotspot.props}
                        id={i}
                        ref={function(e) {
                            setRef('hotspot_' + i, e)
                        }}
                        parent={t}
                        root={root}
                        key={i}
                        style={style}
                        store={store.props.hotspots[i]}
                        isEditable={isEditable}
                        selected={false}
                        getRootContainer={getRootContainer}
                    />)

                }

            })

            if (selected || selected == 0) {

                hotspots.map(function(hotspot, i) {

                    if (selected == i) {

                        r.push(<HotSpot
                            {...hotspot.props}
                            id={i}
                            ref={function(e) {
                                setRef('hotspot_' + i, e)
                            }}
                            parent={t}
                            root={root}
                            key={i}
                            style={style}
                            store={store.props.hotspots[i]}
                            isEditable={isEditable}
                            selected={true}
                            getRootContainer={getRootContainer}
                        />)

                    }

                })

            }

            return r
        }
        return null
    },
    render: function() {

        const { style, root } = this.props
        const setRef = this.setRef
        const getHotPosts = this.getHotPosts

        const { width, height } = this.state

        return (
            <Container
                ref={function(e) {
                    setRef('container', e)
                }}
                style={style}
                autosize={'normal'}
                scale={false}
                width={width}
                height={height}
                autopositionx={'center'}
                autopositiony={'center'}
                boundingx={'center'}
                boundingy={'center'}
            >
                <svg
                    ref={function(e) {
                        setRef('svgcontainer', e)
                    }}
                    className={style.svgcontainer}
                    width={'100%'}
                    height={'100%'}
                    preserveAspectRatio={'none'}
                    viewBox={'0 0 ' + width + ' ' + height}
                >
                    {getHotPosts()}
                </svg>
            </Container>
        )
    }
})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <Hotspots {...this.props} ref={(setRef) ? function(e) {
                setRef('hotspots', e)
            } : null} />
        )
    }
})

export default createReactClass({
    getInitialState: function() {
        this.refElements = {}
        return {}
    },
    setRef: function(a, e) {
        const { setRef } = this.props
        if (a == 'hotspots') {
            this.refElements['Container'] = e
        }
        if (setRef) setRef(a, e)
    },
    displayName: 'Hotspots',
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const setRef = this.setRef
        const input = { ...this.props, style, setRef }
        return (
            <R {...input} />
        )
    }
})
