import { recursiveForms, recursiveSchema } from '../../components/Formsy/json'

import schemas, { schemaVersions } from './schema'
import components from './components'

function getItem(p = {}) {

    const { data, key, field } = p
    const t = (field) ? field.type : typeof data[key]
    const component = (field) ? field.component : null

    if (component === 'imageurl') {
        return components.imageurl(p)
    }

    if (component === 'select') {
        return components.select(p)
    }

    if (component === 'slider') {
        return components.slider(p)
    }

    if (component === 'colorpicker') {
        return components.colorpicker(p)
    }

    if (t === 'array' || t === 'object') {
        return components.innerform(p)
    }

    if (t === 'string') {
        return components.string(p)
    }

    if (t === 'number') {
        return components.number(p)
    }

    if (t === 'boolean') {
        return components.boolean(p)
    }

}

export function addDefaultMetas({ data }) {

    let defaultMetas = [
        {
            'name': 'Object ID',
            'show': [],
            'order': 0,
            'required': true,
            'type': 'string',
            'enabled': ['area', 'building', 'flat'],
            'default': 'object_id',
            'disabled': true,
            'validations': [
                {
                    'name': 'min',
                    'props': { 'value': 1, 'type': 'string' }
                }, {
                    'name': 'max',
                    'props': { 'value': 250, 'type': 'string' }
                }, {
                    'name': 'type',
                    'props': { 'value': ['string'] }
                }
            ],
            'key': 'key'
        },
        {
            'name': 'Object type',
            'show': [],
            'order': 0,
            'required': true,
            'type': 'string',
            'enabled': ['area', 'building', 'flat'],
            'component': 'select',
            'default': 'area',
            'options': [
                { 'label': 'Area', 'value': 'area' },
                { 'label': 'Building', 'value': 'building' },
                { 'label': 'Flat', 'value': 'flat' }
            ],
            'disabled': function({ data }) {
                return data['key'] === 'area';

            },
            'validations': [
                {
                    'name': 'type',
                    'props': { 'value': ['object'] }
                }, {
                    'name': 'arrayin',
                    'props': { 'value': ['area', 'building', 'flat'] }
                }, {
                    'name': 'min',
                    'props': { 'value': 1, 'type': 'object' }
                }, {
                    'name': 'max',
                    'props': { 'value': 1, 'type': 'object' }
                }
            ],
            'key': 'type',
            'update': function(p = {}) {
                const { props = {} } = p
                const parent = props.parent
                if (parent && parent.regenerateForm) parent.regenerateForm()
            }
        },
        {
            'name': 'Object name',
            'show': ['table', 'tooltip', 'popup'],
            'order': 0,
            'required': true,
            'type': 'string',
            'enabled': ['area', 'building', 'flat'],
            'default': 'Object name',
            'validations': [
                {
                    'name': 'min',
                    'props': { 'value': 1, 'type': 'string' }
                }, {
                    'name': 'max',
                    'props': { 'value': 250, 'type': 'string' }
                }, {
                    'name': 'type',
                    'props': { 'value': ['string'] }
                }
            ],
            'key': 'name',
            'update': async function(p = {}) {
                const { props = {} } = p
                const parent = props.parent
                await parent.regenerateForm()

                const root = props.root
                await root.refElements.views.regenerateForm()
            }
        },
        {
            'name': 'Status',
            'show': ['popup'],
            'order': 0,
            'required': true,
            'enabled': ['area', 'building', 'flat'],
            'type': 'string',
            'component': 'select',
            'default': 'available',
            'options': [
                { 'label': 'Available', 'value': 'available' },
                { 'label': 'Reserved', 'value': 'reserved' },
                { 'label': 'Sold', 'value': 'sold' }
            ],
            'validations': [
                {
                    'name': 'type',
                    'props': { 'value': ['object'] }
                }, {
                    'name': 'arrayin',
                    'props': { 'value': ['available', 'reserved', 'sold'] }
                }, {
                    'name': 'min',
                    'props': { 'value': 1, 'type': 'object' }
                }, {
                    'name': 'max',
                    'props': { 'value': 1, 'type': 'object' }
                }
            ],
            'key': 'status',
            'showdata': '{"disable":{"status":["sold","reserved","available"], "type":["building","area"]}}'
        },
        {
            'name': 'Description',
            'show': ['popup'],
            'order': 1,
            'required': false,
            'type': 'string',
            'enabled': [],
            'default': '',
            'validations': [
                {
                    'name': 'min',
                    'props': { 'value': 1, 'type': 'string' }
                }, {
                    'name': 'max',
                    'props': { 'value': 2500, 'type': 'string' }
                }, {
                    'name': 'type',
                    'props': { 'value': ['string'] }
                }
            ],
            'key': 'description',
        },
        {
            'name': 'Number of flats',
            'show': ['table', 'tooltip', 'popup'],
            'order': 2,
            'required': false,
            'enabled': ['building'],
            'type': 'number',
            'default': 0,
            'validations': [
                {
                    'name': 'type',
                    'props': { 'value': ['number'] }
                }, {
                    'name': 'min',
                    'props': { 'value': 0, 'type': 'number' }
                }
            ],
            'key': 'flats_number'
        },

        {
            'name': 'Price',
            'show': ['table', 'tooltip', 'popup'],
            'order': 2,
            'required': false,
            'enabled': ['flat'],
            'type': 'number',
            'default': 0,
            'validations': [
                {
                    'name': 'type',
                    'props': { 'value': ['number'] }
                }, {
                    'name': 'min',
                    'props': { 'value': 0, 'type': 'number' }
                }
            ],
            'key': 'price',
            'format': ['price', 'replace'],
            'formatdata': '{"replace":{"status":["sold"], "type":["flat","building","area"], "value":"-"}}'
        },
        {
            'name': 'Floor',
            'show': ['tooltip', 'popup'],
            'order': 4,
            'required': false,
            'enabled': ['flat'],
            'type': 'number',
            'default': 0,
            'validations': [
                {
                    'name': 'type',
                    'props': { 'value': ['number'] }
                }
            ],
            'key': 'floor'
        },
        {
            'name': 'Size',
            'show': ['tooltip', 'popup'],
            'order': 6,
            'required': false,
            'enabled': ['flat'],
            'type': 'number',
            'default': 0,
            'validations': [
                {
                    'name': 'type',
                    'props': { 'value': ['number'] }
                }, {
                    'name': 'min',
                    'props': { 'value': 0, 'type': 'number' }
                }
            ],
            'key': 'size'
        },
        {
            'name': 'Rooms',
            'show': ['tooltip', 'popup'],
            'order': 8,
            'required': false,
            'enabled': ['flat'],
            'type': 'number',
            'default': 0,
            'validations': [
                {
                    'name': 'type',
                    'props': { 'value': ['number'] }
                }, {
                    'name': 'min',
                    'props': { 'value': 0, 'type': 'number' }
                }
            ],
            'key': 'rooms'
        },

        {
            'name': 'PDF download',
            'show': ['popup'],
            'order': 102,
            'required': false,
            'enabled': ['flat'],
            'type': 'string',
            'default': '',
            'validations': [{
                'name': 'urlorempty',
                'props': {}
            }, {
                'name': 'pathorempty',
                'props': {
                    'value': ['pdf']
                }
            }],
            'key': 'pdf_download'
        }
        /*{
            "name": "PDF background",
            "show": [],
            "order": 102,
            "required": false,
            "enabled": ["flat"],
            "type": "string",
            "component": "imageurl",
            "default": "",
            "validations": [{
                "name":"urlorempty",
                "props":{}
            },{
                "name":"pathorempty",
                "props": {
                    "value":["jpg", "jpeg", "png"]
                },
            }],
            "key": "pdf_background",
        },*/
    ]

    const enabledOverWrite = {
        'default': ['component', 'type', 'validations', 'enabled', 'default', 'disabled', 'options', 'update'],
        'name': ['component', 'type', 'validations', 'enabled', 'default', 'disabled', 'options', 'update', 'required', 'show'],
        'type': ['component', 'type', 'validations', 'enabled', 'default', 'disabled', 'options', 'update', 'required'],
        'status': ['component', 'type', 'validations', 'enabled', 'default', 'disabled', 'options', 'update', 'required'],
        'description': ['component', 'type', 'validations', 'default', 'disabled', 'options', 'update', 'required', 'show', 'order', 'name'],
        'pdf_background': ['component', 'type', 'validations', 'enabled', 'default', 'disabled', 'options', 'update', 'required', 'show', 'order', 'name'],
        'key': ['component', 'type', 'validations', 'enabled', 'default', 'disabled', 'options', 'update', 'required', 'show', 'order', 'name']
    }

    const enableIfEmpty = {
        'default': [],
        'price': ['format', 'formatdata'],
        'status': ['showdata']
    }

    if (data && data.props && data.props.metas && data.props.metas.props && data.props.metas.props.metas) {
        defaultMetas.map(function(meta) {

            const key = meta.key
            const eo = (enabledOverWrite[key]) ? enabledOverWrite[key] : enabledOverWrite['default']
            const ee = (enableIfEmpty[key]) ? enableIfEmpty[key] : enableIfEmpty['default']
            let foundMeta = null

            data.props.metas.props.metas.map(function(savedMeta) {
                const savedKey = savedMeta.key
                if (savedKey === key) {
                    foundMeta = savedMeta
                }
            })

            if (!foundMeta) {
                data.props.metas.props.metas.push({ ...meta })
            } else {
                Object.keys(meta).map(function(key) {
                    if (eo.indexOf(key) > -1) {
                        foundMeta[key] = meta[key]
                    }
                    if (ee.indexOf(key) > -1) {
                        if (!foundMeta[key] && foundMeta[key] !== 0) foundMeta[key] = meta[key]
                    }
                })
            }


        })

    }

    //fixEnableSetDimensions

    if (data && data.props && data.props.views && data.props.views.props && data.props.views.props.views) {
        const views = data.props.views.props.views
        views.map(function(view) {
            if (view &&
                view.elements &&
                view.elements[0] &&
                view.elements[0].children &&
                view.elements[0].children[0] &&
                view.elements[0].children[0].children &&
                view.elements[0].children[0].children[0] &&
                view.elements[0].children[0].children[0].props &&
                view.elements[0].children[0].children[0].props.enableSetDimensions &&
                view.elements[0].children[0].children[0].props.enableSetDimensions[1] &&
                view.elements[0].children[0].children[0].props.enableSetDimensions[1].keepwidth === true &&
                view.elements[0].children[0].children[0].props.enableSetDimensions[1].id &&
                view.elements[0].children[0].children[0].props.enableSetDimensions[1].id !== 'root' &&
                view.elements[0].children[0].children[0].props.enableSetDimensions[1].id.match('root')) {

                //console.log("fixed enableSetDimensions "+view.key +" wrong id ["+view.elements[0].children[0].children[0].props.enableSetDimensions[1].id +"]");

                view.elements[0].children[0].children[0].props.enableSetDimensions[1].id = 'root'
                const imageHeight = view.elements[0].children[0].children[0].props.height
                const imageWidth = view.elements[0].children[0].children[0].props.width
                const wrongHeight = 1080
                const imageHeightToHD = (1920 / imageWidth) * imageHeight

                const shiftY = (wrongHeight - imageHeightToHD) / 2

                if (view &&
                    view.elements &&
                    view.elements[0] &&
                    view.elements[0].children &&
                    view.elements[0].children[1] &&
                    view.elements[0].children[1].children &&
                    view.elements[0].children[1].children[0] &&
                    view.elements[0].children[1].children[0].props &&
                    view.elements[0].children[1].children[0].props.hotspots) {
                    const hotspots = view.elements[0].children[1].children[0].props.hotspots
                    if (hotspots.length) {

                        hotspots.map(function(hotspot) {
                            if ('[[0,0],[100,0],[100,100],[0,100]]' !== JSON.stringify(hotspot.props.points)) {
                                hotspot.props.points.map(function(point) {
                                    point[1] = point[1] - shiftY
                                })
                            }
                        })
                    }
                }
            }
        })
    }

}

export default function() {

    const editdata = {
        editor: {
            0: function(p = {}) {

                const admin = false
                const { data } = p
                const inputSchema = p.schema
                const version = p.version

                if (typeof inputSchema == 'string' && version && schemaVersions[version] && schemaVersions[version][inputSchema]) {
                    if (version === '1.0') {
                        data.jsoneditor = {
                            'schema': 'root',
                            'name': 'Root'
                        }
                    }
                    return recursiveSchema({ data, schemas: schemaVersions[version][inputSchema], getItem })
                } else {
                    if (admin) {
                        return recursiveForms({ data, getItem })
                    } else {
                        return recursiveSchema({ data, schemas, getItem })
                    }
                }
            }
        }
    }

    return { editdata }

}
