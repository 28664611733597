import React from 'react'
import createReactClass from 'create-react-class'

import wapplrcomponents, { wapplrhelpers } from '../../../../../../../components'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'

import Controls from '../components/Controls'
import cts from '../components/Controls/Controls.css'

import FontWrapper from '../components/FontWrapper/index.js'

const Wapplrmenu = wapplrcomponents.menu
const Wapplrcontent = wapplrcomponents.content
const Wapplrpage = wapplrcomponents.page
const wapplravatarhelpers = wapplrhelpers.avatar

export default createReactClass({
    getInitialState() {
        this.refElements = {}
        this.id = this.getId()
        return {
            isMounted: true
        }
    },
    componentDidMount: function() {
        this.createStyle()
    },
    getGlobalState: function() {
        const { construct } = this.props
        return (construct.props && construct.props.state) ? construct.props.state : null
    },
    getId: function() {
        const { construct } = this.props
        return (construct.props && construct.props.id) ? construct.props.id : 'flatchooser_' + new Date().getTime()
    },
    getMe: function() {
        const state = this.getGlobalState()
        const me = (state && state.response && state.response.me) ? state.response.me : null
        if (me) wapplravatarhelpers({ user: me })
        return me
    },
    getUser: function() {
        const post = this.getPost()
        if (post && post.author && post.author.id) {
            const author = post.author
            if (author) wapplravatarhelpers({ user: author })
            return author
        }
        return null
    },
    getPost: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.currentpost && state.response.currentpost[0]) ? state.response.currentpost[0] : null
    },
    getData: function() {
        const { construct } = this.props
        return (construct.props && construct.props.data) ? construct.props.data : null
    },
    getParentRoute: function() {
        const { construct, parentroute } = this.props
        return (construct.props && construct.props.parentroute) ? construct.props.parentroute : parentroute
    },
    getContainerClassName: function() {
        const { style } = this.props
        const { containerClassName } = this.getClassNames()
        const { alignItems, setfixheight } = this.props

        let pageContainerClassName = style['page-container']
        pageContainerClassName = style.container + ' ' + pageContainerClassName + ' ' + style['page-padding']
        if (containerClassName) pageContainerClassName = pageContainerClassName + ' ' + containerClassName
        if (alignItems == 'start') pageContainerClassName = pageContainerClassName + ' ' + style.alignItemsStart
        if (alignItems == 'center') pageContainerClassName = pageContainerClassName + ' ' + style.alignItemsCenter
        if (setfixheight) pageContainerClassName = pageContainerClassName + ' ' + setfixheight
        return pageContainerClassName
    },
    hideHeader: function() {
        const {
            construct,
            containerType,
            controlsChildren,
            disableControls,
            logotext,
            logospan,
            addMenus,
            centerChildren
        } = this.props
        const { disableshare, disabledescription, disablefullscreen } = construct.props
        const hiddenlogo = !!(containerType == 'video' || containerType == 'fullpage')

        if (disableControls) {
            return !!(disableshare && disabledescription && !logotext && !logospan && !addMenus && !centerChildren)
        }
        return !!(hiddenlogo && disableshare && disabledescription && disablefullscreen && !controlsChildren && !addMenus && !centerChildren)
    },
    getHeaderClassName: function() {
        const { style, headerstyle, containerType, headerbgcolor } = this.props
        const hideheader = this.hideHeader()
        let hs = style.header
        if (containerType == 'video' || containerType == 'fullpage' || headerbgcolor) hs = hs + ' ' + headerstyle.transparentappbar
        if (hideheader) {
            hs = hs + ' ' + style.hideheader
        }
        return hs
    },
    createStyle: function() {

        const id = this.id

        const {
            headerstyle,
            playerstyle = {
                controlsiconbutton: 'controlsiconbutton',
                breadstep: 'breadstep',
                breadcrumb: 'breadcrumb'
            },
            headericoncolor,
            headertitlecolor,
            headerlinkscolor,
            headericonsize,
            breadcrumbstyle,
            disablecoltrolsandbreadcrumstyle,
            forcefontfamily,
            customcss
        } = this.props

        const className = id + '_classes'

        const head = document.head || document.getElementsByTagName('head')[0]
        if (document.getElementById(className)) head.removeChild(document.getElementById(className))

        let css = '.' + className + ' {} '
        css = css + ' #' + id + ' table {margin: 0; border: none; }'
        css = css + ' #' + id + ' tr {padding-left: 5px; padding-right:5px; background-color:#ffffff; }'

        if (forcefontfamily) {
            css = css + ' .' + className + ' *' + '{font-family:' + forcefontfamily + '!important;} '
        }

        if (headerlinkscolor && headerstyle) {
            css = css + ' .' + className + ' .' + headerstyle.link + '{color:' + headerlinkscolor + '!important;} '
        }
        if (headericoncolor && headerstyle) {
            css = css + ' .' + className + ' .' + headerstyle.navicon + '{color:' + headericoncolor + '!important;} '
            css = css + ' .' + className + ' .' + headerstyle.iconlink + ' svg {fill:' + headericoncolor + '!important;} '
            css = css + ' .' + className + ' .' + headerstyle.iconlink + ':hover svg{fill:' + headericoncolor + '!important;} '
        }
        if (headericoncolor && cts && !disablecoltrolsandbreadcrumstyle) {
            css = css + ' .' + className + ' .' + cts.iconbutton + ' svg {color:' + headericoncolor + '!important;} '
        }
        if (headericoncolor && playerstyle && !disablecoltrolsandbreadcrumstyle) {
            css = css + ' .' + className + ' .' + playerstyle.controlsiconbutton + ' svg {color:' + headericoncolor + '!important;} '
        }
        if (headertitlecolor && playerstyle && !disablecoltrolsandbreadcrumstyle) {
            css = css + ' .' + className + ' .' + playerstyle.breadstep + ' > span {color:' + headertitlecolor + '!important;} '
            css = css + ' .' + className + ' .' + playerstyle.breadcrumb + ' > div > svg {color:' + headertitlecolor + '!important;} '
        }
        if (disablecoltrolsandbreadcrumstyle && playerstyle) {
            css = css + ' .' + className + ' .' + playerstyle.breadstep + ' > span {color:' + 'rgba(0,0,0,0.87)' + '!important;} '
            css = css + ' .' + className + ' .' + playerstyle.breadcrumb + ' > div > svg {color:' + 'rgba(0,0,0,0.87)' + '!important;} '
        }
        if (headericonsize && playerstyle && !disablecoltrolsandbreadcrumstyle) {
            css = css + ' .' + className + ' .' + playerstyle.breadstep + ' > span {font-size:' + headericonsize + '!important;} '
            css = css + ' .' + className + ' .' + playerstyle.breadcrumb + ' > div > svg {font-size:' + headericonsize + '!important;} '
        }
        if (disablecoltrolsandbreadcrumstyle && playerstyle) {
            css = css + ' .' + className + ' .' + playerstyle.breadstep + ' > span {font-size:' + '14px' + '!important;} '
            css = css + ' .' + className + ' .' + playerstyle.breadcrumb + ' > div > svg {font-size:' + '14px' + '!important;} '
        }
        if (breadcrumbstyle == 'border' && playerstyle) {
            css = css + ' .' + className + ' .' + playerstyle.breadstep + ' > span {border-bottom-style: solid; border-bottom-width: 3px; border-bottom-color: transparent; padding-left:16px!important; padding-right:16px!important;} '
            if (headericoncolor && !disablecoltrolsandbreadcrumstyle) {
                css = css + ' .' + className + ' .' + playerstyle.breadstep + ':last-child > span {border-bottom-color: ' + headericoncolor + ';} '
            } else {
                css = css + ' .' + className + ' .' + playerstyle.breadstep + ':last-child > span {border-bottom-color: #3f454a;} '
            }
            css = css + ' .' + className + ' .' + playerstyle.breadcrumb + ' > div > svg {display:none!important;} '
        }
        if (breadcrumbstyle == 'slash' && playerstyle) {
            if (headericoncolor && !disablecoltrolsandbreadcrumstyle) {
                css = css + ' .' + className + ' .' + playerstyle.breadstep + ' > span:before { content: "/"; color: ' + headericoncolor + '; display: inline-block; padding-right: 8px; font-weight: bold;} '
            } else {
                css = css + ' .' + className + ' .' + playerstyle.breadstep + ' > span:before { content: "/"; color: #3f454a; display: inline-block; padding-right: 8px; font-weight: bold;} '
            }
            css = css + ' .' + className + ' .' + playerstyle.breadcrumb + ' > div > svg {display:none!important;} '
        }
        if (customcss) {
            try {
                const props = this.props
                const ccss = (typeof customcss == 'string') ? JSON.parse(customcss) : customcss
                Object.keys(ccss).forEach(function(key) {
                    const whichStyle = (key == 'app') ? '' : key
                    if (props[whichStyle + 'style'] && typeof ccss[key] == 'object') {
                        Object.keys(ccss[key]).forEach(function(cN) {
                            const replacedClassName = cN.replace(/([\#\.][a-z0-9\-_]*)/gmi, function(x) {
                                const findClassName = (x && x.slice(0, 1) == '.') ? x.slice(1) : x
                                if (props[whichStyle + 'style'][findClassName]) {
                                    return (x && x.slice(0, 1) == '.') ? '.' + props[whichStyle + 'style'][findClassName] : props[whichStyle + 'style'][findClassName]
                                }
                                return x
                            })
                            if (ccss[key][cN]) {
                                css = css + ' .' + className + ' ' + replacedClassName + ' {' + ccss[key][cN] + '}'
                            } else {
                                css = css + ' ' + replacedClassName
                            }
                        })
                    }
                })
            } catch (e) {
                console.log(e)
            }
        }

        const style = document.createElement('style')
        style.type = 'text/css'

        if (style.styleSheet) {
            style.styleSheet.cssText = css
        } else {
            style.appendChild(document.createTextNode(css))
        }

        style.setAttribute('id', className)
        head.appendChild(style)

        return className

    },
    getHeaderStyle: function() {
        const { headerbgcolor, headericoncolor } = this.props
        const s = {}
        if (headerbgcolor) {
            s.backgroundColor = headerbgcolor
        }
        return s
    },
    getAppClassName: function() {
        const { style, asaWidget = false } = this.props
        const id = this.id
        let s = (asaWidget) ? style.app + ' ' + style.widget : style.app
        s = id + '_classes ' + s
        return s
    },
    getOpenClassName: function() {
        const { style } = this.props
        return style['page-container-open']
    },
    getClassNames: function() {
        const { containerType, style, hideHeaderClass } = this.props
        const hideheader = this.hideHeader()
        let className = null
        let containerClassName = null

        if (containerType == 'video') {
            containerClassName = style.video
        }
        if (containerType == 'fullpage') {
            containerClassName = style.fullpage
        }
        if (containerType == 'fullpagewithoutpaddingswithheader') {
            containerClassName = style.fullpagewithoutpaddingswithheader
        }
        if (hideheader) {
            containerClassName = containerClassName + ' ' + style.pagepaddingtopnull
            if (hideHeaderClass) {
                containerClassName = containerClassName + ' ' + hideHeaderClass
            }
        }
        return { className, containerClassName }
    },
    getLogo: function() {

        const {
            logostyle,
            logohref,
            logotext = 'Wapplr',
            logospan = '',
            logoimage,
            logowidth,
            containerType,
            construct
        } = this.props

        const consturctlogohref = construct.props.logohref

        const lh = logohref || consturctlogohref
        let ls = (typeof logostyle == 'string') ? JSON.parse(logostyle) : (logostyle && typeof logostyle == 'object' && !logostyle.length) ? logostyle : null

        if (logoimage) {
            if (!ls) ls = {}
            ls.backgroundImage = 'url("' + logoimage + '")'
            if (!ls.width) ls.width = '150px'
            if (logowidth) ls.width = logowidth
        }

        const logo = (ls) ?
            <div style={{ display: 'block' }}>
                <div style={{ display: 'block', ...ls }}></div>
                <h4>{logotext}</h4><span>{logospan}</span></div> :
            (logotext || logospan) ? <div><h4>{logotext}</h4><span>{logospan}</span></div> : null

        return { logohref: lh, logo }
    },
    setRef: function(a, e) {
        const { setRef } = this.props
        this.refElements[a] = e
        if (setRef) setRef(a, e)
    },
    getFontsData: function() {

        const { construct } = this.props

        const r = {}

        let webfontLoaderFamilies = construct.props.webfontloaderfamilies
        if (typeof webfontLoaderFamilies == 'string') webfontLoaderFamilies = JSON.parse(webfontLoaderFamilies)

        let webfontLoaderCustom = construct.props.webfontloadercustom
        if (typeof webfontLoaderCustom == 'string') webfontLoaderCustom = JSON.parse(webfontLoaderCustom)

        let webfontLoaderUrls = construct.props.webfontloaderurls
        if (typeof webfontLoaderUrls == 'string') webfontLoaderUrls = JSON.parse(webfontLoaderUrls)

        if (webfontLoaderFamilies) r.families = webfontLoaderFamilies
        if (webfontLoaderCustom) r.custom = webfontLoaderCustom
        if (webfontLoaderUrls) r.urls = webfontLoaderUrls

        return r

    },

    render: function() {

        const {
            construct,
            menus,
            muitheme,
            style,
            modulename,
            headerstyle,
            history,
            originalhistory,
            children,
            menuRefs,
            menuData,
            pageRefs,
            onFullScreen,
            getRootContainer,
            containerStyle,
            controlsChildren,
            disableControls,
            addMenus = [],
            centerChildren
        } = this.props

        const me = this.getMe()
        const user = this.getUser()
        const post = this.getPost()
        const data = this.getData()

        const setRef = this.setRef
        const refElements = this.refElements

        const appClassName = this.getAppClassName()
        const pageContainerClassName = this.getContainerClassName()
        const headerClassName = this.getHeaderClassName()
        const headerStyle = this.getHeaderStyle()
        const openClassName = this.getOpenClassName()
        const parentroute = this.getParentRoute()
        const { logohref, logo } = this.getLogo()

        const fontsData = this.getFontsData()
        const rootProps = this.props

        return (
            <MuiThemeProvider muiTheme={getMuiTheme(muitheme)}>
                <Wapplrcontent style={style} className={appClassName}>
                    <div className={appClassName} ref={function(e) {
                        setRef('appcontainer', e)
                    }} style={containerStyle}>
                        <div className={headerClassName} style={headerStyle} ref={function(e) {
                            setRef('headercontainer', e)
                        }}>
                            <Wapplrmenu
                                ref={function(e) {
                                    menuRefs(modulename, e)
                                }}
                                popperRoot={function() {
                                    return getRootContainer()
                                }}
                                data={menuData}
                                style={headerstyle}
                                parentroute={parentroute}
                                breakpoint={800}
                                menu={
                                    [
                                        ...menus[modulename],
                                        ...addMenus,
                                        ...(!disableControls && addMenus.length) ? [{
                                            component: function(props) {
                                                if (!addMenus.length || props.state.narrow) return null
                                                return (!disableControls) ?
                                                    <Controls {...rootProps} containerStyle={null} style={cts}
                                                              onFullScreen={onFullScreen} className={'inMenu'}>
                                                        {(controlsChildren) ?
                                                            React.cloneElement(controlsChildren, {
                                                                disablebreadcrumb: true,
                                                                className: 'inMenu',
                                                                setRef: function() {
                                                                }
                                                            })
                                                            : null
                                                        }
                                                    </Controls> : null
                                            },
                                            NestedLevelFallback: function(props) {
                                                const { children } = props
                                                return React.Children.only(children)
                                            }
                                        }] : []
                                    ]
                                }
                                fixeddrawer={(menus[modulename + '_fixeddrawer'] && menus[modulename + '_fixeddrawer'].length) ? menus[modulename + '_fixeddrawer'] : null}
                                enablestickystyle={true}
                                getStickyElement={function() {
                                    return refElements['appcontainer']
                                }}
                                history={history}
                                type='menu'
                                sendprops={{
                                    me: me,
                                    user: user,
                                    post: post,
                                    data: data,
                                    construct: construct,
                                    originalhistory: originalhistory
                                }}
                                logo={logo}
                                logohref={logohref}
                                swapdirection={true}
                                onesided={true}
                            >
                                {function(props) {
                                    const hideIcons = (addMenus.length && !props.state.narrow) ? true : false
                                    const newProps = {}
                                    if (hideIcons) {
                                        newProps.disableopentoggleicon = true
                                    }
                                    const children = [
                                        (!disableControls) ?
                                            <Controls key={0} {...rootProps} containerStyle={null} style={cts}
                                                      onFullScreen={onFullScreen}
                                                      disableFullscreenFromProps={hideIcons}>
                                                {(controlsChildren) ? React.cloneElement(controlsChildren, newProps) : null}
                                            </Controls> : null,
                                        (centerChildren) ? centerChildren : null
                                    ]
                                    return children
                                }}
                            </Wapplrmenu>
                        </div>
                        <Wapplrpage
                            ref={function(e) {
                                pageRefs(modulename, e)
                            }}
                            className={pageContainerClassName}
                            openClassName={openClassName}
                        >
                            {(children) ? children : null}
                        </Wapplrpage>
                        <FontWrapper {...fontsData} useStates={false} />
                    </div>
                </Wapplrcontent>
            </MuiThemeProvider>
        )
    }
})
