import React from 'react'
import createReactClass from 'create-react-class'
import TemplateDefault from '../templates/template_default'

import { wapplrhelpers } from '../../../../../../../components'
import MetaData from '../components/MetaData'
//noinspection JSUnresolvedVariable
import mds from '../components/MetaData/MetaData.css'

import IconButton from 'material-ui/IconButton'
import LinkIcon from 'material-ui/svg-icons/content/link'
import CodeIcon from 'material-ui/svg-icons/action/code'
import OpenIcon from 'material-ui/svg-icons/action/open-in-new'
import LockOpenIcon from 'material-ui/svg-icons/action/lock-open'
import ShareIcon from 'material-ui/svg-icons/social/share'

import Share from '../components/Share'
//noinspection JSUnresolvedVariable
import shs from '../components/Share/Share.css'

import Select from '../components/Select/'
import sls from '../components/Select/Select.css'

import { generateShareIcon, ShareButtons } from 'react-share'
import { getGA } from './helpers'

const wapplravatarhelpers = wapplrhelpers.avatar

const {
    FacebookShareButton,
    GooglePlusShareButton,
    TwitterShareButton,
    PinterestShareButton,
    RedditShareButton,
    EmailShareButton,
    TumblrShareButton
} = ShareButtons

const FacebookIcon = generateShareIcon('facebook')
const TwitterIcon = generateShareIcon('twitter')
const GooglePlusIcon = generateShareIcon('google')
const PinterestIcon = generateShareIcon('pinterest')
const RedditIcon = generateShareIcon('reddit')
const TumblrIcon = generateShareIcon('tumblr')
const EmailIcon = generateShareIcon('email')

export default createReactClass({
    getInitialState: function() {
        const { selectedView } = this.props
        const currentView = this.getCurrentView()
        return {
            selectedView: (selectedView) ? selectedView : currentView
        }
    },
    sendToTracker: function() {
        const post = this.getPost()
        const url = '/player/' + post.id + '/share'
        const ga = new getGA({ ...this.props })
        ga.addGA({
            url: url
        })
    },
    componentDidMount: function() {
        const { disableTracker } = this.props
        if (!disableTracker) this.sendToTracker()
    },
    componentWillReceiveProps: function(nextProps) {
        const { selectedView } = nextProps
        const currentView = this.getCurrentView()
        const newSelectedView = (selectedView) ? selectedView : currentView

        if (newSelectedView !== this.state.selectedView) {
            this.setState({
                selectedView: newSelectedView
            })
        }
    },
    getData: function() {
        const { construct } = this.props
        return (construct && construct.props && construct.props.data) ? construct.props.data : null
    },
    translate: function({ text }) {
        const settings = this.getSettings() || {}
        const { construct } = this.props
        const { translations = [] } = (construct && construct.props && construct.props.translations) ? { translations: construct.props.translations } : settings
        let translatedText = null
        if (typeof text == 'string' && text) {
            translations.map(function(translate) {
                if (!translatedText && translate.text && translate.translate && translate.text == text) {
                    translatedText = translate.translate
                }
            })
        }
        if (translatedText) return translatedText
        return text
    },
    getViews: function() {
        const data = this.getData()
        const views = (data) ? data.props.views.props.views : []
        return views
    },
    getObjects: function() {
        const data = this.getData()
        const objects = (data) ? data.props.objects.props.objects : []
        return objects
    },
    getSettings: function() {
        const data = this.getData()
        const settings = (data) ? data.props.settings.props.settings[0] : {}
        return settings
    },
    getCurrentView: function() {
        const post = this.getPost()
        const appver = (!post && post && !post.appver || post && post.appver == '1.0') ? '1.0' : '2.0'
        if (appver == '1.0') {
            return 0
        } else {
            const startView = this.getStartView()
            return startView
        }
    },
    getStartView: function() {
        const settings = this.getSettings()
        const startView = settings.view_id
        const views = this.getViews()

        let foundStartView = false
        views.map(function(view) {
            const key = view.key
            if (startView && key == startView) foundStartView = true
        })

        if (foundStartView) return startView
        return views[0].key

    },
    getObjectData: function(p = {}) {
        const { object_id } = p
        if (object_id) {
            const objects = this.getObjects()
            if (objects && objects.length) {
                let foundObject = null
                objects.map(function(object) {
                    if (object.key == object_id) foundObject = object
                })
                return foundObject
            }
        }
        return null
    },
    getViewData: function(p = {}) {
        const { view_id } = p
        if (view_id) {
            const views = this.getViews()
            if (views && views.length) {
                let foundView = null
                views.map(function(view) {
                    if (view.key == view_id) foundView = view
                })
                return foundView
            }
        }
        return null
    },
    getViewObjectData: function({ view_id }) {
        const viewData = this.getViewData({ view_id })
        if (viewData && viewData.object_id) {
            return this.getObjectData({ object_id: viewData.object_id })
        }
        return null
    },
    getViewsOptions: function() {

        const views = this.getViews()
        const getViewData = this.getViewData
        const getViewObjectData = this.getViewObjectData
        const r = []

        views.map(function(view) {
            const view_id = view.key
            const viewData = getViewData({ view_id })
            const viewName = (viewData) ? viewData.name : ''
            const objectData = getViewObjectData({ view_id })
            if (objectData) {
                let name = objectData.name
                if (viewName) {
                    if (name) {
                        name = name + ' [' + viewName + ']'
                    } else {
                        name = viewName
                    }
                }
                if (name) {
                    const add = {
                        value: view_id,
                        label: name
                    }
                    r.push(add)
                }
            }
        })
        return r
    },

    getConstruct: function() {
        const { construct, data } = this.props
        return (construct.props && construct.props) ? construct.props : {}
    },
    getGlobalState: function() {
        const { construct } = this.props
        return (construct.props && construct.props.state) ? construct.props.state : null
    },
    getMe: function() {
        const state = this.getGlobalState()
        const me = (state && state.response && state.response.me) ? state.response.me : null
        if (me) wapplravatarhelpers({ user: me })
        return me
    },
    getUser: function() {
        const post = this.getPost()
        if (post && post.author && post.author.id) {
            const author = post.author
            if (author) wapplravatarhelpers({ user: author })
            return author
        }
        return null
    },
    getPost: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.currentpost && state.response.currentpost[0]) ? state.response.currentpost[0] : null
    },
    getMetaData: function() {

        const t = this
        const {
            history,
            disableShareCode,
            disableIcons,
            disableTokens,
            selectStyle = sls,
            getRootContainer
        } = this.props
        const construct = this.getConstruct()
        const {
            posturl,
            staticscripturl,
            postmedia,
            tokens,
            id,
            enableshareobjectwithhash,
            enableshareembeddedlocation,
            locationhref
        } = construct
        const post = this.getPost()

        const data = []

        const shareIconStyle = {
            display: 'inline-block',
            padding: '4px'
        }
        const shareContainerStyle = {
            paddingTop: '4px',
            paddingBottom: '4px',
            display: 'flex',
            flexWrap: 'wrap',
            marginLeft: '-4px'
        }

        const appver = (!post && post && !post.appver || post && post.appver == '1.0') ? '1.0' : '2.0'
        const { selectedView } = this.state

        const enableShareObjectWithHash = (selectedView && id && enableshareobjectwithhash) ? true : false
        const startView = (appver == '1.0') ? null : this.getStartView()
        const rId = (enableshareembeddedlocation) ? id : post.id
        const rPosturl = (locationhref && enableshareembeddedlocation) ? locationhref : posturl
        const rPosturlWithoutHash = rPosturl.split('#')[0]

        const shareUrl = (enableShareObjectWithHash && selectedView !== startView) ? rPosturlWithoutHash + '#' + rId + selectedView : rPosturlWithoutHash
        const shareMedia = postmedia
        const translate = this.translate

        if (appver == '1.0') {
        } else {

            const viewsOptions = this.getViewsOptions()

            data.push(
                {
                    name: translate({ text: 'selected view' }), value: function() {
                        if (enableShareObjectWithHash) {
                            return (
                                <div>
                                    <Select
                                        dropDownMenuProps={{ parentContainer: getRootContainer() }}
                                        options={viewsOptions}
                                        multiple={false}
                                        value={selectedView}
                                        style={selectStyle}
                                        onChange={function(e, value) {
                                            t.setState({
                                                selectedView: value[0]
                                            })
                                        }}
                                    />
                                </div>
                            )
                        }
                        return null
                    }
                }
            )
        }

        data.push(
            {
                icon: (!disableIcons) ? <ShareIcon /> : null,
                name: (appver == '1.0') ? 'Share' : translate({ text: 'share' }),
                value: function() {
                    return (
                        <div style={shareContainerStyle}>
                            <div style={{ ...shareIconStyle }}>
                                <FacebookShareButton url={shareUrl}>
                                    <FacebookIcon size={32} round={true} />
                                </FacebookShareButton>
                            </div>
                            <div style={shareIconStyle}>
                                <TwitterShareButton url={shareUrl}>
                                    <TwitterIcon size={32} round={true} />
                                </TwitterShareButton>
                            </div>
                            <div style={shareIconStyle}>
                                <GooglePlusShareButton url={shareUrl}>
                                    <GooglePlusIcon size={32} round={true} />
                                </GooglePlusShareButton>
                            </div>
                            <div style={shareIconStyle}>
                                <PinterestShareButton url={shareUrl} media={shareMedia}>
                                    <PinterestIcon size={32} round={true} />
                                </PinterestShareButton>
                            </div>
                            <div style={shareIconStyle}>
                                <TumblrShareButton url={shareUrl}>
                                    <TumblrIcon size={32} round={true} />
                                </TumblrShareButton>
                            </div>
                            <div style={{ ...shareIconStyle }}>
                                <EmailShareButton url={shareUrl}>
                                    <EmailIcon size={32} round={true} />
                                </EmailShareButton>
                            </div>
                        </div>
                    )
                }
            },
            {
                icon: (!disableIcons) ? <LinkIcon /> : null,
                name: (appver == '1.0') ? 'Player link' : translate({ text: 'player link' }),
                value: function({ post, style }) {
                    if (shareUrl) {
                        return (
                            <div>
                                <div style={{
                                    display: 'table-cell',
                                    verticalAlign: 'middle'
                                }}>{(shareUrl.length > 150) ? shareUrl.slice(0, 150) + '...' : shareUrl}</div>
                                <div style={{ display: 'table-cell' }}>
                                    <IconButton
                                        href={shareUrl}
                                        target={'_blank'}
                                    >
                                        <OpenIcon />
                                    </IconButton>
                                </div>
                            </div>
                        )
                    }
                    return null
                }
            },
            (!disableShareCode) ? {
                icon: (!disableIcons) ? <CodeIcon /> : null,
                name: (appver == '1.0') ? 'Embed' : translate({ text: 'embed' }),
                value: function({ post, style }) {
                    if (staticscripturl) {
                        return (
                            <div style={{ marginBottom: '16px' }}>
                                <Share post={post} staticscripturl={staticscripturl} style={shs} />
                            </div>
                        )
                    }
                    return null
                }
            } : { value: null },
            (!disableTokens) ? {
                icon: (!disableIcons) ? <LockOpenIcon /> : null,
                name: (appver == '1.0') ? 'Tokens' : translate({ text: 'tokens' }),
                value: function({ post, style }) {
                    const keys = (tokens) ? Object.keys(tokens) : null
                    if (keys && keys.length) {
                        return (
                            <div>
                                {keys.map(function(key, i) {
                                    const link = tokens[key].link
                                    const name = tokens[key].data
                                    return (
                                        <div key={i}>
                                            <div style={{
                                                display: 'table-cell',
                                                verticalAlign: 'middle'
                                            }}>{name + ': ' + link}</div>
                                            <div style={{ display: 'table-cell' }}>
                                                <IconButton
                                                    href={link}
                                                    target={'_blank'}
                                                    style={{
                                                        width: '36px',
                                                        height: '24px',
                                                        padding: '0',
                                                        paddingLeft: '12px'
                                                    }}
                                                >
                                                    <OpenIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }
                    return null
                }
            } : { value: null }
        )
        return data
    },

    render: function() {

        const {/*style, history, getthumb*/
            disableInitWithStyles,
            disableTemplate,
            Parent = TemplateDefault,
            metaStyle = mds
        } = this.props
        //const me = this.getMe();
        //const user = this.getUser();

        const post = this.getPost()
        const data = this.getData()
        const metadata = this.getMetaData()

        //noinspection JSUnresolvedVariable

        return (
            <Parent {...this.props} containerType={'normal'} containerStyle={{ backgroundColor: '#f5f6f7' }}
                    path={'/share'}>
                {(post && post.id) ?
                    <div>
                        <div>
                            <MetaData
                                disableInitWithStyles={disableInitWithStyles}
                                post={post}
                                style={metaStyle}
                                data={metadata}
                            />
                        </div>
                    </div>
                    :
                    null
                }
            </Parent>
        )
    }
})
