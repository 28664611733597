import React from 'react'
import createReactClass from 'create-react-class'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import CircularProgress from 'material-ui/CircularProgress'
import Portal from 'react-travel'

import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

const Button = createReactClass({
    getInitialState: function() {
        return { isProcessing: false }
    },
    setIsProcessing: async function(value) {
        const { isProcessing } = this.state
        if (isProcessing !== value) {
            await this.setState({
                isProcessing: value
            })
        }
    },
    render: function() {

        const { isProcessing } = this.state

        return <FlatButton
            {...this.props}
            disabled={isProcessing}
            icon={(isProcessing) ? <CircularProgress size={24} thickness={3} /> : null}
        />
    }
})

const Wapplrdialog = createReactClass({
    getInitialState: function() {

        this.refElements = {}

        return {
            dialogtitle: '',
            leftbuttons: null,
            dialogtext: '',
            canceltext: 'Cancel',
            submittext: 'Submit',
            dialogopen: false,
            dialogactions: null,
            submitprops: {},
            cancelprops: {}
        }
    },
    dialogClose: function() {
        this.setState({
            dialogopen: false
        })
    },
    processingStart: function() {
        const submitButton = this.refElements['submitbutton']
        submitButton.setIsProcessing(true)
    },
    processingEnd: async function(p = {}) {

        const submitButton = this.refElements['submitbutton']
        submitButton.setIsProcessing(false)

        if (p) {
            await this.setState(p)
        }

        const action = (this.state) ? this.state.action : null
        const { canceltext = 'Cancel', submittext = 'Submit', submitprops = {}, cancelprops = {} } = this.state
        const actions = this.dialogActions({ action, submittext, canceltext, submitprops, cancelprops })

        await this.setState({
            dialogactions: actions
        })

    },
    dialogOpen: function({
                             action,
                             title,
                             text,
                             content,
                             contentprops,
                             submittext,
                             canceltext,
                             submitprops = {},
                             cancelprops = {},
                             leftbuttons
                         }) {

        const actions = this.dialogActions({ action, submittext, canceltext, submitprops, cancelprops })

        const rsubmittext = (submittext) ? submittext : (this.state) ? this.state.submittext : 'Submit'
        const rcanceltext = (canceltext) ? canceltext : (this.state) ? this.state.canceltext : 'Cancel'

        this.setState({
            submittext: rsubmittext,
            canceltext: rcanceltext,
            dialogtitle: title,
            leftbuttons: leftbuttons,
            dialogtext: text,
            dialogcontent: content,
            dialogcontentprops: contentprops,
            dialogactions: actions,
            dialogopen: true,
            submitprops: submitprops,
            cancelprops: cancelprops,
            action: action
        })

        const processingStart = this.processingStart
        const processingEnd = this.processingEnd
        return { processingStart, processingEnd }
    },
    setRef: function(a, e, n) {
        if (e) {
            if (n) {
                this[n][a] = e
            } else {
                this.refElements[a] = e
            }
        }
    },
    dialogActions: function({
                                action,
                                submittext = 'Submit',
                                canceltext = 'Cancel',
                                submitprops = {},
                                cancelprops = {}
                            }) {

        const setRef = this.setRef

        if (canceltext) {
            return [
                <Button
                    key={0}
                    label={canceltext}
                    primary={true}
                    onTouchTap={this.dialogClose}
                    {...cancelprops}
                />,
                <Button
                    key={1}
                    label={submittext}
                    primary={true}
                    keyboardFocused={true}
                    onTouchTap={(action) ? action : this.dialogClose}
                    ref={function(e) {
                        setRef('submitbutton', e)
                    }}
                    {...submitprops}
                />
            ]
        } else {
            return [
                <Button
                    key={1}
                    label={submittext}
                    primary={true}
                    keyboardFocused={true}
                    onTouchTap={(action) ? action : this.dialogClose}
                    ref={function(e) {
                        setRef('submitbutton', e)
                    }}
                    {...submitprops}
                />
            ]
        }
    },
    render: function() {

        const { className, bodyClassName, autoScrollBodyContent, modal = false, renderTo, style } = this.props
        const Dc = this.state.dialogcontent
        const dcp = (this.state.dialogcontentprops) ? this.state.dialogcontentprops : {}

        return (
            <div>
                {(renderTo) ?
                    <Portal isOpened={true} renderTo={renderTo}>
                        <div className={(className) ? style.renderToRoot + ' ' + className : style.renderToRoot}
                             style={{ display: (this.state.dialogopen) ? 'block' : 'none' }}>
                            <div className={style.renderToLayer}>
                                <div className={style.renderToPaper}>
                                    {(this.state.dialogtitle) ?
                                        <div className={style.renderToTitle}>{this.state.dialogtitle}</div> : null}
                                    <div className={style.renderToPaperContent}>
                                        <div className={style.renderToPaperContentInner}>
                                            {(this.state.dialogtext) ? this.state.dialogtext : null}
                                            {(Dc) ? <Dc {...dcp} /> : null}
                                        </div>
                                    </div>
                                    <div className={style.renderToPaperFooter}>
                                        {(this.state.leftbuttons) ? <div
                                            className={style.renderToLeftButtons}>{this.state.leftbuttons}</div> : null}
                                        {this.state.dialogactions}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Portal> :
                    <Dialog
                        className={(className) ? style.dialog + ' ' + className : style.dialog}
                        title={this.state.dialogtitle}
                        actions={this.state.dialogactions}
                        modal={modal}
                        open={this.state.dialogopen}
                        onRequestClose={this.dialogClose}
                        autoScrollBodyContent={autoScrollBodyContent}
                        bodyClassName={bodyClassName}
                    >
                        {(this.state.dialogtext) ? this.state.dialogtext : null}
                        {(Dc) ? <Dc {...dcp} /> : null}
                    </Dialog>
                }
            </div>
        )
    }
})

const Middle = createReactClass({
    setRef: function(a, e) {
        const { setRef } = this.props
        if (setRef) setRef(a, e)
    },
    render: function() {
        const setRef = this.setRef
        return (
            <Wapplrdialog {...this.props} ref={(setRef) ? function(e) {
                setRef('dialog', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})
