import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

import Formsy from '../Formsy'
import fos from '../Formsy/Formsy.css'
import { recursiveSchema } from '../Formsy/json'

import Wapplrdialog from '../Wapplrdialog'
import wds from '../Wapplrdialog/Wapplrdialog.css'
import Wapplrsnackbar from '../Wapplrsnackbar'
import components from '../../services/editdata/components'

import FlatButton from 'material-ui/FlatButton'
import DownloadIcon from 'material-ui/svg-icons/file/file-download'
import OpenIcon from 'material-ui/svg-icons/action/open-in-new'

function getItem(p = {}) {

    const { label, data, key, field, schemaProps, datas, deep } = p
    const t = (field) ? field.type : typeof data[key]
    const component = (field) ? field.component : null

    if (component == 'select') {
        return components.select(p)
    }

    if (t == 'array' || t == 'object') {
        return components.innerform(p)
    }

    if (t == 'string') {
        return components.string(p)
    }

    if (t == 'number') {
        return components.number(p)
    }

    if (t == 'boolean') {
        return components.boolean(p)
    }

}

const FormAndRequest = createReactClass({
    getInitialState: function() {
        this.refElements = {}
        return {
            post: this.getPost()
        }
    },
    shouldComponentUpdate: function(nextProps, nextState) {
        return false
    },
    getPost: function() {
        const { post = {}, data } = this.props

        post.jsoneditor = {
            'schema': 'root',
            'name': 'Root'
        }

        if (!post.props) post.props = {}

        const form = data.form || { email: '' }
        const addToPost = {}

        Object.keys(form).map(function(key) {
            const formElement = form[key]
            if (typeof formElement == 'object' && formElement) {

            } else {
                if (typeof formElement == 'string' ||
                    typeof formElement == 'number' ||
                    typeof formElement == 'boolean' ||
                    formElement == null) {
                    addToPost[key] = formElement
                }
            }
        })

        Object.keys(addToPost).map(function(key) {
            post.props[key] = addToPost[key]
        })

        return post
    },
    setRef: function(a, e, n) {
        if (e) {
            if (n) {
                this[n][a] = e
            } else {
                this.refElements[a] = e
            }
        }
    },
    success: function(p = {}) {
        const { response = {}, data = {} } = p
        const forSuccess = data.success || {}

        let success = true
        Object.keys(forSuccess).map(function(key) {
            if (response && response[key] == forSuccess[key]) {
            } else {
                success = false
            }
        })

        if (success) {
            return true
        } else {
            return false
        }
    },
    getUrl: function() {

        const { value, data = {} } = this.props
        const { fixMixedContent } = data

        let v = value

        if (fixMixedContent) {
            if (v && v.match) {
                if (window.location.protocol == 'https:') {
                    if (v.match('http:')) {
                        v = v.replace('http:', 'https:')
                    }
                } else {
                    if (v.match('https:')) {
                        v = v.replace('https:', 'http:')
                    }
                }
            }
        }

        return v
    },
    submit: async function({ formData }) {

        const { data = {}, value } = this.props
        const { proxyURL } = data
        //const bodyData = new URLSearchParams(formData);
        const url = this.getUrl()
        const success = this.success
        const errors = []
        let response = null

        const dataObject = {}
        for (var key of formData.keys()) {
            dataObject[key] = formData.get(key)
        }
        const dataString = JSON.stringify(dataObject)
        const basicData = {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: dataString
        }

        const sendUrl = (proxyURL) ? proxyURL : url
        const sendData = (proxyURL) ? {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                url: url,
                options: basicData
            })
        } : {
            ...basicData
        }

        try {
            console.log('Start request: ' + url)
            response = await fetch(sendUrl, sendData)
        } catch (error) {
            errors.push(error)
            console.log('Error request', error)
        }

        try {
            console.log('Response status', response.status, response.statusText, response.headers.get('Status'))
        } catch (error) {
            errors.push(error)
            console.log('Response status error', response, error)
        }

        if (response && response.json) {
            let crj = null
            try {
                const cr = response.clone()
                crj = await cr.json()
            } catch (error) {
                errors.push(error)
                console.log('Invalid json data', response, error)
            }
            if (crj && typeof crj == 'object') response = await response.json()
        }

        try {
            console.log('Response json', JSON.stringify(response), response)
        } catch (error) {
            errors.push(error)
            console.log('Response json error', response, error)
        }

        let isSuccess = false

        try {
            isSuccess = success({ response, data })
        } catch (error) {
            isSuccess = false
            errors.push(error)
            console.log('isSucccess: Response parse error', error)
        }

        if (isSuccess) {
            return { success: isSuccess, response }
        } else {
            console.log('Errors', errors)
            return { success: isSuccess, response, error: 'Error', errors }
        }

    },
    translate: function({ text }) {
        const { translate } = this.props
        if (translate) return translate({ text })
        return text
    },
    responseCallback: function({ success, error, data, response, processingEnd }) {

        const dialog = this.refElements['dialog']
        const translate = this.translate

        if (success) {

            if (processingEnd) {
                processingEnd({
                    action: function() {
                        dialog.dialogClose()
                    },
                    submittext: translate({ text: data.labels.closeName || 'close' }),
                    canceltext: null
                })
            }

            this.snack(translate({ text: data.labels.successMessage || 'Success' }))

            setTimeout(function() {
                dialog.dialogClose()
            }, 2000)

        } else {
            const errorData = this.getErrorMessage({ response, errorData: data.errorData, error })
            this.onError({ response, errorData })
            if (processingEnd) processingEnd()
        }

    },
    getErrorMessage: function({ response, errorData, error }) {
        let errorMessage = ''
        if (response && typeof errorData == 'string' && errorData && response[errorData]) {
            const errorMessage = response[errorData]
        } else if (response && typeof errorData == 'object' && errorData.length) {
            errorData.map(function(key) {
                if (!errorMessage && errorData[key] && response[errorData[key]]) {
                    errorMessage = response[errorData[key]]
                }
            })
        }
        if (!errorMessage && typeof error == 'object') {
            if (error.message) errorMessage = error.message
        }
        if (!errorMessage && typeof error == 'object') {
            if (error.error) errorMessage = error.error
        }
        if (!errorMessage && typeof response == 'object') {
            if (response.message) errorMessage = response.message
        }
        if (!errorMessage && typeof response == 'object') {
            if (response.error) errorMessage = response.error
        }
        if (!errorMessage && typeof error == 'object') {
            JSON.stringify(error)
        }
        if (!errorMessage && typeof response == 'object') {
            JSON.stringify(response)
        }
        if (!errorMessage) errorMessage = 'error'
        return (errorMessage) ? this.translate({ text: errorMessage }) : errorMessage
    },
    onError: function({ response, errorData }) {
        this.snack(errorData)
    },
    snack: function(message) {
        const nodemessage = (message) ? <div>{message}</div> : <div></div>
        const snack = this.refElements['snack'] || this.refElements['snack_root']
        if (snack && snack.open) snack.open(nodemessage)
    },
    getDefaultSchema: function() {
        return {
            root: {
                type: 'object',
                hidden: true,
                fields: {
                    props: {
                        type: 'object',
                        open: true,
                        hidden: true,
                        fields: {}
                    }
                }
            }
        }
    },
    getSchema: function() {

        const { data } = this.props
        const form = data.form || { email: '' }
        const schema = this.getDefaultSchema()
        const translate = this.translate

        Object.keys(form).map(function(key) {
            const formElement = form[key]
            if (typeof formElement == 'object' && formElement) {
                schema.root.fields.props.fields[key] = { ...formElement, requiredUseWithDisableSubmit: true }
                if (schema.root.fields.props.fields[key].name) {
                    schema.root.fields.props.fields[key].name = translate({ text: schema.root.fields.props.fields[key].name })
                }
            }
        })

        return schema

    },
    getFormData: function() {

        const { post } = this.state
        const { data } = this.props
        const form = data.form || { email: '' }

        const formData = new FormData()

        Object.keys(form).map(function(key) {
            const formElement = form[key]
            if (typeof formElement == 'object' && formElement) {
                if (post.props[key] !== undefined) {
                    formData.append(key, post.props[key])
                } else {
                    formData.append(key, null)
                }
            } else {
                if (typeof formElement == 'string' ||
                    typeof formElement == 'number' ||
                    typeof formElement == 'boolean' ||
                    formElement == null) {
                    formData.append(key, formElement)
                }
            }
        })

        return formData

    },
    setPostDatas: async function({ post }) {
        await this.setState({
            post: post
        })
    },
    parseFromString: function({ data, name }) {

        const translate = this.translate

        let html = null

        if (data[name]) {

            let htmlText = data[name]

            if (data[name + 'Props']) {
                const fProps = {}

                Object.keys(data[name + 'Props']).map(function(key) {
                    const value = translate({ text: data[name + 'Props'][key] })
                    fProps[key] = value
                })

                Object.keys(fProps).map(function(key) {
                    const replace = '{' + key + '}'
                    if (htmlText.match(replace)) {
                        const re = new RegExp(replace, 'g')
                        htmlText = htmlText.replace(re, fProps[key])
                    }
                })

            }

            html = <div dangerouslySetInnerHTML={{ __html: htmlText }}></div>

        }


        return html

    },
    touchEnd: async function() {

        const submit = this.submit
        const refElements = this.refElements
        const setRef = this.setRef
        const getFormData = this.getFormData
        const responseCallback = this.responseCallback
        const setPostDatas = this.setPostDatas
        const translate = this.translate

        const { post } = this.state
        const { data, style } = this.props
        const form = data.form || { email: '' }

        const schema = this.getSchema()
        const foundFormElement = (Object.keys(schema.root.fields.props.fields).length) ? true : false

        const footerHtml = this.parseFromString({ data, name: 'footerHtml' })
        const buttonsHtml = this.parseFromString({ data, name: 'buttonsHtml' })

        if (foundFormElement) {

            const dialog = refElements['dialog']

            const { processingStart, processingEnd } = dialog.dialogOpen({
                title: translate({ text: data.title }),
                content: createReactClass({
                    render: function() {
                        return (
                            <div>
                                <Wapplrsnackbar ref={function(e) {
                                    setRef('snack', e)
                                }} className={style.snack} />
                                <Formsy {...this.props} />
                                {(footerHtml) ? <div className={style.footerHtmlContainer}>{footerHtml}</div> : null}
                            </div>
                        )
                    }
                }),
                contentprops: {
                    formdata: recursiveSchema({ data: post, schemas: schema, getItem }),
                    style: fos,
                    post: post,
                    canSubmit: true,
                    setRef: setRef
                },
                submittext: translate({ text: data.labels.submitName || 'submit' }),
                canceltext: translate({ text: data.labels.cancelName || 'cancel' }),
                leftbuttons: buttonsHtml,
                action: async function() {

                    const formsy = refElements['form']
                    const isValid = await formsy.validateFormWithChildren()

                    if (isValid) {

                        processingStart()
                        await setPostDatas({ post })
                        const formData = getFormData()
                        const { response, success, error } = await submit({ formData })
                        responseCallback({ success, error, response, data, processingEnd })

                    } else {

                        responseCallback({
                            success: false,
                            error: { message: translate({ text: data.labels.errorMessage || 'Invalid data' }) },
                            response: null,
                            data
                        })

                    }
                }

            })

            setTimeout(async function() {
                const formsy = refElements['form']
                if (formsy) formsy.validateFormWithChildren()
            }, 300)

        } else {
            const formData = getFormData()
            const { response, success, error } = await submit({ formData })
            responseCallback({ success, error, response, data })
        }

    },
    getRootContainer: function() {
        const { getRootContainer } = this.props
        return (getRootContainer) ? getRootContainer() : null
    },
    render: function() {

        const setRef = this.setRef
        const touchEnd = this.touchEnd
        const { data, style } = this.props
        const translate = this.translate
        const Icon = (data.icon == 'download') ? DownloadIcon : (data.icon == 'open') ? OpenIcon : null
        const renderTo = this.getRootContainer()

        return (
            <div>
                <Wapplrsnackbar ref={function(e) {
                    setRef('snack_root', e)
                }} />
                <Wapplrdialog
                    style={wds}
                    renderTo={renderTo}
                    autoScrollBodyContent={true}
                    modal={true}
                    setRef={setRef}
                />
                <FlatButton
                    onTouchTap={function(e) {
                        touchEnd(e)
                    }}
                    onTouchStart={function(e) {

                    }}
                    label={translate({ text: data.labels.buttonName || 'download' })}
                    icon={(Icon) ? <Icon /> : null}
                />
            </div>
        )
    }
})

const Middle = createReactClass({
    setRef: function(a, e) {
        const { setRef } = this.props
        if (setRef) setRef(a, e)
    },
    render: function() {
        const setRef = this.setRef
        return (
            <FormAndRequest {...this.props} ref={(setRef) ? function(e) {
                setRef('formandrequest', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})
