import React from 'react'
import createReactClass from 'create-react-class'

import withStyles from 'isomorphic-style-loader/lib/withStyles'

import Formsy from 'formsy-react'
import CircularProgress from 'material-ui/CircularProgress'

import InnerForm from './InnerForm'
import formsyWrapper from './wrapper'
import s from '../empty.css'

const EmptyComponent = createReactClass({
    render: function() {
        return null
    }
})

const Empty = formsyWrapper(EmptyComponent)

const comps = {
    innerform: InnerForm,
    hidden: Empty
}

export const Formitems = createReactClass({
    render: function() {

        const { style = s, formdata, root, parent, setRef, refElements, state = {}, formdeep = 0, hidden } = this.props
        const props = this.props
        const r = []

        formdata.map(function(itemdata, formindex) {

            let Comp = (hidden && itemdata.type !== 'innerform') ? comps.hidden : (itemdata.component) ? itemdata.component : (comps[itemdata.type]) ? comps[itemdata.type] : comps.hidden

            const attr = {
                name: 'wapplr_' + formindex,
                formdeep: formdeep,
                parent: parent
            }

            Object.keys(itemdata.attr).forEach(function(i) {
                const attribute = itemdata.attr[i]
                if (attribute || i == 'name' && attribute == 0) {
                    attr[i] = (typeof attribute == 'function') ? attribute(state, props) : attribute
                }
            })

            if (itemdata.type == 'innerform') {
                attr.parent = parent
                attr.root = root || parent
                attr.style = style
                attr.hidden = hidden
            }

            const icon = (itemdata.type == 'button' && state.processing) ?
                <CircularProgress size={24} thickness={3} /> : null
            const rstyle = (itemdata.style) ? (typeof itemdata.style == 'function') ? itemdata.style(state, props) : itemdata.style : null

            r.push(
                <div className={style[itemdata.type]} key={formdeep + '_' + formindex} style={rstyle}>
                    <Comp ref={function(e) {
                        setRef(attr.name, e)
                    }} {...attr} icon={icon} />
                </div>
            )

        })

        return <div className={style.formitems}>{r}</div>

    }
})

const Form = createReactClass({
    getInitialState: function() {
        this.refElements = {}
        const { formdata = [], style, canSubmit = false } = this.props

        return {
            isMounted: false,
            canSubmit: canSubmit,
            processing: false,
            height: 'auto',
            formdata: formdata
        }
    },
    isChanged: function(a, b, deep = 0, maxdeep) {

        let changed = false
        if (maxdeep && maxdeep > deep) {
            const d = deep + 1
            const isChanged = this.isChanged
            if (a && b && typeof a == 'object' && typeof b == 'object') {
                const keys = (a.length == undefined) ? Object.keys(a) : [...a.keys()]
                keys.map(function(key) {
                    if (key !== 'component' &&
                        key !== 'recursiveData' &&
                        key !== 'validations' &&
                        key !== 'validationErrors' &&
                        key !== 'parent' &&
                        key !== 'root' &&
                        key !== 'refElements' &&
                        key !== 'style' &&
                        !changed) {
                        if (typeof a[key] !== typeof b[key]) changed = true
                        if (a[key] && typeof a[key] == 'object') {
                            changed = isChanged(a[key], b[key], d, maxdeep)
                        } else {
                            if (a[key] !== b[key]) changed = true
                        }
                    }
                })
            } else {
                if (typeof a !== typeof b) changed = true
                if (a !== b) changed = true
            }
        }
        return changed
    },
    shouldComponentUpdate: function(nextProps, nextState) {

        const props = this.props
        const changed1 = this.isChanged(props, nextProps, 0, 2)
        const changed2 = (changed1) ? changed1 : this.isChanged(nextProps, props, 0, 2)
        if (changed1 || changed2) {
            return true
        }

        const state = this.state
        const changedState1 = this.isChanged(state, nextState, 0, 2)
        const changedState2 = (changedState1) ? changedState1 : this.isChanged(nextState, state, 0, 2)
        if (changedState1 || changedState2) {
            return true
        }

        return false
    },
    isValid: function() {
        const formsy = this.refElements['formsy']
        return formsy.state.isValid
    },
    setInvalid: async function() {
        const formsy = this.refElements['formsy']
        if (formsy) {
            const { isValid } = formsy.state
            if (isValid) {
                await formsy.setState({
                    isValid: false
                })
            }
        }
    },
    setValid: async function() {
        const formsy = this.refElements['formsy']
        if (formsy) {
            const { isValid } = formsy.state
            const allChildrenIsValid = this.allChildrenIsValid()

            if (!isValid && allChildrenIsValid) {
                await formsy.setState({
                    isValid: true
                })
            }
        }
    },
    setIsValid: function(isValid) {
        if (isValid) {
            this.setValid()
        } else {
            this.setInvalid()
        }
    },
    allChildrenIsValid: function() {
        const refElements = this.refElements
        let isValid = true

        Object.keys(refElements).map(function(key) {
            const e = refElements[key]
            if (e.isValid) {
                const elementIsValid = e.isValid()
                if (!elementIsValid) isValid = false
            }
        })
        return isValid
    },
    setFormDataToChildren: function(formdata) {
        const refElements = this.refElements
        formdata.map(function(data) {
            if (data.type == 'innerform') {
                const childFormData = data.attr.formdata
                const name = data.attr.name
                if (refElements[name] && refElements[name].setFormData) {
                    refElements[name].setFormData(childFormData)
                }
            }
        })
    },
    setFormData: function(formdata) {
        const container = this.refElements['container']

        const state = this.state
        const nextState = { ...state, formdata: formdata }

        const changed1 = this.isChanged(state, nextState, 0, 3)
        const changed2 = (changed1) ? changed1 : this.isChanged(nextState, state, 0, 3)
        if (changed1 || changed2) {
            this.setState({
                formdata: formdata
            })
        } else {
            this.setFormDataToChildren(formdata)
        }
    },
    resetForm: async function(setHeight) {
        const container = this.refElements['container']
        const height = (container) ? container.offsetHeight : null
        await this.setState({
            formdata: [],
            height: (setHeight && height) ? height + 'px' : 'auto'
        })
    },
    regenerateForm: async function() {

        const { formdata = [], style, canSubmit = false } = this.props

        await this.resetForm(true)

        await this.setState({
            formdata: formdata,
            height: 'auto'
        })

    },

    setRef: function(a, e) {
        const { setRef } = this.props
        this.refElements[a] = e
        if (setRef) setRef(a, e)
    },
    componentDidMount: function() {
        const { submitForm } = this.props
        if (submitForm) this.onSubmit = submitForm
        this.setState({
            isMounted: true
        })
    },

    validateForm: function() {
        const formsy = this.refElements['formsy']
        if (formsy && formsy.validateForm) return formsy.validateForm()
        return null
    },
    validateFormWithChildren: async function() {

        const formsy = this.refElements['formsy']
        await formsy.validateForm()
        const { isValid } = formsy.state
        const allChildrenIsValid = this.allChildrenIsValid()

        if (!isValid || !allChildrenIsValid) {
            await this.setIsValid(false)
            return false
        } else {
            await this.setIsValid(true)
            return true
        }
    },
    enableButton: function() {
        this.setState({
            canSubmit: true
        })
    },
    disableButton: function() {
        this.setState({
            canSubmit: false
        })
    },
    processingStart: function(disable) {
        this.setState({
            processing: true,
            canSubmit: !disable
        })
    },
    processingEnd: function(disable) {
        this.setState({
            processing: false,
            canSubmit: !disable
        })
    },
    submitForm: function(a, b, c) {
        this.onSubmit(a, b, c, {
            elements: this.refElements,
            disableButton: this.disableButton,
            enableButton: this.enableButton,
            processingStart: this.processingStart,
            processingEnd: this.processingEnd
        })
    },
    onSubmit: function(a, b, c) {
    },
    notifyFormError: function(data) {
        const { notifyFormError } = this.props
        if (notifyFormError) notifyFormError(data)
    },
    getFormElementByName: function(p = {}) {
        let found = null
        const t = this
        const { e = t, name } = p

        const getFormElementByName = this.getFormElementByName
        if (e && e.refElements) {
            Object.keys(e.refElements).map(function(key) {
                if (key == name) found = e.refElements[key]
                if (!found && e.refElements[key].refElements) {
                    found = getFormElementByName({ e: e.refElements[key], name })
                }
            })
        }
        return found
    },
    scrollToElement: function({ name }) {
        const { getScrollElement } = this.props
        const scrollElement = (getScrollElement) ? getScrollElement() : null
        const foundElement = this.getFormElementByName({ name })
        if (foundElement && scrollElement) {
            const e = foundElement.refElements.container
            scrollElement.scrollTop = e.offsetTop
        }
    },
    render: function() {
        const { className, style } = this.props

        const setRef = this.setRef
        const refElements = this.refElements
        const { formdata, height } = this.state
        const input = {
            ...this.props,
            parent: this,
            root: this,
            setRef: setRef,
            refElements: refElements,
            formdata,
            state: this.state,
            formdeep: 0
        }

        const cn = (className) ? (style[className]) ? style[className] : className : ''

        return (
            <div>
                <div className={cn} ref={function(e) {
                    setRef('container', e)
                }} style={{ height: height }}>
                    <Formsy.Form
                        ref={function(e) {
                            setRef('formsy', e)
                        }}
                        onValid={this.enableButton}
                        onInvalid={this.disableButton}
                        onValidSubmit={this.submitForm}
                        onInvalidSubmit={this.notifyFormError}
                    >
                        <div>
                            {(this.state.isMounted) ? <Formitems {...input} /> : null}
                        </div>
                    </Formsy.Form>
                </div>
            </div>
        )
    }
})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <Form {...this.props} ref={(setRef) ? function(e) {
                setRef('form', e)
            } : null} />
        )
    }
})

export default createReactClass({
    displayName: 'Form',
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})

