export function routes({ configroutes, defaultRootAction, requests, name, c }) {

    const request = requests[name]()
    const userRequest = requests['measuser']()

    const flatchooserapp = c.storedConfig.flatchooserapp

    return [
        {
            path: configroutes['menu' + name + 'slug'] + '/edit',
            data: {
                content: name + 'edit',
                fetchreq: request,
                disablefetchcall: true,
                callback: defaultRootAction
            },
            children: [
                {
                    path: '/:' + name,
                    data: {
                        startroute: configroutes['menu' + name + 'slug'] + '/edit',
                        content: name + 'edit',
                        disablefetchcall: true,
                        fetchreq: request
                    }
                }
            ]
        }, {
            path: configroutes['menu' + name + 'slug'] + '/json',
            data: {
                content: name + 'json',
                fetchreq: request,
                disablefetchcall: true,
                callback: defaultRootAction
            },
            children: [
                {
                    path: '/:' + name,
                    data: {
                        startroute: configroutes['menu' + name + 'slug'] + '/json',
                        content: name + 'json',
                        disablefetchcall: true,
                        fetchreq: request
                    }
                }
            ]
        }, {
            path: configroutes['menu' + name + 'slug'] + '/embed',
            data: {
                content: name + 'embed',
                fetchreq: request,
                disablefetchcall: true,
                callback: defaultRootAction
            },
            children: [
                {
                    path: '/:' + name,
                    data: {
                        startroute: configroutes['menu' + name + 'slug'] + '/embed',
                        content: name + 'embed',
                        disablefetchcall: true,
                        callback: async function(p = {}) {

                            const { next, r, getContent, params } = p
                            const findpath = '/'
                            const postid = params[name]

                            flatchooserapp.startroute = findpath
                            const wapplrcontentdata = getContent(flatchooserapp)

                            if (wapplrcontentdata.content && wapplrcontentdata.status !== 404) {
                                const routedata = wapplrcontentdata.data
                                r.data.fetchreq = routedata.fetchreq || request
                                r.data.content.getFetch = null
                                r.data.content.route = findpath
                                r.title = wapplrcontentdata.content.title
                                if (postid) r.data.params = { [name]: postid }
                                return r
                            }

                            return next()
                        }
                    }
                }, {
                    path: '/:' + name + '/:page',
                    data: {
                        startroute: configroutes['menu' + name + 'slug'] + '/embed',
                        content: name,
                        disablefetchcall: true,
                        callback: async function(p = {}) {

                            const { next, r, getContent, params } = p
                            const findpath = '/' + params.page
                            const postid = params[name]

                            flatchooserapp.startroute = findpath
                            const wapplrcontentdata = getContent(flatchooserapp)

                            if (wapplrcontentdata.content && wapplrcontentdata.status !== 404) {
                                const routedata = wapplrcontentdata.data
                                r.data.fetchreq = routedata.fetchreq || request
                                r.data.content.getFetch = null
                                r.data.content.route = findpath
                                r.title = wapplrcontentdata.content.title
                                if (postid) r.data.params = { [name]: postid }
                                return r
                            }

                            return next()
                        }

                    }
                }
            ]
        },
        {
            path: configroutes['menu' + name + 'slug'] + '/urlquery',
            data: {
                content: name + 'urlquery',
                fetchreq: request,
                disablefetchcall: true,
                callback: defaultRootAction
            },
            children: [
                {
                    path: '/:' + name,
                    data: {
                        startroute: configroutes['menu' + name + 'slug'] + '/urlquery',
                        content: name + 'urlquery',
                        disablefetchcall: true,
                        callback: async function(p = {}) {

                            const { next, r, getContent, params } = p
                            const findpath = '/'
                            const postid = params[name]

                            flatchooserapp.startroute = findpath
                            const wapplrcontentdata = getContent(flatchooserapp)

                            if (wapplrcontentdata.content && wapplrcontentdata.status !== 404) {
                                const routedata = wapplrcontentdata.data
                                r.data.fetchreq = routedata.fetchreq || request
                                r.data.content.getFetch = null
                                r.data.content.route = findpath
                                r.title = wapplrcontentdata.content.title
                                if (postid) r.data.params = { [name]: postid }
                                return r
                            }

                            return next()
                        }
                    }
                }, {
                    path: '/:' + name + '/:page',
                    data: {
                        startroute: configroutes['menu' + name + 'slug'] + '/urlquery',
                        content: name,
                        disablefetchcall: true,
                        callback: async function(p = {}) {

                            const { next, r, getContent, params } = p
                            const findpath = '/' + params.page
                            const postid = params[name]

                            flatchooserapp.startroute = findpath
                            const wapplrcontentdata = getContent(flatchooserapp)

                            if (wapplrcontentdata.content && wapplrcontentdata.status !== 404) {
                                const routedata = wapplrcontentdata.data
                                r.data.fetchreq = routedata.fetchreq || request
                                r.data.content.getFetch = null
                                r.data.content.route = findpath
                                r.title = wapplrcontentdata.content.title
                                if (postid) r.data.params = { [name]: postid }
                                return r
                            }

                            return next()
                        }

                    }
                }
            ]
        },
        {
            path: configroutes['menu' + name + 'slug'] + '/new',
            data: {
                content: name + 'new',
                fetchreq: userRequest
            }
        }, {
            path: configroutes['menu' + name + 'slug'],
            data: {
                content: name,
                fetchreq: request,
                disablefetchcall: true,
                callback: defaultRootAction
            },
            children: [
                {
                    path: '/:' + name,
                    data: {
                        startroute: configroutes['menu' + name + 'slug'],
                        content: name,
                        disablefetchcall: true,
                        callback: async function(p = {}) {

                            const { next, r, getContent, params } = p
                            const findpath = '/'
                            const postid = params[name]

                            flatchooserapp.startroute = findpath
                            const wapplrcontentdata = getContent(flatchooserapp)

                            if (wapplrcontentdata.content && wapplrcontentdata.status !== 404) {
                                const routedata = wapplrcontentdata.data
                                r.data.fetchreq = routedata.fetchreq || request
                                r.data.content.getFetch = null
                                r.data.content.route = findpath
                                r.title = wapplrcontentdata.content.title
                                if (postid) r.data.params = { [name]: postid }
                                return r
                            }

                            return next()
                        }
                    }
                },
                {
                    path: '/:' + name + '/:page',
                    data: {
                        startroute: configroutes['menu' + name + 'slug'],
                        content: name,
                        disablefetchcall: true,
                        callback: async function(p = {}) {

                            const { next, r, getContent, params } = p
                            const findpath = '/' + params.page
                            const postid = params[name]

                            flatchooserapp.startroute = findpath
                            const wapplrcontentdata = getContent(flatchooserapp)

                            if (wapplrcontentdata.content && wapplrcontentdata.status !== 404) {
                                const routedata = wapplrcontentdata.data
                                r.data.fetchreq = routedata.fetchreq || request
                                r.data.content.getFetch = null
                                r.data.content.route = findpath
                                r.title = wapplrcontentdata.content.title
                                if (postid) r.data.params = { [name]: postid }
                                return r
                            }

                            return next()
                        }

                    }
                }
            ]
        }
    ]
}

