import root from './root'
import image from './image'
import hotspot from './hotspot'

import flatchooser1 from './flatchooser1'
import flatchooser2 from './flatchooser2'

export default {
    root: root,
    image: image,
    hotspot: hotspot
}

export const ver1 = {
    ...flatchooser1
}

export const ver2 = {
    ...flatchooser2
}

export const schemaVersions = {
    '1.0': ver1,
    '2.0': ver2
}
