import config from '../config'

const lowername = config.name.toLowerCase()

export default {
    ['post' + lowername + 'title']: 'Player',
    ['menu' + lowername + 'title']: 'Player',
    ['edit' + lowername + 'title']: 'Edit player',
    ['json' + lowername + 'title']: 'Edit player',
    ['fullscreen' + lowername + 'title']: 'Player',
    ['new' + lowername + 'title']: 'New player',
    ['user' + config.path + 'subtitle']: 'Players',
    ['user' + config.path + 'trashsubtitle']: 'Deleted players',
    ['wapplruserapp' + config.path + 'name']: 'Players',
    ['wapplruserapp' + config.path + 'title']: 'User players',
    ['wapplruserapp' + config.path + 'trash']: 'Deleted players',

    [lowername + 'description']: 'Player description',

    ['all' + config.path]: 'All players',
    ['my' + config.path]: 'My players',
    ['filter' + config.path]: 'Categories',

    [lowername + 'searchhint']: function({ user, taxs }) {
        const username = (user && user.name && user.name.first) ? user.name.first : null
        let r = 'Search in players'
        if (username && !taxs) r = 'Search in ' + username + '\'s players'
        if (!username && taxs) r = 'Search in players in ' + taxs + ' category'
        if (username && taxs) r = 'Search in ' + username + '\'s players, and in ' + taxs + ' category'
        return r
    },

    stepsetbasicdata: 'Set basic data',
    stepchooseacover: 'Choose a cover image',
    stepaddsomeimage: 'Add some image',
    stepcategory: 'Select category',

    edithintappjson: 'App data (json)',
    addmoredatabutton: 'Add more data',
    edithintdescription: 'Description',
    saveplayer: 'Save player',
    saveplayernew: 'Create player',

    edithintbackgroundimageurl: 'Background image url',
    edithintdataid: 'Data id',
    editlabelappjsonenableadmin: 'Enable edit for json',
    edithintappver: 'App version',
    edithinttitle: 'Title',
    editlabelaccesstoplayer: 'Access to player',
    editlabelrunscript: 'Run script',
    editlabeldisablescriptnotification: 'Disable script notifications',
    edithinttokens: 'Tokens',
    editnotokens: 'No tokens',
    edithinttoken: 'Token hash',
    edithintselectschema: 'Select schema',
    edithintplusdata: 'Plus data',


    mincharerror: function(a) {
        return 'Minimum ' + a + ' character'
    },
    maxcharerror: function(a) {
        return 'Maximum ' + a + ' character'
    },
    minarrerror: function(a) {
        return 'Minimum ' + a
    },
    maxarrerror: function(a) {
        return 'Maximum ' + a
    },

    editlabelworkstate: 'Work state',

    workstatecreated: 'Created',
    workstateprogress: 'Progress',
    workstatefinished: 'Finished',

    edithintcoproducers: 'Coproducers',

    edithintDisabledChangesScript: 'Disabled from changes by script, eg: ["123465789", "123456788"]',
    parsableArray: "Can't parse the string represents array"

}
