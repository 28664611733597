import React from 'react'
import createReactClass from 'create-react-class'

import TextField from 'material-ui/TextField'

import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

const Text = createReactClass({
    getInitialState: function() {
        return {
            value: this.props.value,
            isMounted: true
        }
    },
    isChanged: function(a, b) {
        let changed = false
        const isChanged = this.isChanged
        if (a && b && typeof a == 'object' && typeof b == 'object') {
            const keys = (a.length == undefined) ? Object.keys(a) : [...a.keys()]
            keys.map(function(key) {
                if (typeof a[key] !== typeof b[key]) changed = true
                if (!changed) {
                    if (a[key] && typeof a[key] == 'object') {
                        changed = isChanged(a[key], b[key])
                    } else {
                        if (a[key] !== b[key]) changed = true
                    }
                }
            })
        } else {
            if (typeof a !== typeof b) changed = true
            if (a !== b) changed = true
        }
        return changed
    },
    shouldComponentUpdate: function(nextProps, nextState) {
        const state = this.state
        const changed1 = this.isChanged(state, nextState)
        const changed2 = (changed1) ? changed1 : this.isChanged(nextState, state)
        if (changed1 || changed2) {
            return true
        }
        return false
    },

    handleUpdate: function(e, nextValue = '') {
        const { onChange } = this.props
        this.setState({
            value: nextValue
        })
        if (onChange) onChange(e, nextValue)
    },
    onBlur: function(e) {
        const { value } = this.state
        const { onBlur } = this.props
        if (onBlur) onBlur(e, value)
    },
    render() {

        const {
            style,
            ...rest
        } = this.props

        const { value } = this.state
        const v = (value) ? value : ''

        return (
            <div className={style.text}>
                <TextField
                    {...rest}
                    onChange={this.handleUpdate}
                    onBlur={this.onBlur}
                    value={v}
                />
            </div>
        )
    }

})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <Text {...this.props} ref={(setRef) ? function(e) {
                setRef('text', e)
            } : null} />
        )
    }
})

export default createReactClass({
    displayName: 'Text',
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})
