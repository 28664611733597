import React from 'react'
import createReactClass from 'create-react-class'

import SelectField from '../MaterialUi/src/SelectField'
import MenuItem from 'material-ui/MenuItem'

import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

import DoneIcon from 'material-ui/svg-icons/action/done'

const Select = createReactClass({
    getInitialState: function() {
        return {
            value: this.props.value,
            isMounted: true
        }
    },
    isChanged: function(a, b) {
        let changed = false
        const isChanged = this.isChanged
        if (a && b && typeof a == 'object' && typeof b == 'object') {
            const keys = (a.length == undefined) ? Object.keys(a) : [...a.keys()]
            keys.map(function(key) {
                if (typeof a[key] !== typeof b[key]) changed = true
                if (!changed) {
                    if (a[key] && typeof a[key] == 'object') {
                        changed = isChanged(a[key], b[key])
                    } else {
                        if (a[key] !== b[key]) changed = true
                    }
                }
            })
        } else {
            if (typeof a !== typeof b) changed = true
            if (a !== b) changed = true
        }
        return changed
    },
    shouldComponentUpdate: function(nextProps, nextState) {
        const state = this.state
        const changed1 = this.isChanged(state, nextState)
        const changed2 = (changed1) ? changed1 : this.isChanged(nextState, state)
        if (changed1 || changed2) {
            return true
        }
        return false
    },


    handleUpdate: function(e, key, payload) {

        const nextValue = []
        if (payload && typeof payload == 'string') {
            nextValue.push(payload)
        }
        if (payload && typeof payload == 'object') {
            nextValue.push(...payload)
        }

        this.setState({
            value: nextValue
        })

        const { onChange } = this.props
        if (onChange) onChange(e, nextValue)

    },
    getChildren: function() {

        const value = this.getRenderValue()
        const { options = [], multiple, inset = true, disableIcon = false } = this.props

        return options.map(function(o, i) {

            const oname = o.label
            const ovalue = o.value
            const checked = (value && typeof value == 'object' && value.indexOf(ovalue) > -1 || value == ovalue) ? true : false

            return <MenuItem
                key={i}
                insetChildren={inset}
                value={ovalue}
                primaryText={oname}
                leftIcon={(disableIcon) ? null : (checked) ? <DoneIcon /> : null}
            />
        })

    },
    getRenderValue: function() {
        const { multiple } = this.props
        const { value = [] } = this.state
        if (multiple && typeof value !== 'object') return [value]
        if (!multiple && typeof value == 'object' && typeof value[0] !== 'undefined') return value[0]
        if (!multiple && typeof value == 'object' && typeof value[0] == 'undefined') return ''
        return value
    },
    render() {

        const {
            options, // eslint-disable-line no-unused-vars
            inset, // eslint-disable-line no-unused-vars
            disableIcon, // eslint-disable-line no-unused-vars
            style,
            containerStyle = {},
            selectStyle = {},
            ...rest
        } = this.props

        const children = this.getChildren()
        const value = this.getRenderValue()

        return (
            <div className={style.select} style={containerStyle}>
                <SelectField
                    {...rest}
                    onChange={this.handleUpdate}
                    value={value}
                    style={selectStyle}
                >
                    {children}
                </SelectField>
            </div>
        )
    }

})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <Select {...this.props} ref={(setRef) ? function(e) {
                setRef('select', e)
            } : null} />
        )
    }
})

export default createReactClass({
    displayName: 'Select',
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})
