import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

import { resizeListen, resizeUnlisten } from 'dom-resize'
import Portal from 'react-travel'
import SvgIcon from 'material-ui/SvgIcon'

export const renderFromData = function({ components = {}, elements }) {
    if (elements && elements.length) {
        return elements.map(function(element, i) {
            if (element && element.component && components[element.component] && components[element.component].component) {

                const C = components[element.component].component

                const defaultProps = components[element.component].props || {}
                const props = element.props || {}
                const children = (element.children && element.children.length) ? renderFromData({
                    components,
                    elements: element.children
                }) : null

                return <C {...props} {...defaultProps} children={children} key={i} store={element} />
            }

            return null
        })
    }


    return null
}

const RecursiveChildComponent = createReactClass({
    getID: function() {
        const id = this.containerID || 0
        this.containerID = id + 1
        return this.containerID
    },
    recursiveCloneChildren: function(children, deep = 0) {
        const { addProps = {}, setRef, enableComponentsAddProps } = this.props
        const getID = this.getID
        const recursiveCloneChildren = this.recursiveCloneChildren

        return React.Children.map(children, function(child, i) {
            var childProps = {}
            if (child && React.isValidElement(child) && child.type && child.type.displayName == 'Container' ||
                child && child.type && child.type.displayName && enableComponentsAddProps && enableComponentsAddProps.indexOf(child.type.displayName) !== -1) {

                childProps = { ...addProps }
                const id = getID()
                if (setRef) {
                    childProps.ref = function(e) {
                        const r = (e && e.refElements && e.refElements['Container']) ? e.refElements['Container'] : e
                        setRef(id, r)
                        if (child.props.id) setRef(child.props.id, r)
                        const { ref } = child
                        if (typeof ref === 'function') {
                            ref(e)
                        }
                    }
                }
                if (!child.props.id) childProps.id = id
                childProps.deep = [deep, i]
                childProps.containerId = id
                childProps.children = recursiveCloneChildren(child.props.children, deep + 1)
                return React.cloneElement(child, childProps)
            }
            return child
        })

    },
    render: function() {
        const { children } = this.props
        this.containerID = 0
        const r = this.recursiveCloneChildren(children)
        return (r && r[0]) ? r[0] : null
    }
})

const Editor = createReactClass({
    getInitialState: function() {
        this.refElements = {}
        return {}
    },
    setRef: function(a, e, n) {
        if (e) {
            if (n) {
                this[n][a] = e
            } else {
                this.refElements[a] = e
            }
        }
    },
    render: function() {
        const { style, editorStyle = {} } = this.props
        const setRef = this.setRef
        return (
            <div ref={function(e) {
                setRef('container', e)
            }} className={style.editor} style={editorStyle}></div>
        )
    }
})

const Root = createReactClass({
    getInitialState: function() {

        this.refElements = {}
        this.resizelisteners = {}

        const data = this.getStateData()

        return {
            visibility: 'visible',
            isMounted: true,
            ...data
        }

    },

    getStateData: function(data) {
        const p = (data) ? data : this.props

        const {
            autosize = 'normal',
            scale = false,
            width = 100,
            height = 100,
            relativewidth = false,
            relativeheight = false,
            autopositionx = false,
            autopositiony = false,
            boundingx = 0,
            boundingy = 0,
            positionx = 0,
            positiony = 0
        } = p

        return {
            autosize: autosize,
            scale: scale,
            width: width,
            height: height,
            relativewidth: relativewidth,
            relativeheight: relativeheight,
            autopositionx: autopositionx,
            autopositiony: autopositiony,
            boundingx: boundingx,
            boundingy: boundingy,
            positionx: positionx,
            positiony: positiony
        }

    },
    setRef: function(a, e, n) {
        if (e) {
            if (n) {
                this[n][a] = e
            } else {
                this.refElements[a] = e
            }
        }
    },
    componentDidMount: function() {
        const addResizeEvent = this.addResizeEvent
        const resizeAll = this.resizeAll
        const setVisible = this.setVisible

        setTimeout(function() {
            resizeAll()
            addResizeEvent()
            setVisible()
        })
    },
    componentWillUnmount: function() {
        this.removeResizeEvent()
        this.state.isMounted = false
        this.setState({
            isMounted: false
        })
    },

    /*Resize*/

    resizelisteners: {},
    removeResizeListener: function(f, id) {
        const resizelisteners = this.resizelisteners
        const r = {}
        Object.keys(resizelisteners).map(function(key) {
            const listener = resizelisteners[key]
            if (listener !== f && id !== key) {
                r[key] = listener
            }
        })
        this.resizelisteners = r
        return r
    },
    addResizeListener: function(f) {
        const resizelisteners = this.resizelisteners
        const id = this.getResizeListenerID()
        resizelisteners[id] = f
        return { resizelisteners, id }
    },
    getResizeListenerID: function() {
        const lastresizelistenerid = this.lastresizelistenerid || 1
        this.lastresizelistenerid = lastresizelistenerid + 1
        return this.lastresizelistenerid
    },
    runResizeListeners: function(e) {
        const t = this
        const resizelisteners = t.resizelisteners
        t.disableRunResizeListeners = true

        Object.keys(resizelisteners).reverse().map(function(key) {
            const listener = resizelisteners[key]
            listener(e)
        })

        setTimeout(function() {
            t.disableRunResizeListeners = false
        }, 100)

    },
    resizeDelay: function(e) {
        if (!this.disableRunResizeListeners) {
            const t = this
            const d = new Date().getTime()
            const lt = t.lasttime || 0
            const delay = 600

            if ((d - lt) > delay) {
                if (t.delayTimeout) clearTimeout(t.delayTimeout)
                t.lasttime = d
                t.runResizeListeners(e)
            } else {
                if (t.delayTimeout) clearTimeout(t.delayTimeout)
                let wt = delay
                if (d - lt < delay) wt = (delay + 10) - (d - lt)
                this.delayTimeout = setTimeout(function() {
                    t.lasttime = new Date().getTime()
                    t.runResizeListeners(e)
                }, wt)
            }
        }
    },
    addResizeEvent: function() {
        const { getResizeElement, addResizeListener, removeResizeListener } = this.props
        const thereIsParentResizeFeature = (addResizeListener && removeResizeListener) ? true : false

        if (!thereIsParentResizeFeature) {
            const resizeElement = getResizeElement()
            if (resizeElement && resizeElement._dRTrigger && resizeElement._dRTrigger.contentDocument) {
                resizeUnlisten(resizeElement)
            }
            if (resizeElement) {
                resizeListen(resizeElement, this.resizeDelay)
            }
        } else {
            const { id } = addResizeListener(this.resizeDelay)
            this.listenerID = id
        }
    },
    removeResizeEvent: function() {

        const { getResizeElement, addResizeListener, removeResizeListener } = this.props
        const thereIsParentResizeFeature = (addResizeListener && removeResizeListener) ? true : false

        if (this.delayTimeout) clearTimeout(this.delayTimeout)
        this.resizelisteners = {}

        if (!thereIsParentResizeFeature) {
            const resizeElement = getResizeElement()
            if (resizeElement && resizeElement._dRTrigger && resizeElement._dRTrigger.contentDocument) {
                resizeUnlisten(resizeElement)
            }
        } else {
            removeResizeListener(this.resizeDelay, this.listenerID || 0)
        }
    },

    resizeAll: function() {
        const refElements = this.refElements
        refElements['container'].resize()
        Object.keys(refElements).map(function(key) {
            if (!isNaN(Number(key)) && refElements[key].resize) {
                refElements[key].resize()
            }
        })
    },

    setVisible: function() {
        const { visibility, isMounted } = this.state
        if (isMounted && visibility == 'hidden') {
            this.setState({
                visibility: 'visible'
            })
        }
    },
    setDimensions: async function(props = {}) {

        const t = this
        const { dimensions = {}, keepwidth } = props
        const { width, height } = this.state
        let newwidth = dimensions.width
        let newheight = dimensions.height

        if (keepwidth) {
            const r = newheight / newwidth
            newwidth = width
            newheight = width * r
        }

        if (newwidth && newheight) {

            if (newwidth !== width || newheight !== height) {
                await this.setState({
                    width: newwidth,
                    height: newheight
                })
                const container = this.refElements['container']
                if (container) {
                    await container.setDimensions(props)
                }

            } else {
                t.resizeAll()
            }
        }
    },
    getClassName: function() {
        const { style, className } = this.props
        const cn = (className) ? (style[className]) ? style[className] : className : null
        return (cn) ? style.root + ' ' + cn : style.root
    },
    updateContainer: function(p = {}) {

        const { data, datas } = p
        const updateids = p.update
        const t = this

        if (updateids && updateids.length) {
            updateids.map(function(update) {
                const { id } = update
                const updateData = update.data
                let e = t.refElements[id]
                const d = (updateData && datas && datas[updateData.deep] && datas[updateData.deep][updateData.key]) ? datas[updateData.deep][updateData.key] : data
                if (e && e.updateContainer) e.updateContainer(d, updateData.key)
            })
        } else {

            this.setState({
                time: new Date().getTime()
            })
        }
    },

    /*Render*/

    render: function() {

        const { children, style, customStyle, editor, editorStyle, store, enableComponentsAddProps } = this.props
        const t = this

        const {
            visibility,
            autosize,
            scale,
            width,
            height,
            autopositionx,
            autopositiony,
            boundingx,
            boundingy,
            positionx,
            positiony
        } = this.state

        const addResizeListener = this.addResizeListener
        const removeResizeListener = this.removeResizeListener
        const resizeAll = this.resizeAll
        const setDimensions = this.setDimensions
        const setRef = this.setRef
        const className = this.getClassName()

        return (
            <div className={style.rootcontainer}>
                {(editor) ? <Editor {...this.props} editorStyle={editorStyle} ref={function(e) {
                    setRef('editor', e)
                }} /> : null}
                <RecursiveChildComponent
                    addProps={{
                        root: t
                    }}
                    setRef={setRef}
                    enableComponentsAddProps={enableComponentsAddProps}
                >
                    <Container
                        ref={function(e) {
                            setRef('container', e)
                        }}
                        style={style}
                        autosize={autosize}
                        scale={scale}
                        width={width}
                        height={height}
                        autopositionx={autopositionx}
                        autopositiony={autopositiony}
                        boundingx={boundingx}
                        boundingy={boundingy}
                        customStyle={{ ...customStyle, visibility: visibility }}
                        className={className}
                        store={store}
                        enableComponentsAddProps={enableComponentsAddProps}
                    >
                        {children}
                    </Container>
                </RecursiveChildComponent>
            </div>
        )
    }
})


export const Tools = createReactClass({
    getInitialState: function() {

        this.touchStart = this.touchStart
        this.touchEnd = this.touchEnd
        this.touchMove = this.touchMove

        this.refElements = {}
        const dimensions = this.getDimensions({ initial: true })

        return {
            isMounted: false,
            ...dimensions
        }
    },
    componentDidMount: function() {
        const setDimensions = this.setDimensions
        const addListeners = this.addListeners
        this.setState({
            isMounted: true
        })
        setTimeout(function() {
            setDimensions()
            addListeners()
        }, 100)
    },
    componentWillUnmount: function() {
        this.setState({
            isMounted: false
        })
        this.removeListeners()
    },
    isEditable: function(p = {}) {
        const { initial } = p
        const { root = {}, editable } = this.props
        const state = this.state || {}
        const { isMounted } = state
        const { props = {} } = root
        const { editor } = props

        return (editor && editable && isMounted || editor && editable && initial) ? editable : false
    },
    setDimensions: function() {

        const {
            width,
            height,
            left,
            top,
            isMounted
        } = this.state

        const newDimensions = this.getDimensions()

        if (newDimensions.width !== width ||
            newDimensions.height !== height ||
            newDimensions.left !== left ||
            newDimensions.top !== top) {
            if (isMounted) {
                this.setState({
                    ...newDimensions
                })
            }
        }

    },
    getToolsStyle: function() {
        const {
            width,
            height,
            left,
            top
        } = this.state

        const { root = {} } = this.props
        const rootRefElements = root.refElements
        const editor = rootRefElements['editor']
        const editorcontainer = (editor) ? editor.refElements['container'] : null

        if (editorcontainer) {
            return {
                width: (width / editorcontainer.offsetWidth) * 100 + '%',
                height: (height / editorcontainer.offsetHeight) * 100 + '%',
                left: (left / editorcontainer.offsetWidth) * 100 + '%',
                top: (top / editorcontainer.offsetHeight) * 100 + '%'
            }
        } else {
            return {
                width: width + 'px',
                height: height + 'px',
                left: left + 'px',
                top: top + 'px'
            }
        }

    },
    getDimensions: function(p = {}) {

        const { initial } = p
        const isEditable = this.isEditable({ initial })

        if (isEditable) {

            const { style, root = {}, getContainer } = this.props
            const container = getContainer()
            const rootRefElements = root.refElements
            const editor = rootRefElements['editor']
            const editorcontainer = (editor) ? editor.refElements['container'] : null

            if (container && editorcontainer) {

                const rect = container.getBoundingClientRect()
                const editorrect = editorcontainer.getBoundingClientRect()

                const width = rect.width
                const height = rect.height
                const left = rect.left - editorrect.left
                const top = rect.top - editorrect.top

                return {
                    width: width,
                    height: height,
                    left: left,
                    top: top
                }

            }
        }

        return {
            width: 0,
            height: 0,
            left: 0,
            top: 0
        }

    },
    /*Refs*/
    setRef: function(a, e, n) {
        if (e) {
            if (n) {
                this[n][a] = e
            } else {
                this.refElements[a] = e
            }
        }
    },
    addListeners: function() {
        this.removeListeners()

        const resize = this.refElements['resize']
        const position = this.refElements['position']

        if (resize) resize.addEventListener('mousedown', this.touchStart, false)
        if (position) position.addEventListener('mousedown', this.touchStart, false)

        window.removeEventListener('mouseup', this.touchEnd)
        window.addEventListener('mouseup', this.touchEnd, false)
    },
    removeListeners: function() {

        const resize = this.refElements['resize']
        const position = this.refElements['position']

        if (resize) resize.removeEventListener('mousedown', this.touchStart)
        if (position) position.removeEventListener('mousedown', this.touchStart)

        window.removeEventListener('mouseup', this.touchEnd)
        window.removeEventListener('mousemove', this.touchMove)
    },
    touchStart: function(e) {
        const target = e.target
        if (target == this.refElements['resize']) {
            this.currentEventType = 'resize'
        } else if (target == this.refElements['position']) {
            this.currentEventType = 'position'
        }
        if (this.currentEventType) {
            const { state, getContainer } = this.props
            const container = getContainer()
            const rect = container.getBoundingClientRect()
            this.mousePosition = {
                startx: e.pageX,
                starty: e.pageY,
                startpositionx: state.positionx,
                startpositiony: state.positiony,
                startwidth: state.width,
                startheight: state.height,
                startscalex: rect.width / state.width,
                startscaley: rect.height / state.height
            }
            window.removeEventListener('mousemove', this.touchMove)
            window.addEventListener('mousemove', this.touchMove, false)
        }
        e.preventDefault()
    },
    touchMove: function(e) {
        let changex = 0
        let changey = 0

        const { setPositionsWithoutState, setDimensionsWithoutState } = this.props

        if (this.currentEventType) {
            changex = e.pageX - this.mousePosition.startx
            changey = e.pageY - this.mousePosition.starty
        }
        if (this.currentEventType == 'resize') {
            setDimensionsWithoutState({
                dimensions: {
                    width: this.mousePosition.startwidth + (changex / this.mousePosition.startscalex),
                    height: this.mousePosition.startheight + (changey / this.mousePosition.startscaley)
                }
            })
            this.setDimensions()
        } else if (this.currentEventType == 'position') {
            setPositionsWithoutState({
                positions: {
                    x: this.mousePosition.startpositionx + (changex / this.mousePosition.startscalex),
                    y: this.mousePosition.startpositiony + (changey / this.mousePosition.startscaley)
                }
            })
            this.setDimensions()
        }


    },
    touchEnd: function(e) {
        window.removeEventListener('mousemove', this.touchMove, false)

        let changex = 0
        let changey = 0

        const { setPositions, setDimensions } = this.props

        if (this.currentEventType) {
            changex = e.pageX - this.mousePosition.startx
            changey = e.pageY - this.mousePosition.starty
        }
        if (this.currentEventType == 'resize') {
            setDimensions({
                dimensions: {
                    width: this.mousePosition.startwidth + (changex / this.mousePosition.startscalex),
                    height: this.mousePosition.startheight + (changey / this.mousePosition.startscaley)
                },
                save: true,
                resizeAll: true
            })
            this.setDimensions()
        } else if (this.currentEventType == 'position') {
            setPositions({
                positions: {
                    x: this.mousePosition.startpositionx + (changex / this.mousePosition.startscalex),
                    y: this.mousePosition.startpositiony + (changey / this.mousePosition.startscaley)
                },
                save: true,
                resizeAll: true
            })
            this.setDimensions()
        }


        this.currentEventType = null
        e.preventDefault()
    },
    render: function() {

        const isEditable = this.isEditable()

        if (isEditable) {

            const { style, root = {}, getContainer, deep, state } = this.props
            const container = getContainer()
            const rootRefElements = root.refElements
            const editor = rootRefElements['editor']
            const editorcontainer = (editor) ? editor.refElements['container'] : null

            if (container && editorcontainer) {

                const toolsStyle = this.getToolsStyle()
                const setRef = this.setRef
                const zIndex = (deep) ? (deep[0] * 100) + deep[1] + 1 : 1

                return (
                    <Portal isOpened={true} renderTo={editorcontainer} className={style.editortool}
                            style={{ ...toolsStyle, zIndex: zIndex }}>
                        <div ref={function(e) {
                            setRef('container', e)
                        }} className={style.editortoolinner}>
                            {(isEditable.position && !state.autopositionx || isEditable.position && !state.autopositionx) ?
                                <div className={style.positiontool}>
                                    <SvgIcon viewBox={'-50 -50 510.988 510.988'}>
                                        <g xmlns='http://www.w3.org/2000/svg'>
                                            {(!state.autopositionx) ? <path
                                                d='M149.654,195.495c0-4.142-3.357-7.5-7.5-7.5l-78.262-0.001l12.527-12.528   c1.406-1.406,2.197-3.314,2.197-5.303c0-1.989-0.791-3.897-2.197-5.303l-14.143-14.143c-2.93-2.929-7.678-2.929-10.607,0   L2.197,200.191c-2.93,2.929-2.93,7.678,0,10.606l49.473,49.474c1.465,1.464,3.385,2.197,5.305,2.197   c1.918,0,3.838-0.732,5.303-2.197l14.143-14.143c1.406-1.406,2.197-3.314,2.197-5.303c0-1.989-0.791-3.897-2.197-5.303   l-12.527-12.527l78.262,0.001c1.99,0,3.896-0.79,5.305-2.197c1.406-1.406,2.195-3.314,2.195-5.303V195.495z' /> : null}
                                            {(!state.autopositionx) ? <path
                                                d='M408.791,200.191l-49.473-49.474c-2.93-2.929-7.678-2.929-10.607,0l-14.143,14.143   c-1.406,1.406-2.197,3.314-2.197,5.303c0,1.989,0.791,3.897,2.197,5.303l12.527,12.528l-78.262,0.001c-4.143,0-7.5,3.358-7.5,7.5   v20c0,1.989,0.789,3.897,2.195,5.303c1.408,1.407,3.314,2.197,5.305,2.197l78.262-0.001l-12.527,12.527   c-1.406,1.406-2.197,3.314-2.197,5.303c0,1.989,0.791,3.897,2.197,5.303l14.143,14.143c1.465,1.464,3.385,2.197,5.303,2.197   c1.92,0,3.84-0.732,5.305-2.197l49.473-49.474C411.721,207.869,411.721,203.12,408.791,200.191z' /> : null}
                                            {(!state.autopositiony) ? <path
                                                d='M164.859,76.42c1.406,1.406,3.314,2.197,5.305,2.197c1.988,0,3.896-0.791,5.303-2.197l12.527-12.527   v78.262c0,1.99,0.789,3.896,2.195,5.305c1.406,1.406,3.315,2.195,5.305,2.195h20c4.141,0,7.5-3.357,7.5-7.5V63.893l12.527,12.527   c1.406,1.406,3.314,2.197,5.305,2.197c1.988,0,3.896-0.791,5.303-2.197l14.143-14.143c2.928-2.93,2.928-7.678,0-10.607   L210.797,2.197c-2.928-2.93-7.678-2.93-10.605,0L150.717,51.67c-1.463,1.465-2.195,3.385-2.195,5.305   c0,1.918,0.733,3.838,2.195,5.303L164.859,76.42z' /> : null}
                                            {(!state.autopositiony) ? <path
                                                d='M246.129,334.568c-1.406-1.406-3.315-2.197-5.303-2.197c-1.99,0-3.898,0.791-5.305,2.197   l-12.527,12.527v-78.262c0-4.143-3.359-7.5-7.5-7.5h-20c-1.99,0-3.898,0.789-5.305,2.195c-1.406,1.408-2.195,3.314-2.195,5.305   v78.262l-12.527-12.527c-1.406-1.406-3.314-2.197-5.303-2.197c-1.99,0-3.898,0.791-5.305,2.197l-14.143,14.143   c-1.463,1.465-2.195,3.385-2.195,5.303c0,1.92,0.733,3.84,2.195,5.305l49.475,49.473c2.928,2.93,7.678,2.93,10.605,0l49.475-49.473   c2.928-2.93,2.928-7.678,0-10.607L246.129,334.568z' /> : null}
                                        </g>
                                    </SvgIcon>
                                    <div className={style.toolinnerbutton} ref={function(e) {
                                        setRef('position', e)
                                    }}></div>
                                </div>
                                : null
                            }
                            {(isEditable.resize) ?
                                <div className={style.resizetool}>
                                    <SvgIcon viewBox={'-50 -50 276.496 276.496'}>
                                        <path
                                            d='M173.93,92.798c-4.971,0-9,4.029-9,9v50.404L30.728,18h50.404c4.971,0,9-4.029,9-9s-4.029-9-9-9H9C4.03,0,0,4.029,0,9  v72.132c0,4.971,4.029,9,9,9s9-4.029,9-9V30.729l134.202,134.202h-50.404c-4.971,0-9,4.029-9,9s4.029,9,9,9h72.132  c4.971,0,9-4.029,9-9v-72.132C182.93,96.828,178.901,92.798,173.93,92.798z'></path>
                                    </SvgIcon>
                                    <div className={style.toolinnerbutton} ref={function(e) {
                                        setRef('resize', e)
                                    }}></div>
                                </div>
                                : null
                            }
                        </div>
                    </Portal>
                )

            }
        }

        return null
    }
})

export const Container = createReactClass({
    getInitialState: function() {

        this.refElements = {}
        const { data } = this.props
        const stateData = this.getStateData(data)

        return {
            isMounted: true,
            newChildrenData: {},
            ...stateData
        }
    },
    componentWillReceiveProps: function(nextProps) {
        const { data } = nextProps
        const stateData = this.getStateData(data)
        this.setState({
            ...stateData
        })
    },
    shouldComponentUpdate: function(nextProps, nextState) {
        const { customStyle = {} } = this.props
        const nextCustomStyle = nextProps.customStyle || {}
        const changedCustomStyle1 = this.isChanged(customStyle, nextCustomStyle)
        const changedCustomStyle2 = (changedCustomStyle1) ? changedCustomStyle1 : this.isChanged(nextCustomStyle, customStyle)
        if (changedCustomStyle1 || changedCustomStyle2) {
            return true
        }

        const state = this.state
        const changed1 = this.isChanged(state, nextState)
        const changed2 = (changed1) ? changed1 : this.isChanged(nextState, state)

        if (changed1 || changed2) {
            return true
        }
        return false
    },
    updateContainer: function(data, key) {
        const { parent, root } = this.props
        if (data) {

            if (key == 'children') {

                root.updateContainer()

            } else {
                if (parent) {
                    parent.setChildProps({
                        ...data
                    })
                }
                const stateData = this.getStateData(data)
                const changed = this.isChanged(stateData, this.state)
                if (changed) {
                    this.setState({
                        ...stateData
                    })
                    if (root) {
                        setTimeout(function() {
                            root.resizeAll()
                        })
                    }
                }
            }
        }
    },
    recCopyEditorId: function(o, c) {
        const recCopyEditorId = this.recCopyEditorId
        if (typeof o == 'object' && typeof c == 'object' && o && c) {
            if (o.__jsoneditorId) {
                Object.defineProperty(c, '__jsoneditorId', {
                    value: o.__jsoneditorId,
                    enumerable: false,
                    configurable: true
                })
            }
            if (o.isArray && o.isArray() && c.isArray && c.isArray()) {
                o.map(function(e, i) {
                    recCopyEditorId(e, c[i])
                })
            }
            if (!o.isArray && !c.isArray) {
                Object.keys(o).map(function(key) {
                    recCopyEditorId(o[key], c[key])
                })
            }
        }
    },
    setChildProps: function({ data, child }) {
        const { newChildrenProps = {} } = this.state
        const childProps = newChildrenProps[child] || {}

        const changed1 = this.isChanged(data, childProps)
        const changed2 = this.isChanged(childProps, data)

        if (changed1 || changed2) {
            const newData = JSON.parse(JSON.stringify(data))
            this.recCopyEditorId(data, newData)

            this.setState({
                newChildrenProps: { ...newChildrenProps, [child]: newData },
                time: new Date().getTime()
            })
        }
    },
    componentDidUpdate: function() {
        this.updateRoot()
    },
    updateRoot: function() {
        const { containerId, root } = this.props
        if (root && containerId) root.refElements[containerId] = this
    },
    getStateData: function(data) {
        const p = (data) ? data : this.props

        const {
            autosize = 'normal',
            scale = false,
            width = 100,
            height = 100,
            relativewidth = false,
            relativeheight = false,
            autopositionx = false,
            autopositiony = false,
            boundingx = 0,
            boundingy = 0,
            positionx = 0,
            positiony = 0
        } = p

        return {
            autosize: autosize,
            scale: scale,
            width: width,
            height: height,
            relativewidth: relativewidth,
            relativeheight: relativeheight,
            autopositionx: autopositionx,
            autopositiony: autopositiony,
            boundingx: boundingx,
            boundingy: boundingy,
            positionx: positionx,
            positiony: positiony
        }

    },
    isChanged: function(a, b, deep = 0, maxdeep) {
        let changed = false
        if (maxdeep && maxdeep > deep || !maxdeep) {
            const d = deep + 1
            const isChanged = this.isChanged
            if (a && b && typeof a == 'object' && typeof b == 'object') {
                const keys = (a.length == undefined) ? Object.keys(a) : [...a.keys()]
                keys.map(function(key) {
                    if (key !== 'children' &&
                        !changed) {
                        if (typeof a[key] !== typeof b[key]) changed = true
                        if (a[key] && typeof a[key] == 'object') {
                            changed = isChanged(a[key], b[key], d, maxdeep)
                        } else {
                            if (a[key] !== b[key]) changed = true
                        }
                    }
                })
            } else {
                if (typeof a !== typeof b) changed = true
                if (a !== b) changed = true
            }
        }
        return changed
    },
    componentDidMount: function() {
        this.updateRoot()
        this.addResizeListener()
    },
    componentWillUnmount: function() {
        this.setState({
            isMounted: false
        })
        this.removeResizeListener()
    },

    getParent: function() {
        const { parentElement } = this.props
        if (parentElement) return parentElement
        const d = this.refElements['container']
        if (d && d.parentElement) return d.parentElement
    },

    /*Refs*/
    setRef: function(a, e, n) {
        if (e) {
            if (n) {
                this[n][a] = e
            } else {
                this.refElements[a] = e
            }
        }
    },

    /*Resize*/
    getRelativeDimensions: function() {

        const { parent = {} } = this.props
        const parentGetRelativeDimensions = parent.getRelativeDimensions
        const parentDimensions = (parentGetRelativeDimensions) ? parentGetRelativeDimensions() : {}

        const {
            width: width,
            height: height,
            relativewidth,
            relativeheight
        } = this.state

        let w = width
        if (relativewidth && parentDimensions && parentDimensions.width) {
            if (relativewidth.match && relativewidth.match('%') && relativewidth.slice(-1) == '%' && !isNaN(Number(relativewidth.slice(0, -1)))) {
                w = Number(relativewidth.slice(0, -1)) * (parentDimensions.width / 100)
            } else if (!isNaN(Number(relativewidth))) {
                w = parentDimensions.width + Number(relativewidth)
            }
        }

        let h = height
        if (relativeheight && parentDimensions && parentDimensions.height) {
            if (relativeheight.match && relativeheight.match('%') && relativeheight.slice(-1) == '%' && !isNaN(Number(relativeheight.slice(0, -1)))) {
                h = Number(relativeheight.slice(0, -1)) * (parentDimensions.height / 100)
            } else if (!isNaN(Number(relativeheight))) {
                h = parentDimensions.height + Number(relativeheight)
            }
        }

        return { width: w, height: h }

    },
    getContainerStyle: function() {
        const { customStyle = {} } = this.props
        const { sizeStyle } = this.getDimensions()
        const { positionStyle } = this.getPositions()
        const { boundingStyle } = this.getBoundingPoints()
        return { ...sizeStyle, ...positionStyle, ...boundingStyle, ...customStyle }
    },
    getBoundingPoints: function() {

        const { boundingx = 0, boundingy = 0 } = this.state

        let rx = 0
        let ry = 0
        let translatex = 0
        let translatey = 0

        if (boundingx == 'center') translatex = -50
        if (boundingx == 'right') translatex = -100
        if (boundingy == 'center') translatey = -50
        if (boundingy == 'right') translatey = -100

        const d = this.refElements['container']

        if (d) {
            if (boundingx == 'center') rx = d.offsetWidth / 2
            if (boundingx == 'right') rx = d.offsetWidth
            if (boundingy == 'center') rx = d.offsetHeight / 2
            if (boundingy == 'right') rx = d.offsetheight
        }

        const boundingStyle = {
            'transform': 'translate(' + translatex + '%, ' + translatey + '%)',
            'WebkitTransform': 'translate(' + translatex + '%, ' + translatey + '%)',
            'MozTransform': 'translate(' + translatex + '%, ' + translatey + '%)',
            'msTransform': 'translate(' + translatex + '%, ' + translatey + '%)',
            'OTransform': 'translate(' + translatex + '%, ' + translatey + '%)'
        }

        return { boundingx: rx, boundingy: ry, boundingStyle: boundingStyle }
    },
    getPositions: function() {

        const { autopositionx, autopositiony, positionx, positiony } = this.state

        const style = {
            position: 'absolute'
        }

        if (autopositionx == 'center') {
            style.left = '50%'
        } else if (autopositionx == 'right') {
            style.left = '100%'
        } else {
            style.left = positionx + 'px'
        }

        if (autopositiony == 'center') {
            style.top = '50%'
        } else if (autopositiony == 'bottom') {
            style.top = '100%'
        } else {
            style.top = positiony + 'px'
        }

        return { positionStyle: style }

    },
    setPositions: function(props = {}) {

        const { positions, resizeAll, save } = props
        const { store, root } = this.props

        if (positions) {
            const {
                autopositionx,
                autopositiony,
                boundingx,
                boundingy,
                positionx,
                positiony
            } = this.state

            const newState = {}
            let changed = false

            if (positions.x !== positionx && !autopositionx) {
                newState.positionx = positions.x
                changed = true
            }

            if (positions.y !== positiony && !autopositiony) {
                newState.positiony = positions.y
                changed = true
            }


            if (changed) {
                this.setState({
                    ...newState
                })

                if (save) {
                    store.props.positionx = newState.positionx
                    store.props.positiony = newState.positiony
                }

                if (resizeAll && root.resizeAll) root.resizeAll()
            }
        }
    },
    setPositionsWithoutState: function({ positions }) {
        if (positions) {
            const {
                autopositionx,
                autopositiony,
                boundingx,
                boundingy
            } = this.state

            const d = this.refElements['container']
            const positionx = d.offsetLeft
            const positiony = d.offsetTop

            const newState = {}
            let changed = false

            if (positions.x !== positionx && !autopositionx) {
                newState.left = positions.x
                changed = true
            }

            if (positions.y !== positiony && !autopositiony) {
                newState.top = positions.y
                changed = true
            }


            if (changed) {
                Object.keys(newState).map(function(key) {
                    d.style[key] = newState[key] + 'px'
                })
            }
        }
    },
    getDimensions: function() {

        const state = this.state || {}
        const t = this
        let rwidth = 0
        let rheight = 0

        const parent = this.getParent()
        const { autosize } = state
        const { width, height } = this.getRelativeDimensions()

        const d = this.refElements['container']

        const parentRect = (parent) ? { width: parent.offsetWidth, height: parent.offsetHeight } : {}
        let w = parentRect.width || 0
        let h = parentRect.height || 0

        if (autosize == 'normal') {

            rwidth = width
            rheight = height

        } else if (autosize == 'parent') {

            rwidth = 0
            rheight = 0

        } else if (autosize == 'contain') {

            if (h || w) {
                const p = w / h
                const r = width / height

                if (p < r) {
                    rwidth = w
                    rheight = w / r
                } else {
                    rwidth = h * r
                    rheight = h
                }

            }

        } else if (autosize == 'cover') {

            if (h || w) {
                const p = w / h
                const r = width / height

                if (p > r) {
                    rwidth = w
                    rheight = w / r
                } else {
                    rwidth = h * r
                    rheight = h
                }

            }

        } else if (autosize == 'autoheight') {

            const r = width / height
            rwidth = w
            rheight = w / r

        }

        const sizeStyle = {}

        if (rwidth && rheight) {
            sizeStyle.width = rwidth + 'px'
            sizeStyle.height = rheight + 'px'
        } else {
            sizeStyle.width = '100%'
            sizeStyle.height = '100%'
        }

        return { width: rwidth, height: rheight, sizeStyle: sizeStyle }

    },
    setDimensions: async function(props = {}) {

        const { dimensions = {}, keepwidth, resizeAll, save } = props
        const { width, height } = this.state

        const { parent = {}, enableSetDimensions = [], root, store } = this.props
        const rootRefElements = (root) ? root.refElements : {}

        let newwidth = dimensions.width
        let newheight = dimensions.height

        if (keepwidth) {
            const r = newheight / newwidth
            newwidth = width
            newheight = width * r
        }

        if (newwidth && newheight) {
            if (newwidth !== width || newheight !== height) {

                this.removeResizeListener()

                if (save) {
                    store.props.width = newwidth
                    store.props.height = newheight
                    store.relativewidth = false
                    store.relativeheight = false
                }

                await this.setState({
                    width: newwidth,
                    height: newheight,
                    relativewidth: false,
                    relativeheight: false
                })

                this.updateContainer(this.state)

                if (enableSetDimensions && enableSetDimensions.length && rootRefElements) {
                    const elements = { ...rootRefElements, root: root, parent: parent }
                    await Promise.all(enableSetDimensions.map(async function(defaultprops = {}) {
                        const { id } = defaultprops
                        if (elements[id] && elements[id].setDimensions) {
                            await elements[id].setDimensions({
                                dimensions: {
                                    width: newwidth,
                                    height: newheight
                                },
                                ...defaultprops,
                                save: save
                            })
                        }
                    }))
                }

                if (resizeAll && root.resizeAll) root.resizeAll()

                this.addResizeListener()
            }
        }
    },
    setDimensionsWithoutState: function({ dimensions = {} }) {

        const d = this.refElements['container']
        const width = d.offsetWidth
        const height = d.offsetHeight

        let newwidth = dimensions.width
        let newheight = dimensions.height

        if (newwidth && newheight) {
            if (newwidth !== width || newheight !== height) {
                d.style.width = newwidth + 'px'
                d.style.height = newheight + 'px'
            }
        }
    },
    setScale: function() {

        const { scale } = this.state
        const { width, height } = this.getRelativeDimensions()

        let scalex = 1
        let scaley = 1

        if (scale) {

            const scaleMultiplier = 1
            const d = this.refElements['container']
            if (d && d.offsetWidth && width) scalex = d.offsetWidth / width * scaleMultiplier
            if (d && d.offsetHeight && height) scaley = d.offsetHeight / height * scaleMultiplier

            if (d.children[0]) {
                d.children[0].style.transform = 'scale(' + scalex + ', ' + scaley + ')'
                d.children[0].style.webkitTransform = 'scale(' + scalex + ', ' + scaley + ')'
                d.children[0].style.WebkitTransform = 'scale(' + scalex + ', ' + scaley + ')'
                d.children[0].style.MozTransform = 'scale(' + scalex + ', ' + scaley + ')'
                d.children[0].style.msTransform = 'scale(' + scalex + ', ' + scaley + ')'
                d.children[0].style.OTransform = 'scale(' + scalex + ', ' + scaley + ')'
            }

        }

    },
    resize: function(e) {

        const d = this.refElements['container']
        const style = this.getContainerStyle()

        Object.keys(style).map(function(key) {
            d.style[key] = style[key]
        })

        this.setScale()
        const tools = this.refElements['tools']
        if (tools) {
            setTimeout(function() {
                tools.setDimensions()
            })
        }

    },
    addResizeListener: function() {
        const { root = {}, disableSignResizeListeners } = this.props
        const { addResizeListener } = root
        const t = this
        const resize = this.resize
        if (addResizeListener && !disableSignResizeListeners) {
            const { resizelisteners, id } = addResizeListener(resize)
            t.listenerID = id
        }
    },
    removeResizeListener: function() {
        const { root = {} } = this.props
        const { removeResizeListener } = root
        if (removeResizeListener) {
            const id = this.listenerID || 0
            const resizelisteners = removeResizeListener(this.resize, this.listenerID)
        }
    },
    getChildren: function() {

        const t = this
        const { enableComponentsAddProps, children } = t.props
        const { newChildrenProps = {} } = this.state

        return React.Children.map(children, function(child, i) {

            const childProps = {}
            const newChildProps = (newChildrenProps[i]) ? newChildrenProps[i] : {}

            if (child.type.displayName && child.type.displayName == 'Container' ||
                child.type.displayName && enableComponentsAddProps && enableComponentsAddProps.indexOf(child.type.displayName) !== -1) {
                childProps.parent = {
                    setDimensions: t.setDimensions,
                    setScale: t.setScale,
                    resize: t.resize,
                    getRelativeDimensions: t.getRelativeDimensions,
                    setChildProps: function(data) {
                        t.setChildProps({ data, child: i })
                    },
                    state: t.state,
                    props: t.props
                }
            }


            return React.cloneElement(child, { ...childProps, ...newChildProps, key: i })

        })
    },
    getClassName: function() {
        const { style, className } = this.props
        const cn = (className) ? (style[className]) ? style[className] : className : null
        return (cn) ? style.container + ' ' + cn : style.container
    },
    getScaleClassName: function() {
        const { style, innerClassName = '' } = this.props
        const cn = (innerClassName) ? (style[innerClassName]) ? style[innerClassName] : innerClassName : null
        return (cn) ? style.scale + ' ' + cn : style.scale
    },

    render: function() {

        const { style } = this.props
        const containerStyle = this.getContainerStyle() || {}

        const setRef = this.setRef
        const children = this.getChildren()
        const className = this.getClassName()
        const scaleClassName = this.getScaleClassName()
        const refElements = this.refElements

        return (
            <div ref={function(e) {
                setRef('container', e)
            }} className={className} style={containerStyle}>
                <div className={scaleClassName}>
                    {children}
                </div>
                <Tools
                    {...this.props}
                    state={this.state}
                    setDimensions={this.setDimensions}
                    setDimensionsWithoutState={this.setDimensionsWithoutState}
                    setPositions={this.setPositions}
                    setPositionsWithoutState={this.setPositionsWithoutState}
                    getContainer={function() {
                        return refElements['container']
                    }}
                    ref={function(e) {
                        setRef('tools', e)
                    }}
                />
            </div>
        )
    }
})

const Middle = createReactClass({
    setRef: function(a, e) {
        const { setRef } = this.props
        if (setRef) setRef(a, e)
    },
    render: function() {
        const setRef = this.setRef
        return (
            <Root {...this.props} ref={(setRef) ? function(e) {
                setRef('root', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})
