import React from 'react'
import createReactClass from 'create-react-class'
import formsyWrapper from '../Formsy/wrapper'

import ColorPickerComponent from './index.js'

const ColorPicker = createReactClass({
    handleUpdate: function(e, nextValue = '') {
        const { onChange, formsyWrapper = {} } = this.props
        formsyWrapper.handleUpdate({ e, nextValue, onChange })
    },
    onBlur: async function(e, nextValue = '') {
        const { onBlur, formsyWrapper = {} } = this.props
        formsyWrapper.handleUpdate({ e, nextValue, onChange: onBlur })
    },
    render() {

        const {
            formsyWrapper = {},
            ...rest
        } = this.props

        const required = formsyWrapper.required

        const value = formsyWrapper.getValue()
        const v = (value) ? value : ''

        return (
            <ColorPickerComponent
                {...rest}
                onChange={this.handleUpdate}
                onBlur={this.onBlur}
                errorText={(!v && required) ? null : formsyWrapper.getErrorMessage()}
                value={v}
            />
        )
    }

})

export default formsyWrapper(ColorPicker)
