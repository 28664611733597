import React from 'react'
import createReactClass from 'create-react-class'

import wapplrcomponents from '../../../../components'

import TemplateDefault from '../../../templates/template_default'
import avs from '../../../../themes/components/Wapplravatar.css'
import ps from '../../../../themes/components/Wapplrpost.css'

import IconButton from 'material-ui/IconButton'
import ShareIcon from 'material-ui/svg-icons/social/share'
import BackIcon from 'material-ui/svg-icons/navigation/arrow-back'
import PublicIcon from 'material-ui/svg-icons/social/public'
import PrivateIcon from 'material-ui/svg-icons/action/lock-outline'
import { getPostStatusData } from '../../../server/poststatus/statuses'
import CharliesPlayer from '../../../components/CharliesPlayer'
import chs from '../../../components/CharliesPlayer/CharliesPlayer.css'

import NotFound from '../../../components/NotFound'
import nfs from '../../../components/NotFound/default.css'

import config from '../config'

const Wapplrpost = wapplrcomponents.post

const lowername = config.name.toLowerCase()

export default createReactClass({
    getInitialState: function() {
        this.refElements = {}

        const { response } = this.props
        const post = this.getPost(response)

        return {
            isMounted: true,
            post: post
        }
    },
    isChanged: function(a, b) {
        let changed = false
        const isChanged = this.isChanged
        if (a && b && typeof a == 'object' && typeof b == 'object') {
            const keys = (a.length === undefined) ? Object.keys(a) : [...a.keys()]
            keys.map(function(key) {
                if (typeof a[key] !== typeof b[key]) changed = true
                if (!changed) {
                    if (a[key] && typeof a[key] == 'object') {
                        changed = isChanged(a[key], b[key])
                    } else {
                        if (a[key] !== b[key]) changed = true
                    }
                }
            })
        } else {
            if (typeof a !== typeof b) changed = true
            if (a !== b) changed = true
        }
        return changed
    },
    shouldComponentUpdate: function(nextProps) {
        const { formdata = [] } = this.props
        const nextFormdata = nextProps.formdata || []

        const changed1 = this.isChanged(formdata, nextFormdata)
        const changed2 = (changed1) ? changed1 : this.isChanged(nextFormdata, formdata)
        return changed1 || changed2;

    },
    componentDidMount: function() {
        const { response } = this.props
        const post = this.getPost(response)
        this.setState({
            post: post
        })
    },


    getPost: function(response) {
        const post = (response && response['current' + lowername] && response['current' + lowername][0]) ? response['current' + lowername][0] : null
        return post
    },
    getPostEditLink: function() {
        const post = this.state.post
        if (post && post.id) {
            return '/' + lowername + '/edit/' + post.id
        }
        return ''
    },
    getPostShareLink: function() {
        const post = this.state.post
        if (post && post.id) {
            return '/' + lowername + '/' + post.id + '/share'
        }
        return ''
    },
    getPostLink: function() {
        const post = this.state.post
        if (post && post.id) {
            return '/' + lowername + '/' + post.id
        }
        return ''
    },
    getHelpers: function() {
        const { helpers } = this.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },
    getGlobalState: function() {
        const { state } = this.getHelpers()
        return state
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    getPostStatus: function() {
        const { curlang } = this.props
        const post = this.state.post
        return getPostStatusData({ post, curlang })
    },

    //Publicicon
    publicicon: function({ post }) {

        const share = post && post.share

        this.getMe()


        if (share === 'public' || !share) {
            return <PublicIcon />
        }
        if (share === 'private') {
            return <PrivateIcon />
        }
        return null
    },

    setRef: function(a, e) {
        const { setRef } = this.props
        this.refElements[a] = e
        if (setRef) setRef(a, e)
    },

    touchEnd: function(e, fn, pt, d) {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { type } = d
        const historyPush = this.historyPush
        if (type === 'share') {
            const postsharelink = this.getPostShareLink()
            historyPush(postsharelink)
        }
        if (type === 'back') {
            const postlink = this.getPostLink()
            historyPush(postlink)
        }
        e.preventDefault()
    },
    historyPush: function(o) {
        const { history } = this.props
        if (o && history && history.push) {
            history.push(o)
        } else {
            if (o) window.location.href = (o.search) ? o.pathname + '?' + o.search : o.pathname
        }
    },
    getShareButton: function() {
        const { flatchooserapp } = this.props
        const touchEnd = this.touchEnd

        const back = !!(flatchooserapp && flatchooserapp.startroute && flatchooserapp.startroute === '/share')

        return (
            <div style={{ display: 'table-cell' }}>
                <IconButton
                    onTouchTap={function(e) {
                        touchEnd(e, 'click', 'click', { type: (back) ? 'back' : 'share' })
                    }}
                    onTouchStart={function() {

                    }}
                >
                    {(back) ? <BackIcon /> : <ShareIcon />}
                </IconButton>
            </div>
        )
    },
    getTokenLinks: function() {
        const { siteurl = '' } = this.props
        const post = this.state.post
        if (post && post.tokens) {
            const tokens = (typeof post.tokens == 'string') ? JSON.parse(post.tokens) : post.tokens
            if (tokens.length) {
                const r = {}
                tokens.map(function(token) {
                    r[token.token] = {
                        link: siteurl + '/' + lowername + '/json/' + post.id + '?token=' + token.token,
                        token: token.token,
                        data: token.data
                    }
                })
                return r
            }
        }
        return null
    },
    render: function() {

        const {
            history, /*getthumb, searchData,*/
            style,
            Parent = TemplateDefault,
            curlang,
            routes = {},
            flatchooserapp,
            siteurl = '',
            staticscript = '/index.js',
            staticserver = ''
        } = this.props
        const post = this.state.post
        const author = (post && post.author) ? post.author : null
        const me = this.getMe()
        const canEdit = (author && author.id && me && me.id === author.id || me && me.isAdmin || me && me.isEditor || post && post.coproducersAccess)
        const edit = canEdit ? this.getPostEditLink() : null
        const { statusname, deletebutton } = this.getPostStatus()
        const publiciconcontent = this.publicicon({ post })

        const globalstate = this.getGlobalState()
        const state = {
            ...globalstate,
            response: (globalstate.response) ? {
                ...globalstate.response,
                currentpost: globalstate.response.currentplayer
            } : {}
        }

        const postlink = this.getPostLink()
        const buttons = this.getShareButton()

        const protocol = 'https:'
        const staticscripturl = (staticserver && staticscript) ? protocol + '//' + staticserver + staticscript : null

        const tokens = this.getTokenLinks()

        return (
            <Parent {...this.props} className={(post && post.id && post.id !== '0') ? style.postcard : null}>
                {(post && post.id && post.id !== '0') ?
                    <div>
                        <CharliesPlayer
                            style={chs}
                            app={flatchooserapp}
                            state={state}
                            data={post && post.appjson}
                            parentroute={postlink}
                            curlang={curlang}
                            history={history}
                            disablehistory={false}

                            customprops={{
                                id: post.id,
                                logohref: '/',
                                posturl: siteurl + postlink,
                                staticscripturl: staticscripturl,
                                postmedia: siteurl + post.cover,
                                enableshareobjectwithhash: true,
                                tokens: tokens,

                                disabledescription: true,
                                disableshare: true,
                                autoheight: true,

                                playerrootclassname: style.playerroot,
                                disablefullscreen: !!(post && post.appver === '1.0' || post && !post.appver)
                            }}
                        />
                        <Wapplrpost
                            className={'cardtype'}
                            history={history}
                            style={ps}
                            post={this.state.post}
                            showauthor={true}
                            avatarstyle={avs}
                            edit={edit}
                            buttons={buttons}
                            statusname={(!deletebutton) ? statusname : null}
                            publiciconcontent={publiciconcontent}
                        />
                    </div>
                    :
                    <NotFound
                        style={nfs}
                        history={history}
                        notFoundText={curlang.notfoundtext}
                        notFoundDesc={curlang.notfounddesc}
                        loggedOutDesc={(me && me.id) ? null : curlang.loggedoutdesc}

                        backLabel={curlang.gotobacktoprev}
                        homeLabel={curlang.gotohomepage}
                        loginLabel={(me && me.id) ? null : curlang.gotologin}
                        signupLabel={(me && me.id) ? null : curlang.gotosignup}
                        profileLabel={(me && me.id) ? curlang.gotoprofile : null}

                        homeHref={'/'}
                        loginHref={(me && me.id) ? null : routes.menuloginslug}
                        signupHref={(me && me.id) ? null : routes.menuloginslug + routes.wapplrloginappsignupslug}
                        profileHref={(me && me.id) ? routes.menuuserslug + '/' + me.id : null}

                    />
                }
            </Parent>
        )
    }
})


