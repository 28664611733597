import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

import PlayerComponent from '../Player'
import pls from '../Player/Player.css'

import FloatingPalette from '../FloatingPalette'
import fps from '../FloatingPalette/FloatingPalette.css'

import Formsy from '../Formsy'
import fos from '../Formsy/Formsy.css'

const Editor = createReactClass({
    getInitialState() {
        this.refElements = {}
        return {
            isMounted: true,
            stepIndex: 0
        }
    },
    componentDidMount: function() {
        const { hooks } = this.props
        const save = this.save
        hooks.getData = function(p = {}) {
            const { callback } = p
            return save({ callback })
        }
        this.addResizeListener()
        const resize = this.resize
        setTimeout(function() {
            resize()
        })
    },
    componentWillUnmount: function() {
        this.removeResizeListener()
    },

    getStore: function() {
        const { construct } = this.props
        return (construct.props && construct.props.store) ? construct.props.store : null
    },
    getGlobalState: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct.props.state : teststate
    },
    getPost: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.currentpost && state.response.currentpost[0]) ? state.response.currentpost[0] : null
    },
    getData: function() {
        const { construct } = this.props
        return (construct.props && construct.props.data) ? construct.props.data : null
    },
    getSchema: function() {
        const { construct } = this.props
        return (construct.props && construct.props.schema) ? construct.props.schema : null
    },
    getAppver: function() {
        const post = this.getPost()
        return (post && post.appver && post.appver == '1.0' || post && post.appver && post.appver == '2.0') ? post.appver : '1.0'
    },
    getParentRoute: function() {
        const { construct, parentroute } = this.props
        return (construct.props && construct.props.parentroute) ? construct.props.parentroute : parentroute
    },

    setPlayerTypeToEditor: async function() {
        const player = this.refElements['player']
        await player.setPlayerTypeToEditor()
    },
    setPlayerTypeToPlayer: async function() {
        const player = this.refElements['player']
        await player.setPlayerTypeToPlayer()
    },


    touchEnd: function(e, fn, pt, d) {
        if (e && e.type == 'mouseup' || e && e.type == 'touchend' || e && e.type == pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { type } = d
        const { children, style, construct } = this.props
        const { onFullScreen } = construct.props
        const state = this.state

        e.preventDefault()
    },
    updatePlayer: function(data) {
        const player = this.refElements['player']
        if (player.updateContainer) player.updateContainer(data)
    },
    reloadPlayer: function(data) {
        const player = this.refElements['player']
        if (player.reload) player.reload()
    },
    updateCompass: function(data) {
        const player = this.refElements['player']
        if (player.updateCompass) player.updateCompass(data)
    },
    setCurrentView: async function(p) {
        const player = this.refElements['player']
        if (player.setCurrentView) await player.setCurrentView(p)
    },

    /*Refs*/

    getElement: function(e) {
        return (e) ? (e.nodeName) ? e : ReactDOM && ReactDOM.findDOMNode && ReactDOM.findDOMNode(e) : null
    },
    setRef: function(a, e) {
        const { setRef } = this.props
        if (e) {
            this.refElements[a] = e
            if (setRef) setRef(a, e)
        }
    },

    /*Form*/

    getEditData: function() {
        const { editdata } = this.props
        const { stepIndex } = this.state
        const data = this.getData()
        const schema = this.getSchema()
        const appver = this.getAppver()
        return editdata['editor'][stepIndex]({ data, schema, version: appver })
    },
    submit: function(newdata) {
        const data = this.getData()
        return data
    },
    save: function({ callback }) {

        const { reloadPlayerAfterSave } = this.props
        const reloadPlayer = this.reloadPlayer

        const data = this.submit()
        const form = this.refElements['form']

        form.validateForm()

        const isValid = form.isValid()
        const r = { data, form: { valid: isValid } }
        if (!isValid) {
            this.openWithoutUpdate()
        }
        if (callback) callback(r)

        if (reloadPlayerAfterSave) {
            setTimeout(function() {
                reloadPlayer()
            }, 100)
        }

        return r
    },
    updateForm: function() {
        const form = this.refElements['form']
        if (form) {
            const formdata = this.getEditData()
            form.setFormData(formdata)
        }
    },

    /*FloatingPalette*/

    open: function() {
        const floatingpalette = this.refElements['floatingpalette']
        this.updateForm()
        floatingpalette.open()
    },
    openWithoutUpdate: function() {
        const floatingpalette = this.refElements['floatingpalette']
        floatingpalette.open()
    },
    close: function() {
        const floatingpalette = this.refElements['floatingpalette']
        floatingpalette.close()
    },
    onOpen: function(p) {
        const { getControls } = this.props
        const editorcontrols = getControls()
        if (editorcontrols) {
            editorcontrols.open({ disableCallback: true })
        }
        this.setPlayerMaxWidth(p)
    },
    onClose: function(p) {
        const { getControls } = this.props
        const editorcontrols = getControls()
        if (editorcontrols) {
            editorcontrols.close({ disableCallback: true })
        }
        this.setPlayerMaxWidth(p)
    },
    setPlayerMaxWidth: function({ open, pinned, pintype }) {

        const { onChangePin } = this.props
        const playercontainer = this.refElements['playercontainer']
        const player = this.refElements['player']

        if (playercontainer && player && player.resize) {
            player.setPlayerClassName({ open, pinned, pintype })
            const currentMaxWidth = playercontainer.style.maxWidth
            const newMaxWidth = (open && pinned && pintype == 'right') ? 'calc(100% - 350px)' : '100%'
            if (currentMaxWidth !== newMaxWidth) {
                playercontainer.style.maxWidth = newMaxWidth
                player.resize()
            }
        }

        if (onChangePin) onChangePin({ open, pinned, pintype })

    },

    addResizeListener: function() {
        const { rootRemoveResizeListener, rootAddResizeListener } = this.props
        const thereIsParentResizeFeature = (rootRemoveResizeListener && rootAddResizeListener) ? true : false
        if (thereIsParentResizeFeature) {
            const { id } = rootAddResizeListener(this.resize)
            this.listenerID = id
        }
    },
    removeResizeListener: function() {
        const { rootRemoveResizeListener, rootAddResizeListener } = this.props
        const thereIsParentResizeFeature = (rootRemoveResizeListener, rootAddResizeListener) ? true : false
        if (thereIsParentResizeFeature) {
            rootRemoveResizeListener(this.resize, this.listenerID || 0)
        }
    },
    resize: function() {
        const floatingpalette = this.refElements['floatingpalette']
        setTimeout(function() {
            floatingpalette.updatePosition({ forceUpdate: false, visibility: 'visible' })
        }, 200)
    },

    render: function() {
        const {
            children,
            style,
            construct,
            formprops = {},
            rootClassName,
            playerProps = {},
            Player = PlayerComponent,
            floatingPaletteProps = {},
            getRootContainer
        } = this.props

        const data = this.getData()
        const touchEnd = this.touchEnd
        const setRef = this.setRef

        const editdata = this.getEditData()
        const submit = this.submit

        const onOpen = this.onOpen
        const onClose = this.onClose

        const setPlayerMaxWidth = this.setPlayerMaxWidth
        const updatePlayer = this.updatePlayer
        const updateCompass = this.updateCompass
        const updateForm = this.updateForm
        const setCurrentView = this.setCurrentView

        const setPlayerTypeToEditor = this.setPlayerTypeToEditor
        const setPlayerTypeToPlayer = this.setPlayerTypeToPlayer

        return (
            <div className={style.editor}>
                <div className={style.player} ref={function(e) {
                    setRef('playercontainer', e)
                }}>
                    <Player {...this.props} data={data} setRef={setRef} style={pls} editor={false}
                            rootClassName={rootClassName} updateForm={updateForm}
                            disableFloatingPalette={true} {...playerProps} />
                </div>
                <FloatingPalette
                    style={fps}
                    setRef={setRef}
                    onOpen={onOpen}
                    onClose={onClose}
                    open={true}
                    onPin={setPlayerMaxWidth}
                    onUnpin={setPlayerMaxWidth}
                    onPintype={setPlayerMaxWidth}
                    onForcePinOnDown={setPlayerMaxWidth}
                    visibility={'hidden'}
                    {...floatingPaletteProps}
                >
                    <div style={{ marginBottom: '20px' }}>
                        <Formsy
                            formdata={editdata}
                            submitForm={submit}
                            updatePlayer={updatePlayer}
                            updateCompass={updateCompass}
                            setCurrentView={setCurrentView}
                            style={fos}
                            post={data}
                            floatingStyle={fps}
                            {...formprops}
                            setRef={setRef}
                            getRootContainer={getRootContainer}
                            setPlayerTypeToEditor={setPlayerTypeToEditor}
                            setPlayerTypeToPlayer={setPlayerTypeToPlayer}
                            canSubmit={true}
                        />
                    </div>
                </FloatingPalette>
            </div>
        )
    }
})

const Middle = createReactClass({
    setRef: function(a, e) {
        const { setRef } = this.props
        const { construct } = this.props
        const setRefConstruct = (construct.props) ? construct.props.setRef : null
        if (setRef) setRef(a, e)
        if (setRefConstruct) setRefConstruct(a, e)
    },
    render: function() {
        const setRef = this.setRef
        return (
            <Editor {...this.props} ref={(setRef) ? function(e) {
                setRef('editor', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})
