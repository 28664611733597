import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

import IconButton from 'material-ui/IconButton'
import MenuIcon from 'material-ui/svg-icons/action/view-list'
import CloseIcon from 'material-ui/svg-icons/action/highlight-off'

import { Step, StepLabel, Stepper } from 'material-ui/Stepper'
import ArrowForwardIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-right'

const BreadCrumb = createReactClass({
    getInitialState: function() {
        this.refElements = {}
        const { view_id } = this.props
        const historyObject = this.getHistory() || {}
        const { history = [], currentHistoryStep } = historyObject
        return {
            view_id,
            history: [...history],
            currentHistoryStep: currentHistoryStep
        }
    },
    componentDidMount: function() {
        const historyObject = this.getHistory()
        if (historyObject.history && historyObject.history.length !== this.state.history ||
            historyObject.currentHistoryStep !== this.state.currentHistoryStep) {
            this.setState({
                ...historyObject
            })
        }
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type == 'mouseup' || e && e.type == 'touchend' || e && e.type == pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { onClose } = this.props
        const { type, view_id, back } = d
        if (type == 'click') {
            this.onClick({ view_id })
        }
        if (type == 'back') {
            this.back({ back })
        }
        e.preventDefault()
    },
    onClick: function({ view_id }) {
        const { setCurrentView } = this.props
        setCurrentView({ view_id })
    },
    back: function({ back }) {
        const { setCurrentView } = this.props
        setCurrentView({ back })
    },
    getDataObject: function() {
        const { dataObject = {} } = this.props
        return dataObject
    },
    getSettings: function() {
        const dataObject = this.getDataObject()
        const settings = dataObject.props.settings.props.settings[0]
        return settings
    },
    getViews: function() {
        const dataObject = this.getDataObject()
        const views = dataObject.props.views.props.views
        return views
    },
    getObjects: function() {
        const dataObject = this.getDataObject()
        const objects = dataObject.props.objects.props.objects
        return objects
    },
    getMetas: function() {
        const dataObject = this.getDataObject()
        const metas = dataObject.props.metas.props.metas
        return metas
    },
    getViewData: function(p = {}) {
        const { view_id } = p
        if (view_id) {
            const views = this.getViews()
            if (views && views.length) {
                let foundView = null
                views.map(function(view) {
                    if (view.key == view_id) foundView = view
                })
                return foundView
            }
        }
        return null
    },
    getObjectData: function(p = {}) {
        const { object_id } = p
        if (object_id) {
            const objects = this.getObjects()
            if (objects && objects.length) {
                let foundObject = null
                objects.map(function(object) {
                    if (object.key == object_id) foundObject = object
                })
                return foundObject
            }
        }
        return null
    },
    getViewObjectData: function({ view_id }) {
        const viewData = this.getViewData({ view_id })
        if (viewData && viewData.object_id) {
            return this.getObjectData({ object_id: viewData.object_id })
        }
        return null
    },
    getPopupBackgroundColor: function() {
        const settings = this.getSettings() || {}
        const { colors = {} } = settings
        const { player = {} } = colors
        const { tooltip = {} } = player
        return tooltip.backgroundColor
    },
    getHistory: function() {
        const { refElements } = this.props
        const player = refElements['player']
        const playerComponent = (player) ? player.refElements['playercomponent'] : null
        const playerState = (playerComponent) ? playerComponent.state : {}
        const { history, currentHistoryStep } = playerState
        return { history, currentHistoryStep }
    },
    setViewId: function(p = {}) {
        const { view_id, currentHistoryStep, history } = this.state
        if (p.view_id !== view_id || currentHistoryStep !== p.currentHistoryStep) {
            this.setState({
                view_id: p.view_id || view_id,
                history: (p.history) ? [...p.history] : history,
                currentHistoryStep: p.currentHistoryStep || currentHistoryStep
            })
        }
    },
    getHistoryToView: function() {
        const { history = [], currentHistoryStep, view_id } = this.state
        const h = [...history]
        if (!h.length && view_id) {
            h.push(view_id)
        }
        return { history: h, currentHistoryStep: (currentHistoryStep) ? currentHistoryStep : h.length - 1 }
    },
    getViewObjectsFromObjectId: function({ object_id }) {
        const views = this.getViews()
        const r = []
        if (views && views.length) {
            views.map(function(view) {
                if (view.object_id == object_id) {
                    r.push(view)
                }
            })
        }
        return r
    },
    getSteps: function() {

        const { style } = this.props
        const { history, currentHistoryStep } = this.getHistoryToView()
        const { view_id } = this.state
        const getViewObjectData = this.getViewObjectData
        const getViewData = this.getViewData
        const touchEnd = this.touchEnd
        const settings = this.getSettings() || {}
        const { title = {} } = settings
        const startView = settings.view_id || 'area'
        const getViewObjectsFromObjectId = this.getViewObjectsFromObjectId

        const r = []
        history.map(function(h_view_id, i) {

            const objectData = getViewObjectData({ view_id: h_view_id })
            let name = (h_view_id == startView && title) ? title : objectData.name

            const viewData = getViewData({ view_id: h_view_id })
            const sameViews = getViewObjectsFromObjectId({ object_id: viewData.object_id })
            sameViews.map(function(view) {
                if (view.key == startView && title) {
                    name = title
                }
            })

            const back = i - history.length + 1
            const send = { type: 'back', back: back }

            let hidden = false
            if (viewData.hidden && i < history.length - 1 && currentHistoryStep !== i) hidden = true

            if (!hidden) {
                r.push(
                    <Step
                        className={style.breadstep}
                        onTouchTap={function(e) {
                            touchEnd(e, 'click', 'click', send)
                        }}
                        onTouchStart={function(e) {

                        }}
                        key={i}>
                        <StepLabel iconContainerStyle={{ display: 'none' }}>{name}</StepLabel>
                    </Step>
                )
            }
        })

        return r

    },
    render: function() {

        const { style } = this.props
        const { history, currentHistoryStep } = this.getHistoryToView()
        const steps = this.getSteps()

        return (
            <div className={style.breadcrumb}>
                {(history.length && steps.length) ?
                    <Stepper activeStep={currentHistoryStep} connector={<ArrowForwardIcon />}>
                        {steps}
                    </Stepper> : null}
            </div>
        )
    }

})

const PlayerControls = createReactClass({
    getInitialState() {
        this.refElements = {}
        const { open } = this.props
        return {
            open: open
        }
    },
    componentDidMount: function() {
    },
    componentWillUnmount: function() {
    },
    getData: function() {
        const { construct, data } = this.props
        if (data) return data
        return (construct.props && construct.props.data) ? construct.props.data : null
    },
    open: function(p = {}) {
        const { disableCallback } = p
        const { open } = this.state
        const { onOpen } = this.props

        if (!open) {
            this.setState({
                open: true
            })
            this.state.open = true
            if (onOpen && !disableCallback) onOpen()
        }
    },
    close: function(p = {}) {
        const { disableCallback } = p
        const { open } = this.state
        const { onClose } = this.props

        if (open) {
            this.setState({
                open: false
            })
            this.state.open = false
            if (onClose && !disableCallback) onClose()
        }
    },
    opentoggle: function() {
        const { open } = this.state
        if (open) {
            this.close()
        } else {
            this.open()
        }
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type == 'mouseup' || e && e.type == 'touchend' || e && e.type == pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { type } = d
        if (type == 'opentoggle') {
            this.opentoggle()
        }
        e.preventDefault()
    },
    setViewId: function(p = {}) {
        if (this.refElements['breadcrumb']) {
            this.refElements['breadcrumb'].setViewId(p)
        }
    },
    setRef: function(a, e) {
        if (e) {
            this.refElements[a] = e
        }
    },
    render: function() {

        const {
            style,
            refElements,
            setCurrentView,
            centerChildren,
            disableopentoggleicon,
            disablebreadcrumb,
            className
        } = this.props
        const { open } = this.state
        const touchEnd = this.touchEnd
        const dataObject = this.getData()
        const setRef = this.setRef

        const cN = (className && style[className]) ?
            style.headercenter + ' ' + style[className] :
            (className) ? style.headercenter + ' ' + className :
                style.headercenter

        return (
            <div className={cN}>
                {(disablebreadcrumb) ? null : <BreadCrumb
                    ref={function(e) {
                        setRef('breadcrumb', e)
                    }}
                    style={style}
                    dataObject={dataObject}
                    refElements={refElements}
                    setCurrentView={setCurrentView}
                />}
                {(centerChildren) ? centerChildren : null}
                {(!disableopentoggleicon) ?
                    <div className={style.controlsicon + ' ' + style.opentoggleicon}>
                        <IconButton
                            onTouchTap={function(e) {
                                touchEnd(e, 'click', 'click', { type: 'opentoggle' })
                            }}
                            onTouchStart={function(e) {

                            }}
                            className={style.controlsiconbutton}
                        >
                            {(open) ? <CloseIcon /> : <MenuIcon />}
                        </IconButton>
                    </div>
                    : null
                }
            </div>
        )
    }
})

const Middle = createReactClass({
    setRef: function(a, e) {
        const { setRef } = this.props
        if (setRef) setRef(a, e)
    },
    render: function() {
        const setRef = this.setRef
        return (
            <PlayerControls {...this.props} ref={(setRef) ? function(e) {
                setRef('playercontrols', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})
