import React from 'react'
import createReactClass from 'create-react-class'

import ps from '../../../../themes/components/Wapplrpost.css'
import avs from '../../../../themes/components/Wapplravatar.css'

import wapplrcomponents from '../../../../components'

import TemplateDefault from '../../../templates/template_default'
import Wapplrsnackbar from '../../../components/Wapplrsnackbar'
import Wapplrdialog from '../../../components/Wapplrdialog'

import PublicIcon from 'material-ui/svg-icons/social/public'
import PrivateIcon from 'material-ui/svg-icons/action/lock-outline'

import fetchs from '../../../services/fetchs'
import { getPostStatusData } from '../../../server/poststatus/statuses'

import { endValidationHelpers } from '../services/editdata'
import CharliesPlayer from '../../../components/CharliesPlayer'
import chs from '../../../components/CharliesPlayer/CharliesPlayer.css'

import config from '../config'

const WapplrpostEdit = wapplrcomponents.editpost

const lowername = config.name.toLowerCase()

const Editor = createReactClass({
    getInitialState: function() {
        this.refElements = {}

        const { response } = this.props
        const post = this.getPost(response)

        return {
            isMounted: true,
            post: post,
            stepIndex: 0,
            orientation: null,
            hiddencontent: true,
            ready: false
        }
    },
    updateDimensions: function(ret) {
        const state = (this.state) ? this.state : {}
        const { orientation = 'horizontal' } = state
        const width = (typeof window !== 'undefined') ? window.innerWidth : 0
        const neworientation = (width > 800) ? 'horizontal' : 'vertical'
        if (ret === true) {
            return neworientation
        } else {
            if (neworientation !== orientation) {
                this.setState({
                    orientation: neworientation
                })
            }
        }
    },
    setSaved: function() {
        const editpost = this.refElements['editpost']
        if (editpost && editpost.updatePost) editpost.updatePost()
    },
    componentDidMount: function() {

        const { response, curlang = {} } = this.props

        const {
            savepostdefaultfail = 'Sorry, there was an issue save your post, please try again',
            savepostsuccessmessage = 'Your post has been saved',
            deletepostdefaultfail = 'Sorry, there was an issue delete your post, please try again',
            deletepostsuccessmessage = 'Your post has been deleted'
        } = curlang

        const post = this.getPost(response)
        const getPostLink = this.getPostLink


        const snack = this.snack
        const setSaved = this.setSaved
        const done = this.done

        this.savefetch = fetchs['save' + lowername]({
            getHelpers: this.getHelpers,
            history: history,
            success: function({ response, processingEnd }) {
                snack(savepostsuccessmessage)
                if (processingEnd) processingEnd(false)
                if (response.status) post.status = response.status
                setSaved()
                setTimeout(function() {
                    //done(getPostLink());
                }, 1500)
            },
            fail: function({ response, invalidateForm }) {
                if (response.messages) {
                    invalidateForm(response.messages)
                } else {
                    const field = response.field || ''
                    const message = response.message || savepostdefaultfail
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })

        const dialogClose = this.dialogClose

        this.deletefetch = fetchs['delete' + lowername]({
            getHelpers: this.getHelpers,
            history: history,
            success: function() {
                dialogClose()
                snack(deletepostsuccessmessage)
                setTimeout(function() {
                    done(getPostLink())
                }, 1500)
            },
            fail: function({ response, invalidateForm }) {
                if (response.messages) {
                    invalidateForm(response.messages)
                } else {
                    const field = response.field || ''
                    const message = response.message || deletepostdefaultfail
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })

        this.setState({
            post: post,
            orientation: this.updateDimensions(true)
        })

        window.addEventListener('resize', this.updateDimensions)

    },

    componentWillUnmount: function() {
        window.removeEventListener('resize', this.updateDimensions)
    },

    //Close

    close: function() {

        const { state } = this.getHelpers()

        const getPostLink = this.getPostLink

        const historyPush = this.historyPush

        if (state.lasturl && !state.lasturl.match('new')) {
            historyPush(state.lasturl)
        } else {
            historyPush(getPostLink())
        }
    },

    done: function(post) {

        const { state } = this.getHelpers()

        const getPostLink = this.getPostLink

        const historyPush = this.historyPush

        if (state.lasturl && !state.lasturl.match('new')) {
            historyPush(state.lasturl)
        } else {
            historyPush(getPostLink(post))
        }
    },

    //Edit functions

    savefetch: function() {
    },
    savepost: function(data, resetForm, invalidateForm, refs = {}) {
        const { getPlayer } = this.props
        const player = (getPlayer) ? getPlayer() : null

        const { stepIndex, saveddata = {}, post } = this.state
        const stepdata = (this.stepdata) ? this.stepdata : {}
        const getData = (player && player.getData) ? player.getData : function({ callback }) {
            callback()
        }
        const t = this
        const snack = this.snack

        const { processingStart, processingEnd } = refs
        if (processingStart && processingEnd) processingStart(true)

        getData({
            callback: function(p = {}) {

                const appjson = p.data
                const form = p.form

                if (form && form.valid === false) {

                    snack('Invalid data from app')
                    if (processingStart && processingEnd) processingEnd()

                } else {

                    const rdata = {
                        ...saveddata,
                        ...data
                    }

                    rdata.tokens = post.tokens

                    if (!rdata.appjsonenableadmin && appjson) {
                        rdata.appjson = appjson
                        post.appjson = JSON.stringify(appjson)
                    }

                    if (rdata.appjson && typeof rdata.appjson == 'string') {
                        rdata.appjson = JSON.parse(rdata.appjson)
                    }

                    if (rdata.appjson && rdata.appjson.props && rdata.appjson.props.settings &&
                        rdata.appjson.props.settings.props && rdata.appjson.props.settings.props.settings &&
                        rdata.appjson.props.settings.props.settings[0]) {

                        const settings = rdata.appjson.props.settings.props.settings[0]
                        if (settings.title) {
                            rdata.title = settings.title
                            post.title = settings.title
                        }
                    }

                    if (rdata.appver === '1.0' && rdata.appjson && rdata.appjson.name ||
                        !rdata.appver && rdata.appjson && rdata.appjson.name) {
                        rdata.title = rdata.appjson.name
                        post.title = rdata.appjson.name
                    }

                    //if (rdata.appjson && typeof rdata.appjson !== "string") rdata.appjson = JSON.stringify(rdata.appjson);

                    rdata.id = (post && post.id) ? post.id : 0

                    t.stepdata = {
                        ...stepdata,
                        [stepIndex]: {
                            data,
                            invalidateForm
                        }
                    }

                    const rinvalidateForm = t.invalidateForm
                    t.savefetch(rdata, resetForm, rinvalidateForm, refs)

                }

            }
        })
    },
    invalidateForm: function(messages = {}) {

        const stepdata = (this.stepdata) ? this.stepdata : {}
        const messagesbystep = {}

        let foundstep = false

        Object.keys(messages).map(function(field) {
            Object.keys(stepdata).map(function(stepindex) {
                const step = stepdata[stepindex]
                const data = step.data
                if (data) {
                    Object.keys(data).map(function(v) {
                        if (v === field) {
                            if (!messagesbystep[stepindex]) {
                                if (foundstep === false) foundstep = stepindex
                                messagesbystep[stepindex] = {}
                            }
                            messagesbystep[stepindex][field] = messages[field]
                        }
                    })
                }
            })
        })

        if (foundstep === false && foundstep !== 0 && foundstep !== '0') {
            const snack = this.snack
            let rm = ''
            Object.keys(messages).map(function(m) {
                rm = (!rm) ? messages[m] : rm + '; ' + messages[m]
            })
            if (rm) snack(rm)
        } else {

            const rmessages = messagesbystep[foundstep]
            this.setState({
                stepIndex: Number(foundstep)
            })
            const refElements = this.refElements
            setTimeout(function() {
                const formsy = refElements['formsy_formsy']
                if (formsy && formsy.updateInputsWithError) formsy.updateInputsWithError(rmessages)
            }, 500)
        }

    },
    nextvalid: function(data) {
        const { stepIndex, saveddata = {} } = this.state
        const stepdata = (this.stepdata) ? this.stepdata : {}

        this.stepdata = {
            ...stepdata,
            [stepIndex]: {
                data
            }
        }

        this.setState({
            stepIndex: stepIndex + 1,
            saveddata: {
                ...saveddata,
                ...data
            }
        })
    },
    nextstep: function(data, resetForm, invalidateForm, refs = {}) {

        const t = this
        const { curlang = {} } = t.props
        const { stepIndex} = t.state

        const nextvalid = t.nextvalid

        const refElements = this.refElements
        const invalid = function(messages) {
            t.setState({
                hiddencontent: false
            })
            setTimeout(function() {
                const formsy = refElements['formsy_formsy']
                if (formsy && formsy.updateInputsWithError) formsy.updateInputsWithError(messages)
            }, 500)

        }

        endValidationHelpers({
            stepIndex,
            onValid: nextvalid,
            onFail: invalid,
            data,
            resetForm,
            invalidateForm: invalid,
            refs,
            curlang
        })

    },
    getstep: function(step) {
        const { stepIndex } = this.state
        if (stepIndex > step) {
            this.setState({
                stepIndex: Number(step)
            })
        }
    },
    deletefetch: function() {
    },
    deletepost: function({ post }) {

        const { curlang } = this.props

        const {
            deletepostsubtitle = 'Delete post',
            deletepostquestion = 'Are you sure want to delete your post?',
            submittext = 'Submit',
            canceltext = 'Cancel'
        } = curlang

        const deletefetch = this.deletefetch
        const dialogClose = this.dialogClose

        const { processingStart, processingEnd } = this.dialogOpen({
            title: deletepostsubtitle,
            text: deletepostquestion,
            submittext: submittext,
            canceltext: canceltext,
            action: function() {

                const data = { id: post.id }
                const resetForm = function() {
                }
                const invalidateForm = function() {
                    dialogClose()
                }
                const refs = {
                    processingEnd: function() {
                        if (processingEnd) processingEnd()
                    },
                    processingStart: function() {
                        if (processingStart) processingStart()
                    }
                }
                deletefetch(data, resetForm, invalidateForm, refs)

            }
        })
    },

    getPostLinkObject: function() {
        const post = this.state.post
        if (post && post.id) {
            return {
                pathname: '/' + lowername + '/' + post.id
            }
        }
        return {}
    },
    getPostLink: function() {
        const post = this.state.post
        if (post && post.id) {
            return '/' + lowername + '/' + post.id
        }
        return ''
    },
    getUserLink: function() {
        const post = this.state.post
        const author = (post && post.author) ? post.author : null
        if (author && author.id) {
            return '/user/' + author.id
        }
        return ''
    },
    getPost: function(response) {
        const post = (response && response['current' + lowername] && response['current' + lowername][0]) ? response['current' + lowername][0] : null
        if (post && post.category && typeof post.category == 'string') post.category = JSON.parse(post.category)
        return post
    },
    getHelpers: function() {
        const { helpers } = this.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },
    historyPush: function(o) {
        const { history } = this.props
        if (o && history && history.push) {
            history.push(o)
        } else {
            if (o) window.location.href = (o.search) ? o.pathname + '?' + o.search : o.pathname
        }
    },
    setRef: function(a, e) {
        this.refElements[a] = e
    },
    getGlobalState: function() {
        const { state } = this.getHelpers()
        return state
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    getPostStatus: function({ post }) {
        const { curlang } = this.props
        return getPostStatusData({ post, curlang })
    },
    getEditData: function() {
        const { editdata } = this.props
        const { stepIndex} = this.state
        return editdata[lowername]['edit'][stepIndex]
    },

    //Dialog
    dialogClose: function() {
        const dialog = this.refElements['dialog']
        if (dialog && dialog.dialogClose) dialog.dialogClose()
    },
    dialogOpen: function({ action, title, text, submittext, canceltext }) {
        const dialog = this.refElements['dialog']
        if (dialog && dialog.dialogOpen) return dialog.dialogOpen({ action, title, text, submittext, canceltext })
        return {}
    },

    //Snack
    snack: function(message) {
        const nodemessage = (message) ? <div>{message}</div> : <div></div>
        const snack = this.refElements['snack']
        if (snack && snack.open) snack.open(nodemessage)
    },
    readycallback: function() {
        const { ready } = this.state
        if (!ready) {
            this.setState({
                ready: true
            })
        }
    },

    //Publicicon
    getpublicicon: function({ post }) {

        const share = post && post.share

        this.getMe()


        if (share === 'public' || !share) {
            return <PublicIcon />
        }
        if (share === 'private') {
            return <PrivateIcon />
        }
        return null
    },

    getstatusname: function({ post }) {
        const { statusname } = this.getPostStatus({ post })
        return statusname
    },
    getdeletebutton: function({ post }) {
        const { deletebutton } = this.getPostStatus({ post })
        return deletebutton
    },
    getrestoretext: function({ post }) {
        const { curlang } = this.props
        const { deletebutton } = this.getPostStatus({ post })
        return (deletebutton) ? null : curlang.saveandrestore
    },
    onOpenHiddenContent: function(open) {
        this.setState({
            hiddencontent: open
        })
    },
    render: function() {

        const { history} = this.props



        const me = this.getMe()

        const savepost = this.savepost
        const nextstep = this.nextstep
        const deletepost = this.deletepost
        const restorepost = this.restorepost

        const state = this.state
        const data = this.getEditData()
        const post = state.post
        const hiddencontent = state.hiddencontent
        const ready = state.ready
        const onOpenHiddenContent = this.onOpenHiddenContent
        const close = this.close
        const setRef = this.setRef
        const author = (post && post.author) ? post.author : null

        const getpublicicon = this.getpublicicon
        const getstatusname = this.getstatusname
        const getdeletebutton = this.getdeletebutton
        const getrestoretext = this.getrestoretext
        const { fetch } = this.getHelpers()
        const { stepIndex } = state


        return (
            <div>
                <div>
                    <div>
                        <WapplrpostEdit history={history}
                                        style={ps}
                                        post={post}
                                        showauthor={true}
                                        avatarstyle={avs}
                                        setRef={setRef}
                                        className={'cardtype'}
                                        save={(stepIndex < 0) ? nextstep : savepost}
                                        deletepost={deletepost}
                                        restorepost={restorepost}
                                        data={data}
                                        close={close}
                                        getstatusname={getstatusname}
                                        getdeletebutton={getdeletebutton}
                                        getrestoretext={getrestoretext}
                                        getpublicicon={getpublicicon}
                                        formprops={{
                                            user: author,
                                            me: me,
                                            fetch: fetch,
                                            setRef: function(a, e) {
                                                setRef('formsy_' + a, e)
                                            },
                                            open: function(open) {
                                                onOpenHiddenContent(open)
                                            },
                                            ready: ready
                                        }}
                                        enablehiddencontent={'form'}
                                        hiddencontent={hiddencontent}
                                        onOpenHiddenContent={onOpenHiddenContent}

                        />
                    </div>
                </div>
                <Wapplrsnackbar ref={function(e) {
                    setRef('snack', e)
                }} style={{ position: 'absolute' }} />
                <Wapplrdialog setRef={setRef} className={ps.dialog} />
            </div>
        )
    }
})


const Post = createReactClass({
    getInitialState: function() {
        this.refElements = {}
        const { response } = this.props
        const post = this.getPost(response)
        return {
            post
        }
    },
    getGlobalState: function() {
        const { state } = this.getHelpers()
        return state
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    getPost: function(response) {
        const post = (response && response['current' + lowername] && response['current' + lowername][0]) ? response['current' + lowername][0] : null
        if (post && post.category && typeof post.category == 'string') post.category = JSON.parse(post.category)
        return post
    },
    getHelpers: function() {
        const { helpers } = this.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },
    getPostLink: function() {
        const post = this.state.post
        if (post && post.id) {
            return '/' + lowername + '/' + post.id
        }
        return ''
    },
    setRef: function(a, e) {
        this.refElements[a] = e
    },
    readycallback: function() {
        const t = this
        setTimeout(function() {
            const editpost = t.refElements['editpostwrapper']
            if (editpost && editpost.readycallback) editpost.readycallback()
        })
    },

    render: function() {

        const {
            siteurl = '',
            curlang = {},
            history,
            flatchooserapp,
            staticscript = '/index.js',
            staticserver = '',
            style
        } = this.props
        const { post } = this.state
        const globalstate = this.getGlobalState()
        const state = {
            ...globalstate,
            response: (globalstate.response) ? {
                ...globalstate.response,
                currentpost: globalstate.response.currentplayer
            } : {}
        }
        const postlink = this.getPostLink()
        const me = this.getMe()
        const author = (post && post.author) ? post.author : null
        const setRef = this.setRef
        const refElements = this.refElements
        const readycallback = this.readycallback

        const protocol = 'https:'
        const staticscripturl = (staticserver && staticscript) ? protocol + '//' + staticserver + staticscript : null

        flatchooserapp.startroute = '/edit'

        return (
            <div>
                {(author && author.id && me && me.id === author.id || me && me.isAdmin || me && me.isEditor || post && post.coproducersAccess) ?
                    <div>
                        <CharliesPlayer
                            style={chs}
                            setRef={setRef}
                            app={flatchooserapp}
                            state={state}
                            data={post && post.appjson}
                            parentroute={postlink}
                            curlang={curlang}
                            history={history}
                            disablehistory={false}

                            customprops={{
                                logohref: '/',
                                posturl: siteurl + postlink,
                                staticscripturl: staticscripturl,
                                postmedia: siteurl + post.cover,

                                disabledescription: true,
                                disableshare: true,
                                autoheight: true,
                                headertypeatplayer: 'fullpagewithheader',

                                playerrootclassname: style.editorplayerroot,
                                iseditor: true,
                                readycallback: readycallback,
                                disablefullscreen: !!(post && post.appver === '1.0' || post && !post.appver)
                            }}
                        />
                        <Editor {...this.props} ref={function(e) {
                            setRef('editpostwrapper', e)
                        }} getPlayer={function() {
                            return refElements['charliesplayer']
                        }} />
                    </div> : null
                }
            </div>
        )
    }
})

export default createReactClass({
    render: function() {
        const { style } = this.props
        return (
            <TemplateDefault {...this.props} className={style.postcard}>
                <Post {...this.props} />
            </TemplateDefault>
        )
    }
})
