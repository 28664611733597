import root from './root'
import image from './image'
import hotspot from './hotspot'

export default {
    flatchooser_1: {
        root: {
            'type': 'object',
            'hidden': true,
            'fields': {
                'props': {
                    'id': 'root',
                    'hidden': true,
                    'type': 'object',
                    'fields': {
                        'objects': {
                            'type': 'object',
                            'hidden': true,
                            'fields': {
                                'props': {
                                    'type': 'object',
                                    'hidden': true,
                                    'fields': {
                                        'objects': {
                                            'hidden': true,
                                            'type': 'array',
                                            'fieldsName': 'name',
                                            'name': 'root',
                                            'fields': {},
                                            'autoFields': {
                                                'enable': {
                                                    'default': {
                                                        'type': 'object',
                                                        'fields': {}
                                                    },
                                                    'dinamic': function(p = {}) {

                                                        const data = p.data[p.key][p.i]
                                                        const metas = p.parentData.datas[1].props.metas.props.metas

                                                        if (metas && metas.length) {
                                                            const r = {}
                                                            metas.map(function(meta) {
                                                                const key = meta.key
                                                                const enabled = meta.enabled || []
                                                                let go = (enabled.indexOf(data.type) > -1)

                                                                if (key === 'name' || key === 'status' || key === 'price' || key === 'floor' || key === 'size' || key === 'rooms' || key === 'flatsnumber') {
                                                                } else {
                                                                    go = false
                                                                }

                                                                if (go) {
                                                                    r[key] = {
                                                                        ...meta
                                                                    }

                                                                    if (key.match('custom')) {
                                                                        let validations = meta.validations || []
                                                                        let foundValidation = null

                                                                        if (validations && validations.length) {
                                                                            validations.map(function(validation) {
                                                                                if (validation.name === 'type') {
                                                                                    foundValidation = validation
                                                                                }
                                                                            })
                                                                        }

                                                                        if (!foundValidation) {
                                                                            foundValidation = {
                                                                                'name': 'type',
                                                                                'props': { 'value': [] }
                                                                            }
                                                                        }

                                                                        if (meta.type === 'number' || meta.type === 'string') {
                                                                            foundValidation.props.value = [meta.type]
                                                                        }

                                                                        if (foundValidation.props.value && foundValidation.props.value.length) {
                                                                            if (!validations) validations = []
                                                                            validations.push(foundValidation)
                                                                        }

                                                                        if (validations && validations.length) {
                                                                            r[key].validations = validations
                                                                        }

                                                                    }

                                                                }
                                                            })
                                                            return { fields: r }
                                                        }
                                                        return {}
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        image: image,
        hotspot: hotspot
    },
    flatchooser_2: {
        root: {
            'type': 'object',
            'hidden': true,
            'fields': {
                'props': {
                    'id': 'root',
                    'hidden': true,
                    'type': 'object',
                    'fields': {
                        'objects': {
                            'type': 'object',
                            'hidden': true,
                            'fields': {
                                'props': {
                                    'type': 'object',
                                    'hidden': true,
                                    'fields': {
                                        'objects': {
                                            'hidden': true,
                                            'type': 'array',
                                            'fieldsName': 'name',
                                            'name': 'root',
                                            'fields': {},
                                            'autoFields': {
                                                'enable': {
                                                    'default': {
                                                        'type': 'object',
                                                        'fields': {}
                                                    },
                                                    'dinamic': function(p = {}) {

                                                        const data = p.data[p.key][p.i]
                                                        const metas = p.parentData.datas[1].props.metas.props.metas

                                                        if (metas && metas.length) {
                                                            const r = {}
                                                            metas.map(function(meta) {
                                                                const key = meta.key
                                                                const enabled = meta.enabled || []
                                                                const go = (enabled.indexOf(data.type) > -1)
                                                                if (go) {
                                                                    r[key] = {
                                                                        ...meta
                                                                    }

                                                                    if (key.match('custom')) {
                                                                        let validations = meta.validations || []
                                                                        let foundValidation = null

                                                                        if (validations && validations.length) {
                                                                            validations.map(function(validation) {
                                                                                if (validation.name === 'type') {
                                                                                    foundValidation = validation
                                                                                }
                                                                            })
                                                                        }

                                                                        if (!foundValidation) {
                                                                            foundValidation = {
                                                                                'name': 'type',
                                                                                'props': { 'value': [] }
                                                                            }
                                                                        }

                                                                        if (meta.type === 'number' || meta.type === 'string') {
                                                                            foundValidation.props.value = [meta.type]
                                                                        }

                                                                        if (foundValidation.props.value && foundValidation.props.value.length) {
                                                                            if (!validations) validations = []
                                                                            validations.push(foundValidation)
                                                                        }

                                                                        if (validations && validations.length) {
                                                                            r[key].validations = validations
                                                                        }

                                                                    }

                                                                }
                                                            })
                                                            return { fields: r }
                                                        }
                                                        return {}
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        image: image,
        hotspot: hotspot
    }
}
