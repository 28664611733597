// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XMppU {\n    width: 100%;\n    height: 100%;\n    position: absolute;\n    top: 0;\n    left: 0;\n}\n\n._5l0Iy {\n    width: 100%;\n    height: 100%;\n    position: absolute;\n    top: 0;\n    left: 0;\n    background-repeat: no-repeat;\n    background-position: 50%;\n    background-size: contain;\n}\n\n.DmhzZ {\n    width: 100%;\n    height: 100%;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"svgimage": "XMppU",
	"backgroundimage": "_5l0Iy",
	"svg": "DmhzZ"
};
module.exports = ___CSS_LOADER_EXPORT___;
