import React from 'react'
import createReactClass from 'create-react-class'

import wapplrcomponents from '../../../../components'
import { getPostStatusData } from '../../../server/poststatus/statuses'
import CharliesPlayer from '../../../components/CharliesPlayer'
import chs from '../../../components/CharliesPlayer/CharliesPlayer.css'

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'

import config from '../config'


const Wapplrcontent = wapplrcomponents.content

const lowername = config.name.toLowerCase()

export default createReactClass({
    getInitialState: function() {
        this.refElements = {}
        const { response } = this.props
        const post = this.getPost(response)
        return {
            isMounted: true,
            post: post,
            resx: ('undefined' !== typeof window) ? window.innerWidth : 16,
            resy: ('undefined' !== typeof window) ? window.innerHeight : 9
        }
    },
    getPost: function(response) {
        const post = (response && response['current' + lowername] && response['current' + lowername][0]) ? response['current' + lowername][0] : null
        return post
    },
    getDimensions: function() {
        const d = {
            resx: ('undefined' !== typeof window) ? window.innerWidth : 16,
            resy: ('undefined' !== typeof window) ? window.innerHeight : 9
        }
        const { resx, resy } = this.state
        if (resx !== d.resx || resy !== d.resy) {
            this.setState(d)
        }
    },
    componentDidMount: function() {
        this.getDimensions()
    },
    componentWillUnmount: function() {
    },
    getPostEditLink: function() {
        const post = this.state.post
        if (post && post.id) {
            return '/' + lowername + '/edit/' + post.id
        }
        return ''
    },
    getPostLink: function() {
        const post = this.state.post
        if (post && post.id) {
            return '/' + lowername + '/embed/' + post.id
        }
        return ''
    },
    getPostBaseLink: function() {
        const post = this.state.post
        if (post && post.id) {
            return '/' + lowername + '/' + post.id
        }
        return ''
    },
    getHelpers: function() {
        const { helpers } = this.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },
    getGlobalState: function() {
        const { state } = this.getHelpers()
        return state
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    getPostStatus: function() {
        const { curlang } = this.props
        const post = this.state.post
        return getPostStatusData({ post, curlang })
    },

    setRef: function(a, e) {
        const { setRef } = this.props
        this.refElements[a] = e
        if (setRef) setRef(a, e)
    },
    getPaletteProps: function() {

        const {
            paletteenabledtypes,
            paletteopen,
            palettepinned,
            palettepintype,
            paletteposx,
            paletteposy,
            palettedisablemove,
            palettedisableclose,
            paletteforcepinondownbreakpoint,
            paletteforcepinondownismobile,
            paletteforcepinondownistouchable,
            palettedisablcloseonlyoneiconremains,
            palettebg,
            palettepinnedrelative
        } = this.getDefaultData()

        const enabledPaletteTypes = (paletteenabledtypes) ? (typeof paletteenabledtypes == 'string') ? JSON.parse(paletteenabledtypes) : paletteenabledtypes : ['floatingPalette', 'pinnedOnRight', 'pinnedOnDown']
        const open = (paletteopen !== 0)
        const pinned = !!(palettepinned)
        const pintype = (palettepintype === 'down') ? 'down' : 'right'
        const palettePosX = (!isNaN(Number(paletteposx))) ? Number(paletteposx) : 10
        const palettePosY = (!isNaN(Number(paletteposy))) ? Number(paletteposy) : 10
        const disableMove = !!(palettedisablemove)
        const disableClose = !!(palettedisableclose)
        const forcePinOnDownBreakPoint = (!isNaN(Number(paletteforcepinondownbreakpoint))) ? Number(paletteforcepinondownbreakpoint) : 800
        const forcePinOnDownIsMobile = (paletteforcepinondownismobile !== 0)
        const forcePinOnDownIsTouchable = !!(paletteforcepinondownistouchable)
        const disablCloseOnlyOneIconRemains = (palettedisablcloseonlyoneiconremains !== 0)

        const defaultEnabledPaletteTypes = { 'floatingPalette': 0, 'pinnedOnRight': 0, 'pinnedOnDown': 0 }

        Object.keys(defaultEnabledPaletteTypes).map(function(key) {
            if (enabledPaletteTypes.indexOf(key) > -1) defaultEnabledPaletteTypes[key] = 1
        })

        const typeValues = Object.keys(defaultEnabledPaletteTypes).map(function(key) {
            return defaultEnabledPaletteTypes[key]
        })

        const paletteType = typeValues.toString()

        const props = {
            open: open,
            pinned: pinned,
            pintype: pintype,
            palettePosX: palettePosX,
            palettePosY: palettePosY,
            forcePinOnDownBreakPoint: forcePinOnDownBreakPoint,
            disableMove: disableMove,
            disableClose: disableClose,
            forcePinOnDownIsMobile: forcePinOnDownIsMobile,
            forcePinOnDownIsTouchable: forcePinOnDownIsTouchable,
            disablCloseOnlyOneIconRemains: disablCloseOnlyOneIconRemains,
            customStyle: (palettebg) ? {
                backgroundColor: palettebg
            } : null,

            disableChangePin: false,
            disableChangePinType: false,
            pinnedRelative: palettepinnedrelative
        }

        switch (paletteType) {
            case '1,0,0':
                props.disableChangePin = true
                props.pinned = false
                break
            case '0,1,0':
                props.disableChangePin = true
                props.disableChangePinType = true
                props.pinned = true
                props.pintype = 'right'
                break
            case '0,0,1':
                props.disableChangePin = true
                props.disableChangePinType = true
                props.pinned = true
                props.pintype = 'down'
                break
            case '1,1,0':
                props.disableChangePinType = true
                props.pintype = 'right'
                break
            case '1,0,1':
                props.disableChangePinType = true
                props.pintype = 'down'
                break
            case '0,1,1':
                props.disableChangePin = true
                props.pinned = true
                break
            default:

        }

        return props

    },
    getTranslations: function() {
        let { translations } = this.getDefaultData()
        if (typeof translations == 'string') {
            try {
                translations = translations.replace(/\n/g, '')
                translations = translations.replace(/'/g, '"')
                translations = JSON.parse(translations)
                return translations
            } catch (err) {
                //console.log(err);
                return null
            }
        }
        return null
    },
    getAddMenus: function() {
        let { addmenus } = this.getDefaultData()
        if (typeof addmenus == 'string') {
            try {
                addmenus = JSON.parse(addmenus)
                return addmenus
            } catch (err) {
                //console.log(err);
                return undefined
            }
        }
        return undefined
    },
    getDefaultData: function() {

        const r = { ...this.props }
        const { template } = r

        if (template === 'design1') {

            if (r['height'] === undefined) r['height'] = '100%'
            if (r['appClassName'] === undefined) r['appClassName'] = 'appembed'
            if (r['autoheight'] === undefined) r['autoheight'] = true
            if (r['enableshareobjectwithhash'] === undefined) r['enableshareobjectwithhash'] = '1'
            if (r['enableshareembeddedlocation'] === undefined) r['enableshareembeddedlocation'] = '1'
            if (r['enablega'] === undefined) r['enablega'] = '1'
            if (r['id'] === undefined) r['id'] = 'flatchooser-unique-id'
            if (r['enableclicktosoldobject'] === undefined) r['enableclicktosoldobject'] = 0

            if (r['splitcontainer'] === undefined) r['splitcontainer'] = '1'

        }

        if (template === 'design2') {

            if (r['height'] === undefined) r['height'] = '100%'
            if (r['appClassName'] === undefined) r['appClassName'] = 'appembed'
            if (r['autoheight'] === undefined) r['autoheight'] = true
            if (r['disabledescription'] === undefined) r['disabledescription'] = '1'
            if (r['disableshare'] === undefined) r['disableshare'] = '1'
            if (r['disablefullscreen'] === undefined) r['disablefullscreen'] = 0
            if (r['headertypeatplayer'] === undefined) r['headertypeatplayer'] = 'fullpagewithheader'
            if (r['alignitems'] === undefined) r['alignitems'] = 'start'
            if (r['headertitlecolor'] === undefined) r['headertitlecolor'] = 'rgba(255,255,255,1)'
            if (r['headerlinkscolor'] === undefined) r['headerlinkscolor'] = 'rgba(255,255,255,1)'
            if (r['headerbgcolor'] === undefined) r['headerbgcolor'] = 'rgba(0,0,0,1)'
            if (r['headericoncolor'] === undefined) r['headericoncolor'] = 'rgba(255,255,255,1)'
            if (r['headericonsize'] === undefined) r['headericonsize'] = '14px'
            if (r['breadcrumbstyle'] === undefined) r['breadcrumbstyle'] = 'border'
            if (r['bgcolor'] === undefined) r['bgcolor'] = 'rgba(245, 246, 247,0)'
            if (r['layoutpadding'] === undefined) r['layoutpadding'] = '4px'
            if (r['enableshareobjectwithhash'] === undefined) r['enableshareobjectwithhash'] = '1'
            if (r['enableshareembeddedlocation'] === undefined) r['enableshareembeddedlocation'] = '1'
            if (r['enablega'] === undefined) r['enablega'] = 1
            if (r['id'] === undefined) r['id'] = 'flatchooser-unique-id'
            if (r['enableclosebox'] === undefined) r['enableclosebox'] = '1'
            if (r['closeboxicon'] === undefined) r['closeboxicon'] = 'plusminus'
            if (r['showboxestitle'] === undefined) r['showboxestitle'] = '1'
            if (r['enableboxstyle'] === undefined) r['enableboxstyle'] = '1'
            if (r['boxcontpadding'] === undefined) r['boxcontpadding'] = '4px'
            if (r['enablefilter'] === undefined) r['enablefilter'] = '1'
            if (r['enablesharebox'] === undefined) r['enablesharebox'] = '1'
            if (r['enablepreviouslybox'] === undefined) r['enablepreviouslybox'] = '1'
            if (r['enableplayerboxstyle'] === undefined) r['enableplayerboxstyle'] = '1'
            if (r['enablecontrolstoplayerheader'] === undefined) r['enablecontrolstoplayerheader'] = '1'
            if (r['paletteenabledtypes'] === undefined) r['paletteenabledtypes'] = '["pinnedOnRight"]'
            if (r['paletteopen'] === undefined) r['paletteopen'] = '1'
            if (r['palettepinned'] === undefined) r['palettepinned'] = '1'
            if (r['palettepintype'] === undefined) r['palettepintype'] = 'right'
            if (r['paletteposx'] === undefined) r['paletteposx'] = '-10'
            if (r['paletteposy'] === undefined) r['paletteposy'] = '10'
            if (r['palettedisablemove'] === undefined) r['palettedisablemove'] = '1'
            if (r['palettedisableclose'] === undefined) r['palettedisableclose'] = '1'
            if (r['paletteforcepinondownbreakpoint'] === undefined) r['paletteforcepinondownbreakpoint'] = '800'
            if (r['paletteforcepinondownistouchable'] === undefined) r['paletteforcepinondownistouchable'] = 0
            if (r['paletteforcepinondownismobile'] === undefined) r['paletteforcepinondownismobile'] = '1'
            if (r['palettedisablcloseonlyoneiconremains'] === undefined) r['palettedisablcloseonlyoneiconremains'] = '1'
            if (r['palettepinnedrelative'] === undefined) r['palettepinnedrelative'] = 1
            if (r['prevnextnavposition'] === undefined) r['prevnextnavposition'] = 'top'
            if (r['tooltipscale'] === undefined) r['tooltipscale'] = 1
            if (r['prevnextbgcolor'] === undefined) r['prevnextbgcolor'] = 'rgba(235,235,235,1)'
            if (r['liststatuscolorwidth'] === undefined) r['liststatuscolorwidth'] = '10px'

            /*if (r["webfontloaderfamilies"] == undefined) r["webfontloaderfamilies"]=0;
            if (r["webfontloadercustom"] == undefined) r["webfontloadercustom"]='["Domaine Display Regular"]';
            if (r["webfontloaderurls"] == undefined) r["webfontloaderurls"]='["https://app-flatchooser.reeltime.no/demo/fonts/domaine_display/style.css"]';
            if (r["forcefontfamily"] == undefined) r["forcefontfamily"]="Domaine Display Regular";*/

            if (r['webfontloaderfamilies'] === undefined) r['webfontloaderfamilies'] = 0
            if (r['webfontloadercustom'] === undefined) r['webfontloadercustom'] = '["Proxima Nova"]'
            if (r['webfontloaderurls'] === undefined) r['webfontloaderurls'] = '["https://app-flatchooser.reeltime.no/demo/fonts/proxima_nova/style.css"]'
            if (r['forcefontfamily'] === undefined) r['forcefontfamily'] = 'Proxima Nova'

            /*if (r["webfontloaderfamilies"] == undefined) r["webfontloaderfamilies"]='["Montserrat"]';
            if (r["webfontloadercustom"] == undefined) r["webfontloadercustom"]=0;
            if (r["webfontloaderurls"] == undefined) r["webfontloaderurls"]=0;
            if (r["forcefontfamily"] == undefined) r["forcefontfamily"]="Montserrat";*/

            /*if (r["webfontloaderfamilies"] == undefined) r["webfontloaderfamilies"]='["Montserrat"]';
            if (r["webfontloadercustom"] == undefined) r["webfontloadercustom"]=0;
            if (r["webfontloaderurls"] == undefined) r["webfontloaderurls"]=0;
            if (r["forcefontfamily"] == undefined) r["forcefontfamily"]="Montserrat";*/

            /*if (r["addmenus"] == undefined) r["addmenus"] = JSON.stringify([
             {name: "Back", onClick: "historyBack", lefticon: "BackIcon", justicon: true},
             {name: "Home", link: "https://privatmegleren.no/"},
             {name: "Price list", link: "https://privatmegleren.no/"},
             {name: "Contact", link: "https://privatmegleren.no/"}
             ]);*/

            /*if (r["logostyle"] == undefined) r["logostyle"] = JSON.stringify({
                backgroundImage: "url('"+"https://app-flatchooser.carco.hu/demo/demo_logo.jpg"+"')",
                width: "250px"
            });*/

            if (r['splitcontainer'] === undefined) r['splitcontainer'] = '1'

        }

        if (template === 'design3') {

            if (r['height'] === undefined) r['height'] = '100%'
            if (r['appClassName'] === undefined) r['appClassName'] = 'appembed'
            if (r['autoheight'] === undefined) r['autoheight'] = true
            if (r['disabledescription'] === undefined) r['disabledescription'] = '1'
            if (r['disableshare'] === undefined) r['disableshare'] = '1'
            if (r['disablefullscreen'] === undefined) r['disablefullscreen'] = 0
            if (r['headertypeatplayer'] === undefined) r['headertypeatplayer'] = 'fullpagewithheader'
            if (r['alignitems'] === undefined) r['alignitems'] = 'start'
            if (r['headertitlecolor'] === undefined) r['headertitlecolor'] = 'rgba(255,255,255,1)'
            if (r['headerlinkscolor'] === undefined) r['headerlinkscolor'] = 'rgba(255,255,255,1)'
            if (r['headerbgcolor'] === undefined) r['headerbgcolor'] = 'rgba(0,0,0,1)'
            if (r['headericoncolor'] === undefined) r['headericoncolor'] = 'rgba(255,255,255,1)'
            if (r['headericonsize'] === undefined) r['headericonsize'] = '14px'
            if (r['breadcrumbstyle'] === undefined) r['breadcrumbstyle'] = 'border'
            if (r['bgcolor'] === undefined) r['bgcolor'] = 'rgba(245, 246, 247,0)'
            if (r['layouttype'] === undefined) r['layouttype'] = 'page'
            if (r['layoutpadding'] === undefined) r['layoutpadding'] = '4px'
            if (r['sidebarwidth'] === undefined) r['sidebarwidth'] = '280px'
            if (r['enableshareobjectwithhash'] === undefined) r['enableshareobjectwithhash'] = '1'
            if (r['enableshareembeddedlocation'] === undefined) r['enableshareembeddedlocation'] = '1'
            if (r['enablega'] === undefined) r['enablega'] = 1
            if (r['id'] === undefined) r['id'] = 'flatchooser-unique-id'
            if (r['enableclosebox'] === undefined) r['enableclosebox'] = '1'
            if (r['closeboxicon'] === undefined) r['closeboxicon'] = 'plusminus'
            if (r['showboxestitle'] === undefined) r['showboxestitle'] = '1'
            if (r['enableboxstyle'] === undefined) r['enableboxstyle'] = '1'
            if (r['boxcontpadding'] === undefined) r['boxcontpadding'] = '4px'
            if (r['enablefilter'] === undefined) r['enablefilter'] = '1'
            if (r['enablesharebox'] === undefined) r['enablesharebox'] = '1'
            if (r['enablepreviouslybox'] === undefined) r['enablepreviouslybox'] = '1'
            if (r['enableplayerboxstyle'] === undefined) r['enableplayerboxstyle'] = '1'
            if (r['enablecontrolstoplayerheader'] === undefined) r['enablecontrolstoplayerheader'] = '1'
            if (r['palettebg'] === undefined) r['palettebg'] = 'transparent'
            if (r['paletteenabledtypes'] === undefined) r['paletteenabledtypes'] = '["pinnedOnRight"]'
            if (r['paletteopen'] === undefined) r['paletteopen'] = '1'
            if (r['palettepinned'] === undefined) r['palettepinned'] = '1'
            if (r['palettepintype'] === undefined) r['palettepintype'] = 'right'
            if (r['paletteposx'] === undefined) r['paletteposx'] = '-10'
            if (r['paletteposy'] === undefined) r['paletteposy'] = '10'
            if (r['palettedisablemove'] === undefined) r['palettedisablemove'] = '1'
            if (r['palettedisableclose'] === undefined) r['palettedisableclose'] = '1'
            if (r['paletteforcepinondownbreakpoint'] === undefined) r['paletteforcepinondownbreakpoint'] = '800'
            if (r['paletteforcepinondownistouchable'] === undefined) r['paletteforcepinondownistouchable'] = 0
            if (r['paletteforcepinondownismobile'] === undefined) r['paletteforcepinondownismobile'] = '1'
            if (r['palettedisablcloseonlyoneiconremains'] === undefined) r['palettedisablcloseonlyoneiconremains'] = '1'
            if (r['palettepinnedrelative'] === undefined) r['palettepinnedrelative'] = 0
            if (r['prevnextnavposition'] === undefined) r['prevnextnavposition'] = 'top'
            if (r['tooltipscale'] === undefined) r['tooltipscale'] = 1
            if (r['prevnextbgcolor'] === undefined) r['prevnextbgcolor'] = 'rgba(235,235,235,1)'
            if (r['liststatuscolorwidth'] === undefined) r['liststatuscolorwidth'] = '10px'

            /*if (r["webfontloaderfamilies"] == undefined) r["webfontloaderfamilies"]=0;
             if (r["webfontloadercustom"] == undefined) r["webfontloadercustom"]='["Domaine Display Regular"]';
             if (r["webfontloaderurls"] == undefined) r["webfontloaderurls"]='["https://app-flatchooser.reeltime.no/demo/fonts/domaine_display/style.css"]';
             if (r["forcefontfamily"] == undefined) r["forcefontfamily"]="Domaine Display Regular";*/

            if (r['webfontloaderfamilies'] === undefined) r['webfontloaderfamilies'] = 0
            if (r['webfontloadercustom'] === undefined) r['webfontloadercustom'] = '["Proxima Nova"]'
            if (r['webfontloaderurls'] === undefined) r['webfontloaderurls'] = '["https://app-flatchooser.reeltime.no/demo/fonts/proxima_nova/style.css"]'
            if (r['forcefontfamily'] === undefined) r['forcefontfamily'] = 'Proxima Nova'

            /*if (r["webfontloaderfamilies"] == undefined) r["webfontloaderfamilies"]='["Montserrat"]';
             if (r["webfontloadercustom"] == undefined) r["webfontloadercustom"]=0;
             if (r["webfontloaderurls"] == undefined) r["webfontloaderurls"]=0;
             if (r["forcefontfamily"] == undefined) r["forcefontfamily"]="Montserrat";*/

            /*if (r["webfontloaderfamilies"] == undefined) r["webfontloaderfamilies"]='["Montserrat"]';
             if (r["webfontloadercustom"] == undefined) r["webfontloadercustom"]=0;
             if (r["webfontloaderurls"] == undefined) r["webfontloaderurls"]=0;
             if (r["forcefontfamily"] == undefined) r["forcefontfamily"]="Montserrat";*/

            /*if (r["addmenus"] == undefined) r["addmenus"] = JSON.stringify([
             {name: "Back", onClick: "historyBack", lefticon: "BackIcon", justicon: true},
             {name: "Home", link: "https://privatmegleren.no/"},
             {name: "Price list", link: "https://privatmegleren.no/"},
             {name: "Contact", link: "https://privatmegleren.no/"}
             ]);*/

            /*if (r["logostyle"] == undefined) r["logostyle"] = JSON.stringify({
             backgroundImage: "url('"+"https://app-flatchooser.carco.hu/demo/demo_logo.jpg"+"')",
             width: "250px"
             });*/

        }

        if (template === 'design4') {

            if (r['height'] === undefined) r['height'] = '100%'
            if (r['appClassName'] === undefined) r['appClassName'] = 'appembed'
            if (r['autoheight'] === undefined) r['autoheight'] = true
            if (r['disabledescription'] === undefined) r['disabledescription'] = '1'
            if (r['disableshare'] === undefined) r['disableshare'] = '1'
            if (r['disablefullscreen'] === undefined) r['disablefullscreen'] = 0
            if (r['headertypeatplayer'] === undefined) r['headertypeatplayer'] = 'fullpagewithheader'
            if (r['alignitems'] === undefined) r['alignitems'] = 'start'
            if (r['headertitlecolor'] === undefined) r['headertitlecolor'] = 'rgba(0,54,75,1)'
            if (r['headerlinkscolor'] === undefined) r['headerlinkscolor'] = 'rgba(0,54,75,1)'
            if (r['headerbgcolor'] === undefined) r['headerbgcolor'] = 'rgba(192,225,232,1)'
            if (r['headericoncolor'] === undefined) r['headericoncolor'] = 'rgba(0,54,75,1)'
            if (r['headericonsize'] === undefined) r['headericonsize'] = '14px'
            if (r['customcss'] === undefined) {
                r['customcss'] = JSON.stringify({
                    'header': {
                        ' @media (min-width: 800px) {': '',
                        '  .appbar': 'height:170px!important;',
                        ' }': '',
                        '.appbar': 'display: flex; align-items: center; justify-content: center;',
                        '.appbar > div': 'text-transform: capitalize!important; letter-spacing: 0.036em!important; font-size: 16px!important;',
                        '.navigation > a': 'text-transform: capitalize!important; letter-spacing: 0.036em!important; font-size: 16px!important;'
                    },
                    'player': {
                        '.playercomponent > div': 'letter-spacing: 0.036em!important; font-size: 16px!important;',
                        '.playercomponent table, .playercomponent thead, .playercomponent tr': 'border-bottom: 1px solid rgb(255, 255, 255, 0)!important;',
                        '      @media (min-width: 800px) {': '',
                        '       .tablevalue': 'height: 92px!important;',
                        '      }': '',
                        '.breadstep': 'letter-spacing: 0.036em!important; font-size: 16px!important;',
                        '.breadcrumb div.breadstep:first-child > span': 'text-transform: capitalize!important; font-weight:bold!important; font-size: 19px!important;',
                        '.breadcrumb div.breadstep:first-child > span:before': 'content: ""',
                        '.breadcrumb div.breadstep:nth-child(3) > span:before': 'content: ""',
                        '.breadcrumb div.breadstep:nth-child(3)': 'margin-left:auto!important; padding-left: 96px;',
                        '.breadcrumb div.breadstep:last-child': 'margin-right:auto!important;',
                        '.playercomponent.flat > div:nth-child(2)': 'background-color:#00364b!important;',
                        '.infocontainer.flat table': 'background-color:#00364b!important;',
                        '.infocontainer.flat .popupbody tr > td:nth-child(2)': 'text-align: right!important;',
                        '.infocontainer.flat tr, .infocontainer.flat tr a': 'color:#ffffff!important;',
                        '.infocontainer.flat .popup': 'padding: 80px; background-color: #00364b;',
                        '.infocontainer.flat .prevnextrow': 'background-color: #00364b!important;',
                        '.infocontainer.flat .popupbody': 'border-bottom-style: none;',
                        '.infocontainer.flat svg': 'color:#ffffff!important; fill:#ffffff!important;',
                        '.infocontainer.flat tr a': 'border-style: solid!important; border-width: 1px!important; height: 46px!important; line-height: 46px!important;width: 100%;',
                        '.infocontainer.flat tr a span': 'text-transform: none!important;',
                        '.infocontainer.flat .popupheader': 'background-color: transparent!important; border-bottom: 1px solid rgb(255 255 255 / 10%)!important;',
                        '.infocontainer.flat .popupheader .prevnextrow': 'background-color: transparent!important;',

                        '.playerprevnextcont': 'background-color: #00364b!important;',
                        '.playerprevnextcontabsolute .compasscolumncontainer': 'background-color: #00364b!important;',
                        '.playerprevnextcont div': 'color: #ffffff!important;',
                        '.playerprevnextcont svg': 'color:#ffffff!important; fill:#ffffff!important;',
                        '.playerprevnextcont .popupbody tr > td:nth-child(2)': 'text-align: right!important;',
                        '.playerprevnextcont .popupbody': 'border-bottom-style: none;',
                        '.playerprevnextcont tr, .playerprevnextcont tr a': 'color:#ffffff!important;',
                        '.playerprevnextcont .popupheader': 'background-color: transparent!important; border-bottom: 1px solid rgb(255 255 255 / 10%)!important;',
                        '.playerprevnextcont .popupheader .prevnextrow': 'background-color: transparent!important;',

                        '.filterinnertitle': 'display:none;',
                        '@media (max-width: 1366px) {': '',
                        ' .infocontainer.flat .popup': 'padding: 32px;',
                        '}': '',
                        ' @media (max-width: 1080px) {': '',
                        '  .infocontainer.flat .popup': 'padding: 20px;',
                        ' }': '',
                        '  @media (min-width: 810px) {': '',
                        '   .playercomponent > div:nth-child(1)': 'max-width:calc(100% - 360px)!important',
                        '   .playercomponent > div:nth-child(2)': 'width: 360px!important',
                        '  }': '',
                        '   @media (min-width: 1080px) {': '',
                        '    .playercomponent > div:nth-child(1)': 'max-width:calc(100% - 420px)!important',
                        '    .playercomponent > div:nth-child(2)': 'width: 420px!important',
                        '   }': '',
                        '    @media (min-width: 1240px) {': '',
                        '     .playercomponent > div:nth-child(1)': 'max-width:calc(100% - 530px)!important',
                        '     .playercomponent > div:nth-child(2)': 'width: 530px!important',
                        '    }': ''
                    },
                    'app': {
                        ' @media (min-width: 800px) {': '',
                        '  .page-padding': 'margin-top: -170px;!important',
                        '  .fullpagewithoutpaddingswithheader': 'padding-top: 170px!important;',
                        ' }': ''
                    }
                })
            }
            if (r['breadcrumbstyle'] === undefined) r['breadcrumbstyle'] = 'slash'
            if (r['bgcolor'] === undefined) r['bgcolor'] = 'rgba(245, 246, 247,0)'
            if (r['playerbgcolor'] === undefined) r['playerbgcolor'] = 'rgba(192,225,232,1)'
            //if (r["layouttype"] == undefined) r["layouttype"]="page";
            if (r['layoutpadding'] === undefined) r['layoutpadding'] = '0px'
            if (r['sidebarwidth'] === undefined) r['sidebarwidth'] = '280px'
            if (r['enableshareobjectwithhash'] === undefined) r['enableshareobjectwithhash'] = '1'
            if (r['enableshareembeddedlocation'] === undefined) r['enableshareembeddedlocation'] = '1'
            if (r['enablega'] === undefined) r['enablega'] = 1
            if (r['id'] === undefined) r['id'] = 'flatchooser-unique-id'
            if (r['enableclosebox'] === undefined) r['enableclosebox'] = '1'
            if (r['closeboxicon'] === undefined) r['closeboxicon'] = '0'
            if (r['showboxestitle'] === undefined) r['showboxestitle'] = JSON.stringify(['filter', 'previously', 'share'])
            if (r['enableboxstyle'] === undefined) r['enableboxstyle'] = 0
            if (r['boxcontpadding'] === undefined) r['boxcontpadding'] = '0px'
            if (r['enablefilter'] === undefined) r['enablefilter'] = '1'
            if (r['enablesharebox'] === undefined) r['enablesharebox'] = 0
            if (r['enablepreviouslybox'] === undefined) r['enablepreviouslybox'] = 0
            if (r['boxesorder'] === undefined) r['boxesorder'] = JSON.stringify(['filter', 'info', 'previously', 'share'])
            if (r['enableplayerboxstyle'] === undefined) r['enableplayerboxstyle'] = 0
            if (r['enablecontrolstoplayerheader'] === undefined) r['enablecontrolstoplayerheader'] = 0
            if (r['disableopentoggleicon'] === undefined) r['disableopentoggleicon'] = '1'
            if (r['palettebg'] === undefined) r['palettebg'] = 'rgba(255,255,255,1)'
            if (r['paletteenabledtypes'] === undefined) r['paletteenabledtypes'] = '["pinnedOnRight"]'
            if (r['paletteopen'] === undefined) r['paletteopen'] = '1'
            if (r['palettepinned'] === undefined) r['palettepinned'] = '1'
            if (r['palettepintype'] === undefined) r['palettepintype'] = 'right'
            if (r['paletteposx'] === undefined) r['paletteposx'] = '-10'
            if (r['paletteposy'] === undefined) r['paletteposy'] = '10'
            if (r['palettedisablemove'] === undefined) r['palettedisablemove'] = '1'
            if (r['palettedisableclose'] === undefined) r['palettedisableclose'] = '1'
            if (r['paletteforcepinondownbreakpoint'] === undefined) r['paletteforcepinondownbreakpoint'] = '800'
            if (r['paletteforcepinondownistouchable'] === undefined) r['paletteforcepinondownistouchable'] = 0
            if (r['paletteforcepinondownismobile'] === undefined) r['paletteforcepinondownismobile'] = '1'
            if (r['palettedisablcloseonlyoneiconremains'] === undefined) r['palettedisablcloseonlyoneiconremains'] = '1'
            if (r['palettepinnedrelative'] === undefined) r['palettepinnedrelative'] = 0
            if (r['prevnextnavposition'] === undefined) r['prevnextnavposition'] = JSON.stringify({
                'type': {
                    'flat': 'topinpopupheader',
                    'building': 'topinpopupheader'
                }
            })
            if (r['prevnextnavhidetitle'] === undefined) r['prevnextnavhidetitle'] = '1'
            if (r['tooltipscale'] === undefined) r['tooltipscale'] = 1
            if (r['prevnextbgcolor'] === undefined) r['prevnextbgcolor'] = 'transparent'
            if (r['liststatuscolorwidth'] === undefined) r['liststatuscolorwidth'] = '0px'
            if (r['infoheaderposition'] === undefined) r['infoheaderposition'] = JSON.stringify({
                'type': {
                    'area': 'stage',
                    'building': 'stage'
                }
            })
            if (r['compassposition'] === undefined) r['compassposition'] = JSON.stringify({
                'type': {
                    'area': 'stage',
                    'building': 'stage'
                }
            })

            /*if (r["webfontloaderfamilies"] == undefined) r["webfontloaderfamilies"]=0;
             if (r["webfontloadercustom"] == undefined) r["webfontloadercustom"]='["Domaine Display Regular"]';
             if (r["webfontloaderurls"] == undefined) r["webfontloaderurls"]='["https://app-flatchooser.reeltime.no/demo/fonts/domaine_display/style.css"]';
             if (r["forcefontfamily"] == undefined) r["forcefontfamily"]="Domaine Display Regular";*/

            if (r['webfontloaderfamilies'] === undefined) r['webfontloaderfamilies'] = 0
            if (r['webfontloadercustom'] === undefined) r['webfontloadercustom'] = '["Avenir Medium"]'
            if (r['webfontloaderurls'] === undefined) r['webfontloaderurls'] = '["https://app-flatchooser.reeltime.no/demo/fonts/avenir_medium/style.css"]'
            if (r['forcefontfamily'] === undefined) r['forcefontfamily'] = 'Avenir Medium, Arial'

            /*if (r["webfontloaderfamilies"] == undefined) r["webfontloaderfamilies"]='["Montserrat"]';
             if (r["webfontloadercustom"] == undefined) r["webfontloadercustom"]=0;
             if (r["webfontloaderurls"] == undefined) r["webfontloaderurls"]=0;
             if (r["forcefontfamily"] == undefined) r["forcefontfamily"]="Montserrat";*/

            /*if (r["webfontloaderfamilies"] == undefined) r["webfontloaderfamilies"]='["Montserrat"]';
             if (r["webfontloadercustom"] == undefined) r["webfontloadercustom"]=0;
             if (r["webfontloaderurls"] == undefined) r["webfontloaderurls"]=0;
             if (r["forcefontfamily"] == undefined) r["forcefontfamily"]="Montserrat";*/

            if (r['addmenus'] === undefined) r['addmenus'] = JSON.stringify([
                { name: 'About', link: 'https://privatmegleren.no/' },
                { name: 'Price', link: 'https://privatmegleren.no/' },
                { name: 'Contact us', link: 'https://privatmegleren.no/' }
            ])

            /*if (r["logostyle"] == undefined) r["logostyle"] = JSON.stringify({
             backgroundImage: "url('"+"https://app-flatchooser.carco.hu/demo/demo_logo.jpg"+"')",
             width: "250px"
             });*/

            if (r['splitcontainer'] === undefined) r['splitcontainer'] = '1'

        }

        return r

    },
    render: function() {

        const {
            height = '100vh',
            appClassName = 'app',
            autoheight,
            splitcontainer,
            playerratio,
            disabledescription,
            disableshare,
            disablefullscreen,
            headertypeatplayer,
            alignitems,
            headertitlecolor,
            headerlinkscolor,
            headerbgcolor,
            headericoncolor,
            headericonsize,
            disableopentoggleicon,
            customcss,
            breadcrumbstyle,
            bgcolor,
            playerbgcolor,
            enableshareobjectwithhash,
            enableshareembeddedlocation,
            enablega,
            id,
            enableclosebox,
            closeboxicon,
            showboxestitle,
            enableboxstyle,
            boxcontpadding,
            enablefilter,
            enablesharebox,
            enablepreviouslybox,
            enableplayerboxstyle,
            boxesorder,
            enablecontrolstoplayerheader,
            layoutpadding,
            layouttype,
            sidebarwidth,
            enableclicktosoldobject,
            prevnextnavposition,
            prevnextnavhidetitle,
            tooltipscale,
            prevnextbgcolor,
            infoheaderposition,
            compassposition,
            logostyle,
            logoimage,
            logowidth,
            currentviewid,
            liststatuscolorwidth,
            webfontloaderfamilies,
            webfontloadercustom,
            webfontloaderurls,
            forcefontfamily
        } = this.getDefaultData()

        const {
            history,
            style,
            curlang,
            flatchooserapp,
            siteurl = '',
            muiTheme,
            avatarUrlTargetBlank = false,
            staticscript = '/index.js',
            staticserver = '',
            googleTrackingId,
            readycallback
        } = this.props

        const post = this.state.post

        const globalstate = this.getGlobalState()
        const state = {
            ...globalstate,
            response: (globalstate.response) ? {
                ...globalstate.response,
                currentpost: globalstate.response.currentplayer
            } : {}
        }
        const postbaselink = this.getPostBaseLink()
        const postlink = this.getPostLink()



        const protocol = 'https:'
        const staticscripturl = (staticserver && staticscript) ? protocol + '//' + staticserver + staticscript : null
        const paletteProps = this.getPaletteProps()
        const translations = this.getTranslations()
        const addmenus = this.getAddMenus()

        return (
            <MuiThemeProvider muiTheme={getMuiTheme(muiTheme)}>
                <Wapplrcontent style={style}>
                    <div className={style[appClassName]}>
                        <CharliesPlayer
                            style={chs}
                            containerStyle={{ height: height }}
                            app={flatchooserapp}
                            state={state}
                            data={post && post.appjson}
                            parentroute={postlink}
                            curlang={curlang}
                            history={history}
                            disablehistory={true}

                            customprops={{
                                id,
                                logohref: postbaselink,
                                posturl: siteurl + postbaselink,
                                staticscripturl: staticscripturl,
                                postmedia: siteurl + post.cover,
                                enableshareobjectwithhash,
                                enableshareembeddedlocation,
                                locationhref: (typeof window !== 'undefined') ? window.location.href : null,
                                enablega: (enablega) ? googleTrackingId : false,

                                autoheight: (headertypeatplayer === 'video') ? autoheight : true,
                                setfixheight: (!(autoheight) || (height && height.match('px')) || (height && height.match('vh'))),
                                playerratio,
                                splitcontainer,
                                avatarUrlTargetBlank,

                                headertypeatplayer,
                                alignitems,
                                headertitlecolor,
                                headerlinkscolor,
                                headerbgcolor,
                                headericoncolor,
                                headericonsize,
                                disableopentoggleicon,
                                customcss,
                                breadcrumbstyle,
                                bgcolor,
                                playerbgcolor,
                                disabledescription,
                                disableshare,
                                disablefullscreen,

                                layoutpadding,
                                layouttype,
                                sidebarwidth,

                                enableclosebox,
                                closeboxicon,
                                showboxestitle,
                                enableboxstyle,
                                boxcontpadding,
                                enablefilter,
                                enablesharebox,
                                enablepreviouslybox,
                                boxesorder,
                                enableplayerboxstyle,
                                enablecontrolstoplayerheader,
                                addmenus: addmenus,
                                enableclicktosoldobject: enableclicktosoldobject,
                                prevnextnavposition: prevnextnavposition,
                                prevnextnavhidetitle: prevnextnavhidetitle,
                                tooltipscale: tooltipscale,
                                prevnextbgcolor: prevnextbgcolor,
                                infoheaderposition: infoheaderposition,
                                compassposition: compassposition,
                                logostyle: logostyle,
                                logoimage: logoimage,
                                logowidth: logowidth,
                                currentviewid: currentviewid,

                                liststatuscolorwidth: liststatuscolorwidth,

                                paletteprops: paletteProps,
                                translations: translations,

                                readycallback,

                                webfontloaderfamilies,
                                webfontloadercustom,
                                webfontloaderurls,
                                forcefontfamily
                            }}
                        />
                    </div>
                </Wapplrcontent>
            </MuiThemeProvider>
        )
    }
})

