import React from 'react'
import createReactClass from 'create-react-class'

export default function(Component) {
    const WrapperComponent = createReactClass({
        getInitialState: function() {
            const { isValid } = this.props

            const validData = isValid(this.props.value)

            return {
                value: this.props.value,
                isValid: validData.isValid,
                validationError: (validData.errorMessage) ? [validData.errorMessage] : [],
                externalError: []
            }
        },
        isChanged: function(a, b) {
            let changed = false
            const isChanged = this.isChanged
            if (a && b && typeof a == 'object' && typeof b == 'object') {
                const keys = (a.length == undefined) ? Object.keys(a) : [...a.keys()]
                keys.map(function(key) {
                    if (typeof a[key] !== typeof b[key]) changed = true
                    if (!changed) {
                        if (a[key] && typeof a[key] == 'object') {
                            changed = isChanged(a[key], b[key])
                        } else {
                            if (a[key] !== b[key]) changed = true
                        }
                    }
                })
            } else {
                if (typeof a !== typeof b) changed = true
                if (a !== b) changed = true
            }
            return changed
        },
        shouldComponentUpdate: function(nextProps, nextState) {
            const state = this.state
            const changed1 = this.isChanged(state, nextState)
            const changed2 = (changed1) ? changed1 : this.isChanged(nextState, state)
            if (changed1 || changed2) {
                return true
            }
            return false
        },
        componentDidMount: function() {
            const isValid = this.isValid()
            if (!isValid) {
                this.isValidSetToParent()
            }
        },

        isValidSetToParent: function(setValidFromOutside) {
            const isValid = (setValidFromOutside !== undefined) ? setValidFromOutside : this.isValid()
            const { parent } = this.props
            if (parent && parent.setIsValid) parent.setIsValid(isValid)
        },
        getValue: function() {
            return this.state.value
        },
        setValue: async function(newValue) {
            const state = this.state
            const { isValid } = this.props
            const validData = isValid(newValue)

            const nextState = {
                ...state,
                value: newValue,
                isValid: validData.isValid,
                validationError: (validData.errorMessage) ? [validData.errorMessage] : []
            }

            const changed1 = this.isChanged(state, nextState)
            const changed2 = (changed1) ? changed1 : this.isChanged(nextState, state)
            if (changed1 || changed2) {
                nextState.externalError = []
                await this.setState(nextState)
                await this.isValidSetToParent(validData.isValid)
            }
        },
        setExternalError: async function(p = {}) {
            const { error = '' } = p
            const value = (p.value !== undefined) ? p.value : this.state.value
            if (error) {
                await this.setState({
                    value: value,
                    isValid: false,
                    externalError: [error]
                })
                this.isValidSetToParent(false)
            }
        },
        isValid: function() {
            return this.state.isValid
        },
        getErrorMessage: function() {
            return this.getErrorMessages()[0]
        },
        getErrorMessages: function() {
            return (this.state.externalError && this.state.externalError[0]) ? this.state.externalError : this.state.validationError
        },
        handleUpdate: function({ e, nextValue, onChange }) {
            if (onChange) {
                const setValue = this.setValue
                onChange({ setValue, value: nextValue })
            } else {
                this.setValue(nextValue)
            }
        },
        updateValue: async function() {
            const { updateValue } = this.props
            if (updateValue) {
                const value = updateValue()
                await this.setValue(value)
            }
        },

        render: function() {

            const {
                validations, // eslint-disable-line no-unused-vars
                validationError, // eslint-disable-line no-unused-vars
                validationErrors, // eslint-disable-line no-unused-vars
                getErrorMessage,  // eslint-disable-line no-unused-vars
                isValid, // eslint-disable-line no-unused-vars
                formdeep,  // eslint-disable-line no-unused-vars
                parent, // eslint-disable-line no-unused-vars
                updateValue, // eslint-disable-line no-unused-vars
                ...rest
            } = this.props

            const formsyWrapper = {
                getValue: this.getValue,
                setValue: this.setValue,
                updateValue: this.updateValue,
                handleUpdate: this.handleUpdate,
                getErrorMessage: this.getErrorMessage,
                required: this.props.required
            }

            return React.createElement(Component, { ...rest, formsyWrapper })
        }
    })

    function getDisplayName(component) {
        return (
            component.displayName ||
            component.name ||
            (typeof component === 'string' ? component : 'Component')
        )
    }

    WrapperComponent.displayName = getDisplayName(Component)

    return WrapperComponent

};
