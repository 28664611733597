// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".v4ceB {\n    position: relative;\n    display: block;\n}\n\n.-\\+wrW {\n    position: absolute;\n    line-height: 22px;\n    top: 38px;\n    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;\n    z-index: 1;\n    transform: scale(0.75) translate(0px, -28px);\n    transform-origin: left top 0px;\n    pointer-events: none;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n            user-select: none;\n    color: rgba(0, 0, 0, 0.3);\n    top: 3px;\n    font-size: 16px;\n}\n\n.JVEGP {\n}\n\n.GD0Jw {\n    position: absolute;\n    line-height: 22px;\n    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;\n    z-index: 1;\n    transform: scale(0.75) translate(0px, -28px);\n    transform-origin: left top 0px;\n    pointer-events: none;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n            user-select: none;\n    color: rgb(63, 69, 74);\n    top: 3px;\n    font-size: 16px;\n    right: 0;\n}\n\n.BiuJ9 {\n    position: relative;\n    bottom: 40px;\n    font-size: 12px;\n    line-height: 12px;\n    color: rgb(244, 67, 54);\n    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slidercontainer": "v4ceB",
	"sliderlabel": "-+wrW",
	"sliderslider": "JVEGP",
	"slidervalue": "GD0Jw",
	"slidererror": "BiuJ9"
};
module.exports = ___CSS_LOADER_EXPORT___;
