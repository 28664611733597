export default function(props = {}) {

    const {
        width = 1920,
        height = 1080,
        id = 'root',
        jsoneditor = null,
        children = [],
        backgroundImage = '',
        backgroundFilters,
        backgroundWidth = 1920,
        backgroundHeight = 1080,
        backgroundStyle
    } = props

    return {
        'component': 'Root',
        'props': {
            'id': id,
            'scale': false,
            'width': width,
            'height': height,
            'customStyle': {
                'backgroundColor': 'rgba(0,0,0,0)'
            },
            'innerClassName': null,
            'enableComponentsAddProps': ['SvgImage', 'Hotspots']
        },
        'children': [
            {
                'component': 'Container',
                'props': {
                    'autosize': 'contain',
                    'scale': false,
                    'width': backgroundWidth,
                    'height': backgroundHeight,
                    'autopositionx': 'center',
                    'autopositiony': 'center',
                    'boundingx': 'center',
                    'boundingy': 'center',
                    'customStyle': {
                        'backgroundColor': 'rgba(0,0,0,0)'
                    },
                    'innerClassName': null,
                    'enableComponentsAddProps': ['SvgImage']
                },
                'children': [
                    {
                        'component': 'SvgImage',
                        'jsoneditor': jsoneditor,
                        'props': {
                            'id': id + '_background',
                            'enableSetDimensions': [
                                { 'id': 'parent', 'keepwidth': false },
                                { 'id': 'root', 'keepwidth': true }
                            ],
                            'image': backgroundImage,
                            'filters': backgroundFilters,
                            'width': backgroundWidth,
                            'height': backgroundHeight,
                            'containerStyle': backgroundStyle
                        }
                    }
                ]
            },
            {
                'component': 'Container',
                'props': {
                    'autosize': 'contain',
                    'scale': true,
                    'relativewidth': '100%',
                    'relativeheight': '100%',
                    'autopositionx': 'center',
                    'autopositiony': 'center',
                    'boundingx': 'center',
                    'boundingy': 'center',
                    'customStyle': {
                        'backgroundColor': 'rgba(0,0,0,0)'
                    },
                    'innerClassName': null,
                    'enableComponentsAddProps': ['Hotspots']
                },
                'children': children
            }
        ]
    }
}
