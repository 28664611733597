import React from 'react'
import createReactClass from 'create-react-class'

import MaterialSlider from 'material-ui/Slider'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

const Slider = createReactClass({
    getInitialState: function() {

        const { min = 0, max = 100, step = 1, value = 0 } = this.props

        return {
            min: min,
            max: max,
            step: step,
            value: value,
            isMounted: true
        }
    },
    handleUpdate: function(e, nextValue = 0) {
        const { onChange } = this.props
        this.setState({
            value: nextValue
        })
        if (onChange) onChange(e, nextValue)
    },
    onBlur: function(e) {
        const { value } = this.state
        const { onBlur } = this.props
        if (onBlur) onBlur(e, value)
    },
    componentWillUnmount: function() {
        const { isMounted } = this.state
        if (isMounted) {
            this.setState({
                isMounted: false
            })
        }
    },
    componentWillUnmount: function() {
    },
    render() {

        const {
            floatingLabelText,  // eslint-disable-line no-unused-vars
            onBlur,  // eslint-disable-line no-unused-vars
            className = '',
            sliderClassName = '',
            labelClassName = '',
            valueClassName = '',
            errorText = '',
            style,
            ...rest
        } = this.props

        const value = this.state.value
        const v = (value) ? value : 0

        const { min, max, step } = this.state

        return (
            <div className={style.slidercontainer + ' ' + className}>
                <label className={style.sliderlabel + ' ' + labelClassName}>
                    {floatingLabelText}
                </label>
                <span className={style.slidervalue + ' ' + valueClassName}>{v}</span>
                <div className={style.sliderslider + ' ' + sliderClassName}>
                    <MaterialSlider
                        {...rest}
                        min={min}
                        max={max}
                        step={step}
                        value={v}
                        onChange={this.handleUpdate}
                        onDragStop={this.onBlur}
                    />
                </div>
                {(errorText) ? <div className={style.slidererror}>{errorText}</div> : null}
            </div>
        )
    }

})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <Slider {...this.props} ref={(setRef) ? function(e) {
                setRef('slider', e)
            } : null} />
        )
    }
})

export default createReactClass({
    displayName: 'Slider',
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})
