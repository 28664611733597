import React from 'react'
import createReactClass from 'create-react-class'
import TemplateDefault from '../templates/template_default'

import { wapplrhelpers } from '../../../../../../../components'
import PostContent from '../components/PostContent'

import { getGA } from './helpers'

const wapplravatarhelpers = wapplrhelpers.avatar

export default createReactClass({
    sendToTracker: function() {
        const post = this.getPost()
        const url = '/player/' + post.id + '/description'
        const ga = new getGA({ ...this.props })
        ga.addGA({
            url: url
        })
    },
    componentDidMount: function() {
        this.sendToTracker()
    },
    getGlobalState: function() {
        const { construct } = this.props
        return (construct.props && construct.props.state) ? construct.props.state : null
    },
    getMe: function() {
        const state = this.getGlobalState()
        const me = (state && state.response && state.response.me) ? state.response.me : null
        if (me) wapplravatarhelpers({ user: me })
        return me
    },
    getUser: function() {
        const post = this.getPost()
        if (post && post.author && post.author.id) {
            const author = post.author
            if (author) wapplravatarhelpers({ user: author })
            return author
        }
        return null
    },
    getPost: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.currentpost && state.response.currentpost[0]) ? state.response.currentpost[0] : null
    },
    getData: function() {
        const { construct } = this.props
        return (construct.props && construct.props.data) ? construct.props.data : null
    },
    getStore: function() {
        const { construct } = this.props
        return (construct.props && construct.props.store) ? construct.props.store : null
    },
    getAvatarUrlTargetBlank: function() {
        const { construct } = this.props
        return (construct.props && construct.props.avatarUrlTargetBlank) ? construct.props.avatarUrlTargetBlank : false
    },

    historyPushCallback: function() {
        const store = this.getStore()
        if (store && store.setState) {
            store.setState({ fullscreen: false })
        }
    },
    getHistory: function() {
        const { originalhistory = {} } = this.props
        const historyPushCallback = this.historyPushCallback
        return {
            ...originalhistory,
            push: function(a, b, c) {
                if (historyPushCallback) historyPushCallback()
                if (originalhistory.push) originalhistory.push(a, b, c)
            }
        }
    },

    render: function() {

        const { style, getthumb, avatarstyle } = this.props
        const me = this.getMe()
        const post = this.getPost()
        const user = this.getUser()
        const data = this.getData()
        const history = this.getHistory()
        const avatarUrlTargetBlank = this.getAvatarUrlTargetBlank()

        return (
            <TemplateDefault {...this.props} containerType={'normal'} containerStyle={{ backgroundColor: '#f5f6f7' }}
                             path={'/description'}>
                {(post && post.id) ?
                    <div>
                        <PostContent
                            post={post}
                            history={history}
                            getthumb={getthumb}
                            avatarstyle={avatarstyle}
                            avatarUrlTargetBlank={avatarUrlTargetBlank}
                        />
                    </div>
                    :
                    null
                }
            </TemplateDefault>
        )
    }
})
