// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._55i5S {\n    width: 100%;\n}\n\n._55i5S > div {\n    width: 100% !important;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "_55i5S"
};
module.exports = ___CSS_LOADER_EXPORT___;
