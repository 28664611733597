import initflatchooser from './module_flatchooser'

export function initmodules(p) {

    const { storedConfig = {} } = p
    const { flatchooserapp, flatchooserfunctions } = initflatchooser(p)

    storedConfig.flatchooserapp = flatchooserapp

    return {
        flatchooserapp,
        flatchooserfunctions
    }

}
