import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

const CompanyData = createReactClass({
    getInitialState: function() {
        const { post, data } = this.props
        return {
            post: post,
            data: data,
            isMounted: false
        }
    },
    componentDidMount: function() {
        this.setState({
            isMounted: true
        })
    },
    componentWillUnmount: function() {
        this.setState({
            isMounted: false
        })
    },
    render: function() {

        const { style } = this.props
        const post = this.state.post
        const datas = this.state.data

        return (
            <div className={(post && datas) ? style.container : null}>
                {(post && datas) ?
                    datas.map(function(d, i) {

                        if (post) {
                            let value = null
                            if (typeof d.value == 'function') {
                                value = d.value({ post: post, style })
                            } else {
                                value = post[d.value]
                            }
                            if (value) {
                                const vs = (d.valuestyle) ? d.valuestyle : {}
                                const inserttype = (d.insterttype) ? d.insterttype : null
                                const valuestyle = (d.icon || d.name) ? { paddingLeft: '48px', ...vs } : vs
                                if (d.replace) value = d.replace(value)

                                return (
                                    <div key={i} className={style.row}>
                                        {(d.icon || d.name) ?
                                            <div className={style.namecontainer}>
                                                <div className={style.icon}>
                                                    {(d.icon) ? d.icon : null}
                                                </div>
                                                <div className={style.name}>
                                                    {d.name}
                                                </div>
                                            </div>
                                            : null}
                                        {(inserttype == 'html') ?
                                            <div className={style.value} style={valuestyle}
                                                 dangerouslySetInnerHTML={{ __html: value }}></div> :
                                            <div className={style.value} style={valuestyle}>{value}</div>}
                                    </div>
                                )
                            }
                        }
                    }) : null}
            </div>
        )
    }
})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <CompanyData {...this.props} ref={(setRef) ? function(e) {
                setRef('companydata', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})

