export default function(c) {
    /*Set Contents*/
    const contents = {
        'home': {
            title: c.hometitle || 'Player',
            component: 'content',
            content: c.templates.home,
            history: c.history
        },
        'share': {
            title: c.sharetitle || 'Share',
            component: 'content',
            content: c.templates.share,
            history: c.history
        },
        'description': {
            title: c.descriptiontitle || 'Description',
            component: 'content',
            content: c.templates.description,
            history: c.history
        }
    }

    if (c.pages) {
        Object.keys(c.pages).map(function(k) {
            const name = c.pages[k].name
            const title = c.pages[k].title

            if (name) {
                contents[name] = {
                    title: title,
                    component: 'content',
                    content: c.templates[name],
                    history: c.history
                }
            }

        })
    }

    return contents

}
