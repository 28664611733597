import React from 'react'
import createReactClass from 'create-react-class'

import { Formitems } from './index.js'

import IconButton from 'material-ui/IconButton'
import DownIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-down'
import UpIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-up'
import AddIcon from 'material-ui/svg-icons/content/add'
import RemoveIcon from 'material-ui/svg-icons/action/delete'

export default createReactClass({
    getInitialState: function() {
        this.refElements = {}
        const { open = false, formdata, title = '' } = this.props
        this.setJsoneditorId()
        return {
            formdata: formdata,
            open: open,
            height: 'auto',
            isValid: true,
            title: title
        }
    },
    setJsoneditorId: function(props) {

        const p = (props) ? props : this.props
        const { recursiveData, name } = p

        if (recursiveData && name) {
            const { data, key } = recursiveData

            const dataObject = (data && key && data[key] && typeof data[key] == 'object') ? data[key] : (data && typeof data == 'object') ? data : null

            if (dataObject) {
                Object.defineProperty(dataObject, '__jsoneditorId', {
                    value: name,
                    enumerable: false,
                    configurable: true
                })
            }
        }

    },
    isChanged: function(a, b, deep = 0, maxdeep) {

        let changed = false
        if (maxdeep && maxdeep > deep) {
            const d = deep + 1
            const isChanged = this.isChanged
            if (a && b && typeof a == 'object' && typeof b == 'object') {
                const keys = (a.length == undefined) ? Object.keys(a) : [...a.keys()]
                keys.map(function(key) {
                    if (key !== 'component' &&
                        key !== 'recursiveData' &&
                        key !== 'validations' &&
                        key !== 'validationErrors' &&
                        key !== 'parent' &&
                        key !== 'root' &&
                        key !== 'refElements' &&
                        key !== 'style' &&
                        !changed) {
                        if (typeof a[key] !== typeof b[key]) changed = true
                        if (a[key] && typeof a[key] == 'object') {
                            changed = isChanged(a[key], b[key], d, maxdeep)
                        } else {
                            if (a[key] !== b[key]) changed = true
                        }
                    }
                })
            } else {
                if (typeof a !== typeof b) changed = true
                if (a !== b) changed = true
            }
        }
        return changed
    },
    componentWillReceiveProps: function(nextProps) {

        const { formdata, recursiveData } = nextProps
        if (recursiveData) {
            const {
                field,
                data,
                key,
                innerFormType,
                schemas,
                schemaProps,
                datas,
                deep,
                getItem,
                getRecursiveItem,
                getName
            } = recursiveData
            const fd = [...getRecursiveItem({ ...recursiveData })[0].attr.formdata]
            const title = (getName) ? getName({
                field: (field.updateField) ? field.updateField() : field,
                key,
                schemaProps
            }) : nextProps.title

            this.setState({
                formdata: fd,
                title: title
            })
        } else {
            const title = nextProps.title
            this.setState({
                formdata: formdata,
                title: title
            })
        }

    },
    componentDidUpdate: function() {
        this.setJsoneditorId()
    },
    shouldComponentUpdate: function(nextProps, nextState) {
        const state = this.state
        const changed1 = this.isChanged(state, nextState, 0, 3)
        const changed2 = (changed1) ? changed1 : this.isChanged(nextState, state, 0, 3)
        if (changed1 || changed2) {
            return true
        }
        return false
    },

    isValid: function() {
        return this.state.isValid
    },
    setInvalid: async function() {
        const { parent } = this.props
        const { isValid } = this.state
        if (isValid) {
            await this.setState({
                isValid: false
            })
        }
        if (parent && parent.setInvalid) await parent.setInvalid()
    },
    setValid: async function() {
        const { parent } = this.props
        const { isValid } = this.state
        const allChildrenIsValid = this.allChildrenIsValid()
        if (!isValid && allChildrenIsValid) {
            await this.setState({
                isValid: true
            })
        }
        if (parent && parent.setValid) await parent.setValid()
    },
    setIsValid: async function(isValid) {
        if (isValid) {
            await this.setValid()
        } else {
            await this.setInvalid()
        }
    },
    allChildrenIsValid: function() {
        const refElements = this.refElements
        let isValid = true
        Object.keys(refElements).map(function(key) {
            if (key !== 'container') {
                const e = refElements[key]
                if (e.isValid) {
                    const elementIsValid = e.isValid()
                    if (!elementIsValid) isValid = false
                }
            }
        })
        return isValid
    },
    setFormDataToChildren: function(formdata) {
        const refElements = this.refElements
        formdata.map(function(data) {
            if (data.type == 'innerform') {
                const childFormData = data.attr.formdata
                const name = data.attr.name
                if (refElements[name] && refElements[name].setFormData) {
                    refElements[name].setFormData(childFormData)
                }
            } else {
                const value = data.attr.value()
                const name = data.attr.name
                if (refElements[name] && refElements[name].setValue) {
                    refElements[name].setValue(value)
                }
            }
        })
    },
    setFormData: function(formdata) {
        const container = this.refElements['container']

        const state = this.state
        const nextState = { ...state, formdata: formdata }

        const changed1 = this.isChanged(state, nextState, 0, 3)
        const changed2 = (changed1) ? changed1 : this.isChanged(nextState, state, 0, 3)
        if (changed1 || changed2) {
            this.setState({
                formdata: formdata
            })
        } else {
            this.setFormDataToChildren(formdata)
        }
    },

    open: async function(p = {}) {
        const { enableScroll } = p
        const { onOpen, recursiveData = {}, root, name } = this.props
        const { open } = this.state
        if (!open) {
            await this.setState({
                open: true
            })
            if (onOpen) await onOpen({ ...recursiveData, props: this.props })
        }
        if (enableScroll) root.scrollToElement({ name })
    },
    openAndAllParentOpen: async function(p = {}) {
        const t = this
        const { e = t } = p
        const r = []
        const rec = function(e) {
            if (e.open) r.push(e)
            if (e.props && e.props.parent) {
                rec(e.props.parent)
            }
        }
        rec(e)
        r.reverse()
        await Promise.all(r.map(function(e, i) {
            const enableScroll = (i == r.length - 1) ? true : false
            e.open({ enableScroll })
        }))
    },
    close: async function() {
        const { onClose, recursiveData = {} } = this.props
        const { open } = this.state
        if (open) {
            await this.setState({
                open: false
            })
            if (onClose) await onClose({ ...recursiveData, props: this.props })
        }
    },
    toggle: async function() {
        const { open } = this.state
        if (open) {
            await this.close()
        } else {
            await this.open()
        }
    },
    addChild: function() {

        const { add, onChange, recursiveData = {} } = this.props

        if (add) {

            const {
                field,
                data,
                key,
                innerFormType,
                schemas,
                schemaProps,
                datas,
                deep,
                getItem,
                getRecursiveItem
            } = recursiveData

            const props = this.props
            const addR = (typeof add == 'function') ? add(props) : add
            const addObject = {}

            Object.keys(addR).map(function(key) {
                addObject[key] = (typeof addR[key] == 'function') ? addR[key](props) : addR[key]
            })

            data[key].push(JSON.parse(JSON.stringify(addObject)))
            const fd = [...getRecursiveItem(recursiveData)[0].attr.formdata]

            fd.map(function(fdata, i) {
                if (fdata.attr.name && !data[key][i].__jsoneditorId) {
                    Object.defineProperty(data[key][i], '__jsoneditorId', {
                        value: fdata.attr.name,
                        enumerable: false,
                        configurable: true
                    })
                }
            })

            this.setState({
                formdata: fd
            })

            if (onChange) onChange({ value: data[key] })

        }
    },
    resetForm: async function(setHeight) {
        const container = this.refElements['container']
        const height = (container) ? container.offsetHeight : null
        await this.setState({
            formdata: [],
            height: (setHeight && height) ? height + 'px' : 'auto'
        })
    },
    regenerateForm: async function() {

        const { remove, onChange, recursiveData = {} } = this.props
        const {
            field,
            data,
            key,
            innerFormType,
            schemas,
            schemaProps,
            datas,
            deep,
            getItem,
            getRecursiveItem,
            getName
        } = recursiveData

        this.refElements = {}
        const fd = [...getRecursiveItem({ ...recursiveData })[0].attr.formdata]
        const title = (getName) ? getName({
            field: (field.updateField) ? field.updateField() : field,
            key,
            schemaProps
        }) : this.props.title

        await this.resetForm(true)

        await this.setState({
            formdata: fd,
            height: 'auto',
            title: title
        })

    },
    removeChild: async function(name, id) {

        const { remove, onChange, recursiveData = {} } = this.props

        const {
            field,
            data,
            key,
            innerFormType,
            schemas,
            schemaProps,
            datas,
            deep,
            getItem,
            getRecursiveItem
        } = recursiveData

        data[key].splice(name, 1)

        Object.keys(field.fields).map(function(n, i) {
            if (i >= name) delete field.fields[n]
        })

        const newDatas = {}
        Object.keys(datas).map(function(key) {
            if (key < deep) newDatas[key] = datas[key]
        })

        const refElements = this.refElements
        const newRefElements = {}
        let newI = 0

        this.refElements = []

        const fd = [...getRecursiveItem({ ...recursiveData, field: field, datas: newDatas })[0].attr.formdata]

        await this.resetForm(true)

        await this.setState({
            formdata: fd,
            height: 'auto'
        })

        await this.setValid()

        if (onChange) onChange({ value: data[key] })

    },
    remove: function() {
        const { remove, parent = {}, recursiveData = {}, name } = this.props
        if (remove && parent && parent.removeChild) {
            const { key } = recursiveData
            if (key || key == 0) {
                parent.removeChild(key, name)
            }
        }

    },
    titleClick: function() {
        const { onClickTitle, recursiveData = {} } = this.props
        if (onClickTitle) onClickTitle({ ...recursiveData, props: this.props })
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type == 'mouseup' || e && e.type == 'touchend' || e && e.type == pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { type } = d
        if (type == 'toggle') {
            this.toggle()
        }
        if (type == 'add') {
            this.addChild()
        }
        if (type == 'remove') {
            this.remove()
        }
        if (type == 'title') {
            this.titleClick()
        }
        e.preventDefault()
    },

    setRef: function(a, e) {
        if (e) this.refElements[a] = e
    },

    render: function() {

        const { root, style, formdeep, hidden } = this.props
        const { open, formdata, title } = this.state

        const state = this.state
        const setRef = this.setRef
        const refElements = this.refElements

        const { add, remove } = this.props

        const t = (title || title == 0) ? title.toString() : ''
        const { height, isValid } = this.state
        const touchEnd = this.touchEnd

        return (
            <div ref={function(e) {
                setRef('container', e)
            }} className={style.innerform} style={{ height: height }}>
                <div className={style.innerformheader}>
                    <div className={style.innerformvalid}>
                        <div className={style.innerformvalidinner}
                             style={{ backgroundColor: (isValid) ? 'green' : 'red' }}></div>
                    </div>
                    <div className={style.innerformtitle}
                         onTouchTap={function(e) {
                             touchEnd(e, 'click', 'click', { type: 'title' })
                         }}
                         onTouchStart={function(e) {

                         }}>
                        {t}
                    </div>
                    {(add && open) ? <div className={style.innerformadd}>
                        <IconButton
                            onTouchTap={function(e) {
                                touchEnd(e, 'click', 'click', { type: 'add' })
                            }}
                            onTouchStart={function(e) {

                            }}
                        >
                            <AddIcon />
                        </IconButton>
                    </div> : null}
                    {(remove) ? <div className={style.innerformremove}>
                        <IconButton
                            onTouchTap={function(e) {
                                touchEnd(e, 'click', 'click', { type: 'remove' })
                            }}
                            onTouchStart={function(e) {

                            }}
                        >
                            <RemoveIcon />
                        </IconButton>
                    </div> : null}
                    <div className={style.innerformopen}>
                        <IconButton
                            onTouchTap={function(e) {
                                touchEnd(e, 'click', 'click', { type: 'toggle' })
                            }}
                            onTouchStart={function(e) {

                            }}
                        >
                            {(open) ? <UpIcon /> : <DownIcon />}
                        </IconButton>
                    </div>
                </div>
                <div
                    className={(open) ? style.innerformcontainer : style.innerformcontainer + ' ' + style.innerformcontainerclose}>
                    <Formitems
                        formdata={formdata}
                        state={state}
                        setRef={setRef}
                        refElements={refElements}
                        parent={this}
                        root={root}
                        style={style}
                        formdeep={formdeep + 1}
                        hidden={(hidden || !open) ? true : false}
                    />
                </div>
            </div>
        )

    }
})
