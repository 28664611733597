import React from 'react'
import createReactClass from 'create-react-class'
import formsyWrapper from '../Formsy/wrapper'

import SelectComponent from './index.js'

const Select = createReactClass({
    handleUpdate: async function(e, nextValue = 0) {
        const { onChange, formsyWrapper = {} } = this.props
        formsyWrapper.handleUpdate({ e, nextValue, onChange })
    },
    render() {

        const {
            formsyWrapper = {},
            ...rest
        } = this.props

        const required = formsyWrapper.required
        const value = formsyWrapper.getValue()
        const v = (value) ? value : []

        return (
            <SelectComponent
                {...rest}
                onChange={this.handleUpdate}
                errorText={(!v.length && required) ? null : formsyWrapper.getErrorMessage()}
                value={v}
            />
        )
    }

})

export default formsyWrapper(Select)
