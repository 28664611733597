import settings from './settings'
import metas from './metas'
import objects from './objects'
import views from './views'
import animations from './animations'
import galleries from './galleries'

export default {
    'type': 'object',
    'hidden': true,
    'fields': {
        'props': {
            'id': 'root',
            'hidden': true,
            'type': 'object',
            'fields': {
                'settings': {
                    ...settings
                },
                'metas': {
                    ...metas
                },
                'objects': {
                    ...objects
                },
                'views': {
                    ...views
                },
                'animations': {
                    ...animations
                },
                'galleries': {
                    ...galleries
                }
            }
        }
    }
}
