import React from 'react'
import createReactClass from 'create-react-class'
import formsyWrapper from '../Formsy/wrapper'

import ToggleComponent from './index.js'

const Toggle = createReactClass({
    handleUpdate: function(e, nextValue = false) {
        const { onChange, formsyWrapper = {} } = this.props
        formsyWrapper.handleUpdate({ e, nextValue, onChange })
    },
    render() {

        const {
            formsyWrapper = {},
            ...rest
        } = this.props

        const required = formsyWrapper.required

        const value = formsyWrapper.getValue()
        const v = (value) ? value : false

        return (
            <ToggleComponent
                {...rest}
                onChange={this.handleUpdate}
                errorText={formsyWrapper.getErrorMessage()}
                value={v}
            />
        )
    }

})

export default formsyWrapper(Toggle)
