// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._0FGPS {\n    display: block;\n    width: 100%;\n    height: 100%;\n}\n\n@supports (-ms-ime-align: auto) {\n    ._0FGPS {\n        display: table;\n    }\n}\n\n@supports (-ms-accelerator: true) {\n    ._0FGPS {\n        display: table;\n    }\n}\n\n@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {\n    ._0FGPS {\n        display: table;\n    }\n}\n\n.x3KCo {\n    display: block;\n    width: 100%;\n    height: 100%;\n    position: relative;\n}\n\n.Mah45 {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    display: block;\n}\n\n.DB5Rc {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n._8FKEb {\n    display: block;\n    width: 100%;\n    height: 100%;\n    max-width: 100%;\n    position: relative !important;\n}\n\ndiv:-webkit-full-screen ._8FKEb {\n    max-height: 100vh;\n    min-height: 100vh;\n}\n\ndiv:fullscreen ._8FKEb {\n    max-height: 100vh;\n    min-height: 100vh;\n}\n\n/*Editor*/\n\n.P94Zw {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    z-index: 1200;\n    top: 0;\n    left: 0;\n}\n\n.RaEb1 {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    z-index: 2;\n    background-color: #faebd700;\n    border-style: dashed;\n    border-width: 1px;\n    border-color: #4e9bec;\n}\n\n.x5CGZ {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n}\n\n.-ScF0 {\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 48px;\n    height: 48px;\n    padding: 12px;\n    background-color: #f5f6f7b3;\n}\n\n.A1VhM {\n    position: absolute;\n    right: 0;\n    bottom: 0;\n    width: 48px;\n    height: 48px;\n    padding: 12px;\n    background-color: #f5f6f7b3;\n}\n\n._99\\+V\\+ {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rootcontainer": "_0FGPS",
	"container": "x3KCo",
	"scale": "Mah45",
	"flex": "DB5Rc",
	"root": "_8FKEb",
	"editor": "P94Zw",
	"editortool": "RaEb1",
	"editortoolinner": "x5CGZ",
	"positiontool": "-ScF0",
	"resizetool": "A1VhM",
	"toolinnerbutton": "_99+V+"
};
module.exports = ___CSS_LOADER_EXPORT___;
