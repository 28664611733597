import { backgroundColor, filters, getObject, image } from './basic'

export default {
    'type': 'object',
    'fields': {
        'props': {
            'type': 'object',
            'hidden': true,
            'fields': {
                'image': image,
                'width': {
                    'type': 'number',
                    'validations': [
                        {
                            'name': 'type',
                            'props': { 'value': ['number'] }
                        }, {
                            'name': 'min',
                            'props': { 'value': 0, 'type': 'number' }
                        }
                    ]
                },
                'height': {
                    'type': 'number',
                    'validations': [
                        {
                            'name': 'type',
                            'props': { 'value': ['number'] }
                        }, {
                            'name': 'min',
                            'props': { 'value': 0, 'type': 'number' }
                        }
                    ]
                },
                'filters': filters,
                'containerStyle': getObject({
                    'hidden': true,
                    'fields': {
                        'backgroundColor': backgroundColor
                    }
                })
            }
        }
    }
}
