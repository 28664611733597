function getName({ field, key, schemaProps }) {
    let name = field.name || key.toString()
    if (field.name == 'root' && schemaProps && schemaProps.name) name = schemaProps.name
    return name
}

const updateField = function(pr = {}) {

    const { data, i, fieldsName, f, p } = pr
    let allTypeField = pr.allTypeField || {}
    if (!allTypeField.fields) allTypeField.fields = {}

    const dinamicFields = (f.autoFields && f.autoFields.enable && f.autoFields.enable.dinamic) ? f.autoFields.enable.dinamic({
        ...p,
        i
    }) : { fields: {} }
    let name = (typeof fieldsName == 'string' && data[fieldsName]) ? data[fieldsName] : (typeof fieldsName == 'function') ? fieldsName(data, p) : null

    const r = {
        ...allTypeField,
        ...dinamicFields,
        fields: { ...allTypeField.fields, ...dinamicFields.fields },
        updateField: function() {
            return updateField({ data, i, fieldsName, f, p, allTypeField })
        }
    }
    if (name) r.name = name
    return r
}

function getFields(p = {}) {
    const { field, type, data, key, fieldsName } = p
    const f = { ...field, fields: (field.fields) ? { ...field.fields } : {} }
    if (type == 'array' || f.autoFields) {

        const allTypeField = (f.autoFields && f.autoFields.enable && f.autoFields.enable.default) ? f.autoFields.enable.default : field.fields[0]
        const excepts = (f.autoFields && f.autoFields.except) ? f.autoFields.except : {}

        if (!f.fields) f.fields = {}
        if ((data[key].length == undefined)) {
            Object.keys(data[key]).map(function(i) {
                let except = (excepts[i]) ? true : false
                if (!except && typeof excepts.default == 'function') {
                    except = excepts.default({ data: data[key][i] })
                }
                if (!except) {
                    if (!f.fields[i]) f.fields[i] = updateField({
                        data: data[key][i],
                        i,
                        fieldsName,
                        f,
                        p,
                        allTypeField
                    })
                    if (typeof fieldsName == 'string' && data[key][i][fieldsName]) f.fields[i].name = data[key][i][fieldsName]
                    if (typeof fieldsName == 'function') f.fields[i].name = fieldsName(data[key], p)
                }
            })
        } else {
            data[key].map(function(k, i) {
                let except = (excepts[i]) ? true : false
                if (!except && typeof excepts.default == 'function') {
                    except = excepts.default({ data: data[key][i] })
                }
                if (!except) {
                    f.fields[i] = updateField({ data: data[key][i], i, fieldsName, f, p, allTypeField })
                }
            })
        }
    }

    return f
}

function getRecursiveItem(p) {

    const { data, key, innerFormType, schemas, schemaProps, datas, deep, getItem } = p
    let field = (p.field && p.field.updateField) ? p.field.updateField() : p.field

    const r = []

    const type = field.type || 'string'
    const name = getName({ field, key, schemaProps })
    const fieldsName = field.fieldsName
    const def = field.default
    const hidden = field.hidden
    const add = field.add
    const remove = field.remove
    const innerFormProps = field.innerFormProps || {}
    const parentData = (p.parentData && field.parentData) ? field.parentData : null

    if (!parentData && type !== 'number' && type !== 'boolean' && !data[key] ||
        type == 'number' && !data[key] && data[key] !== 0 ||
        type == 'boolean' && !data[key] && data[key] !== false) {
        if (def || def == false || def == 0 || def == '') {
            data[key] = def
        } else {
            if (type == 'number') data[key] = 0
            if (type == 'string') data[key] = ''
            if (type == 'object') data[key] = {}
            if (type == 'array') data[key] = []
            if (type == 'boolean') data[key] = false
        }
    }

    if (field.fields) {

        const f = getFields({ ...p, field, type, data, key, fieldsName })
        let sendData = {
            data: data[key],
            schema: f,
            innerFormType,
            getItem,
            schemas,
            schemaProps,
            datas,
            deep,
            key,
            parentData: p
        }
        if (parentData) sendData = {
            ...sendData,
            data: p.parentData.data[p.parentData.key],
            datas: p.parentData.datas,
            deep: p.parentData.deep,
            key: p.parentData.key
        }

        if (hidden) {
            r.push(...recursiveSchema(sendData))
        } else {
            r.push(
                getItem({
                    type: innerFormType,
                    attr: {
                        title: name,
                        formdata: recursiveSchema(sendData),
                        recursiveData: { ...p, getRecursiveItem, getName },
                        add: add,
                        remove: (typeof remove == 'function') ? remove({
                            data,
                            key,
                            field,
                            schemaProps,
                            datas,
                            deep
                        }) : remove,
                        ...innerFormProps
                    },
                    data, key, field, schemaProps, datas, deep
                })
            )
        }

    } else {
        if (type == 'object' || type == 'array') {

            let sendData = {
                data: data[key],
                schema: field,
                innerFormType,
                getItem,
                schemas,
                schemaProps,
                datas,
                deep,
                key,
                parentData: p
            }
            if (parentData) sendData = {
                ...sendData,
                data: p.parentData.data[p.parentData.key],
                datas: p.parentData.datas,
                deep: p.parentData.deep,
                key: p.parentData.key
            }

            r.push(...recursiveSchema(sendData))

        } else {
            r.push(getItem({ label: name, data, key, field, schemaProps, datas, deep }))
        }
    }

    return r

}

function getDatas({ data, datas, deep, key }) {
    const ud = (datas) ? datas : {}
    if (!ud[deep]) ud[deep] = {}
    const k = (key) ? key : 'root'
    ud[deep][k] = data
    return ud
}

function getSchemaProps({ schema, data, schemas, schemaProps }) {
    const s = (schema) ? schema : (data.jsoneditor && data.jsoneditor.schema && schemas[data.jsoneditor.schema]) ? schemas[data.jsoneditor.schema] : null
    const sp = (data.jsoneditor) ? data.jsoneditor : (schemaProps) ? schemaProps : null
    return { schema: s, schemaProps: sp }
}

function getReturnObject(p = {}) {
    const { data, schema, innerFormType, getItem, datas, schemaProps, key, field, deep = 0, innerFormProps = {} } = p
    const e = []
    let r = e
    if (data.jsoneditor && data.jsoneditor.name && !schema.hidden) {
        const attr = {
            title: data.jsoneditor.name,
            formdata: [],
            ...innerFormProps
        }
        r = attr.formdata
        e.push(getItem({
            type: innerFormType,
            attr,
            data, field: schema, schemaProps, datas, deep, key
        }))
    }
    return { e, r }
}

function recursiveWithoutSchema({ data, innerFormType, schemas, schemaProps, getItem, parentData }) {
    const r = []
    const type = (data.length) ? 'array' : 'object'
    const keys = (type == 'array') ? [...data.keys(data)] : Object.keys(data)

    keys.map(function(key) {
        const item = data[key]
        if (item && typeof item == 'object') {
            r.push(...recursiveSchema({ data: item, key, innerFormType, getItem, schemas, schemaProps, parentData }))
        }
    })

    return r
}

export function recursiveSchema(p = {}) {

    const { data, innerFormType = 'innerform', getItem, schemas, deep = 0, key, parentData } = p

    const { schema, schemaProps } = getSchemaProps({ schema: p.schema, schemaProps: p.schemaProps, data, schemas })
    const datas = getDatas({ data, deep, key, datas: p.datas })
    const { e, r } = getReturnObject({
        data, schema, innerFormType, getItem, datas, schemaProps, key, field: schema, deep, innerFormProps: {
            recursiveData: { ...p, field: schema, datas, schemaProps, getRecursiveItem: recursiveSchema }
        }
    })

    if (schema && schema.fields) {

        Object.keys(schema.fields).map(function(key) {
            const field = schema.fields[key]
            r.push(
                ...getRecursiveItem({
                    field,
                    data,
                    key,
                    innerFormType,
                    schemas,
                    schemaProps,
                    datas,
                    deep: deep + 1,
                    getItem,
                    parentData
                })
            )
        })

    } else {
        r.push(
            ...recursiveWithoutSchema({ data, innerFormType, schemas, schemaProps, getItem, parentData })
        )
    }

    return e
}

export function recursiveForms({ data, innerFormType = 'innerform', getItem }) {

    const r = []
    const type = (data && data.length) ? 'array' : 'object'
    const keys = (type == 'array') ? [...data.keys(data)] : Object.keys(data)

    keys.map(function(key) {
        const item = data[key]
        if (item && typeof item == 'object') {
            r.push({
                type: innerFormType,
                attr: {
                    title: key.toString(),
                    formdata: recursiveForms({ data: item, innerFormType, getItem })
                }
            })
        } else {
            r.push(getItem({ label: key, data, key }))
        }

    })

    return r
}
