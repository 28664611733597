import React from 'react'
import ReactDOM from 'react-dom'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'

import IconButton from 'material-ui/IconButton'

import DownIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-down'
import UpIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-up'

import PlusIcon from 'material-ui/svg-icons/content/add'
import MinusIcon from 'material-ui/svg-icons/content/remove'

import BackIcon from 'material-ui/svg-icons/navigation/arrow-back'
import CloseIcon from 'material-ui/svg-icons/content/clear'

import Select from '../Select'
import sls from '../Select/Select.css'

import MobileDetect from 'mobile-detect'

import RightIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-right'
import LeftIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-left'

import RotateRightIcon from 'material-ui/svg-icons/image/rotate-right'
import RotateLeftIcon from 'material-ui/svg-icons/image/rotate-left'

import FlatButton from 'material-ui/FlatButton'
import DownloadIcon from 'material-ui/svg-icons/file/file-download'
import OpenIcon from 'material-ui/svg-icons/action/open-in-new'
import path from 'path'

import CircularProgress from 'material-ui/CircularProgress'

import NoMatches from '../NoMatches'
import nms from '../NoMatches/NoMatches.css'

import 'whatwg-fetch'
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table'

import Root, { Container, renderFromData } from '../Container'
import cs from '../Container/Container.css'

import SvgImage from '../SvgImage'
import svgs from '../SvgImage/SvgImage.css'

import Hotspots from '../Hotspots'
import hts from '../Hotspots/Hotspots.css'

import FloatingPalette from '../FloatingPalette'
import fps from '../FloatingPalette/FloatingPalette.css'
import InputRange from 'react-input-range'

import mpls from '../MetaData/PlayerData.css'

import FormAndRequest from './FormAndRequest'
import SvgIcon from 'material-ui/SvgIcon'

const fetch = function(self) {
    return {
        default: self.fetch.bind(self),
        Headers: self.Headers,
        Request: self.Request,
        Response: self.Response
    }
}(('undefined' !== typeof window && window.self) ? window.self : {
    fetch: function() {
    }
}).default

const delay = ms => new Promise(r => setTimeout(r, ms))

const Parent = createReactClass({
    render: function() {
        const { children } = this.props
        return (
            <div>
                {children}
            </div>
        )
    }
})

const Elements = createReactClass({
    getInitialState: function() {
        const { autoheight, data, editor } = this.props
        return {
            autoheight: autoheight,
            data: data,
            editor: editor
        }
    },
    isChanged: function(a, b, deep = 0, maxdeep) {

        let changed = false
        if (maxdeep && maxdeep > deep || !maxdeep) {
            const d = deep + 1
            const isChanged = this.isChanged
            if (a && b && typeof a == 'object' && typeof b == 'object') {
                const keys = (a.length == undefined) ? Object.keys(a) : [...a.keys()]
                keys.map(function(key) {
                    if (!changed) {
                        if (typeof a[key] !== typeof b[key]) changed = true
                        if (a[key] && typeof a[key] == 'object') {
                            changed = isChanged(a[key], b[key], d, maxdeep)
                        } else {
                            if (a[key] !== b[key]) changed = true
                        }
                    }
                })
            } else {
                if (typeof a !== typeof b) changed = true
                if (a !== b) changed = true
            }
        }
        return changed
    },
    componentWillReceiveProps: function(nextProps) {
        const { data, autoheight, editor } = nextProps
        this.setState({
            data,
            autoheight,
            editor
        })
    },
    shouldComponentUpdate: function(nextProps, nextState) {
        const state = this.state
        const changed1 = this.isChanged(state, nextState, 0, 0)
        const changed2 = (changed1) ? changed1 : this.isChanged(nextState, state, 0, 0)
        if (changed1 || changed2) {
            return true
        }
        return false
    },
    getElementsFromData: function() {

        const { autoheight, data, editor } = this.state
        const {
            getRootContainer, rootRemoveResizeListener, rootAddResizeListener,
            updateForm, className, setRef, setCurrentView, dataObject, getListElement,
            disableHotspots, construct
        } = this.props

        const t = this

        return renderFromData({
            components: {
                Root: {
                    component: Root,
                    props: {
                        editor: editor,
                        editorStyle: { zIndex: 0 },
                        style: cs,
                        setRef: setRef,
                        getResizeElement: function() {
                            return getRootContainer()
                        },
                        removeResizeListener: rootRemoveResizeListener,
                        addResizeListener: rootAddResizeListener,
                        autosize: (autoheight) ? 'autoheight' : 'parent',
                        className: className,
                        dataObject: dataObject,
                        setCurrentView: setCurrentView
                    }
                },
                Container: {
                    component: Container,
                    props: {
                        style: cs
                    }
                },
                SvgImage: {
                    component: SvgImage,
                    props: {
                        style: svgs
                    }
                },
                Hotspots: {
                    component: Hotspots,
                    props: {
                        style: hts,
                        getListElement: getListElement,
                        disableHotspots: disableHotspots,
                        getRootContainer: getRootContainer,
                        getResizeElement: function() {
                            return getRootContainer()
                        },
                        construct: construct
                    }
                }
            },
            elements: data.elements
        })
    },
    render: function() {

        const getElementsFromData = this.getElementsFromData
        const { style } = this.props

        return (
            <div className={style.player}>
                {getElementsFromData()}
            </div>
        )
    }
})

const Effect = createReactClass({
    getInitialState: function() {
        this.refElements = {}
        const { effectData = {} } = this.props
        return {
            ...effectData
        }
    },
    isChanged: function(a, b, deep = 0, maxdeep) {

        let changed = false
        if (maxdeep && maxdeep > deep || !maxdeep) {
            const d = deep + 1
            const isChanged = this.isChanged
            if (a && b && typeof a == 'object' && typeof b == 'object') {
                const keys = (a.length == undefined) ? Object.keys(a) : [...a.keys()]
                keys.map(function(key) {
                    if (!changed) {
                        if (typeof a[key] !== typeof b[key]) changed = true
                        if (a[key] && typeof a[key] == 'object') {
                            changed = isChanged(a[key], b[key], d, maxdeep)
                        } else {
                            if (a[key] !== b[key]) changed = true
                        }
                    }
                })
            } else {
                if (typeof a !== typeof b) changed = true
                if (a !== b) changed = true
            }
        }
        return changed
    },
    componentWillReceiveProps: function(nextProps) {
        const { effectData = {} } = this.props
        return {
            ...effectData
        }
    },
    shouldComponentUpdate: function(nextProps, nextState) {
        const state = this.state
        const changed1 = this.isChanged(state, nextState, 0, 0)
        const changed2 = (changed1) ? changed1 : this.isChanged(nextState, state, 0, 0)
        if (changed1 || changed2) {
            return true
        }
        return false
    },
    componentDidMount: function() {
        this.setDuration()
        this.startEffect()
        const steps = this.getAnimation({ returnType: 'steps' })
        if (steps) {
            this.setSteps({ steps })
        }
    },
    setRef: function(a, e, n) {
        if (e) {
            if (n) {
                this[n][a] = e
            } else {
                this.refElements[a] = e
            }
        }
    },
    setDuration: function() {
        const { duration = 0 } = this.state
        const container = this.refElements['effectcontainer']
        if (container) {
            container.style.webkitTransitionDuration = duration + 'ms'
            container.style.WebkitTransitionDuration = duration + 'ms'
            container.style.MozTransitionDuration = duration + 'ms'
            container.style.OTransitionDuration = duration + 'ms'
            container.style.msTransitionDuration = duration + 'ms'
            container.style.transitionDuration = duration + 'ms'
        }
    },
    setSteps: function({ steps }) {
        const container = this.refElements['effectcontainer']
        if (container) {
            container.style['transition-timing-function'] = 'steps(' + steps + ')'
        }
    },
    startEffect: function() {
        const { className, duration = 0, endStyle } = this.state
        const { style } = this.props
        const container = this.refElements['effectcontainer']
        const steps = this.getAnimation({ returnType: 'steps' })

        if (container) {
            let cN = style.effect
            cN = cN + ' ' + style[className + 'Start']
            container.className = cN
            setTimeout(function() {
                container.className = cN + ' ' + style[className + 'End']
                const endS = (endStyle) ? endStyle({ steps }) : {}

                Object.keys(endS).map(function(key) {
                    container.style[key] = endS[key]
                })
            }, 1)
        }
    },
    getAnimation: function(p = {}) {
        const { returnType } = p
        const { animation = {}, reverse } = this.state
        const { first_image, last_image } = animation
        if (first_image && last_image) {
            const lastN = path.basename(last_image, path.extname(last_image))
            const firstN = path.basename(first_image, path.extname(first_image))
            const folder = path.dirname(first_image)
            const ext = path.extname(first_image)

            if (!isNaN(Number(lastN)) && !isNaN(Number(firstN))) {
                if (returnType == 'steps') return (Number(lastN) + 1) - Number(firstN)
                const r = []
                for (let i = Number(firstN); i < Number(lastN) + 1; i++) {
                    const image = folder + '/' + i.toString() + '' + ext
                    if (returnType == 'images') {
                        r.push(image)
                    } else {
                        r.push(<div key={i} style={{ backgroundImage: 'url(' + image + ')' }}></div>)
                    }
                }
                if (reverse) r.reverse()
                return r
            }
        }
        return null
    },
    render: function() {
        const { effectStyle = {}, ...rest } = this.props
        const { data } = this.state
        const { style } = rest
        const setRef = this.setRef
        const animation = this.getAnimation()

        return (
            <div className={style.effect} style={effectStyle} ref={function(e) {
                setRef('effectcontainer', e)
            }}>
                {(animation) ? <div className={style.animationInner}>{animation}</div> :
                    <Elements {...rest} data={data} disableHotspots={true} />}
            </div>
        )
    }
})

const Player = createReactClass({
    getInitialState() {

        const { setViewId, enableCompassOnStage } = this.props
        this.refElements = {}

        const { construct, editor } = this.props
        const autoheight = this.getAutoHeight()
        const currentView = this.getStartView()
        const historyObject = this.addHistory({ view_id: currentView })
        const fakeHistory = this.getFakeHistoryWhenStartFromHash()
        const ho = (fakeHistory && fakeHistory.length) ? {
            history: [...fakeHistory, historyObject.history[0]],
            currentHistoryStep: fakeHistory.length
        } : { ...historyObject }

        if (setViewId) setViewId({ view_id: currentView, ...ho })

        return {
            autoheight: autoheight,
            isMounted: true,
            currentView: currentView,
            effectData: false,
            height: 0,
            editor: editor,
            imagePreloadProcessing: false,
            enableCompassOnStage: enableCompassOnStage,
            ...ho
        }
    },
    setPlayerTypeToEditor: async function() {
        const { iseditor } = this.state
        if (!iseditor) {
            await this.setState({
                iseditor: true
            })
        }
    },
    setPlayerTypeToPlayer: async function() {
        const { iseditor } = this.state
        if (iseditor) {
            await this.setState({
                iseditor: false
            })
        }
    },
    componentDidMount: function() {
        const { construct } = this.props
        const { readycallback } = construct.props
        if (readycallback) readycallback()

        this.addResizeListener()
        const resizePalette = this.resizePalette
        setTimeout(function() {
            resizePalette()
        })

    },
    componentWillUnmount: function() {
        this.removeResizeListener()
    },
    setRef: function(a, e, n, f) {
        if (e || f) {
            if (n) {
                this[n][a] = e
            } else {
                this.refElements[a] = e
            }
        }
    },
    getAutoHeight: function() {
        const { construct } = this.props
        return (construct.props && construct.props.autoheight) ? construct.props.autoheight : null
    },
    getPlayerRootClassName: function() {
        const { construct, rootClassName } = this.props
        let cn = (construct.props && construct.props.playerrootclassname) ? construct.props.playerrootclassname : null
        return (rootClassName) ? rootClassName + ' ' + cn : cn
    },
    getData: function() {
        const { construct, data } = this.props
        if (data) return data
        return (construct.props && construct.props.data) ? construct.props.data : null
    },
    getConstruct: function() {
        const { construct, data } = this.props
        return (construct.props && construct.props) ? construct.props : {}
    },

    getHotSpotsData: function(p = {}) {
        const { view_id, style } = this.props
        const vid = (p.view_id) ? p.view_id : view_id
        const viewData = this.getViewData({ view_id: vid })

        const viewObjectsFromObjectId = this.getViewObjectsFromObjectId({ object_id: viewData.object_id })
        const hotspots = []
        viewObjectsFromObjectId.map(function(view) {
            const hs = view.elements[0].children[1].children[0].props.hotspots
            hs.map(function(h) {
                h.parent_view_id = view.key
            })
            hotspots.push(...hs)
        })
        return hotspots
    },
    getViewObjectsFromObjectId: function({ object_id }) {
        const data = this.getData()
        const views = data.props.views.props.views
        const r = []
        if (views && views.length) {
            views.map(function(view) {
                if (view.object_id == object_id) {
                    r.push(view)
                }
            })
        }
        return r
    },
    getViewData: function(p = {}) {
        const { view_id } = p
        if (view_id) {
            const data = this.getData()
            const views = data.props.views.props.views
            if (views && views.length) {
                let foundView = null
                views.map(function(view) {
                    if (view.key == view_id) foundView = view
                })
                return foundView
            }
        }
        return null
    },
    findParentView: function(view_id) {
        const data = this.getData()
        const views = data.props.views.props.views
        const getHotSpotsData = this.getHotSpotsData
        let foundParentView = false
        views.map(function(view) {
            const key = view.key
            const hotspots = getHotSpotsData({ view_id: key })
            hotspots.map(function(hotspot, i) {
                if (hotspot.props.view_id == view_id) {
                    foundParentView = key
                }
            })
        })
        return foundParentView
    },
    findRecursiveParentView: function(view_id, store = []) {
        const parent = this.findParentView(view_id)
        if (parent) {
            store.push(parent)
            this.findRecursiveParentView(parent, store)
        }
        return store
    },
    getFakeHistoryWhenStartFromHash: function() {
        const startView = this.getViewIdFromHash() || this.getViewIdFromShareCode()
        if (startView) {
            return this.findRecursiveParentView(startView).reverse()
        }
        return []
    },
    getViewIdFromShareCode: function() {
        const construct = this.getConstruct()
        const { currentviewid } = construct
        if (currentviewid) return currentviewid
        return null
    },
    getViewIdFromHash: function() {

        const construct = this.getConstruct()
        const { id, enableshareobjectwithhash } = construct

        if (id && enableshareobjectwithhash && typeof window !== 'undefined') {

            let fullhash = window.location.hash
            if (fullhash && fullhash.match(id)) {

                if (fullhash.slice(0, 1) == '#') fullhash = fullhash.slice(1)
                const view_id = fullhash.split(id)[1]
                const data = this.getData()
                const views = data.props.views.props.views

                let foundStartView = false
                views.map(function(view) {
                    const key = view.key
                    if (key == view_id) foundStartView = view_id
                })

                return foundStartView

            }
        }

        return null
    },
    getStartView: function(historyObject) {
        const data = this.getData()
        const settings = data.props.settings.props.settings[0]
        const startView = this.getViewIdFromHash() || this.getViewIdFromShareCode() || settings.view_id
        const views = data.props.views.props.views

        let foundStartView = false
        views.map(function(view) {
            const key = view.key
            if (startView && key == startView) foundStartView = true
        })

        if (foundStartView) return startView
        return views[0].key
    },
    effects: {
        'fade': {
            duration: 1000,
            className: 'fade'
        },
        'zoom': {
            duration: 1500,
            className: 'zoom'
        },
        'zoomIn': {
            duration: 1500,
            className: 'zoomIn'
        },
        'slide': {
            duration: 1000,
            className: 'slide'
        },
        'slideRight': {
            duration: 1000,
            className: 'slideRight'
        },
        'slideBottom': {
            duration: 1000,
            className: 'slideBottom'
        },
        'animation': {
            duration: 1000,
            className: 'animation',
            endStyle: function({ steps }) {
                const sp = steps * -100
                return {
                    left: sp + '%'
                }
            }
        }
    },
    getAnimation: function({ start_view_id, end_view_id }) {
        const data = this.getData()
        const animations = (data.props.animations && data.props.animations.props && data.props.animations.props.animations) ? data.props.animations.props.animations : []
        let foundAnim = null
        let foundReverseAnim = null
        animations.map(function(animation) {
            if (animation.start_view_id == start_view_id && animation.end_view_id == end_view_id) {
                foundAnim = { ...animation }
            }
            if (animation.start_view_id == end_view_id && animation.end_view_id == start_view_id) {
                foundReverseAnim = { ...animation }
            }
        })
        return { animation: foundAnim, reverseAnimation: foundReverseAnim }
    },
    getEffectData: function(p = {}) {
        const { effect, start_view_id, end_view_id } = p

        const { animation, reverseAnimation } = this.getAnimation({ start_view_id, end_view_id })

        if (animation) {
            return {
                ...this.effects['animation'],
                animation
            }
        }

        if (reverseAnimation) {
            return {
                ...this.effects['animation'],
                animation: reverseAnimation,
                reverse: true
            }
        }

        const data = this.getData()
        const settings = data.props.settings.props.settings[0]

        let defaultEffect = settings.default_effect || 'fade'
        if (!this.effects[defaultEffect]) defaultEffect = 'fade'

        const rEffect = (effect && this.effects[effect]) ? effect : defaultEffect
        return this.effects[rEffect]
    },
    addHistory: function(p = {}) {
        const { view_id, step } = p
        const state = this.state || {}
        const { history = [], currentHistoryStep = 0 } = state
        if (view_id) {

            const newHistory = (history.indexOf(view_id) > -1) ? [...history.slice(0, history.indexOf(view_id)), view_id] : (step || step == 0) ? [...history.slice(0, step), view_id] : [...history, view_id]
            const newCurrentStep = newHistory.length - 1

            return {
                history: newHistory,
                currentHistoryStep: newCurrentStep
            }
        }
    },
    backHistory: function(p = {}) {
        let { back = -1 } = p
        if (back == true) back = -1

        const { history = [], currentHistoryStep = 0 } = this.state

        if (currentHistoryStep + back > -1) {
            return this.addHistory({ view_id: history[currentHistoryStep + back], step: currentHistoryStep + back })
        }
        return {
            history,
            currentHistoryStep
        }
    },
    getAnimationImages: function(effectData = {}) {

        const { animation = {} } = effectData
        const { first_image, last_image } = animation

        if (first_image && last_image) {
            const lastN = path.basename(last_image, path.extname(last_image))
            const firstN = path.basename(first_image, path.extname(first_image))
            const folder = path.dirname(first_image)
            const ext = path.extname(first_image)

            if (!isNaN(Number(lastN)) && !isNaN(Number(firstN))) {
                const r = []
                for (let i = Number(firstN); i < Number(lastN) + 1; i++) {
                    const image = folder + '/' + i.toString() + '' + ext
                    r.push(image)
                }
                return r
            }
        }
        return null
    },
    setImagePreloadProcessing: async function(data) {
        const { imagePreloadProcessing } = this.state
        if (imagePreloadProcessing !== data) {
            await this.setState({
                imagePreloadProcessing: data
            })
        }
    },
    animationPreloader: async function(effectData) {

        const images = this.getAnimationImages(effectData)
        if (!this.loadedImages) this.loadedImages = []

        if (!images || images && !images.length) return

        const t = this
        const r = []

        images.map(function(src) {
            if (t.loadedImages.indexOf(src) == -1) r.push(src)
        })

        if (!r || r && !r.length) return

        await this.setImagePreloadProcessing(true)

        return await Promise.all(r.map(function(src) {
            return new Promise((resolve, reject) => {
                let img = new Image()
                img.onload = function() {
                    //console.log("loaded image, src: " + src);
                    t.loadedImages.push(src)
                    resolve()
                }
                img.onerror = reject
                img.src = src
            })
        }))

    },
    getGalleryName: function(data) {

        const dataObject = this.getData()
        const objects = dataObject.props.objects.props.objects

        const gallery_name = data.name
        const object_id = data.object_id
        let foundObject = null
        if (object_id) {
            objects.map(function(o) {
                if (object_id == o.key) foundObject = o
            })
        }

        let rName = ''

        if (foundObject) {
            const name = (foundObject) ? foundObject.name : ''
            if (name) rName = name
            if (gallery_name && gallery_name !== name) rName = rName + ' [' + gallery_name + ']'
        } else {
            if (gallery_name) rName = gallery_name
        }

        return rName

    },
    getGalleryViewsAndObjectData: function(gallery) {
        const r = []
        let foundObject = null
        if (gallery.object_id) {
            const dataObject = this.getData()
            const views = dataObject.props.views.props.views
            const objects = dataObject.props.objects.props.objects

            if (gallery.object_id) {
                objects.map(function(o) {
                    if (gallery.object_id == o.key) foundObject = o
                })
            }

            if (foundObject) {
                views.map(function(view) {
                    if (view.object_id == gallery.object_id) {
                        r.push(view)
                    }
                })
            }
        }
        return { views: (r.length) ? r : null, object: foundObject }
    },
    getGalleryImages: function(gallery) {
        const rImages = (gallery.images && gallery.images.length) ? [...gallery.images] : []
        if (gallery.add_view_image && gallery.object_id) {
            const { views } = this.getGalleryViewsAndObjectData(gallery)
            if (views && views[0]) {
                let image = null
                try {
                    image = views[0].elements[0].children[0].children[0].props.image
                    if (image) {
                        let foundImage = null
                        Object.keys(rImages).map(function(key) {
                            const im = rImages[key]
                            if (im && im.image_url == image) {
                                foundImage = im
                            }
                        })
                        if (!foundImage) {
                            rImages.push({
                                image_url: image,
                                backgroundColor: gallery.view_image_backgroundColor
                            })
                        }
                    }
                } catch (error) {
                }
            }
        }
        return rImages
    },
    setCurrentView: async function(p = {}) {

        const { setViewId } = this.props
        const { effect, effect_data, force, back, step, galleryData = {}, enableFloatingPaletteScrollTop, active } = p

        if (effect == 'openUrl' && effect_data) {
            const ed = JSON.parse(effect_data).openUrl
            const url = ed.url
            const target = ed.target || '_blank'
            if (url) {
                if (target == '_self') {
                    window.location.href = url
                } else {
                    window.open(url)
                }
                return
            }
        }

        const historyObject = (!p.view_id) ? this.backHistory({ back }) : this.addHistory({
            view_id: p.view_id,
            step: step
        })
        const view_id = historyObject.history[historyObject.currentHistoryStep]

        const { currentView } = this.state
        let foundView = false

        if (view_id) {
            const data = this.getData()
            const views = data.props.views.props.views

            views.map(function(view) {
                const key = view.key
                if (key == view_id) foundView = true
            })
        }

        let foundGallery = false
        if (view_id) {
            const data = this.getData()
            const galleries = (data.props.galleries) ? data.props.galleries.props.galleries : null

            if (galleries) {
                galleries.map(function(gallery) {
                    const key = gallery.key
                    if (key == view_id) foundGallery = gallery
                })
            }
        }

        const getGalleryImages = this.getGalleryImages
        const getGalleryName = this.getGalleryName
        const getGalleryViewsAndObjectData = this.getGalleryViewsAndObjectData

        if (foundGallery) {
            this.refElements['gallery'].open({
                images: getGalleryImages(foundGallery),
                currentImage: 0,
                name: getGalleryName(foundGallery),
                ...getGalleryViewsAndObjectData(foundGallery),
                ...galleryData
            })
            return
        } else {
            const galleryIsOpen = this.refElements['gallery'].isOpen()
            this.refElements['gallery'].close()
        }

        if (foundView && currentView !== view_id || force) {

            const bodyScrollTop = document.documentElement.scrollTop
            const bodyOverFlowStyle = document.body.style.overflowY
            if (enableFloatingPaletteScrollTop) {
                document.body.style.overflowY = 'hidden'
            }

            const effectData = this.getEffectData({ effect, start_view_id: currentView, end_view_id: view_id })
            const duration = effectData.duration || 0

            if (this.state.effectData) {
                await delay(500)
                if (this.state.effectData) {
                    return null
                }
            }

            if (this.state.imagePreloadProcessing) {
                await delay(500)
                if (this.state.imagePreloadProcessing) {
                    return null
                }
            }

            const floatingpalette = this.refElements['floatingpalette']
            const floatingPaletteState = {}

            if (floatingpalette && floatingpalette.state) {
                floatingPaletteState.open = floatingpalette.state.open
                floatingPaletteState.pinned = floatingpalette.state.pinned
                floatingPaletteState.pintype = floatingpalette.state.pintype
                floatingPaletteState.palettePosX = floatingpalette.state.x
                floatingPaletteState.palettePosY = (floatingpalette.state.minY) ? floatingpalette.state.y - floatingpalette.state.minY : floatingpalette.state.y
                floatingPaletteState.palettePosMinY = floatingpalette.state.minY
                floatingPaletteState.parentWidth = floatingpalette.state.width
                floatingPaletteState.forcePinOnDownBreakPoint = floatingpalette.state.forcePinOnDownBreakPoint
                floatingPaletteState.forcePinOnDownIsMobile = floatingpalette.state.forcePinOnDownIsMobile
                floatingPaletteState.forcePinOnDownIsTouchable = floatingpalette.state.forcePinOnDownIsTouchable
                floatingPaletteState.visibility = floatingpalette.state.visibility
                floatingPaletteState.scrollTop = (enableFloatingPaletteScrollTop) ? floatingpalette.refElements.content.scrollTop : 0
            }

            const boxesState = (this.props.boxesState) ? {
                info: (this.props.boxesState.info) ? { ...this.props.boxesState.info } : {},
                orderByMetas: (this.props.boxesState.orderByMetas) ? { ...this.props.boxesState.orderByMetas } : {},
                previously: (this.props.boxesState.previously) ? { ...this.props.boxesState.previously } : {},
                filter: (this.props.boxesState.filter) ? { ...this.props.boxesState.filter } : {},
                filters: (this.props.boxesState.filters) ? { ...this.props.boxesState.filters } : {},
                share: (this.props.boxesState.share) ? { ...this.props.boxesState.share } : {}
            } : {}

            const list = this.refElements['list']
            const infoBox = (list) ? list.refElements['infoBox'] : null
            if (infoBox && infoBox.state) boxesState.info = { ...infoBox.state }
            if (typeof active !== 'undefined') {
                boxesState.info.active = active
            }

            if (list && list.state && list.state.orderByMetas) {
                if (!boxesState.orderByMetas) boxesState.orderByMetas = {}
                boxesState.orderByMetas[currentView] = { ...list.state.orderByMetas }
            }

            const previously = this.refElements['previously']
            const previouslyBox = (previously) ? previously.refElements['previouslyBox'] : null
            if (previouslyBox && previouslyBox.state) boxesState.previously = { ...previouslyBox.state }

            const filter = this.refElements['filter']
            const filterBox = (filter) ? filter.refElements['filterBox'] : null
            if (filterBox && filterBox.state) {
                boxesState.filter = { ...filterBox.state }
            }

            if (filter && filter.state) {
                if (!boxesState.filters) boxesState.filters = {}
                boxesState.filters[currentView] = { ...filter.state }
            }

            const share = this.refElements['share']
            const shareBox = (share) ? share.refElements['shareBox'] : null
            if (shareBox && shareBox.state) boxesState.share = { ...shareBox.state }

            const editor = this.refElements['root'].refElements['editor']
            const editorContainer = (editor) ? editor.refElements['container'] : null
            if (editorContainer) {
                editorContainer.style.opacity = 0
            }

            await this.animationPreloader(effectData)

            await this.setState({
                effectData: { ...effectData, data: this.getViewObject({ view_id }) },
                imagePreloadProcessing: false,
                ...historyObject
            })

            if (setViewId) setViewId({
                view_id: view_id,
                floatingPaletteState: floatingPaletteState,
                boxesState: boxesState, ...historyObject
            })

            if (duration) await delay(duration)

            const playercontainer = this.refElements['playerinnercontainer']
            const playerBox = this.refElements['playerBox']
            const playerBoxContent = (playerBox) ? playerBox.refElements['content'] : null
            const playerContainerInner = null //(this.refElements["playercontainerinner"] && this.refElements["playercontainerinner"].children) ? this.refElements["playercontainerinner"].children[0] : null;
            const height = (playerContainerInner) ? playerContainerInner.offsetHeight : (playerBoxContent) ? playerBoxContent.offsetHeight : (playercontainer) ? playercontainer.offsetHeight : 0

            const newState = {
                currentView: view_id,
                height: 0,
                effectData: null
            }

            await this.setState(newState)

            this.setPlayerClassName()

            if (enableFloatingPaletteScrollTop) {
                if (bodyScrollTop) {
                    setTimeout(function() {
                        window.scrollTo(0, bodyScrollTop)
                    }, 1)
                }
                document.body.style.overflowY = bodyOverFlowStyle || 'initial'
            }

            //await delay(500);

            /*await this.setState({
                effectData: null,
                height: 0
            })*/

            if (editorContainer) {
                editorContainer.style.opacity = 1
            }

        }

    },
    getViewObject: function({ view_id }) {
        const data = this.getData()
        const views = data.props.views.props.views
        let foundView = false
        views.map(function(view) {
            const key = view.key
            if (view_id && key == view_id) foundView = view
        })
        if (foundView) return foundView
        return views[0]
    },
    getCurrentViewObject: function() {
        const data = this.getData()
        const { currentView } = this.state
        const views = data.props.views.props.views
        let foundView = false
        views.map(function(view) {
            const key = view.key
            if (currentView && key == currentView) foundView = view
        })
        if (foundView) return foundView
        return views[0]
    },
    updateContainer: function(p = {}) {
        const container = this.refElements['root']
        if (container) container.updateContainer(p)
    },
    resize: function() {
        const container = this.refElements['root']
        if (container) container.resizeAll()

        const floatingpalette = this.refElements['floatingpalette']
        if (floatingpalette) floatingpalette.updatePosition({ forceUpdate: false, visibility: 'visible' })
    },

    /*Ratio*/

    getObjects: function() {
        const data = this.getData()
        const objects = data.props.objects.props.objects
        return objects
    },
    getObjectData: function(p = {}) {
        const { object_id } = p
        if (object_id) {
            const objects = this.getObjects()
            if (objects && objects.length) {
                let foundObject = null
                objects.map(function(object) {
                    if (object.key == object_id) foundObject = object
                })
                return foundObject
            }
        }
        return null
    },
    getRatioClassName: function(p = {}) {

        const floatingpalette = this.refElements['floatingpalette']
        const floatingPaletteState = (floatingpalette && floatingpalette.getPinSettings) ? floatingpalette.getPinSettings() : {}

        const {
            open = floatingPaletteState.open,
            pinned = floatingPaletteState.pinned,
            pintype = floatingPaletteState.pintype
        } = p

        const { style, playerratio } = this.props

        const r = {
            className: style.autoratio
        }

        if (playerratio) {

            const viewData = this.getCurrentViewObject() || {}
            const { object_id } = viewData
            const objectData = this.getObjectData({ object_id: object_id }) || { type: 'undefinedType' }
            const type = objectData.type

            let foundRatio = null

            try {
                const p = JSON.parse(playerratio)
                p.map(function(ratioProperties) {

                    let foundType = !(ratioProperties.type)

                    if (type && ratioProperties.type && ratioProperties.type.indexOf(type) > -1) {
                        foundType = true
                    }

                    let foundPintype = !(ratioProperties.pintype)

                    if (type && ratioProperties.pintype && ratioProperties.pintype.indexOf(pintype) > -1) {
                        foundPintype = true
                    }

                    if (foundType && foundPintype) {
                        foundRatio = ratioProperties
                    }
                })
            } catch (e) {
            }

            if (foundRatio) {
                r.ratio = foundRatio.ratio
                r.className = object_id + '_ratio_classes' + ' ' + style.fixratio
                this.createRatioStyle({ object_id, ratioData: foundRatio })
            }

        }

        return r
    },
    createRatioStyle: function({ object_id, ratioData }) {

        if (ratioData && ratioData.ratio) {

            const className = object_id + '_ratio_classes'

            const { style } = this.props

            const head = document.head || document.getElementsByTagName('head')[0]
            if (document.getElementById(className)) {
                return null
            }

            let css = '.' + className + '.' + style.fixratio + ' .' + style.player + ' { padding-bottom: ' + ratioData.ratio + '; }'

            const styleTag = document.createElement('style')

            styleTag.type = 'text/css'

            if (styleTag.styleSheet) {
                styleTag.styleSheet.cssText = css
            } else {
                styleTag.appendChild(document.createTextNode(css))
            }

            styleTag.setAttribute('id', className)

            head.appendChild(styleTag)

        }

    },

    /*Compass*/

    updateCompass: async function(p = {}) {
        const compass = this.refElements['compass']
        if (compass && p.compass) {
            compass.updateCompass(p)
        } else if (!this.state.enableCompassOnStage && p.compass) {
            await this.setState({
                enableCompassOnStage: true
            })
            if (this.refElements['compass']) {
                this.refElements['compass'].updateCompass(p)
            }
        } else if (typeof p.compass == 'string') {
            await this.setState({
                renderTime: new Date().getTime()
            })
            if (this.refElements['compass']) {
                this.refElements['compass'].updateCompass(p)
            }
        }
    },

    /*FloatingPalette*/

    open: function() {
        const floatingpalette = this.refElements['floatingpalette']
        floatingpalette.open()
    },
    openWithoutUpdate: function() {
        const floatingpalette = this.refElements['floatingpalette']
        floatingpalette.open()
    },
    close: function() {
        const floatingpalette = this.refElements['floatingpalette']
        floatingpalette.close()
    },
    onOpen: function(p) {
        const { getControls } = this.props
        const playercontrols = getControls()
        if (playercontrols) {
            playercontrols.open({ disableCallback: true })
        }
        this.setPlayerMaxWidth(p)
    },
    onClose: function(p) {
        const { getControls } = this.props
        const playercontrols = getControls()
        if (playercontrols) {
            playercontrols.close({ disableCallback: true })
        }
        this.setPlayerMaxWidth(p)
    },
    setPlayerMaxWidth: function({ open, pinned, pintype }) {

        const { onChangePin, sidebarWidth = '350px' } = this.props
        const playercontainer = this.refElements['playerinnercontainer']

        if (playercontainer) {
            this.setPlayerClassName({ open, pinned, pintype })
            const currentMaxWidth = playercontainer.style.maxWidth
            const newMaxWidth = (open && pinned && pintype == 'right') ? 'calc(100% - ' + sidebarWidth + ')' : '100%'
            if (currentMaxWidth !== newMaxWidth) {
                playercontainer.style.maxWidth = newMaxWidth
                this.resize()
            }
        }

        if (onChangePin) onChangePin({ open, pinned, pintype })

    },
    setPlayerClassName: function(p = {}) {

        const floatingpalette = this.refElements['floatingpalette']
        const floatingPaletteState = (floatingpalette && floatingpalette.getPinSettings) ? floatingpalette.getPinSettings() : {}

        const {
            open = floatingPaletteState.open,
            pinned = floatingPaletteState.pinned,
            pintype = floatingPaletteState.pintype
        } = p

        const { style } = this.props
        const playercontainer = this.refElements['playerinnercontainer']
        const ratioData = this.getRatioClassName({ open, pinned, pintype })
        let className = style.playercontainer + ' ' + ratioData.className

        if (open) {
            className = className + ' ' + style['pinned_' + pinned]
            className = className + ' ' + style['pintype_' + pintype]
        }

        if (playercontainer) {
            playercontainer.className = className
        }

    },

    addResizeListener: function() {
        const { rootRemoveResizeListener, rootAddResizeListener } = this.props
        const thereIsParentResizeFeature = (rootRemoveResizeListener && rootAddResizeListener) ? true : false
        if (thereIsParentResizeFeature) {
            const { id } = rootAddResizeListener(this.resizePalette)
            this.listenerID = id
        }
    },
    removeResizeListener: function() {
        const { rootRemoveResizeListener, rootAddResizeListener } = this.props
        const thereIsParentResizeFeature = (rootRemoveResizeListener, rootAddResizeListener) ? true : false
        if (thereIsParentResizeFeature) {
            rootRemoveResizeListener(this.resizePalette, this.listenerID || 0)
        }
    },
    resizePalette: function() {
        const floatingpalette = this.refElements['floatingpalette']
        const gallery = this.refElements['gallery']
        setTimeout(function() {
            if (floatingpalette) floatingpalette.updatePosition({ forceUpdate: false, visibility: 'visible' })
            if (gallery) gallery.resize()
        }, 200)
    },
    getStyle: function() {
        const { height } = this.state
        const { playerbgcolor = '#f5f6f7' } = this.props

        if (height) {
            return {
                height: height + 'px',
                backgroundColor: playerbgcolor
            }
        }

        return {
            backgroundColor: playerbgcolor
        }
    },
    getListElement: function() {
        return this.refElements['list']
    },
    getGallery: function(viewData) {
        let foundGallery = null
        if (viewData && viewData.gallery && viewData.gallery.gallery_id) {
            const galleries = this.getGalleries()
            if (galleries && galleries.length) {
                galleries.map(function(gallery) {
                    if (gallery.key == viewData.gallery.gallery_id) {
                        foundGallery = gallery
                    }
                })
            }
        }
        return foundGallery
    },
    getGalleries: function() {
        const dataObject = this.getData()
        const views = dataObject.props.galleries.props.galleries
        return views
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type == 'mouseup' || e && e.type == 'touchend' || e && e.type == pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { type, data } = d
        if (type == 'setCurrentView') {
            this.setCurrentView(data)
        }
        e.preventDefault()
    },
    render: function() {

        const {
            style, disableFloatingPalette, floatingPaletteProps = {},
            getRootContainer, rootRemoveResizeListener, rootAddResizeListener, updateForm, enablePlayerBoxStyle,
            enableControlsToPlayerHeader, playerHeaderChildren, construct, boxContPadding, enablePrevNextNavOnStage,
            enableInfoHeaderOnStage, playerbgcolor, listProps = {}
        } = this.props

        const { enableCompassOnStage } = this.state

        const { autoheight, effectData, height, imagePreloadProcessing } = this.state

        const onOpen = this.onOpen
        const onClose = this.onClose
        const setPlayerMaxWidth = this.setPlayerMaxWidth
        const setRef = this.setRef

        const className = this.getPlayerRootClassName()
        const data = this.getCurrentViewObject()
        const dataObject = this.getData()
        const playerHeightStyle = this.getStyle()
        const effectStyle = this.getStyle()
        const setCurrentView = this.setCurrentView

        const getListElement = this.getListElement
        const { iseditor } = this.state
        const touchEnd = this.touchEnd

        const viewData = this.getViewData({ view_id: this.state.currentView })
        const sameViews = (viewData && viewData.object_id) ? this.getViewObjectsFromObjectId({ object_id: viewData.object_id }) : null
        const enableCompass = (sameViews && sameViews.length > 1 || viewData && viewData.compass) ? true : false
        const showCompass = (enableCompass && enableCompassOnStage) ? true : false

        const prevNextProps = {
            style,
            view_id: this.state.currentView,
            construct,
            dataObject,
            setCurrentView,
            refElements: {
                playercomponent: {
                    state: this.state
                }
            }
        }

        const gallery = this.getGallery(data)
        const justCompass = (enablePrevNextNavOnStage || enableInfoHeaderOnStage) ? false : (showCompass) ? true : false
        const floatingPaletteStyle = (floatingPaletteProps && floatingPaletteProps.customStyle && floatingPaletteProps.customStyle.backgroundColor) ? floatingPaletteProps.customStyle.backgroundColor : '#ffffff'

        return (
            <div className={style.playercontainer} ref={function(e) {
                setRef('playerinnercontainer', e)
            }}>
                <Box style={style}
                     enableBoxStyle={enablePlayerBoxStyle}
                     showTitle={enablePlayerBoxStyle}
                     contentStyle={{ position: 'relative', overflow: 'hidden' }}
                     ref={function(e) {
                         setRef('playerBox', e)
                     }}
                     headerChildren={(enableControlsToPlayerHeader) ? playerHeaderChildren : null}
                     boxContPadding={boxContPadding}
                >
                    <div style={playerHeightStyle} className={style.playercontainerinner} ref={function(e) {
                        setRef('playercontainerinner', e)
                    }}>
                        <Elements
                            {...{
                                style,
                                editor: iseditor,
                                getRootContainer,
                                rootRemoveResizeListener,
                                rootAddResizeListener,
                                updateForm,
                                className,
                                setRef,
                                autoheight,
                                data,
                                setCurrentView,
                                dataObject,
                                getListElement,
                                construct
                            }}
                        />
                        {(enablePrevNextNavOnStage || enableInfoHeaderOnStage || showCompass) ?
                            <div
                                className={(justCompass) ? style.playerprevnextcont + ' ' + style.playerprevnextcontabsolute : style.playerprevnextcont}
                                style={{ backgroundColor: floatingPaletteStyle }}>
                                {(enableInfoHeaderOnStage) ?
                                    <div className={style.prevnextinfo}>
                                        {(listProps && listProps.view_id) ?
                                            <List {...listProps} ref={null} justInfo={true} forceOpen={true}
                                                  disableSameViews={showCompass} /> : null}
                                    </div> : null}
                                {(enablePrevNextNavOnStage) ? <div className={style.prevnextcolumncontainer}>
                                    <PrevNext {...prevNextProps} />
                                </div> : null}
                                {(showCompass) ? <div className={style.compasscolumncontainer}
                                                      style={(justCompass) ? { backgroundColor: floatingPaletteStyle } : {}}>
                                    <Compass {...prevNextProps} ref={function(e) {
                                        setRef('compass', e, null, true)
                                    }} />
                                </div> : null}
                            </div>
                            : null
                        }
                        {(gallery && gallery.images && gallery.images.length) ?
                            <div className={style.stageGalleryButtons + ' ' + style.galleryPrevButton}
                                 onTouchTap={function(e) {
                                     touchEnd(e, 'click', 'click', {
                                         type: 'setCurrentView',
                                         data: {
                                             view_id: gallery.key,
                                             galleryData: { currentImage: gallery.images.length - 1 }
                                         }
                                     })
                                 }}
                                 onTouchStart={function(e) {

                                 }}>
                                <div><LeftIcon /></div>
                            </div> : null}
                        {(gallery && gallery.images && gallery.images.length) ?
                            <div className={style.stageGalleryButtons + ' ' + style.galleryNextButton}
                                 onTouchTap={function(e) {
                                     touchEnd(e, 'click', 'click', {
                                         type: 'setCurrentView',
                                         data: { view_id: gallery.key, galleryData: { currentImage: 0 } }
                                     })
                                 }}
                                 onTouchStart={function(e) {

                                 }}>
                                <div><RightIcon /></div>
                            </div> : null}
                    </div>
                    {(effectData) ?
                        <Effect
                            {...{
                                style, editor: false, getRootContainer, rootRemoveResizeListener,
                                rootAddResizeListener, updateForm, className, setRef, autoheight,
                                effectData, dataObject, effectStyle, construct
                            }}
                        />
                        : null
                    }
                    {(imagePreloadProcessing) ?
                        <div className={style.processbox}>
                            <div className={style.process}>
                                <CircularProgress size={60} thickness={5} />
                            </div>
                        </div>
                        : null
                    }
                    <Gallery style={style} ref={function(e) {
                        setRef('gallery', e)
                    }} setCurrentView={setCurrentView} />
                </Box>
            </div>
        )
    }
})

const ListRow = createReactClass({
    getInitialState: function() {
        const { active } = this.props
        this.refElements = {}
        const isMobile = this.isMobile()
        return {
            active: active,
            isMobile: isMobile
        }
    },
    componentDidMount: async function() {
        const { disableRunHotSpotFunction } = this.props
        const isMobile = this.isMobile()
        if (isMobile !== this.state.isMobile) {
            this.setState({
                isMobile: isMobile,
                mountedTime: new Date().getTime()
            })
        } else {
            this.setState({
                mountedTime: new Date().getTime()
            })
        }
        if (!isMobile) {
            this.addListeners()
        } else if (this.state.active && this.props.active && this.state.active == this.props.active && !disableRunHotSpotFunction) {
            const t = this
            setTimeout(function() {
                t.runHotSpotFunction({ func: 'setActive' })
            }, 2000)
        }
    },
    componentWillUnmount: function() {
        if (this.timer) {
            clearTimeout(this.timer)
        }
    },
    isMobile: function() {
        if (typeof window !== 'undefined') {
            const md = new MobileDetect(window.navigator.userAgent)
            return md.mobile() || md.tablet() || md.phone()
        }
        return false
    },
    setActive: function() {

        const { parent_view_id, setCurrentView } = this.props
        const { id, setParentActive } = this.props

        const historyData = this.getHistoryData()
        const { currentHistoryStep = 0 } = historyData
        if (parent_view_id && setCurrentView) {
            setCurrentView({
                view_id: parent_view_id,
                enableFloatingPaletteScrollTop: true,
                step: currentHistoryStep,
                active: id
            })
            return
        }

        const { active } = this.state
        if (!active) {
            this.setState({
                active: true
            })
            if (setParentActive) setParentActive({ id })
        }
    },
    removeActive: function() {
        const { active, setParentInactive } = this.state
        if (active) {
            this.setState({
                active: false
            })
        }
    },
    getHistoryData: function() {
        const { refElements } = this.props
        const player = refElements['playercomponent']
        const playerState = (player) ? player.state : {}
        const { history, currentHistoryStep } = playerState
        return { history, currentHistoryStep }
    },
    mouseOver: function() {
        const { mountedTime } = this.state
        const { parent_view_id } = this.props
        if (mountedTime && (new Date().getTime() - 1000) > mountedTime) {
            const time = (parent_view_id) ? 300 : 0
            const t = this
            this.timer = setTimeout(function() {
                t.setActive()
                t.runHotSpotFunction({ func: 'setActive' })
            }, time)
        }
    },
    mouseLeave: function() {
        if (this.timer) {
            clearTimeout(this.timer)
        }
        this.removeActive()
        this.runHotSpotFunction({ func: 'removeActive' })
    },
    runHotSpotFunction({ func }) {
        const { view_id } = this.props
        let foundHotSpots = this.getHotSpots()
        if (foundHotSpots && foundHotSpots.refElements) {
            Object.keys(foundHotSpots.refElements).map(function(key) {
                const element = foundHotSpots.refElements[key]
                if (element.props && element.props.view_id == view_id && element[func]) {
                    element[func]()
                }
            })
        }
    },
    getHotSpots: function() {
        const { refElements } = this.props
        if (refElements && refElements.playercomponent && refElements.playercomponent.refElements) {
            const { root = {} } = refElements.playercomponent.refElements
            const rootRefElements = root.refElements || {}
            let foundHotSpots = null
            Object.keys(rootRefElements).map(function(key) {
                const element = rootRefElements[key]
                if (element.props.hotspots && element.props.hotspots.length) {
                    foundHotSpots = element
                }
            })
            return foundHotSpots
        }
        return null
    },
    removeListeners: function() {
        const E = this.refElements['row']
        const e = (E) ? ReactDOM.findDOMNode(E) : null
        if (e) {
            e.removeEventListener('mouseenter', this.mouseOver)
            e.removeEventListener('mouseleave', this.mouseLeave)
        }
    },
    addListeners: function() {
        const E = this.refElements['row']
        const e = (E) ? ReactDOM.findDOMNode(E) : null
        if (e) {
            this.removeListeners()
            e.addEventListener('mouseenter', this.mouseOver)
            e.addEventListener('mouseleave', this.mouseLeave)
        }
    },
    setRef: function(a, e, n) {
        if (e) {
            if (n) {
                this[n][a] = e
            } else {
                this.refElements[a] = e
            }
        }
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type == 'mouseup' || e && e.type == 'touchend' || e && e.type == pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { onClose } = this.props
        const { type } = d
        if (type == 'onClick') {
            this.onClick()
        }
        e.preventDefault()
    },
    onClick: function() {
        const { view_id, setCurrentView, effect, effect_data, disabledClick } = this.props
        const { active, isMobile } = this.state
        if (disabledClick) {

        } else {
            if (setCurrentView) {
                if (isMobile) {
                    if (active) {
                        setCurrentView({ view_id, effect, effect_data })
                    } else {
                        this.setActive()
                        this.runHotSpotFunction({ func: 'setActive' })
                    }
                } else {
                    setCurrentView({ view_id, effect, effect_data })
                }
            }
        }
    },
    render: function() {

        const { tableData, style, view_id, viewName, containerStyle = {}, disabled, listStatusColorWidth } = this.props
        const { active, isMobile } = this.state
        const setRef = this.setRef
        const touchEnd = this.touchEnd

        const statusColumnStyle = (listStatusColorWidth) ? { width: listStatusColorWidth } : {}

        let name = ''

        tableData.map(function(data, i) {
            if (data.meta.key == 'name') {
                name = data.value
            }
        })

        if (viewName) {
            if (name) {
                name = name + ' [' + viewName + ']'
            } else {
                name = viewName
            }
        }

        let classNameEndfix = (isMobile) ? '_table_classes ismobile' : '_table_classes'
        if (disabled) classNameEndfix = classNameEndfix + ' disabled'
        const className = (active) ? view_id + classNameEndfix + ' active' : view_id + classNameEndfix

        return (
            <TableRow
                style={containerStyle}
                onTouchTap={function(e) {
                    touchEnd(e, 'click', 'click', { type: 'onClick' })
                }}
                onTouchStart={function(e) {

                }}
                ref={function(e) {
                    setRef('row', e)
                }} className={style.table + ' ' + className}>
                <TableRowColumn key={-2} className={style.tablerowcolor + ' ' + 'tablerowcolor'}
                                style={statusColumnStyle}></TableRowColumn>
                {(name) ? <TableRowColumn key={-1} className={style.tablevalue}>{name}</TableRowColumn> : null}
                {tableData.map(function(data, i) {
                    if (data.meta.key !== 'name') {
                        return (
                            <TableRowColumn key={i} className={style.tablevalue}>{data.value}</TableRowColumn>
                        )
                    }
                })}
            </TableRow>
        )

    }
})


const FilterBox = createReactClass({

    getInitialState: function() {
        this.refElements = {}
        const { view_id, filterState = {} } = this.props
        const filtersData = this.getFiltersData()

        return {
            view_id,
            filtersData,
            filteredHotSpotsNumber: 0,
            changedFilter: 0,
            ...(filterState && filterState.view_id == view_id) ? filterState : {}
        }
    },
    updateComponent: function(p = {}) {
        const { view_id, filterState = {}, onChange } = p
        if (view_id !== this.state.view_id) {
            const filtersData = this.getFiltersData()
            const nd = {
                view_id,
                filtersData,
                filteredHotSpotsNumber: 0,
                changedFilter: 0,
                ...(filterState && filterState.view_id == view_id) ? filterState : {}
            }
            if (nd.filteredHotSpotsNumber && nd.filteredHotSpots && onChange) {
                onChange(nd.filteredHotSpots)
            }
            this.setState(nd)
        }

    },
    componentWillReceiveProps: function(nextProps) {
        this.props = nextProps
        this.updateComponent(nextProps)
    },
    componentDidMount: function() {
        this.updateComponent(this.props)
    },

    touchEnd: function(e, fn, pt, d) {
        if (e && e.type == 'mouseup' || e && e.type == 'touchend' || e && e.type == pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { type, back } = d
        if (type == 'clear') {
            this.setDefault()
        }
        e.preventDefault()
    },

    getDataObject: function() {
        const { dataObject = {} } = this.props
        return dataObject
    },
    getSettings: function() {
        const dataObject = this.getDataObject()
        const settings = dataObject.props.settings.props.settings[0]
        return settings
    },
    getViews: function() {
        const dataObject = this.getDataObject()
        const views = dataObject.props.views.props.views
        return views
    },
    getObjects: function() {
        const dataObject = this.getDataObject()
        const objects = dataObject.props.objects.props.objects
        return objects
    },
    getMetas: function() {
        const dataObject = this.getDataObject()
        const metas = dataObject.props.metas.props.metas
        return metas
    },
    getViewObjectsFromObjectId: function({ object_id }) {
        const views = this.getViews()
        const r = []
        if (views && views.length) {
            views.map(function(view) {
                if (view.object_id == object_id) {
                    r.push(view)
                }
            })
        }
        return r
    },
    getViewData: function(p = {}) {
        const { view_id } = p
        if (view_id) {
            const views = this.getViews()
            if (views && views.length) {
                let foundView = null
                views.map(function(view) {
                    if (view.key == view_id) foundView = view
                })
                return foundView
            }
        }
        return null
    },
    getObjectData: function(p = {}) {
        const { object_id } = p
        if (object_id) {
            const objects = this.getObjects()
            if (objects && objects.length) {
                let foundObject = null
                objects.map(function(object) {
                    if (object.key == object_id) foundObject = object
                })
                return foundObject
            }
        }
        return null
    },
    getViewObjectData: function({ view_id }) {
        const viewData = this.getViewData({ view_id })
        if (viewData && viewData.object_id) {
            return this.getObjectData({ object_id: viewData.object_id })
        }
        return null
    },
    translate: function({ text }) {
        const settings = this.getSettings() || {}
        const { construct } = this.props
        const { translations = [] } = (construct && construct.props && construct.props.translations) ? { translations: construct.props.translations } : settings
        let translatedText = null
        if (typeof text == 'string' && text) {
            translations.map(function(translate) {
                if (!translatedText && translate.text && translate.translate && translate.text == text) {
                    translatedText = translate.translate
                }
            })
        }
        if (translatedText) return translatedText
        return text
    },
    setRef: function(a, e, n) {
        if (e) {
            if (n) {
                this[n][a] = e
            } else {
                this.refElements[a] = e
            }
        }
    },

    getHotSpotsData: function(p = {}) {
        const { view_id, style } = this.props
        const vid = (p.view_id) ? p.view_id : view_id
        const viewData = this.getViewData({ view_id: vid })

        const viewObjectsFromObjectId = this.getViewObjectsFromObjectId({ object_id: viewData.object_id })
        const hotspots = []
        viewObjectsFromObjectId.map(function(view) {
            const hs = view.elements[0].children[1].children[0].props.hotspots
            hs.map(function(h) {
                h.parent_view_id = view.key
            })
            hotspots.push(...hs)
        })
        return hotspots
    },
    getHotSpotsObjectType: function() {
        const hotspots = this.getHotSpotsData()
        const getViewObjectData = this.getViewObjectData
        const getMetasByType = this.getMetasByType
        const r = {
            area: [],
            building: [],
            flat: [],
            notype: []
        }
        hotspots.map(function(hotspot) {
            const view_id = hotspot.props.view_id
            const objectData = getViewObjectData({ view_id })
            const type = (objectData && objectData.type) ? objectData.type : null
            const metas = getMetasByType({ type: type })
            if (metas && metas.length) {
                if (type == null) {
                    r.notype.push(hotspot)
                } else {
                    r[type].push(hotspot)
                }
            }
        })
        return r
    },
    getMetasByType: function({ type }) {

        const metas = this.getMetas()
        const r = []

        metas.map(function(meta) {

            const show = meta.show || []
            const go = (show.indexOf('filter') > -1) ? true : false

            if (go && meta.enabled && meta.enabled.indexOf(type) > -1) {
                r.push(meta)
            }
        })

        return r

    },
    setDefault: function() {
        this.clear()
        this.onChange({})
    },
    clear: function() {
        const filtersData = this.getFiltersData()
        this.setState({
            filtersData: filtersData,
            filteredHotSpotsNumber: 0,
            changedFilter: 0
        })
    },
    onChange: function(filteredHotSpots = {}) {
        const { onChange } = this.props
        if (onChange) onChange(filteredHotSpots)
    },
    getFiltersData: function() {

        const getMetasByType = this.getMetasByType
        const hotspotsTypes = this.getHotSpotsObjectType()
        const getViewObjectData = this.getViewObjectData
        const r = {}

        Object.keys(hotspotsTypes).map(function(key, i) {
            if (hotspotsTypes[key].length) {
                const type = (key == 'notype') ? null : key
                if (type) {
                    const metas = getMetasByType({ type: type })
                    r[type] = {}
                    metas.map(function(meta) {

                        r[type][meta.key] = {
                            type: meta.type,
                            name: meta.name,
                            key: meta.key,
                            endfix: meta.endfix,
                            format: meta.format,
                            formatdata: meta.formatdata,
                            hotspots: hotspotsTypes[key]
                        }

                        hotspotsTypes[key].map(function(hotspot) {
                            if (meta.type == 'number') {

                                const view_id = hotspot.props.view_id
                                const objectData = getViewObjectData({ view_id })

                                if (objectData[meta.key] && !isNaN(Number(objectData[meta.key])) || objectData[meta.key] == 0) {
                                    const value = objectData[meta.key]

                                    const minValue = Math.floor(value)
                                    const maxValue = (Math.floor(value) < value) ? Math.floor(value) + 1 : Math.floor(value)

                                    if (!r[type][meta.key].max) {
                                        r[type][meta.key].max = maxValue
                                        r[type][meta.key].valueMax = maxValue
                                    }
                                    if (!r[type][meta.key].min) {
                                        r[type][meta.key].min = minValue
                                        r[type][meta.key].valueMin = minValue
                                    }

                                    if (maxValue > r[type][meta.key].max) {
                                        r[type][meta.key].max = maxValue
                                        r[type][meta.key].valueMax = maxValue
                                    }
                                    if (minValue < r[type][meta.key].min) {
                                        r[type][meta.key].min = minValue
                                        r[type][meta.key].valueMin = minValue
                                    }

                                }

                            }
                        })

                    })
                }
            }
        })

        const e = {}

        Object.keys(r).map(function(type) {
            Object.keys(r[type]).map(function(metakey) {
                const filter = r[type][metakey]
                if (filter.type == 'number' && filter.min !== filter.max) {
                    if (!e[type]) e[type] = {}
                    e[type][metakey] = filter
                }
            })
        })

        return e

    },
    formatMetaValue: function({ meta, value = '' }) {

        function getFormatDataObject(type, data) {
            const dataObject = (data && typeof data == 'string') ? JSON.parse(data) : (data && typeof data == 'object') ? data : {}
            return (dataObject && type && dataObject[type]) ? dataObject[type] : {}
        }

        const formats = {
            price: function(value, data) {
                const n = 0
                const x = 0
                const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')'
                return (value && value.toFixed || value == 0) ? value.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$& ') : value
            },
            endFix: function(value, data) {
                const endFix = getFormatDataObject('endFix', data)
                return (value && endFix.value) ? value + ' ' + endFix.value : value
            }
        }

        const endFix = meta.endfix
        let format = meta.format || []
        let formatdata = meta.formatdata || {}

        if (endFix) {
            if (typeof format == 'string') {
                format = [format, 'endfix']
            } else {
                format = [...format]
                format.push('endFix')
            }
            if (typeof formatdata == 'string') {
                formatdata = JSON.parse(formatdata)
            } else {
                formatdata = { ...formatdata }
            }
            if (!formatdata.endFix) formatdata.endFix = { value: endFix }
        }

        if (format) {

            let r = value

            if (format && typeof format == 'string' && formats[format]) {
                r = formats[format](r, formatdata)
            }
            if (format && typeof format == 'object' && format.length) {
                Object.keys(formats).map(function(fkey) {
                    if (format.indexOf(fkey) > -1) {
                        r = formats[fkey](r, formatdata)
                    }
                })
            }

            return r

        }

        return value

    },
    sendToList: function(p = {}) {

        const { filtersData } = p
        const filteredHotSpots = {}
        let newChangedFilter = 0
        const getViewObjectData = this.getViewObjectData

        Object.keys(filtersData).map(function(type, i) {
            Object.keys(filtersData[type]).map(function(metaKey) {
                const meta = filtersData[type][metaKey]
                if (meta.valueMin !== meta.min || meta.valueMax !== meta.max) {
                    newChangedFilter = newChangedFilter + 1
                }

                if (meta.valueMin > meta.min || meta.valueMax < meta.max) {
                    const hotspots = meta.hotspots
                    hotspots.map(function(hotspot) {

                        const view_id = hotspot.props.view_id
                        const objectData = getViewObjectData({ view_id })

                        if (objectData[metaKey] < meta.valueMin || objectData[metaKey] > meta.valueMax) {
                            filteredHotSpots[view_id] = { ...objectData }
                        }
                    })
                }
            })
        })

        const { filteredHotSpotsNumber, changedFilter } = this.state
        const newFilteredHotSpotsNumber = Object.keys(filteredHotSpots).length
        if (newFilteredHotSpotsNumber !== filteredHotSpotsNumber || changedFilter !== newChangedFilter) {
            this.setState({
                filteredHotSpotsNumber: newFilteredHotSpotsNumber,
                changedFilter: newChangedFilter,
                filteredHotSpots: filteredHotSpots
            })
        }


        this.onChange(filteredHotSpots)
    },
    getFilterByType: function(p) {

        const t = this
        const { style } = this.props
        const { type, metas } = p
        const { filtersData } = this.state

        const translate = this.translate
        const title = translate({ text: type + ' filter' })
        const formatMetaValue = this.formatMetaValue

        return (
            <div key={type} className={style.filterinnerbox}>
                <div className={style.filterinnertitle}>{title}</div>
                <div className={style.filterinnercontent}>
                    {metas.map(function(meta, i) {

                        const name = translate({ text: meta.name })
                        const metaType = meta.type

                        if (metaType == 'number') {

                            const min = filtersData[type][meta.key].min
                            const max = filtersData[type][meta.key].max
                            let step = 1
                            if ((max - min) > 100) step = 5
                            if ((max - min) > 300) step = 10
                            if ((max - min) > 1000) step = 100
                            if ((max - min) > 10000) step = 1000
                            if ((max - min) > 100000) step = 10000
                            if ((max - min) > 1000000) step = 100000

                            const valueMin = filtersData[type][meta.key].valueMin
                            const valueMax = filtersData[type][meta.key].valueMax

                            const thisMetaData = filtersData[type][meta.key]

                            return (
                                <div className={style.filtercontrol} key={i}>
                                    <div className={style.filtercontrolheader}>
                                        <div className={style.filtercontroltitle}>{name}</div>
                                    </div>
                                    <div className={style.filtercontrolcontent}>
                                        <div className={style.filterrange}>
                                            <InputRange
                                                step={step}
                                                allowSameValues={true}
                                                classNames={{
                                                    activeTrack: style['input-range__track'] + ' ' + style['input-range__track--active'],
                                                    disabledInputRange: style['input-range'] + ' ' + style['input-range--disabled'],
                                                    inputRange: style['input-range'],
                                                    labelContainer: style['input-range__label-container'],
                                                    maxLabel: style['input-range__label'] + ' ' + style['input-range__label--max'],
                                                    minLabel: style['input-range__label'] + ' ' + style['input-range__label--min'],
                                                    slider: style['input-range__slider'],
                                                    sliderContainer: style['input-range__slider-container'],
                                                    track: style['input-range__track'] + ' ' + style['input-range__track--background'],
                                                    valueLabel: style['input-range__label'] + ' ' + style['input-range__label--value']
                                                }}
                                                minValue={min}
                                                maxValue={max}
                                                value={{ min: valueMin, max: valueMax }}
                                                formatLabel={function(value) {
                                                    return formatMetaValue({ meta: meta, value: value })
                                                }}
                                                onChange={function(value) {
                                                    const nd = {
                                                        filtersData: {
                                                            ...filtersData,
                                                            [type]: {
                                                                ...filtersData[type],
                                                                [meta.key]: {
                                                                    ...filtersData[type][meta.key],
                                                                    valueMin: value.min,
                                                                    valueMax: value.max
                                                                }
                                                            }
                                                        }
                                                    }
                                                    t.setState(nd)
                                                }}
                                                onChangeComplete={function(value) {
                                                    const nd = {
                                                        filtersData: {
                                                            ...filtersData,
                                                            [type]: {
                                                                ...filtersData[type],
                                                                [meta.key]: {
                                                                    ...filtersData[type][meta.key],
                                                                    valueMin: value.min,
                                                                    valueMax: value.max
                                                                }
                                                            }
                                                        }
                                                    }
                                                    t.sendToList(nd)
                                                    t.setState(nd)
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        return null

                    })}
                </div>
            </div>
        )
    },

    render: function() {

        const { style } = this.props
        const touchEnd = this.touchEnd
        const setRef = this.setRef
        const { filtersData, filteredHotSpotsNumber, changedFilter } = this.state

        const getFilterByType = this.getFilterByType
        const title = (changedFilter) ? this.translate({ text: 'filter' }) + ' (' + changedFilter + ')' : this.translate({ text: 'filter' })

        const r = []

        Object.keys(filtersData).map(function(key, i) {
            const type = key
            const metas = (filtersData[type]) ? Object.keys(filtersData[type]).map(function(key) {
                return filtersData[type][key]
            }) : null

            if (metas && metas.length) {
                r.push(
                    getFilterByType({
                        type: type,
                        metas: metas
                    })
                )
            }
        })

        if (r.length) {

            const headerChildren = (changedFilter) ?
                <div>
                    <IconButton
                        onTouchTap={function(e) {
                            touchEnd(e, 'click', 'click', { type: 'clear' })
                        }}
                        onTouchStart={function(e) {

                        }}>
                        <CloseIcon />
                    </IconButton>
                </div> : null

            return (
                <Box {...this.props} title={title} headerChildren={headerChildren} ref={function(e) {
                    setRef('filterBox', e)
                }}>
                    <div className={style.filtercontainer}>
                        <div className={style.filterbox}>
                            {r}
                        </div>
                    </div>
                </Box>
            )

        }

        return null

    }
})

const ShareBox = createReactClass({

    getInitialState: function() {
        this.refElements = {}
        const { view_id } = this.props
        return {
            view_id
        }
    },
    updateComponent: function(p = {}) {
        const { view_id } = p
        if (view_id !== this.state.view_id) {
            this.setState({ view_id })
        }

    },
    componentWillReceiveProps: function(nextProps) {
        this.updateComponent(nextProps)
    },
    componentDidMount: function() {
    },

    touchEnd: function(e, fn, pt, d) {
        if (e && e.type == 'mouseup' || e && e.type == 'touchend' || e && e.type == pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { type, back } = d
        if (type == 'share') {
        }
        e.preventDefault()
    },
    translate: function({ text }) {
        const settings = this.getSettings() || {}
        const { construct } = this.props
        const { translations = [] } = (construct && construct.props && construct.props.translations) ? { translations: construct.props.translations } : settings
        let translatedText = null
        if (typeof text == 'string' && text) {
            translations.map(function(translate) {
                if (!translatedText && translate.text && translate.translate && translate.text == text) {
                    translatedText = translate.translate
                }
            })
        }
        if (translatedText) return translatedText
        return text
    },
    setRef: function(a, e, n) {
        if (e) {
            if (n) {
                this[n][a] = e
            } else {
                this.refElements[a] = e
            }
        }
    },
    getDataObject: function() {
        const { dataObject = {} } = this.props
        return dataObject
    },
    getSettings: function() {
        const dataObject = this.getDataObject()
        const settings = dataObject.props.settings.props.settings[0]
        return settings
    },
    render: function() {

        const { style, ShareComponent } = this.props
        const { view_id } = this.state
        const touchEnd = this.touchEnd
        const title = this.translate({ text: 'share' })
        const setRef = this.setRef

        return (

            <Box {...this.props} title={title} ref={function(e) {
                setRef('shareBox', e)
            }}>
                <div className={style.sharecontainer}>
                    <ShareComponent {...this.props}
                                    disableShareCode={true}
                                    disableTokens={true}
                                    disableIcons={true}
                                    disableTracker={true}
                                    selectedView={view_id}
                                    selectStyle={style}
                                    metaStyle={mpls}
                                    Parent={Parent}
                    />
                </div>
            </Box>
        )
    }
})

const PreviouslyBox = createReactClass({

    getInitialState: function() {
        this.refElements = {}
        const { view_id } = this.props
        const historyObject = this.getHistory(this.props) || {}
        const { history = [], currentHistoryStep } = historyObject

        return {
            view_id,
            history: [...history],
            currentHistoryStep: currentHistoryStep
        }
    },
    updateComponent: function(props) {
        const getHistory = this.getHistory
        const historyObject = getHistory(props)
        if (historyObject.history && historyObject.history.length !== this.state.history ||
            historyObject.currentHistoryStep !== this.state.currentHistoryStep || this.state.view_id == props.view_id) {
            this.setState({
                view_id: props.view_id,
                ...historyObject
            })
        }
        this.addClasses()
    },
    componentWillReceiveProps: function(nextProps) {
        this.updateComponent(nextProps)
    },
    componentDidMount: function() {
        this.updateComponent(this.props)
    },

    touchEnd: function(e, fn, pt, d) {
        if (e && e.type == 'mouseup' || e && e.type == 'touchend' || e && e.type == pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { type, back } = d
        if (type == 'back') {
            this.back({ back })
        }
        e.preventDefault()
    },
    back: function({ back }) {
        const { setCurrentView } = this.props
        setCurrentView({ back })
    },
    thereBack: function() {
        const { refElements } = this.props
        const player = refElements['playercomponent']
        const playerState = (player) ? player.state : {}
        const { history, currentHistoryStep } = playerState
        let backStep = (currentHistoryStep > 0) ? history[currentHistoryStep - 1] : false
        if (backStep) {
            const viewData = this.getViewData({ view_id: backStep })
            if (viewData && viewData.hidden) return false
        }
        return backStep
    },
    getHistory: function(props) {
        const { refElements } = props
        const player = refElements['playercomponent']
        const playerState = (player) ? player.state : {}
        const { history, currentHistoryStep } = playerState
        return { history, currentHistoryStep }
    },

    getDataObject: function() {
        const { dataObject = {} } = this.props
        return dataObject
    },
    getSettings: function() {
        const dataObject = this.getDataObject()
        const settings = dataObject.props.settings.props.settings[0]
        return settings
    },
    getViews: function() {
        const dataObject = this.getDataObject()
        const views = dataObject.props.views.props.views
        return views
    },
    getObjects: function() {
        const dataObject = this.getDataObject()
        const objects = dataObject.props.objects.props.objects
        return objects
    },
    getMetas: function() {
        const dataObject = this.getDataObject()
        const metas = dataObject.props.metas.props.metas
        return metas
    },
    getViewObjectsFromObjectId: function({ object_id }) {
        const views = this.getViews()
        const r = []
        if (views && views.length) {
            views.map(function(view) {
                if (view.object_id == object_id) {
                    r.push(view)
                }
            })
        }
        return r
    },
    getViewData: function(p = {}) {
        const { view_id } = p
        if (view_id) {
            const views = this.getViews()
            if (views && views.length) {
                let foundView = null
                views.map(function(view) {
                    if (view.key == view_id) foundView = view
                })
                return foundView
            }
        }
        return null
    },
    getObjectData: function(p = {}) {
        const { object_id } = p
        if (object_id) {
            const objects = this.getObjects()
            if (objects && objects.length) {
                let foundObject = null
                objects.map(function(object) {
                    if (object.key == object_id) foundObject = object
                })
                return foundObject
            }
        }
        return null
    },
    getViewObjectData: function({ view_id }) {
        const viewData = this.getViewData({ view_id })
        if (viewData && viewData.object_id) {
            return this.getObjectData({ object_id: viewData.object_id })
        }
        return null
    },

    getHotSpotColors: function({ view_id }) {

        const r = {
            defaultColor: 'rgba(0,0,0,0)',
            hoverColor: 'rgba(255,255,255,1)'
        }

        if (view_id) {
            const settings = this.getSettings() || {}
            const { colors = {} } = settings
            const objectData = this.getViewObjectData({ view_id })
            if (objectData && objectData.type) {
                if (colors && colors[objectData.type] && colors[objectData.type][objectData.status]) {
                    return { ...colors[objectData.type][objectData.status] }
                }
            }
        }

        return r

    },
    createStyle: function({ view_id }) {

        const className = view_id + '_previously_classes'

        const head = document.head || document.getElementsByTagName('head')[0]
        if (document.getElementById(className)) {
            return null
        }

        const hotSpotColors = this.getHotSpotColors({ view_id })

        let css = '.' + className + ':hover .previously-item { background-color:' + hotSpotColors.hoverColor + '!important; color:#ffffff!important; text-align:center!important; font-weight:bold!important}'

        const style = document.createElement('style')

        style.type = 'text/css'

        if (style.styleSheet) {
            style.styleSheet.cssText = css
        } else {
            style.appendChild(document.createTextNode(css))
        }

        style.setAttribute('id', className)

        head.appendChild(style)
    },
    addClasses: function() {
        const createStyle = this.createStyle
        const { history, currentHistoryStep, view_id } = this.state
        if (history && history.length) {
            history.map(function(h_view_id, i) {
                createStyle({ view_id: h_view_id })
            })
        }
    },
    translate: function({ text }) {
        const settings = this.getSettings() || {}
        const { construct } = this.props
        const { translations = [] } = (construct && construct.props && construct.props.translations) ? { translations: construct.props.translations } : settings
        let translatedText = null
        if (typeof text == 'string' && text) {
            translations.map(function(translate) {
                if (!translatedText && translate.text && translate.translate && translate.text == text) {
                    translatedText = translate.translate
                }
            })
        }
        if (translatedText) return translatedText
        return text
    },
    setRef: function(a, e, n) {
        if (e) {
            if (n) {
                this[n][a] = e
            } else {
                this.refElements[a] = e
            }
        }
    },
    getFlexBasis: function() {
        const { sidebarWidth = '350px' } = this.props
        let n = 350
        if (sidebarWidth && sidebarWidth.slice && sidebarWidth.slice(-2) == 'px') {
            if (!isNaN(Number(sidebarWidth.slice(0, -2)))) {
                n = Number(sidebarWidth.slice(0, -2))
            }
        }
        return { flexBasis: (n - 54) + 'px' }
    },
    getSteps: function() {

        const { style } = this.props
        const { history, currentHistoryStep, view_id } = this.state
        const getViewObjectData = this.getViewObjectData
        const getViewData = this.getViewData
        const touchEnd = this.touchEnd
        const flexBasisStyle = this.getFlexBasis()

        const r = []
        history.map(function(h_view_id, i) {

            if (h_view_id !== view_id) {

                const className = h_view_id + '_previously_classes'

                const viewData = getViewData({ view_id: h_view_id })
                const objectData = getViewObjectData({ view_id: h_view_id })

                const viewName = (viewData) ? viewData.name : ''
                let name = objectData.name

                if (viewName) {
                    if (name) {
                        name = name + ' [' + viewName + ']'
                    } else {
                        name = viewName
                    }
                }

                const back = i - history.length + 1
                const send = { type: 'back', back: back }
                const image = (viewData && viewData.elements) ? viewData.elements[0].children[0].children[0].props.image : null
                const bgStyle = (image) ? { backgroundImage: 'url(' + image + ')' } : null

                const hidden = (viewData && viewData.hidden) ? true : false

                if (!hidden) {
                    r.push(
                        <div
                            style={flexBasisStyle}
                            className={style.previouslyitem + ' ' + className}
                            onTouchTap={function(e) {
                                touchEnd(e, 'click', 'click', send)
                            }}
                            onTouchStart={function(e) {

                            }}
                            key={i}>
                            <div className={style.previouslyitembox}>
                                <div className={style.previouslyitemcover} style={bgStyle}></div>
                                <div className={style.previouslyitemtitle + ' ' + 'previously-item'}>{name}</div>
                            </div>
                        </div>
                    )
                }
            }

        })

        return r

    },
    render: function() {

        const { style } = this.props
        const show = this.thereBack()
        const { history } = this.state
        const title = this.translate({ text: 'previously' })
        const setRef = this.setRef
        const steps = this.getSteps()

        if (show && history && history.length && steps && steps.length) {
            return (
                <Box {...this.props} title={title} ref={function(e) {
                    setRef('previouslyBox', e)
                }}>
                    <div className={style.previouslycontainer}>
                        <div className={style.previouslybox}>
                            {steps}
                        </div>
                    </div>
                </Box>
            )
        } else {
            return null
        }
    }
})

const PrevNext = createReactClass({
    getInitialState: function() {
        this.refElements = {}
        return {
            view_id: this.props.view_id
        }
    },
    componentDidMount: function() {
        this.updateStyles()
    },
    componentWillReceiveProps: async function(nextProps) {
        const { view_id } = nextProps
        if (view_id !== this.state.view_id) {
            await this.setState({
                view_id: view_id
            })
            this.updateStyles()
        }
    },
    updateStyles: function() {
        const { view_id } = this.state
        this.getPrevNextViewData(true)
    },
    getPrevNextParent: function(props = {}) {
        const { children, disableParentTable } = props

        if (disableParentTable) return children

        return (
            <Table fixedHeader={true} style={{ backgroundColor: 'transparent' }}>
                <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                    {children}
                </TableHeader>
            </Table>
        )
    },
    getPrevNextRowBackgroundStyle: function() {
        const { construct = {} } = this.props
        const { props } = construct
        if (props.prevnextbgcolor) return { backgroundColor: props.prevnextbgcolor }
        return {}
    },
    getPrevNextViewData: function(initial) {
        const { view_id } = this.state
        const historyData = this.getHistoryData()
        const { history, currentHistoryStep = 0 } = historyData
        const createInfoStyle = this.createInfoStyle
        const getViewData = this.getViewData

        if (history && history.length && currentHistoryStep > 0) {
            const prevViewId = history[currentHistoryStep - 1]

            if (prevViewId) {

                const hotspots = this.getHotSpotsData({ view_id: prevViewId })
                const curretViewData = getViewData({ view_id })

                let isSameObjectView = false
                let foundSameViews = []
                let foundCurrentView = false
                hotspots.map(function(hotspot, i) {
                    const viewData = getViewData({ view_id: hotspot.props.view_id })
                    if (viewData.object_id == curretViewData.object_id) {
                        foundSameViews.push(viewData.key)
                    }
                    if (viewData.key == curretViewData.key) {
                        foundCurrentView = true
                    }
                })

                const viewId = (!foundCurrentView && foundSameViews.length) ? foundSameViews[0] : view_id

                const hp = []
                hotspots.map(function(hotspot, i) {
                    const viewData = getViewData({ view_id: hotspot.props.view_id })
                    if (viewData && viewData.hidden && hotspot.props.view_id !== viewId) {
                    } else {
                        hp.push(hotspot)
                    }
                })

                const r = {}
                hp.map(function(hotspot, i) {
                    if (hotspot.props.view_id == viewId) {
                        if (hp[i - 1] && hp[i - 1].props.view_id) {
                            r.prev = hp[i - 1].props.view_id
                            if (initial) {
                                createInfoStyle({ view_id: r.prev })
                            }
                        }
                        if (hp[i + 1] && hp[i + 1].props.view_id) {
                            r.next = hp[i + 1].props.view_id
                            if (initial) {
                                createInfoStyle({ view_id: r.next })
                            }
                        }
                    }
                })

                return r
            }
        }
        return null
    },
    getHotSpotColors: function({ view_id }) {

        const r = {
            defaultColor: 'rgba(0,0,0,0)',
            hoverColor: 'rgba(255,255,255,1)'
        }

        if (view_id) {
            const settings = this.getSettings() || {}
            const { colors = {} } = settings
            const objectData = this.getViewObjectData({ view_id })
            if (objectData && objectData.type) {
                if (colors && colors[objectData.type] && colors[objectData.type][objectData.status]) {
                    return { ...colors[objectData.type][objectData.status] }
                }
            }
        }

        return r

    },
    createInfoStyle: function({ view_id }) {

        const prevNextClassName = view_id + '_prevnext_classes'

        const head = document.head || document.getElementsByTagName('head')[0]
        if (document.getElementById(prevNextClassName)) {
            return null
        }

        const hotSpotColors = this.getHotSpotColors({ view_id })

        let css = '.' + prevNextClassName + ':hover { background-color:' + hotSpotColors.hoverColor + '!important; color:#ffffff!important;}'
        css = css + ' .' + prevNextClassName + ':hover svg { color:#ffffff!important; }'

        const style = document.createElement('style')

        style.type = 'text/css'

        if (style.styleSheet) {
            style.styleSheet.cssText = css
        } else {
            style.appendChild(document.createTextNode(css))
        }

        style.setAttribute('id', prevNextClassName)

        head.appendChild(style)
    },
    getDataObject: function() {
        const { dataObject = {} } = this.props
        return dataObject
    },
    getSettings: function() {
        const dataObject = this.getDataObject()
        const settings = dataObject.props.settings.props.settings[0]
        return settings
    },
    getViews: function() {
        const dataObject = this.getDataObject()
        const views = dataObject.props.views.props.views
        return views
    },
    getObjects: function() {
        const dataObject = this.getDataObject()
        const objects = dataObject.props.objects.props.objects
        return objects
    },
    getViewObjectsFromObjectId: function({ object_id }) {
        const views = this.getViews()
        const r = []
        if (views && views.length) {
            views.map(function(view) {
                if (view.object_id == object_id) {
                    r.push(view)
                }
            })
        }
        return r
    },
    getViewData: function(p = {}) {
        const { view_id } = p
        if (view_id) {
            const views = this.getViews()
            if (views && views.length) {
                let foundView = null
                views.map(function(view) {
                    if (view.key == view_id) foundView = view
                })
                return foundView
            }
        }
        return null
    },
    translate: function({ text }) {
        const settings = this.getSettings() || {}
        const { construct } = this.props
        const { translations = [] } = (construct && construct.props && construct.props.translations) ? { translations: construct.props.translations } : settings
        let translatedText = null
        if (typeof text == 'string' && text) {
            translations.map(function(translate) {
                if (!translatedText && translate.text && translate.translate && translate.text == text) {
                    translatedText = translate.translate
                }
            })
        }
        if (translatedText) return translatedText
        return text
    },
    getObjectData: function(p = {}) {
        const { object_id } = p
        if (object_id) {
            const objects = this.getObjects()
            if (objects && objects.length) {
                let foundObject = null
                objects.map(function(object) {
                    if (object.key == object_id) foundObject = object
                })
                return foundObject
            }
        }
        return null
    },
    getViewObjectData: function({ view_id }) {
        const viewData = this.getViewData({ view_id })
        if (viewData && viewData.object_id) {
            return this.getObjectData({ object_id: viewData.object_id })
        }
        return null
    },
    getHotSpotsData: function(p = {}) {
        const { view_id, style } = this.props
        const vid = (p.view_id) ? p.view_id : view_id
        const viewData = this.getViewData({ view_id: vid })

        const viewObjectsFromObjectId = this.getViewObjectsFromObjectId({ object_id: viewData.object_id })
        const hotspots = []
        viewObjectsFromObjectId.map(function(view) {
            const hs = view.elements[0].children[1].children[0].props.hotspots
            hs.map(function(h) {
                h.parent_view_id = view.key
            })
            hotspots.push(...hs)
        })
        return hotspots
    },
    getHistoryData: function() {
        const { refElements } = this.props
        const player = refElements['playercomponent']
        const playerState = (player) ? player.state : {}
        const { history, currentHistoryStep } = playerState
        return { history, currentHistoryStep }
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type == 'mouseup' || e && e.type == 'touchend' || e && e.type == pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { view_id, type } = d
        if (type == 'prevnext') {
            this.prevnext({ view_id })
        }
        e.preventDefault()
    },
    prevnext: function({ view_id }) {
        const { setCurrentView } = this.props
        const historyData = this.getHistoryData()
        const { currentHistoryStep = 0 } = historyData
        if (view_id) {
            setCurrentView({ view_id: view_id, step: currentHistoryStep, enableFloatingPaletteScrollTop: true })
        }
    },
    render: function() {

        const {
            disableParentTable,
            style,
            prevnextnavhidetitle,
            justNext,
            justPrev,
            floatingPaletteProps
        } = this.props
        const prevNextData = this.getPrevNextViewData() || {}
        const { prev, next } = prevNextData
        const touchEnd = this.touchEnd
        const Parent = this.getPrevNextParent
        const prevNextRowBackgroundStyle = this.getPrevNextRowBackgroundStyle()

        if (prev || next) {

            const prevViewData = (prev) ? this.getViewData({ view_id: prev }) : null
            const nextViewData = (next) ? this.getViewData({ view_id: next }) : null

            const prevObjectData = (prevViewData && prevViewData.object_id) ? this.getViewObjectData({ view_id: prev }) : null
            const nextObjectData = (nextViewData && nextViewData.object_id) ? this.getViewObjectData({ view_id: next }) : null

            let prevName = (prevObjectData && prevObjectData.name) ? prevObjectData.name : ''
            let nextName = (nextObjectData && nextObjectData.name) ? nextObjectData.name : ''

            const prevViewName = (prevViewData && prevViewData.name) ? prevViewData.name : ''
            const nextViewName = (nextViewData && nextViewData.name) ? nextViewData.name : ''

            if (prevViewName) {
                if (prevName) {
                    prevName = prevName + ' [' + prevViewName + ']'
                } else {
                    prevName = prevViewName
                }
            }
            if (nextViewName) {
                if (nextName) {
                    nextName = nextName + ' [' + nextViewName + ']'
                } else {
                    nextName = nextViewName
                }
            }

            const prevClassName = prev + '_prevnext_classes'
            const nextClassName = next + '_prevnext_classes'

            const prevClasses = (justPrev) ? style.prevnextcolumn + ' ' + style.left + ' ' + style.justprevcolumn : style.prevnextcolumn + ' ' + style.left
            const nextClasses = (justNext) ? style.prevnextcolumn + ' ' + style.right + ' ' + style.justnextcolumn : style.prevnextcolumn + ' ' + style.right

            const content = [
                <TableRowColumn
                    key={1}
                    onTouchTap={(prevViewData) ? function(e) {
                        touchEnd(e, 'click', 'click', { type: 'prevnext', view_id: prev })
                    } : null}
                    onTouchStart={function(e) {

                    }}
                    className={(prevViewData) ? prevClasses + ' ' + prevClassName : prevClasses}
                    style={(prevViewData) ? { cursor: 'pointer' } : null}
                >
                    {(prevViewData) ?
                        <div className={style.prevnextbutton}>
                            <div className={style.prevnexticon}><LeftIcon /></div>
                            {(!prevnextnavhidetitle) ? <div className={style.prevnext}>{prevName}</div> : null}
                        </div>
                        : null
                    }
                </TableRowColumn>,
                <TableRowColumn
                    key={2}
                    onTouchTap={(nextViewData) ? function(e) {
                        touchEnd(e, 'click', 'click', { type: 'prevnext', view_id: next })
                    } : null}
                    onTouchStart={function(e) {

                    }}
                    className={(nextViewData) ? nextClasses + ' ' + nextClassName : nextClasses}
                    style={(nextViewData) ? { cursor: 'pointer' } : null}
                >
                    {(nextViewData) ? <div className={style.prevnextbutton}>
                        {(!prevnextnavhidetitle) ? <div className={style.prevnext}>{nextName}</div> : null}
                        <div className={style.prevnexticon}><RightIcon /></div>
                    </div> : null}
                </TableRowColumn>
            ]

            if (justPrev) {
                return content[0]
            }
            if (justNext) {
                return content[1]
            }

            return (
                <Parent disableParentTable={disableParentTable} floatingPaletteProps={floatingPaletteProps}>
                    <TableRow key={0} className={style.prevnextrow} style={prevNextRowBackgroundStyle}>
                        {content}
                    </TableRow>
                </Parent>
            )
        }

        return null

    }
})

const CompassIcon = createReactClass({
    getInitialState: function() {
        const { degree } = this.props
        return {
            degree: degree || 0
        }
    },
    setDegree: function(degree) {
        this.setState({
            degree
        })
    },
    render: function() {
        const { style } = this.props
        return (
            <div className={style.compassIcon}>
                <SvgIcon viewBox={'0,0,1000,1000'}>
                    <g xmlns='http://www.w3.org/2000/svg'>
                        <path
                            className={style.compassIconPath}
                            style={{
                                transform: 'rotate(' + this.state.degree + 'deg)',
                                WebkitTransform: 'rotate(' + this.state.degree + 'deg)',
                                MozTransform: 'rotate(' + this.state.degree + 'deg)',
                                OTransform: 'rotate(' + this.state.degree + 'deg)',
                                msTransform: 'rotate(' + this.state.degree + 'deg)'
                            }}
                            xmlns='http://www.w3.org/2000/svg'
                            d='M706.2,141.1c-3.1,17.1-6,32.6-8.8,48c-14.8,79.7-29.5,159.5-44.3,239.2c-8.7,46.7-17.3,93.5-26.1,140.2c-0.4,2.2-1.9,4.7-3.6,6.2C542.6,643.8,461.8,712.8,381,781.8c-27.7,23.7-55.5,47.3-83.2,70.9c-1.2,1-2.5,1.9-4.8,3.8c1.9-10.3,3.4-19.3,5.1-28.3c24.6-132.9,49.2-265.8,74-398.6c0.5-2.5,2.3-5.3,4.3-7c62.5-53.5,125.2-106.9,187.8-160.4c46.1-39.3,92.1-78.7,138.2-118C703.1,143.6,704,142.9,706.2,141.1z M341.9,770.7c0.4,0.2,0.7,0.5,1.1,0.7c84.6-71.9,169.2-143.8,254.2-216c-65.1-37.6-129.5-74.8-194.4-112.2C382.4,552.9,362.2,661.8,341.9,770.7z' />
                    </g>
                </SvgIcon>
            </div>
        )
    }
})

const Compass = createReactClass({
    getInitialState: function() {
        this.refElements = {}
        const { view_id } = this.props
        return {
            view_id: view_id,
            compass: this.getCompassDegree({ view_id })
        }
    },
    getCompassDegree: function(p = {}) {
        const viewData = p.viewData || this.getViewData({ view_id: p.view_id || this.state.view_id })
        const compass = viewData.compass || 'north'
        let d = 0
        switch (compass) {
            case 'north':
                d = 0
                break
            case 'northeast':
                d = 45
                break
            case 'east':
                d = 90
                break
            case 'southeast':
                d = 135
                break
            case 'south':
                d = 180
                break
            case 'southwest':
                d = 225
                break
            case 'west':
                d = 270
                break
            case 'nortwest':
                d = 315
                break
            default:
                d = 0
        }
        return d
    },
    componentWillReceiveProps: async function(nextProps) {
        const { view_id } = nextProps
        if (view_id !== this.state.view_id) {
            await this.setState({
                view_id: view_id,
                compass: this.getCompassDegree({ view_id })
            })
            this.setDegree(this.state.compass)
        }
    },
    updateCompass: async function(p = {}) {
        if (p && typeof p.compass == 'string') {
            await this.setState({
                compass: this.getCompassDegree({ viewData: { compass: p.compass } })
            })
            this.setDegree(this.state.compass)
        }
    },
    componentDidMount: function() {
        this.setDegree(this.state.compass)
    },
    getPrevNextParent: function(props = {}) {
        const { children, disableParentTable } = props

        if (disableParentTable) return children

        return (
            <Table fixedHeader={true} style={{ backgroundColor: 'transparent' }}>
                <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                    {children}
                </TableHeader>
            </Table>
        )
    },
    getPrevNextRowBackgroundStyle: function() {
        const { construct = {} } = this.props
        const { props } = construct
        if (props.prevnextbgcolor) return { backgroundColor: props.prevnextbgcolor }
        return {}
    },
    getPrevNextViewData: function(initial) {
        const { view_id } = this.state
        const getViewData = this.getViewData

        const viewData = getViewData({ view_id })
        const views = this.getViewObjectsFromObjectId({ object_id: viewData.object_id })

        const r = {}
        if (views && views.length > 1) {
            views.map(function(view, i) {
                if (view.key == view_id) {
                    if (views[i - 1] && views[i - 1].key) {
                        r.prev = views[i - 1].key
                    }
                    if (!r.prev && views[views.length - 1].key !== view_id) {
                        r.prev = views[views.length - 1].key
                    }
                    if (views[i + 1] && views[i + 1].key) {
                        r.next = views[i + 1].key
                    }
                    if (!r.next && views[0].key !== view_id) {
                        r.next = views[0].key
                    }
                }
            })
        }

        return r
    },
    getDataObject: function() {
        const { dataObject = {} } = this.props
        return dataObject
    },
    getSettings: function() {
        const dataObject = this.getDataObject()
        const settings = dataObject.props.settings.props.settings[0]
        return settings
    },
    getViews: function() {
        const dataObject = this.getDataObject()
        const views = dataObject.props.views.props.views
        return views
    },
    getObjects: function() {
        const dataObject = this.getDataObject()
        const objects = dataObject.props.objects.props.objects
        return objects
    },
    getViewObjectsFromObjectId: function({ object_id }) {
        const views = this.getViews()
        const r = []
        if (views && views.length) {
            views.map(function(view) {
                if (view.object_id == object_id) {
                    r.push(view)
                }
            })
        }
        return r
    },
    getViewData: function(p = {}) {
        const { view_id } = p
        if (view_id) {
            const views = this.getViews()
            if (views && views.length) {
                let foundView = null
                views.map(function(view) {
                    if (view.key == view_id) foundView = view
                })
                return foundView
            }
        }
        return null
    },
    translate: function({ text }) {
        const settings = this.getSettings() || {}
        const { construct } = this.props
        const { translations = [] } = (construct && construct.props && construct.props.translations) ? { translations: construct.props.translations } : settings
        let translatedText = null
        if (typeof text == 'string' && text) {
            translations.map(function(translate) {
                if (!translatedText && translate.text && translate.translate && translate.text == text) {
                    translatedText = translate.translate
                }
            })
        }
        if (translatedText) return translatedText
        return text
    },
    getObjectData: function(p = {}) {
        const { object_id } = p
        if (object_id) {
            const objects = this.getObjects()
            if (objects && objects.length) {
                let foundObject = null
                objects.map(function(object) {
                    if (object.key == object_id) foundObject = object
                })
                return foundObject
            }
        }
        return null
    },
    getViewObjectData: function({ view_id }) {
        const viewData = this.getViewData({ view_id })
        if (viewData && viewData.object_id) {
            return this.getObjectData({ object_id: viewData.object_id })
        }
        return null
    },
    getHistoryData: function() {
        const { refElements } = this.props
        const player = refElements['playercomponent']
        const playerState = (player) ? player.state : {}
        const { history, currentHistoryStep } = playerState
        return { history, currentHistoryStep }
    },
    setDegree: function(degree) {
        if (this.refElements['compassicon']) {
            this.refElements['compassicon'].setDegree(degree)
        }
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type == 'mouseup' || e && e.type == 'touchend' || e && e.type == pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { view_id, type } = d
        if (type == 'prevnext') {
            this.prevnext({ view_id })
        }
        e.preventDefault()
    },
    prevnext: function({ view_id }) {
        const { setCurrentView } = this.props
        const historyData = this.getHistoryData()
        const { currentHistoryStep = 0 } = historyData
        if (view_id) {
            setCurrentView({ view_id: view_id, step: currentHistoryStep, enableFloatingPaletteScrollTop: true })
        }
    },
    setRef: function(a, e, n) {
        if (e) {
            if (n) {
                this[n][a] = e
            } else {
                this.refElements[a] = e
            }
        }
    },
    render: function() {

        const { disableParentTable, style, prevnextnavhidetitle, floatingPaletteProps } = this.props
        const prevNextData = this.getPrevNextViewData() || {}
        const { prev, next } = prevNextData
        const touchEnd = this.touchEnd
        const Parent = this.getPrevNextParent
        const setRef = this.setRef

        if (prev || next || typeof this.state.compass == 'number') {

            const prevViewData = (prev) ? this.getViewData({ view_id: prev }) : null
            const nextViewData = (next) ? this.getViewData({ view_id: next }) : null

            const prevObjectData = (prevViewData && prevViewData.object_id) ? this.getViewObjectData({ view_id: prev }) : null
            const nextObjectData = (nextViewData && nextViewData.object_id) ? this.getViewObjectData({ view_id: next }) : null

            let prevName = (prevObjectData && prevObjectData.name) ? prevObjectData.name : ''
            let nextName = (nextObjectData && nextObjectData.name) ? nextObjectData.name : ''

            const prevViewName = (prevViewData && prevViewData.name) ? prevViewData.name : ''
            const nextViewName = (nextViewData && nextViewData.name) ? nextViewData.name : ''

            if (prevViewName) {
                if (prevName) {
                    prevName = prevName + ' [' + prevViewName + ']'
                } else {
                    prevName = prevViewName
                }
            }
            if (nextViewName) {
                if (nextName) {
                    nextName = nextName + ' [' + nextViewName + ']'
                } else {
                    nextName = nextViewName
                }
            }

            const content = [
                <TableRowColumn
                    key={1}
                    onTouchTap={(prevViewData) ? function(e) {
                        touchEnd(e, 'click', 'click', { type: 'prevnext', view_id: prev })
                    } : null}
                    onTouchStart={function(e) {

                    }}
                    style={(prevViewData) ? { cursor: 'pointer' } : null}
                >
                    {(prevViewData) ?
                        <div className={style.compassbutton}>
                            <div className={style.compassbuttonIcon}><RotateLeftIcon /></div>
                        </div>
                        : null
                    }
                </TableRowColumn>,
                <TableRowColumn key={2}>
                    <CompassIcon degree={0} style={style} ref={function(e) {
                        setRef('compassicon', e)
                    }} />
                </TableRowColumn>,
                <TableRowColumn
                    key={3}
                    onTouchTap={(nextViewData) ? function(e) {
                        touchEnd(e, 'click', 'click', { type: 'prevnext', view_id: next })
                    } : null}
                    onTouchStart={function(e) {

                    }}
                    style={(nextViewData) ? { cursor: 'pointer' } : null}
                >
                    {(nextViewData) ? <div className={style.compassbutton}>
                        <div className={style.compassbuttonIcon}><RotateRightIcon /></div>
                    </div> : null}
                </TableRowColumn>
            ]

            return (
                <Parent disableParentTable={disableParentTable} floatingPaletteProps={floatingPaletteProps}>
                    <TableRow key={0} className={style.compass}>
                        {content}
                    </TableRow>
                </Parent>
            )
        }

        return null

    }
})

const List = createReactClass({
    getInitialState: function() {

        this.refElements = {}
        const hotspots = this.getHotSpotsData()
        const thereHotspots = (hotspots && hotspots.length) ? true : false
        const { filteredHotSpots = {}, orderByMetas = {}, forceOpen, active } = this.props

        return {
            infoOpen: (forceOpen) ? true : (thereHotspots) ? false : true,
            filteredHotSpots: filteredHotSpots,
            orderByMetas: orderByMetas,
            active: active
        }
    },
    componentDidMount: function() {
        const { view_id } = this.props
        this.addClasses()
        this.createInfoStyle({ view_id })
    },
    getDataObject: function() {
        const { dataObject = {} } = this.props
        return dataObject
    },
    getSettings: function() {
        const dataObject = this.getDataObject()
        const settings = dataObject.props.settings.props.settings[0]
        return settings
    },
    getViews: function() {
        const dataObject = this.getDataObject()
        const views = dataObject.props.views.props.views
        return views
    },
    getGalleries: function() {
        const dataObject = this.getDataObject()
        const views = dataObject.props.galleries.props.galleries
        return views
    },
    getObjects: function() {
        const dataObject = this.getDataObject()
        const objects = dataObject.props.objects.props.objects
        return objects
    },
    getMetas: function() {
        const dataObject = this.getDataObject()
        const metas = dataObject.props.metas.props.metas
        return metas
    },
    getViewObjectsFromObjectId: function({ object_id }) {
        const views = this.getViews()
        const r = []
        if (views && views.length) {
            views.map(function(view) {
                if (view.object_id == object_id) {
                    r.push(view)
                }
            })
        }
        return r
    },
    getViewData: function(p = {}) {
        const { view_id } = p
        if (view_id) {
            const views = this.getViews()
            if (views && views.length) {
                let foundView = null
                views.map(function(view) {
                    if (view.key == view_id) foundView = view
                })
                return foundView
            }
        }
        return null
    },
    translate: function({ text }) {
        const settings = this.getSettings() || {}
        const { construct } = this.props
        const { translations = [] } = (construct && construct.props && construct.props.translations) ? { translations: construct.props.translations } : settings
        let translatedText = null
        if (typeof text == 'string' && text) {
            translations.map(function(translate) {
                if (!translatedText && translate.text && translate.translate && translate.text == text) {
                    translatedText = translate.translate
                }
            })
        }
        if (translatedText) return translatedText
        return text
    },
    getObjectData: function(p = {}) {
        const { object_id } = p
        if (object_id) {
            const objects = this.getObjects()
            if (objects && objects.length) {
                let foundObject = null
                objects.map(function(object) {
                    if (object.key == object_id) foundObject = object
                })
                return foundObject
            }
        }
        return null
    },
    getViewObjectData: function({ view_id }) {
        const viewData = this.getViewData({ view_id })
        if (viewData && viewData.object_id) {
            return this.getObjectData({ object_id: viewData.object_id })
        }
        return null
    },
    getViewsByObjectId: function(p = {}) {
        const { object_id } = p
        if (object_id) {
            const views = this.getViews()
            if (views && views.length) {
                const foundViews = []
                views.map(function(view) {
                    if (view.object_id == object_id) foundViews.push(view)
                })
                return foundViews
            }
        }
        return []
    },
    getPopupBackgroundColor: function() {
        const settings = this.getSettings() || {}
        const { colors = {} } = settings
        const { player = {} } = colors
        const { tooltip = {} } = player
        return tooltip.backgroundColor
    },
    showOrNot: function(show, showdata, type, objectData) {
        if (showdata) {
            let sd = showdata
            if (typeof sd == 'string') sd = JSON.parse(sd)
            if (sd && typeof sd == 'object') {
                const { disable = {} } = sd
                if (typeof disable == 'object') {
                    let foundAll = true
                    Object.keys(sd.disable).map(function(key) {
                        const disableDatas = sd.disable[key]
                        if (disableDatas.indexOf(objectData[key]) == -1) {
                            foundAll = false
                        }
                    })
                    if (foundAll) return false
                }
            }
        }
        return (show.indexOf(type) > -1) ? true : false
    },
    getDataToView: function(p = {}) {
        const { type = 'table', forceKeys = [], view_id } = p
        const { style, getRootContainer } = this.props
        const objectData = this.getViewObjectData({ view_id })
        const metas = this.getMetas()
        const translate = this.translate
        const setRef = this.setRef
        const refElements = this.refElements
        const getViewsByObjectId = this.getViewsByObjectId
        const getHotSpotsData = this.getHotSpotsData
        const getViewObjectData = this.getViewObjectData
        const showOrNot = this.showOrNot

        if (objectData) {

            const enabled = {}
            const orders = []
            const r = []

            function getFormatDataObject(type, data) {
                const dataObject = (data && typeof data == 'string') ? JSON.parse(data) : (data && typeof data == 'object') ? data : {}
                return (dataObject && type && dataObject[type]) ? dataObject[type] : {}
            }

            const formats = {
                price: function(value, data) {
                    const n = 0
                    const x = 0
                    const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')'
                    return (value && value.toFixed || value == 0) ? value.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$& ') : value
                },
                endFix: function(value, data) {
                    const endFix = getFormatDataObject('endFix', data)
                    if (endFix && typeof endFix.not !== 'undefined') {
                        if (typeof endFix.not == 'string' && endFix.not == value) {
                            return value
                        } else if (typeof endFix.not == 'object' && endFix.not.length) {
                            let foundEqual = false
                            endFix.not.forEach(function(not) {
                                if (value == not) {
                                    foundEqual = true
                                }
                            })
                            if (foundEqual) {
                                return value
                            }
                        }
                    }
                    return (value && endFix.value) ? value + ' ' + endFix.value : value
                },
                replace: function(value, data) {
                    const replace = getFormatDataObject('replace', data)
                    const { status = [], type = [] } = replace
                    if (status.indexOf(objectData.status) > -1 && type.indexOf(objectData.type) > -1) {
                        if (replace.value || replace.value == '' || replace.value == 0) return replace.value
                    }
                    return value
                },
                count: function(value, data) {
                    const count = getFormatDataObject('count', data)
                    const { status = [], type = [] } = count
                    const viewsForObject = getViewsByObjectId({ object_id: objectData.key })
                    const aViewForObject = (viewsForObject) ? viewsForObject[0] : null
                    if (aViewForObject) {
                        let ct = 0
                        const hotspots = getHotSpotsData({ view_id: aViewForObject.key })
                        hotspots.map(function(hotspot) {
                            const view_id = hotspot.props.view_id
                            const objectData = getViewObjectData({ view_id })
                            if (objectData && status.indexOf(objectData.status) > -1 && type.indexOf(objectData.type) > -1) {
                                ct = ct + 1
                            }
                        })
                        if (typeof count.value == 'object' && count.value[ct.toString()]) {
                            return count.value[ct.toString()]
                        }
                        return ct
                    }
                    return value
                },
                download: function(value, data) {
                    const download = getFormatDataObject('download', data)
                    const disableIcon = (download && download.disableIcon) ? true : false
                    const label = (download && typeof download.label == 'string') ? download.label : 'download'
                    return (
                        <FlatButton
                            label={translate({ text: label })}
                            href={value}
                            icon={(disableIcon) ? null : <DownloadIcon />}
                            target={'_blank'}
                            download={true}
                        />
                    )

                },
                open: function(value, data) {
                    const open = getFormatDataObject('open', data)
                    const disableIcon = (open && open.disableIcon) ? true : false
                    return (
                        <FlatButton
                            label={translate({ text: 'open' })}
                            href={value}
                            icon={(disableIcon) ? null : <OpenIcon />}
                            target={'_blank'}
                            download={true}
                        />
                    )

                },
                formAndRequest: function(value, data) {
                    const formAndRequestData = getFormatDataObject('formAndRequest', data)
                    const savedPost = {}

                    return (<FormAndRequest
                        value={value}
                        data={formAndRequestData}
                        translate={translate}
                        style={style}
                        post={savedPost}
                        getRootContainer={getRootContainer}
                    />)

                }
            }

            Object.keys(objectData).map(function(key) {
                metas.map(function(meta) {

                    const show = meta.show || []
                    const showdata = meta.showdata

                    const go = (showdata) ? showOrNot(show, showdata, type, objectData) : (show.indexOf(type) > -1) ? true : false

                    if (go && meta.key == key || meta && meta.key == key && forceKeys.indexOf(key) > -1) {

                        if (meta.enabled && meta.enabled.indexOf(objectData.type) > -1) {

                            const endFix = meta.endfix
                            let value = objectData[key]

                            if (key == 'status') {
                                value = translate({ text: value })
                            }

                            let format = meta.format || []
                            let formatdata = meta.formatdata || {}

                            if (endFix) {
                                if (typeof format == 'string') {
                                    format = [format, 'endfix']
                                } else {
                                    format = [...format]
                                    format.push('endFix')
                                }
                                if (typeof formatdata == 'string') {
                                    formatdata = JSON.parse(formatdata)
                                } else {
                                    formatdata = { ...formatdata }
                                }
                                if (!formatdata.endFix) {
                                    formatdata.endFix = { value: endFix }
                                }
                                if (typeof formatdata.endFix.value == 'undefined') {
                                    formatdata.endFix.value = endFix
                                }
                            }

                            if (format) {

                                if (format && typeof format == 'string' && formats[format]) {
                                    value = formats[format](value, formatdata)
                                }
                                if (format && typeof format == 'object' && format.length) {
                                    if (formatdata && typeof formatdata.order == 'object' && formatdata.order.length) {
                                        formatdata.order.map(function(fkey) {
                                            if (format.indexOf(fkey) > -1) {
                                                value = formats[fkey](value, formatdata)
                                            }
                                        })
                                    } else {
                                        Object.keys(formats).map(function(fkey) {
                                            if (format.indexOf(fkey) > -1) {
                                                value = formats[fkey](value, formatdata)
                                            }
                                        })
                                    }
                                }
                            }

                            enabled[key] = ({ value: value, meta: meta })
                            orders.push([meta.order, key])

                        }
                    }
                })
            })
            orders.sort(function(a, b) {
                const aorder = a[0] || 0
                const border = b[0] || 0
                return (aorder > border) ? 1 : -1
            })
            orders.map(function(a) {
                const key = a[1]
                r.push(enabled[key])
            })

            return r
        }
        return null
    },
    addClasses: function() {
        const createStyle = this.createStyle
        const hotspots = this.getHotSpotsData()
        const getRow = this.getRow
        if (hotspots && hotspots.length) {
            hotspots.map(function(hotspot) {
                createStyle({ view_id: hotspot.props.view_id })
            })
        }
    },
    getHotSpotColors: function({ view_id }) {

        const r = {
            defaultColor: 'rgba(0,0,0,0)',
            hoverColor: 'rgba(255,255,255,1)'
        }

        if (view_id) {
            const settings = this.getSettings() || {}
            const { colors = {} } = settings
            const objectData = this.getViewObjectData({ view_id })
            if (objectData && objectData.type) {
                if (colors && colors[objectData.type] && colors[objectData.type][objectData.status]) {
                    return { ...colors[objectData.type][objectData.status] }
                }
            }
        }

        return r

    },
    createInfoStyle: function({ view_id }) {

        const className = view_id + '_info_classes'

        const head = document.head || document.getElementsByTagName('head')[0]
        if (document.getElementById(className)) {
            return null
        }

        const hotSpotColors = this.getHotSpotColors({ view_id })

        let css = '.' + className + ' .popupheader { background-color:' + hotSpotColors.hoverColor + '!important; color:#ffffff!important; text-align:center!important; font-weight:bold!important}'

        const style = document.createElement('style')

        style.type = 'text/css'

        if (style.styleSheet) {
            style.styleSheet.cssText = css
        } else {
            style.appendChild(document.createTextNode(css))
        }

        style.setAttribute('id', className)

        head.appendChild(style)
    },
    createStyle: function({ view_id }) {

        const className = view_id + '_table_classes'
        const disabledColor = 'rgb(158, 158, 158)'

        const head = document.head || document.getElementsByTagName('head')[0]
        if (document.getElementById(className)) {
            return null
        }

        const hotSpotColors = this.getHotSpotColors({ view_id })

        const defaultColor = 'transparent'

        let css = '.' + className + ' { background-color:' + defaultColor + '!important; }'
        css = css + ' .' + className + ' td { color: rgba(0, 0, 0, 0.87)!important; }'
        css = css + ' .' + className + ' .tablerowcolor { background-color:' + hotSpotColors.hoverColor + '!important; }'

        css = css + ' .' + className + '.active { background-color:' + hotSpotColors.hoverColor + '!important;; }'
        css = css + ' .' + className + '.active td { color: #ffffff!important; }'

        css = css + ' .' + className + ':hover { background-color:' + hotSpotColors.hoverColor + '!important;; }'
        css = css + ' .' + className + ':hover td { color: #ffffff!important; }'

        css = css + ' .' + className + '.ismobile:hover { background-color:' + '#ffffff' + '!important;; }'
        css = css + ' .' + className + '.ismobile:hover td { color: rgba(0, 0, 0, 0.87)!important; }'

        css = css + ' .' + className + '.ismobile.active { background-color:' + hotSpotColors.hoverColor + '!important;; }'
        css = css + ' .' + className + '.ismobile.active td { color: #ffffff!important; }'

        css = css + ' .' + className + '.disabled { background-color:' + '#ffffff' + '!important;; }'
        css = css + ' .' + className + '.disabled td { color: ' + disabledColor + '!important; }'
        css = css + ' .' + className + '.disabled .tablerowcolor { background-color:' + disabledColor + '!important; }'

        css = css + ' .' + className + '.active.disabled { background-color:' + disabledColor + '!important;; }'
        css = css + ' .' + className + '.active.disabled td { color: ' + '#ffffff' + '!important; }'
        css = css + ' .' + className + '.active.disabled .tablerowcolor { background-color:' + disabledColor + '!important; }'

        const style = document.createElement('style')

        style.type = 'text/css'

        if (style.styleSheet) {
            style.styleSheet.cssText = css
        } else {
            style.appendChild(document.createTextNode(css))
        }

        style.setAttribute('id', className)

        head.appendChild(style)
    },
    setRef: function(a, e, n) {
        if (e) {
            if (n) {
                this[n][a] = e
            } else {
                this.refElements[a] = e
            }
        }
    },
    setActive: function({ id }) {
        const { active } = this.state
        if (active !== id) {
            if (this.refElements['row_' + active]) this.refElements['row_' + active].mouseLeave()
            this.setState({
                active: id
            })
        }
    },
    getRow: function({
                         view_id,
                         parent_view_id,
                         effect,
                         effect_data,
                         rowi,
                         id,
                         active,
                         containerStyle,
                         disabled,
                         disabledClick,
                         listStatusColorWidth,
                         disableRunHotSpotFunction
                     }) {

        const { style, refElements, setCurrentView } = this.props
        const tableData = this.getDataToView({ type: 'table', forceKeys: ['name'], view_id: view_id })
        const viewData = this.getViewData({ view_id })
        const name = (viewData) ? viewData.name : ''
        const setRef = this.setRef

        const setActive = this.setActive

        if (tableData && tableData.length) {

            return <ListRow
                key={rowi}
                ref={function(e) {
                    setRef('row_' + id, e)
                }}
                style={style}
                tableData={tableData}
                view_id={view_id}
                parent_view_id={parent_view_id}
                setCurrentView={setCurrentView}
                refElements={refElements}
                effect={effect}
                effect_data={effect_data}
                viewName={name}
                id={id}
                active={active}
                setParentActive={setActive}
                containerStyle={containerStyle}
                disabled={disabled}
                disabledClick={disabledClick}
                listStatusColorWidth={listStatusColorWidth}
                disableRunHotSpotFunction={disableRunHotSpotFunction}
            />
        }

        return null

    },
    getHotSpotsData: function(p = {}) {
        const { view_id, style } = this.props
        const vid = (p.view_id) ? p.view_id : view_id
        const viewData = this.getViewData({ view_id: vid })

        const viewObjectsFromObjectId = this.getViewObjectsFromObjectId({ object_id: viewData.object_id })
        const hotspots = []
        viewObjectsFromObjectId.map(function(view) {
            const hs = view.elements[0].children[1].children[0].props.hotspots
            hs.map(function(h) {
                h.parent_view_id = view.key
            })
            hotspots.push(...hs)
        })
        return hotspots
    },
    getHotSpotsObjectType: function() {
        const hotspots = this.getHotSpotsData()
        const getViewObjectData = this.getViewObjectData
        const r = {
            area: [],
            building: [],
            flat: [],
            notype: []
        }
        hotspots.map(function(hotspot) {
            const view_id = hotspot.props.view_id
            const objectData = getViewObjectData({ view_id })
            const type = (objectData && objectData.type) ? objectData.type : null
            if (type == null) {
                r.notype.push(hotspot)
            } else {
                r[type].push(hotspot)
            }
        })
        return r
    },
    getHotSpotsDataByType: function({ type }) {
        const hotspots = this.getHotSpotsData()
        const getViewObjectData = this.getViewObjectData
        const r = []
        hotspots.map(function(hotspot, i) {
            const view_id = hotspot.props.view_id
            const objectData = getViewObjectData({ view_id })
            const t = (objectData && objectData.type) ? objectData.type : null
            if (t == type || type == 'undefined') {
                r.push(hotspot)
            }
        })
        return r
    },
    isMobile: function() {
        if (typeof window !== 'undefined') {
            const md = new MobileDetect(window.navigator.userAgent)
            return md.mobile() || md.tablet() || md.phone()
        }
        return false
    },
    getRows: function() {
        const { style } = this.props
        const hotspotsTypes = this.getHotSpotsObjectType()
        const getRowsViewByType = this.getRowsViewByType
        const r = []

        Object.keys(hotspotsTypes).map(function(key, i) {
            if (hotspotsTypes[key].length) {
                const type = (key == 'notype') ? null : key
                if (type) {
                    r.push(
                        getRowsViewByType({
                            type: type,
                            dataKey: key
                        })
                    )
                }
            }
        })

        if (r.length) return <div className={style.inforows}>{r}</div>
        return null

    },
    setOrderByMeta: function({ type, meta }) {
        const { orderByMetas = {} } = this.state
        const v = orderByMetas[type] || {}
        if (meta !== v.meta) {

            const newSet = {
                ...orderByMetas,
                [type]: {
                    meta: meta,
                    order: 1
                }
            }

            this.setState({
                orderByMetas: newSet
            })

        } else {

            let order = v.order || 0
            order = order + 1

            const newSet = {
                ...orderByMetas
            }

            if (order <= 2) {
                newSet[type] = {
                    meta: meta,
                    order: order
                }
            } else {
                delete newSet[type]
            }

            this.setState({
                orderByMetas: newSet
            })

        }
    },
    getRowsViewByType: function(p = {}) {

        const { view_id } = this.props
        const { disableShowActive, type, dataKey } = p
        const { style } = this.props
        const { construct } = this.props
        const enableclicktosoldobject = (construct && construct.props && construct.props.enableclicktosoldobject) ? true : false
        const listStatusColorWidth = (construct && construct.props && construct.props.liststatuscolorwidth) ? construct.props.liststatuscolorwidth : null
        const getRow = this.getRow

        const { active, filteredHotSpots = {}, orderByMetas = {} } = this.state
        const currentOrderByMeta = orderByMetas[type] || {}
        const isMobile = this.isMobile()
        const hotspots = this.getHotSpotsData()
        const getViewObjectData = this.getViewObjectData
        const getDataToView = this.getDataToView
        const translate = this.translate
        const touchEnd = this.touchEnd

        if (hotspots && hotspots.length) {

            const activeObject = (hotspots[active]) ? getViewObjectData({ view_id: hotspots[active].props.view_id }) : {}
            const activeType = (activeObject) ? activeObject.type : null
            let activeN = 0

            let titles = null
            const rHotSpots = []

            let showN = -1
            hotspots.map(function(hotspot, i) {
                const objectData = getViewObjectData({ view_id: hotspot.props.view_id })
                const t = (objectData && objectData.type) ? objectData.type : null
                const hide = (filteredHotSpots[hotspot.props.view_id]) ? true : false
                const disabledClick = (!enableclicktosoldobject && objectData && objectData.status == 'sold') ? true : false

                if (t == type) {
                    rHotSpots.push({
                        props: {
                            view_id: hotspot.props.view_id,
                            parent_view_id: (hotspot.parent_view_id && view_id !== hotspot.parent_view_id) ? hotspot.parent_view_id : null,
                            rowi: i,
                            id: i,
                            effect: hotspot.props.effect,
                            effect_data: hotspot.props.effect_data,
                            disabled: hide,
                            disabledClick: disabledClick,
                            listStatusColorWidth: listStatusColorWidth,
                            active: (active == i) ? true : false
                        }, objectData, hotspot
                    })

                    if (!titles) {
                        const tableData = getDataToView({
                            type: 'table',
                            forceKeys: ['name'],
                            view_id: hotspot.props.view_id
                        })

                        if (tableData) {
                            titles = []
                            tableData.map(function(data, i) {

                                const order = (currentOrderByMeta.meta == data.meta.key) ? currentOrderByMeta.order : 0

                                titles.push({
                                    name: translate({ text: data.meta.name }),
                                    key: data.meta.key,
                                    order: order
                                })
                            })
                        }
                    }

                }
            })

            const orderedHotspots = [...rHotSpots]

            function getSortByMeta(a, b) {
                if (currentOrderByMeta.meta && currentOrderByMeta.order == 1 ||
                    currentOrderByMeta.meta && currentOrderByMeta.order == 2) {
                    const meta = currentOrderByMeta.meta
                    const order = (currentOrderByMeta.order == 1) ? 'forward' : 'backward'
                    const aObjectData = a.objectData
                    const bObjectData = b.objectData
                    if (order == 'forward') {
                        return (aObjectData[meta] < bObjectData[meta]) ? -1 : (aObjectData[meta] > bObjectData[meta]) ? 1 : 0
                    } else {
                        return (aObjectData[meta] > bObjectData[meta]) ? -1 : (aObjectData[meta] < bObjectData[meta]) ? 1 : 0
                    }
                }
                return false
            }

            orderedHotspots.sort(function(a, b) {
                const aProps = a.props
                const bProps = b.props
                if (!aProps.disabled && bProps.disabled) return -1
                if (aProps.disabled && !bProps.disabled) return 1

                const sortByMeta = getSortByMeta(a, b)
                if (sortByMeta) return sortByMeta
                return (aProps.rowi < bProps.rowi) ? -1 : 1
            })

            const renderedHotspots = orderedHotspots.map(function(hd) {
                showN = showN + 1
                if (hotspots[active] == hd.hotspot) activeN = showN
                return getRow(hd.props)
            })

            if (rHotSpots && rHotSpots.length) {

                return (
                    <Table fixedHeader={true} key={dataKey} style={{ backgroundColor: 'transparent' }}>
                        <TableHeader
                            displaySelectAll={false}
                            adjustForCheckbox={false}
                        >
                            {(titles && titles.length) ?
                                <TableRow>
                                    {titles.map(function(title, i) {
                                        return (
                                            <TableHeaderColumn
                                                onTouchTap={function(e) {
                                                    touchEnd(e, 'click', 'click', {
                                                        type: 'setOrderByMeta',
                                                        data: { type: type, meta: title.key }
                                                    })
                                                }}
                                                key={i}
                                                className={style.tablevalue + ' ' + style.tablevaluetitle}>
                                                {(title.order == 1) ? title.name + ' ▼' : (title.order == 2) ? title.name + ' ▲' : title.name}
                                            </TableHeaderColumn>
                                        )
                                    })}
                                </TableRow> :
                                <TableRow></TableRow>
                            }
                        </TableHeader>
                        <TableBody displayRowCheckbox={false}>
                            {(activeN && isMobile && !disableShowActive && activeType == type) ?
                                getRow({
                                    view_id: hotspots[active].props.view_id,
                                    rowi: -1,
                                    id: active,
                                    active: true,
                                    effect: hotspots[active].props.effect,
                                    effect_data: hotspots[active].props.effect_data,
                                    containerStyle: {
                                        borderBottom: '4px solid rgb(196, 196, 196)'
                                    },
                                    disableRunHotSpotFunction: true
                                })
                                : null
                            }
                            {renderedHotspots}
                        </TableBody>
                    </Table>
                )
            }
        }
        return null

    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type == 'mouseup' || e && e.type == 'touchend' || e && e.type == pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { onClose } = this.props
        const { type, view_id, href, data } = d
        if (type == 'setOrderByMeta') {
            this.setOrderByMeta(data)
        }
        if (type == 'toogleInfo') {
            this.toogleInfo()
        }
        if (type == 'back') {
            this.back()
        }
        if (type == 'clearFilter') {
            this.clearFilter()
        }
        if (type == 'setCurrentView') {
            const { setCurrentView } = this.props
            if (setCurrentView) setCurrentView(data)
        }
        e.preventDefault()
    },
    clearFilter: function() {
        const { clearFilter } = this.props
        if (clearFilter) {
            clearFilter()
        }
    },
    thereBack: function() {
        const { refElements } = this.props
        const player = refElements['playercomponent']
        const playerState = (player) ? player.state : {}
        const { history, currentHistoryStep } = playerState
        let backStep = (currentHistoryStep > 0) ? history[currentHistoryStep - 1] : false
        if (backStep) {
            const viewData = this.getViewData({ view_id: backStep })
            if (viewData && viewData.hidden) return false
        }
        return backStep
    },
    getHistoryData: function() {
        const { refElements } = this.props
        const player = refElements['playercomponent']
        const playerState = (player) ? player.state : {}
        const { history, currentHistoryStep } = playerState
        return { history, currentHistoryStep }
    },
    back: function() {
        const { setCurrentView } = this.props
        setCurrentView({ back: true })
    },
    openInfo: function() {
        const { infoOpen } = this.state
        if (!infoOpen) {
            this.setState({
                infoOpen: true
            })
        }
    },
    closeInfo: function() {
        const { infoOpen } = this.state
        if (infoOpen) {
            this.setState({
                infoOpen: false
            })
        }
    },
    toogleInfo: function() {
        const { infoOpen } = this.state
        if (infoOpen) {
            this.closeInfo()
        } else {
            this.openInfo()
        }
    },
    getPrevNextProps: function() {
        const {
            style,
            view_id,
            construct,
            dataObject,
            setCurrentView,
            refElements,
            prevnextnavhidetitle,
            floatingPaletteProps
        } = this.props
        return {
            style,
            view_id,
            construct,
            dataObject,
            setCurrentView,
            refElements,
            prevnextnavhidetitle,
            floatingPaletteProps
        }
    },
    getGallery: function(viewData) {
        let foundGallery = null
        if (viewData && viewData.gallery && viewData.gallery.gallery_id) {
            const galleries = this.getGalleries()
            if (galleries && galleries.length) {
                galleries.map(function(gallery) {
                    if (gallery.key == viewData.gallery.gallery_id) {
                        foundGallery = gallery
                    }
                })
            }
        }
        return foundGallery
    },
    getPrevNextNavPosition: function() {
        const { view_id, construct } = this.props
        const objectData = this.getViewObjectData({ view_id }) || { type: 'undefinedType' }
        let prevnextnavposition = (construct && construct.props) ? construct.props.prevnextnavposition : null
        try {
            prevnextnavposition = JSON.parse(prevnextnavposition)
        } catch (e) {
        }
        if (prevnextnavposition && prevnextnavposition.type && objectData.type && prevnextnavposition.type[objectData.type]) {
            return prevnextnavposition.type[objectData.type]
        }
        return prevnextnavposition
    },
    getInfoHeaderPosition: function() {
        const { view_id, construct } = this.props
        const objectData = this.getViewObjectData({ view_id }) || { type: 'undefinedType' }
        let infoheaderposition = (construct && construct.props) ? construct.props.infoheaderposition : null
        try {
            infoheaderposition = JSON.parse(infoheaderposition)
        } catch (e) {
        }
        if (infoheaderposition && infoheaderposition.type && objectData.type && infoheaderposition.type[objectData.type]) {
            return infoheaderposition.type[objectData.type]
        }
        return infoheaderposition
    },
    getInfo: function() {

        const { view_id, style, setCurrentView, construct, forceOpen, disableSameViews } = this.props
        const prevnextnavposition = this.getPrevNextNavPosition()
        const getPrevNextProps = this.getPrevNextProps

        const setRef = this.setRef
        const touchEnd = this.touchEnd
        const { infoOpen } = this.state
        const hotspots = this.getHotSpotsData()
        const thereHotspots = (hotspots && hotspots.length) ? true : false
        const thereBack = this.thereBack()

        const historyData = this.getHistoryData()
        const { currentHistoryStep = 0 } = historyData

        const paperstyle = {
            display: 'flex',
            position: 'relative',
            width: 'auto',
            flexDirection: 'column',
            padding: '0px 0px',
            backgroundColor: 'rgba(0,0,0,0)'
        }

        const popupData = this.getDataToView({ type: 'popup', forceKeys: ['name'], view_id: view_id })
        const viewData = this.getViewData({ view_id })
        const viewName = (viewData) ? viewData.name : ''
        const hotSpotColors = this.getHotSpotColors({ view_id })
        const gallery = this.getGallery(viewData)
        const translate = this.translate

        if (popupData && popupData.length) {

            let name = ''

            popupData.map(function(data, i) {
                if (data.meta.key == 'name') {
                    name = data.value
                }
            })

            const tempname = name

            if (viewName) {
                if (name) {
                    name = name + ' [' + viewName + ']'
                } else {
                    name = viewName
                }
            }

            const className = view_id + '_info_classes'

            const viewObjectsFromObjectId = this.getViewObjectsFromObjectId({ object_id: viewData.object_id })
            const selectDataToViews = []

            if (viewObjectsFromObjectId && viewObjectsFromObjectId.length > 1 && !disableSameViews) {
                viewObjectsFromObjectId.map(function(view) {

                    let name = tempname
                    const viewName = view.name

                    if (viewName) {
                        if (name) {
                            name = name + ' [' + viewName + ']'
                        } else {
                            name = viewName
                        }
                    }

                    selectDataToViews.push({
                        label: name,
                        value: view.key
                    })
                })
            }

            return (
                <div className={style.popup + ' ' + className}>
                    <Table fixedHeader={true} style={{ backgroundColor: 'transparent' }}>

                        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                            <TableRow className={style.popupheader + ' popupheader'}>
                                {
                                    (thereBack) ?
                                        <TableRowColumn
                                            className={style.backbutton + ' backbutton'}
                                            onTouchTap={function(e) {
                                                touchEnd(e, 'click', 'click', { type: 'back' })
                                            }}
                                            onTouchStart={function(e) {

                                            }}
                                            style={{ width: '48px', padding: '12px' }}>
                                            <BackIcon />
                                        </TableRowColumn> :
                                        <TableRowColumn style={{ width: '48px', padding: '12px' }}></TableRowColumn>
                                }
                                {
                                    (prevnextnavposition == 'topinpopupheader') ?
                                        <PrevNext {...getPrevNextProps()} justPrev={true} />
                                        : null
                                }
                                {(selectDataToViews && selectDataToViews.length > 1) ?
                                    <TableRowColumn style={{ textAlign: 'center' }}>
                                        <Select
                                            style={sls}
                                            multiple={false}
                                            options={selectDataToViews}
                                            value={view_id}
                                            onChange={function(e, value) {
                                                const v = (typeof value == 'object') ? value[0] : value
                                                if (typeof v == 'string') {
                                                    if (v !== view_id) setCurrentView({
                                                        view_id: v,
                                                        step: currentHistoryStep
                                                    })
                                                }
                                            }}
                                            containerStyle={{
                                                display: 'flex'
                                            }}
                                            selectStyle={{
                                                fontSize: '13px'
                                            }}
                                            labelStyle={{
                                                color: '#ffffff'
                                            }}
                                            underlineStyle={{
                                                borderColor: 'transparent'
                                            }}
                                            selectedMenuItemStyle={{
                                                color: hotSpotColors.hoverColor
                                            }}
                                            disableIcon={true}
                                            inset={false}
                                        />
                                    </TableRowColumn> :
                                    <TableRowColumn style={{ textAlign: 'center' }}>{name}</TableRowColumn>
                                }
                                {
                                    (prevnextnavposition == 'topinpopupheader') ?
                                        <PrevNext {...getPrevNextProps()} justNext={true} /> :
                                        (thereHotspots && popupData.length > 1) ?
                                            <TableRowColumn
                                                onTouchTap={function(e) {
                                                    touchEnd(e, 'click', 'click', { type: 'toogleInfo' })
                                                }}
                                                onTouchStart={function(e) {

                                                }}
                                                style={{ width: '48px', padding: '12px' }}>
                                                {(forceOpen) ? null : (infoOpen) ? <UpIcon /> : <DownIcon />}
                                            </TableRowColumn> :
                                            <TableRowColumn style={{ width: '48px', padding: '12px' }}></TableRowColumn>
                                }
                            </TableRow>
                        </TableHeader>
                        {(infoOpen) ?
                            <TableBody displayRowCheckbox={false} className={style.popupbody}>
                                {(prevnextnavposition == 'top') ?
                                    <PrevNext {...getPrevNextProps()} disableParentTable={true} /> : null}
                                {popupData.map(function(data, i) {
                                    if (data.meta.key !== 'name') {
                                        const hideTitleObject = data.meta.hidetitle || []
                                        const hideTitle = (hideTitleObject.indexOf('popup') > -1) ? true : false

                                        if (hideTitle) {
                                            return (
                                                <TableRow className={style.tableRowFullWidth} selectable={false}
                                                          key={i}>
                                                    <TableRowColumn className={style.tableRowColumnFullWidth}
                                                                    key={i + '_2'}>{data.value}</TableRowColumn>
                                                </TableRow>

                                            )
                                        }


                                        return (
                                            <TableRow selectable={false} key={i}>
                                                <TableRowColumn
                                                    key={i + '_1'}>{translate({ text: data.meta.name })}</TableRowColumn>
                                                <TableRowColumn key={i + '_2'}>{data.value}</TableRowColumn>
                                            </TableRow>
                                        )
                                    }
                                    return null
                                })}
                                <TableRow className={style.tableRowLast} selectable={false} key={'last'}>
                                    <TableRowColumn key={'1'}></TableRowColumn>
                                    <TableRowColumn key={'2'}></TableRowColumn>
                                </TableRow>
                            </TableBody>
                            : (prevnextnavposition == 'top') ? <TableBody><PrevNext {...getPrevNextProps()}
                                                                                    disableParentTable={true} /></TableBody> : null
                        }
                    </Table>
                    {(gallery && gallery.images && gallery.images.length && infoOpen) ?
                        <div className={style.popupGalleryPreview}>
                            <div className={style.galleryMoreImages}>
                                {(gallery.images.map(function(image, i) {
                                    return <div
                                        key={i}
                                        className={style.galleryMoreImage}
                                        style={{ backgroundImage: 'url(' + image.image_url + ')' }}
                                        onTouchTap={function(e) {
                                            touchEnd(e, 'click', 'click', {
                                                type: 'setCurrentView',
                                                data: { view_id: gallery.key, galleryData: { currentImage: i } }
                                            })
                                        }}
                                        onTouchStart={function(e) {

                                        }}></div>
                                }))}
                            </div>
                        </div> : null
                    }
                </div>
            )
        }

        return null

    },
    setFilteredHotspots: function(filteredHotSpots) {
        if (filteredHotSpots) {
            this.setState({
                filteredHotSpots: filteredHotSpots
            })
        }
    },
    render: function() {

        const { style, construct, view_id, justInfo } = this.props
        const prevnextnavposition = this.getPrevNextNavPosition()
        const { filteredHotSpots = {} } = this.state
        const Rows = this.getRows
        const Info = this.getInfo
        const infoheaderposition = this.getInfoHeaderPosition()
        const setRef = this.setRef
        const getPrevNextProps = this.getPrevNextProps

        const filtered = Object.keys(filteredHotSpots).length
        const hotspots = this.getHotSpotsData()
        const allnumber = (hotspots) ? hotspots.length : 0
        const shownumber = allnumber - filtered
        const title = (filtered) ? this.translate({ text: 'info' }) + ' (' + shownumber + '/' + allnumber + ')' : this.translate({ text: 'info' })

        const touchEnd = this.touchEnd
        const objectData = this.getViewObjectData({ view_id }) || { type: 'undefinedType' }

        const headerChildren = (filtered) ?
            <div>
                <IconButton
                    onTouchTap={function(e) {
                        touchEnd(e, 'click', 'click', { type: 'clearFilter' })
                    }}
                    onTouchStart={function(e) {

                    }}>
                    <CloseIcon />
                </IconButton>
            </div> : null

        if (justInfo) {
            return <Info />
        }

        return (
            <Box {...this.props} title={title} className={style.infoheader} headerChildren={headerChildren}
                 ref={function(e) {
                     setRef('infoBox', e)
                 }}>
                <div className={style.infocontainer + ' ' + style[objectData.type]}>
                    {(infoheaderposition == 'stage') ? null : <Info />}
                    <Rows />
                    {(prevnextnavposition !== 'top' && prevnextnavposition !== 'stage' && prevnextnavposition !== 'topinpopupheader') ?
                        <PrevNext {...getPrevNextProps()} /> : null}
                </div>
            </Box>
        )
    }
})

const Box = createReactClass({
    getInitialState: function() {
        this.refElements = {}
        const { openBox = true } = this.props

        return {
            open: openBox
        }
    },
    open: function() {
        const { open } = this.state
        if (!open) {
            this.setState({
                open: true
            })
        }
    },
    close: function() {
        const { open } = this.state
        if (open) {
            this.setState({
                open: false
            })
        }
    },
    toogle: function() {
        const { open } = this.state
        if (open) {
            this.close()
        } else {
            this.open()
        }
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type == 'mouseup' || e && e.type == 'touchend' || e && e.type == pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { onClose } = this.props
        const { type } = d
        if (type == 'toogle') {
            this.toogle()
        }
        e.preventDefault()
    },
    setRef: function(a, e) {
        if (e) this.refElements[a] = e
    },
    render: function() {
        const {
            children, style, showTitle, enableBoxStyle, enableCloseBox, title, headerChildren, closeBoxIcon,
            contentStyle = {}, boxContPadding
        } = this.props
        const { open } = this.state
        const touchEnd = this.touchEnd
        const setRef = this.setRef

        const Upicon = (closeBoxIcon == 0 || closeBoxIcon == '0') ? null : (closeBoxIcon == 'plusminus') ? MinusIcon : UpIcon
        const Downicon = (closeBoxIcon == 0 || closeBoxIcon == '0') ? null : (closeBoxIcon == 'plusminus') ? PlusIcon : DownIcon

        const padding = (boxContPadding) ? boxContPadding : (enableBoxStyle) ? '8px' : '0px'
        const defaultClass = style.boxcont

        return (
            <div className={(enableBoxStyle) ? defaultClass + ' ' + style.boxstylecont : defaultClass}
                 style={{ padding: padding }}>
                <div className={(enableBoxStyle) ? style.box + ' ' + style.boxstyle : style.box}>
                    {(showTitle) ?
                        enableCloseBox ?
                            <div className={style.boxheader + ' ' + style.boxclosable}>
                                {(title) ? <div className={style.boxtitle}
                                                onTouchTap={function(e) {
                                                    touchEnd(e, 'click', 'click', { type: 'toogle' })
                                                }}
                                                onTouchStart={function(e) {

                                                }}
                                    >{title}</div>
                                    : null
                                }
                                {(headerChildren) ?
                                    <div className={style.boxheaderchildren}>
                                        {headerChildren}
                                    </div> : null
                                }
                                <div className={style.boxtrighticon}>
                                    {(Upicon && Downicon) ? <IconButton
                                        onTouchTap={function(e) {
                                            touchEnd(e, 'click', 'click', { type: 'toogle' })
                                        }}
                                        onTouchStart={function(e) {

                                        }}
                                        className={style.boxcloseicon}>
                                        {(open) ? <Upicon /> : <Downicon />}
                                    </IconButton> : null}
                                </div>
                            </div> :
                            <div className={style.boxheader}>
                                {(title) ? <div className={style.boxtitle}>{title}</div> : null}
                                {(headerChildren) ?
                                    <div
                                        className={(title) ? style.boxheaderchildren : style.boxheaderchildren + ' ' + style.boxheaderchildrenfull}>
                                        {headerChildren}
                                    </div> : null
                                }
                            </div>
                        : null
                    }
                    {(children) ?
                        <div style={contentStyle}
                             className={(open) ? null : (enableCloseBox) ? style.boxhidecontent : null}
                             ref={function(e) {
                                 setRef('content', e)
                             }}>
                            {children}
                        </div> : null
                    }
                </div>
            </div>
        )

    }
})

const Gallery = createReactClass({
    getInitialState: function() {
        this.refElements = {}
        return {
            ...this.getData(this.props)
        }
    },
    setRef: function(a, e, n) {
        if (e) {
            this.refElements[a] = e
        }
    },
    getData: function(props) {
        const { images = [], currentImage = 0, open = false, name = '', views, object, enableLoop = false } = props
        const rImages = []
        if (images && images.length) {
            images.map(function(image) {
                if (image.image_url) {
                    rImages.push(image)
                }
            })
        }
        let rCurrentImage = currentImage
        if (!rImages[currentImage]) rCurrentImage = 0
        return { images: rImages, currentImage: rCurrentImage, open: open, name: name, views, object, enableLoop }
    },
    componentDidMount: function() {
        this.resize()
    },
    resize: function() {
        const { style } = this.props
        const galleryElement = this.refElements['gallery']
        if (galleryElement) {
            let className = style.gallery
            if (galleryElement.offsetWidth < 600) {
                className = style.gallery + ' ' + style.galleryMobile
            }

            if (galleryElement.className !== className) {
                galleryElement.className = className
            }
        }
    },
    next: function() {
        const { images, currentImage } = this.state
        const nextImage = (images[currentImage + 1]) ? currentImage + 1 : 0
        this.setState({
            currentImage: nextImage
        })
    },
    prev: function() {
        const { images, currentImage } = this.state
        const prevImage = (images[currentImage - 1]) ? currentImage - 1 : images.length - 1
        this.setState({
            currentImage: prevImage
        })
    },
    setCurrentImage: function(props) {
        const { currentImage } = props
        const { images } = this.state
        if (typeof currentImage == 'number' && images[currentImage] && this.state.currentImage !== currentImage) {
            this.setState({
                currentImage: currentImage
            })
        }
    },
    open: async function(props) {
        const { images, currentImage, open, name, views, object } = this.getData(props)
        await this.setState({
            images: (typeof props.images == 'object') ? images : this.state.images,
            currentImage: (typeof props.currentImage == 'number') ? currentImage : this.state.currentImage,
            name: (typeof props.name == 'string') ? name : this.state.name,
            views: (typeof props.views == 'object') ? views : this.state.views,
            object: (typeof props.object == 'object') ? object : this.state.object,
            open: true
        })
        this.resize()
    },
    close: function() {
        this.setState({
            open: false
        })
    },
    isOpen: function() {
        return this.state.open
    },
    getCurrentImageData: function() {
        const { images, currentImage } = this.state
        return images[currentImage]
    },
    getPrevImageData: function() {
        const { images, currentImage, enableLoop } = this.state
        const prevImage = (images[currentImage - 1] || !enableLoop) ? currentImage - 1 : images.length - 1
        return images[prevImage]
    },
    getNextImageData: function() {
        const { images, currentImage, enableLoop } = this.state
        const nextImage = (images[currentImage + 1] || !enableLoop) ? currentImage + 1 : 0
        return images[nextImage]
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type == 'mouseup' || e && e.type == 'touchend' || e && e.type == pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { onClose } = this.props
        const { type, data } = d
        if (type == 'prev') {
            this.prev()
        }
        if (type == 'next') {
            this.next()
        }
        if (type == 'close') {
            this.close()
        }
        if (type == 'setCurrentImage') {
            this.setCurrentImage(data)
        }
        if (type == 'setCurrentView') {
            this.setCurrentView(data)
        }
        e.preventDefault()
    },
    setCurrentView: function(data) {
        const { setCurrentView } = this.props
        if (setCurrentView) {
            setCurrentView(data)
        }
    },
    getViewObjectsFromObjectId: function({ object_id }) {
        const views = this.getViews()
        const r = []
        if (views && views.length) {
            views.map(function(view) {
                if (view.object_id == object_id) {
                    r.push(view)
                }
            })
        }
        return r
    },
    getViewData: function() {
        const { views, object } = this.state
        const viewData = (views) ? views[0] : null

        if (object && viewData) {
            let name = (object && object.name) ? object.name : ''
            const viewName = (viewData && viewData.name) ? viewData.name : ''
            if (viewName) {
                if (name && name !== viewName) {
                    name = name + ' [' + viewName + ']'
                } else {
                    name = viewName
                }
            }
            return { name: name, view_id: viewData.key }
        }
        return null
    },
    getGalleryCurrentImageStateStyle: function() {
        const r = {}
        const { currentImage, images } = this.state
        if (images.length < 5) {
            let marginLeft = (110 - (images.length * 22)) / 2
            r.marginLeft = marginLeft + 'px'
        } else {
            let marginLeft = ((currentImage + 1) * -22) + 66
            if (currentImage + 1 < 3) marginLeft = 0
            if (currentImage + 1 > images.length - 3) marginLeft = ((images.length - 3 + 1) * -22) + 66
            r.marginLeft = marginLeft + 'px'
        }
        return r
    },
    getCurrentImageStyle: function(currentImageData) {
        const r = {}
        if (currentImageData && currentImageData.image_url) {
            r.backgroundImage = 'url(' + currentImageData.image_url + ')'
        }
        if (currentImageData && currentImageData.backgroundColor) {
            r.backgroundColor = currentImageData.backgroundColor
        }
        return r
    },
    render: function() {
        const { style } = this.props
        const { images, currentImage, open, name } = this.state

        const touchEnd = this.touchEnd
        const currentImageData = this.getCurrentImageData()
        const prevImageData = this.getPrevImageData()
        const nextImageData = this.getNextImageData()
        const viewData = this.getViewData()
        const setRef = this.setRef
        const currentImageStateStyle = this.getGalleryCurrentImageStateStyle()
        const getCurrentImageStyle = this.getCurrentImageStyle
        const currentImageStyle = this.getCurrentImageStyle(currentImageData)

        if (open) {
            return (
                <div className={style.gallery} ref={function(e) {
                    setRef('gallery', e)
                }}>
                    {(images && images.length && currentImageData) ?
                        <div className={style.galleryImages}>
                            <div className={style.galleryImage}
                                 style={currentImageStyle}></div>
                            {(prevImageData) ? <div className={style.galleryPrevButton}
                                                    onTouchTap={function(e) {
                                                        touchEnd(e, 'click', 'click', { type: 'prev' })
                                                    }}
                                                    onTouchStart={function(e) {

                                                    }}>
                                <div><LeftIcon /></div>
                            </div> : null}
                            {(nextImageData) ? <div className={style.galleryNextButton}
                                                    onTouchTap={function(e) {
                                                        touchEnd(e, 'click', 'click', { type: 'next' })
                                                    }}
                                                    onTouchStart={function(e) {

                                                    }}>
                                <div><RightIcon /></div>
                            </div> : null}
                            {(prevImageData || nextImageData) ?
                                <div className={style.galleryCurrentImageState}>
                                    <div className={style.galleryCurrentImageMaxContainer}>
                                        <div className={style.galleryCurrentImageMax} style={currentImageStateStyle}>
                                            {(images.map(function(image, i) {
                                                const className = (i == currentImage) ? style.galleryCurrentImageStateElem + ' ' + style.galleryCurrentImageCurrent :
                                                    (i + 1 == currentImage || i - 1 == currentImage) ? style.galleryCurrentImageStateElem + ' ' + style.galleryCurrentImageNear : style.galleryCurrentImageStateElem
                                                return <div
                                                    key={i}
                                                    className={className}
                                                    onTouchTap={function(e) {
                                                        touchEnd(e, 'click', 'click', {
                                                            type: 'setCurrentImage',
                                                            data: { currentImage: i }
                                                        })
                                                    }}
                                                    onTouchStart={function(e) {

                                                    }}></div>
                                            }))}
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                        </div> :
                        <div className={style.galleryImages}>
                            <div className={style.galleryNoMatches}>
                                <NoMatches style={nms} notFoundText={''} />
                            </div>
                        </div>
                    }
                    <div className={style.gallerySide}>
                        <div className={style.gallerySideHeader}>
                            <div className={style.gallerySideTitle}>{(name) ? name : ''}</div>
                            <div className={style.galleryCloseButton}>
                                <IconButton
                                    onTouchTap={function(e) {
                                        touchEnd(e, 'click', 'click', { type: 'close' })
                                    }}
                                    onTouchStart={function(e) {

                                    }}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                        {(currentImageData && currentImageData.name) ?
                            <div className={style.galleryImageName}>{currentImageData.name}</div> : null}
                        {(currentImageData && currentImageData.description) ?
                            <div className={style.galleryImageDesc}>{currentImageData.description}</div> : null}
                        {(prevImageData || nextImageData) ?
                            <div className={style.galleryMoreImages}>
                                {(images.map(function(image, i) {
                                    const className = (i == currentImage) ? style.galleryMoreImage + ' ' + style.galleryMoreImageCurrent : style.galleryMoreImage
                                    return <div
                                        key={i}
                                        className={className}
                                        style={getCurrentImageStyle(image)}
                                        onTouchTap={function(e) {
                                            touchEnd(e, 'click', 'click', {
                                                type: 'setCurrentImage',
                                                data: { currentImage: i }
                                            })
                                        }}
                                        onTouchStart={function(e) {

                                        }}></div>
                                }))}
                            </div>
                            : null
                        }
                        {(viewData && viewData.view_id) ?
                            <div className={style.galleryViewButton}>
                                <FlatButton
                                    label={viewData.name}
                                    icon={<OpenIcon />}
                                    onTouchTap={function(e) {
                                        touchEnd(e, 'click', 'click', {
                                            type: 'setCurrentView',
                                            data: { view_id: viewData.view_id }
                                        })
                                    }}
                                    onTouchStart={function(e) {

                                    }}
                                />
                            </div>
                            : null
                        }
                    </div>
                </div>
            )
        }
        return null
    }
})

const PlayerAndPalette = createReactClass({
    getInitialState: function() {
        this.refElements = {}
        return {}
    },
    setPlayerTypeToEditor: async function() {
        const player = this.refElements['playercomponent']
        await player.setPlayerTypeToEditor()
    },
    setPlayerTypeToPlayer: async function() {
        const player = this.refElements['playercomponent']
        await player.setPlayerTypeToPlayer()
    },
    open: function() {
        const player = this.refElements['playercomponent']
        player.open()
        this.setLayoutVisibility(true)
    },
    openWithoutUpdate: function() {
        const player = this.refElements['playercomponent']
        player.open()
        this.setLayoutVisibility(true)
    },
    close: function() {
        const player = this.refElements['playercomponent']
        player.close()
        this.setLayoutVisibility(false)
    },
    onOpen: function(p) {
        const player = this.refElements['playercomponent']
        player.onOpen(p)
        this.setLayoutVisibility(true)
    },
    onClose: function(p) {
        const player = this.refElements['playercomponent']
        player.onClose(p)
        this.setLayoutVisibility(false)
    },
    setLayoutVisibility: function(open) {
        const layout = this.refElements['layout']
        layout.setVisibility(open)
    },
    setPlayerMaxWidth: function(p) {
        const player = this.refElements['playercomponent']
        player.setPlayerMaxWidth(p)
        this.setPlayerComponentClassName(p)
    },
    setPlayerClassName: function(p) {
        const player = this.refElements['playercomponent']
        player.setPlayerClassName(p)
        this.setPlayerComponentClassName(p)
    },
    updateContainer: function(p = {}) {
        const player = this.refElements['playercomponent']
        player.updateContainer(p)
    },
    updateCompass: function(p = {}) {
        const player = this.refElements['playercomponent']
        player.updateCompass(p)
    },
    resize: function() {
        const player = this.refElements['playercomponent']
        player.resize()
    },
    setCurrentView: async function(p) {
        const player = this.refElements['playercomponent']
        await player.setCurrentView(p)
    },
    setRef: function(a, e, n) {
        if (e) {
            if (n) {
                this[n][a] = e
            } else {
                this.refElements[a] = e
            }
        }
        const player = this.refElements['playercomponent']
        player.setRef(a, e, n)
    },
    getData: function() {
        const { construct, data } = this.props
        if (data) return data
        return (construct.props && construct.props.data) ? construct.props.data : null
    },
    setViewId: function(p = {}) {
        const { onChangeView } = this.props
        const { view_id } = this.state
        if (view_id !== p.view_id) {

            this.setState({
                view_id: p.view_id,
                floatingPaletteState: p.floatingPaletteState,
                boxesState: p.boxesState
            })

            const { getControls } = this.props
            const refElements = this.refElements

            setTimeout(function() {
                const playercontrols = getControls()
                if (playercontrols && playercontrols.setViewId) {
                    playercontrols.setViewId(p)
                }
            })

            if (onChangeView) onChangeView(p)
        }
    },
    onChangeFilter: function(filteredHotSpots = {}) {
        const list = this.refElements['list']
        list.setFilteredHotspots(filteredHotSpots)
    },
    clearFilter: function() {
        const list = this.refElements['filter']
        list.setDefault()
    },

    getDataObject: function() {
        return this.getData()
    },
    getViews: function() {
        const dataObject = this.getDataObject()
        const views = dataObject.props.views.props.views
        return views
    },
    getObjects: function() {
        const dataObject = this.getDataObject()
        const objects = dataObject.props.objects.props.objects
        return objects
    },
    getViewObjectsFromObjectId: function({ object_id }) {
        const views = this.getViews()
        const r = []
        if (views && views.length) {
            views.map(function(view) {
                if (view.object_id == object_id) {
                    r.push(view)
                }
            })
        }
        return r
    },
    getViewData: function(p = {}) {
        const { view_id } = p
        if (view_id) {
            const views = this.getViews()
            if (views && views.length) {
                let foundView = null
                views.map(function(view) {
                    if (view.key == view_id) foundView = view
                })
                return foundView
            }
        }
        return null
    },
    getObjectData: function(p = {}) {
        const { object_id } = p
        if (object_id) {
            const objects = this.getObjects()
            if (objects && objects.length) {
                let foundObject = null
                objects.map(function(object) {
                    if (object.key == object_id) foundObject = object
                })
                return foundObject
            }
        }
        return null
    },
    getViewObjectData: function({ view_id }) {
        const viewData = this.getViewData({ view_id })
        if (viewData && viewData.object_id) {
            return this.getObjectData({ object_id: viewData.object_id })
        }
        return null
    },
    getPrevNextNavPosition: function() {
        const { construct } = this.props
        const { view_id } = this.state
        const objectData = this.getViewObjectData({ view_id }) || { type: 'undefinedType' }
        let prevnextnavposition = (construct && construct.props) ? construct.props.prevnextnavposition : null
        try {
            prevnextnavposition = JSON.parse(prevnextnavposition)
        } catch (e) {
        }
        if (prevnextnavposition && prevnextnavposition.type && objectData.type && prevnextnavposition.type[objectData.type]) {
            return prevnextnavposition.type[objectData.type]
        }
        return prevnextnavposition
    },
    getInfoHeaderPosition: function() {
        const { construct } = this.props
        const { view_id } = this.state
        const objectData = this.getViewObjectData({ view_id }) || { type: 'undefinedType' }
        let infoheaderposition = (construct && construct.props) ? construct.props.infoheaderposition : null
        try {
            infoheaderposition = JSON.parse(infoheaderposition)
        } catch (e) {
        }
        if (infoheaderposition && infoheaderposition.type && objectData.type && infoheaderposition.type[objectData.type]) {
            return infoheaderposition.type[objectData.type]
        }
        return infoheaderposition
    },
    getCompassPosition: function() {
        const { construct } = this.props
        const { view_id } = this.state
        const objectData = this.getViewObjectData({ view_id }) || { type: 'undefinedType' }
        let compassposition = (construct && construct.props) ? construct.props.compassposition : null
        try {
            compassposition = JSON.parse(compassposition)
        } catch (e) {
        }
        if (compassposition && compassposition.type && objectData.type && compassposition.type[objectData.type]) {
            return compassposition.type[objectData.type]
        }
        return compassposition
    },
    setPlayerComponentClassName: function(p) {
        const container = this.refElements['playercomponentcotnainer']
        if (container) {
            container.className = this.getPlayerComponentClassName(p)
        }
    },
    getPlayerComponentClassName: function(p = {}) {

        const { style, splitcontainer } = this.props
        const { view_id } = this.state
        const objectData = this.getViewObjectData({ view_id }) || { type: 'undefinedType' }
        let t = style.playercomponent + ' ' + style[objectData.type]
        if (splitcontainer) {
            t = t + ' ' + style.splitcontainer
        }

        const floatingpalette = this.refElements['floatingpalette']
        const floatingPaletteState = (floatingpalette && floatingpalette.getPinSettings) ? floatingpalette.getPinSettings() : {}

        const {
            open = floatingPaletteState.open,
            pinned = floatingPaletteState.pinned,
            pintype = floatingPaletteState.pintype
        } = p

        if (open) {
            t = t + ' ' + style['pinned_' + pinned]
            t = t + ' ' + style['pintype_' + pintype]
        }

        return t
    },
    getChildren: function() {

        const {
            style, floatingPaletteProps = {}, disableFloatingPalette, enablePreviouslyBox, showBoxesTitle,
            enableCloseBox, enableBoxStyle, enableFilter, enableShareBox, closeBoxIcon, layoutType, construct,
            getRootContainer, boxContPadding, sidebarWidth, boxesorder = ['info', 'filter', 'previously', 'share'],
            prevnextnavhidetitle
        } = this.props

        const { view_id, floatingPaletteState = {}, boxesState = {} } = this.state
        const boxesOrder = (typeof boxesorder == 'string') ? JSON.parse(boxesorder) : boxesorder

        const playerComponentClassName = this.getPlayerComponentClassName()

        const onOpen = this.onOpen
        const onClose = this.onClose
        const setRef = this.setRef

        const setPlayerMaxWidth = this.setPlayerMaxWidth
        const setViewId = this.setViewId
        const data = this.getData()
        const refElements = this.refElements
        const setCurrentView = this.setCurrentView
        const onChangeFilter = this.onChangeFilter
        const clearFilter = this.clearFilter

        const prevnextnavposition = this.getPrevNextNavPosition()
        const infoheaderposition = this.getInfoHeaderPosition()

        let compassposition = this.getCompassPosition()
        if (compassposition !== 'stage') compassposition = 'stage'

        let sbt = showBoxesTitle
        try {
            sbt = JSON.parse(sbt)
        } catch (e) {
        }

        const playerProps = {
            ...this.props,
            boxesState: boxesState,
            setViewId: setViewId,
            ref: function(e) {
                setRef('playercomponent', e)
            },
            boxContPadding: boxContPadding
        }

        const listProps = {
            construct: construct,
            getRootContainer: getRootContainer,
            ref: function(e) {
                setRef('list', e)
            },
            dataObject: data,
            view_id: view_id,
            style: style,
            refElements: refElements,
            setCurrentView: setCurrentView,
            showTitle: (typeof sbt == 'object' && sbt.indexOf && sbt.indexOf('info') > -1 || sbt == '1' || sbt == 1) ? true : false,
            enableCloseBox: enableCloseBox,
            closeBoxIcon: closeBoxIcon,
            enableBoxStyle: enableBoxStyle,
            openBox: (boxesState.info && boxesState.info.open !== undefined) ? boxesState.info.open : undefined,
            active: (boxesState.info && typeof boxesState.info.active !== 'undefined') ? boxesState.info.active : null,
            filteredHotSpots: (boxesState.filters && boxesState.filters[view_id] && boxesState.filters[view_id].filteredHotSpots) ?
                boxesState.filters[view_id].filteredHotSpots : undefined,
            orderByMetas: (boxesState.orderByMetas && boxesState.orderByMetas[view_id]) ?
                boxesState.orderByMetas[view_id] : undefined,
            clearFilter: clearFilter,
            boxContPadding: boxContPadding,
            prevnextnavhidetitle: prevnextnavhidetitle,
            floatingPaletteProps: floatingPaletteProps
        }

        const filterProps = {
            construct: construct,
            ref: function(e) {
                setRef('filter', e)
            },
            dataObject: data,
            view_id: view_id,
            style: style,
            refElements: refElements,
            setCurrentView: setCurrentView,
            showTitle: (typeof sbt == 'object' && sbt.indexOf && sbt.indexOf('filter') > -1 || sbt == '1' || sbt == 1) ? true : false,
            enableCloseBox: enableCloseBox,
            closeBoxIcon: closeBoxIcon,
            enableBoxStyle: enableBoxStyle,
            openBox: (boxesState.filter && boxesState.filter.open !== undefined) ? boxesState.filter.open : false,
            filterState: (boxesState.filters && boxesState.filters[view_id]) ? boxesState.filters[view_id] : undefined,
            onChange: onChangeFilter,
            boxContPadding: boxContPadding
        }

        const previouslyProps = {
            construct: construct,
            ref: function(e) {
                setRef('previously', e)
            },
            dataObject: data,
            view_id: view_id,
            style: style,
            refElements: refElements,
            setCurrentView: setCurrentView,
            showTitle: (typeof sbt == 'object' && sbt.indexOf && sbt.indexOf('previously') > -1 || sbt == '1' || sbt == 1) ? true : false,
            enableCloseBox: enableCloseBox,
            closeBoxIcon: closeBoxIcon,
            enableBoxStyle: enableBoxStyle,
            openBox: (boxesState.previously && boxesState.previously.open !== undefined) ? boxesState.previously.open : false,
            boxContPadding: boxContPadding,
            sidebarWidth: sidebarWidth
        }

        const shareProps = {
            construct: construct,
            ref: function(e) {
                setRef('share', e)
            },
            dataObject: data,
            view_id: view_id,
            style: style,
            showTitle: (typeof sbt == 'object' && sbt.indexOf && sbt.indexOf('share') > -1 || sbt == '1' || sbt == 1) ? true : false,
            enableCloseBox: enableCloseBox,
            closeBoxIcon: closeBoxIcon,
            enableBoxStyle: enableBoxStyle,
            openBox: (boxesState.share && boxesState.share.open !== undefined) ? boxesState.share.open : false,
            boxContPadding: boxContPadding,
            ...this.props
        }

        const floatingPaletteRenderProps = {
            construct: construct,
            style: fps,
            setRef: setRef,
            onOpen: onOpen,
            onClose: onClose,
            open: true,
            onPin: setPlayerMaxWidth,
            onUnpin: setPlayerMaxWidth,
            onPintype: setPlayerMaxWidth,
            onForcePinOnDown: setPlayerMaxWidth,
            pinRightAndUnpinWidth: sidebarWidth,
            className: style.floatingPalette,
            pintypeClasses: style,
            ...floatingPaletteProps,
            ...floatingPaletteState
        }

        let leftChildren = null
        let centerChildren = null
        let rightChildren = null

        if (layoutType == 'page') {

            centerChildren =
                <div className={playerComponentClassName} ref={function(e) {
                    setRef('playercomponentcotnainer', e)
                }}>
                    <Player {...playerProps}
                            enablePrevNextNavOnStage={(prevnextnavposition == 'stage') ? true : false}
                            enableInfoHeaderOnStage={(infoheaderposition == 'stage') ? true : false}
                            enableCompassOnStage={(compassposition == 'stage') ? true : false}
                            listProps={listProps}
                    />
                    {(!disableFloatingPalette) ?
                        <FloatingPalette {...floatingPaletteRenderProps} customStyle={{ borderLeftStyle: 'none' }}>
                            {(view_id) ?
                                <div style={{ margin: '-20px', marginBottom: '0' }}>
                                    <List {...listProps} />
                                </div>
                                : null
                            }
                        </FloatingPalette> : null}
                </div>

            leftChildren = (!disableFloatingPalette && view_id) ?
                <div>
                    {(enableFilter) ? <FilterBox {...filterProps} /> : null}
                    {(enablePreviouslyBox) ? <PreviouslyBox {...previouslyProps} /> : null}
                    {(enableShareBox) ? <ShareBox {...shareProps} /> : null}
                </div> : null

        } else {

            centerChildren =
                <div className={playerComponentClassName} ref={function(e) {
                    setRef('playercomponentcotnainer', e)
                }}>
                    <Player {...playerProps}
                            enablePrevNextNavOnStage={(prevnextnavposition == 'stage') ? true : false}
                            enableInfoHeaderOnStage={(infoheaderposition == 'stage') ? true : false}
                            enableCompassOnStage={(compassposition == 'stage') ? true : false}
                            listProps={listProps}
                    />
                    {(!disableFloatingPalette) ?
                        <FloatingPalette {...floatingPaletteRenderProps}>
                            {(view_id) ?
                                <div style={{ margin: '-20px', marginBottom: '0' }}>
                                    {boxesOrder.map(function(type, i) {
                                        if (type == 'info') {
                                            return <List key={i} {...listProps} />
                                        }
                                        if (type == 'filter') {
                                            return (enableFilter) ? <FilterBox key={i} {...filterProps} /> : null
                                        }
                                        if (type == 'previously') {
                                            return (enablePreviouslyBox) ?
                                                <PreviouslyBox key={i} {...previouslyProps} /> : null
                                        }
                                        if (type == 'share') {
                                            return (enableShareBox) ? <ShareBox key={i} {...shareProps} /> : null
                                        }
                                    })}
                                </div>
                                : null
                            }
                        </FloatingPalette> : null}
                </div>

        }

        return {
            leftChildren,
            centerChildren,
            rightChildren
        }
    },
    render: function() {

        const { style, floatingPaletteProps = {}, layoutPadding, sidebarWidth, layoutType } = this.props
        const { floatingPaletteState } = this.state
        const setRef = this.setRef

        return (
            <Layout
                customStyle={(layoutPadding) ? { padding: layoutPadding } : {}}
                ref={(setRef) ? function(e) {
                    setRef('layout', e)
                } : null}
                setRef={setRef}
                style={style}
                floatingPaletteProps={floatingPaletteProps}
                floatingPaletteState={floatingPaletteState}
                sidebarWidth={sidebarWidth}
                {...this.getChildren()}
            />
        )

    }
})

const Layout = createReactClass({
    getInitialState: function() {
        this.refElements = {}
        return {}
    },
    setRef: function(a, e, n) {
        const { setRef } = this.props
        if (e) {
            if (n) {
                this[n][a] = e
            } else {
                this.refElements[a] = e
            }
        }
        if (setRef) setRef(a, e, n)
    },
    getVisible: function(open) {
        const { floatingPaletteProps = {}, floatingPaletteState = {} } = this.props
        return (open !== undefined) ?
            open : (floatingPaletteProps.open !== undefined) ?
                floatingPaletteProps.open : (floatingPaletteState.open !== undefined) ?
                    floatingPaletteState.open : true
    },
    setVisibility: function(open) {
        const o = this.getVisible(open)
        const layoutLeft = this.refElements['layoutLeft']
        if (o) {
            if (layoutLeft) layoutLeft.style.display = 'block'
        } else {
            if (layoutLeft) layoutLeft.style.display = 'none'
        }
    },
    render: function() {
        const {
            style,
            leftChildren = null,
            centerChildren = null,
            rightChildren = null,
            customStyle = {},
            sidebarWidth = '350px',
            className = ''
        } = this.props
        const setRef = this.setRef
        const visible = this.getVisible()

        const sideStyle = (sidebarWidth) ? { flexBasis: sidebarWidth } : null

        return (
            <div className={style.layout + ' ' + className} style={customStyle}>
                {(leftChildren) ?
                    <div style={{ display: (visible) ? 'block' : 'none' }} className={style.layoutleft}
                         style={sideStyle} ref={function(e) {
                        setRef('layoutLeft', e)
                    }}>
                        {leftChildren}
                    </div> : null}
                {(centerChildren) ?
                    <div className={style.layoutcenter} ref={function(e) {
                        setRef('layoutCenter', e)
                    }}>
                        {centerChildren}
                    </div> : null}
                {(rightChildren) ?
                    <div className={style.layoutright} ref={function(e) {
                        setRef('layoutRight', e)
                    }}>
                        {rightChildren}
                    </div> : null}
            </div>
        )

    }

})

const Middle = createReactClass({
    setRef: function(a, e) {
        const { setRef } = this.props
        const { construct } = this.props
        const setRefConstruct = (construct.props) ? construct.props.setRef : null
        if (setRef) setRef(a, e)
        if (setRefConstruct) setRefConstruct(a, e)
    },
    render: function() {
        const setRef = this.setRef
        return (
            <PlayerAndPalette {...this.props} ref={(setRef) ? function(e) {
                setRef('player', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})
