import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'

import wapplrcomponents from '../../../../../../../../components'
import s from '../empty.css'
import avs from '../../../themes/default/Wapplravatar.css'
import ps from '../../../themes/default/Wapplrpost.css'

import PublicIcon from 'material-ui/svg-icons/social/public'
import PrivateIcon from 'material-ui/svg-icons/action/lock-outline'

const Wapplrpost = wapplrcomponents.post

const Post = createReactClass({
    getInitialState: function() {
        this.refElements = {}
        const { post } = this.props
        return {
            isMounted: false,
            post: post
        }
    },
    componentDidMount: function() {
        this.setState({
            isMounted: true
        })
    },

    //Publicicon
    publicicon: function({ post }) {
        const share = post && post.share
        if (share == 'public' || !share) {
            return <PublicIcon />
        }
        if (share == 'private') {
            return <PrivateIcon />
        }
        return null
    },

    setRef: function(a, e) {
        const { setRef } = this.props
        this.refElements[a] = e
        if (setRef) setRef(a, e)
    },
    render: function() {

        const { history, getthumb, avatarstyle = avs, ...more } = this.props
        const post = this.state.post
        const publiciconcontent = this.publicicon({ post })

        return (
            <Wapplrpost
                {...more}
                history={history}
                avatarstyle={avatarstyle}
                style={ps}
                showauthor={true}
                post={this.state.post}
                publiciconcontent={publiciconcontent}
            />
        )
    }
})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <Post {...this.props} ref={(setRef) ? function(e) {
                setRef('post', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})
