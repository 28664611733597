import React from 'react'
import createReactClass from 'create-react-class'

const loadedFonts = []

export default createReactClass({
    getInitialState: function() {
        return {
            loaded: true,
            loadedFonts: loadedFonts
        }
    },
    componentDidMount: function() {
        this.loadFonts()
    },
    loadFont: function({ families, custom, urls, callback }) {
        const WebFont = require('webfontloader')

        import('webfontloader').then(function() {
            window.WebFontConfig = {
                active: function() {
                    sessionStorage.fonts = true
                }
            }
            const t = this
            if (families && families.length || custom && custom.length) {
                const newFonts = []
                if (families && families.length) {
                    families.map(function(familyName) {
                        if (!loadedFonts[familyName]) newFonts.push(familyName)
                    })
                }

                const newCustomFonts = []
                if (custom && custom.length) {
                    custom.map(function(familyName) {
                        if (!loadedFonts[familyName]) newCustomFonts.push(familyName)
                    })
                }

                if (newFonts && newFonts.length || newCustomFonts && newCustomFonts.length) {

                    const config = {
                        loading: function() {
                            //console.log("loading");
                        },
                        active: function() {
                            //console.log("active")
                        },
                        inactive: function() {
                            //console.log("inactive")
                        },
                        fontloading: function(familyName, fvd) {
                            //console.log("fontloading", familyName, fvd);
                        },
                        fontactive: function(familyName, fvd) {
                            //console.log("fontactive", familyName, fvd);
                            loadedFonts[familyName] = true
                            let loaded = 0
                            if (newFonts && newFonts.length) {
                                newFonts.map(function(font) {
                                    if (loadedFonts[font]) loaded = loaded + 1
                                })
                            }
                            if (newCustomFonts && newCustomFonts.length) {
                                newCustomFonts.map(function(font) {
                                    if (loadedFonts[font]) loaded = loaded + 1
                                })
                            }
                            const ngfl = (newFonts && newFonts.length) ? newFonts.length : 0
                            const ncfl = (newCustomFonts && newCustomFonts.length) ? newCustomFonts.length : 0
                            const nfl = ngfl + ncfl

                            if (loaded == nfl) {
                                if (callback) callback()
                            }


                        },
                        fontinactive: function(familyName, fvd) {
                            //console.log("fontinactive", familyName, fvd);
                        }
                    }

                    if (newFonts && newFonts.length) {
                        config.google = {
                            families: newFonts
                        }
                    }


                    if (newCustomFonts && newCustomFonts.length) {
                        config.custom = {
                            families: newCustomFonts
                        }
                        if (urls) {
                            config.custom.urls = urls
                        }
                    }

                    //console.log(config);

                    WebFont.load(config)

                } else {
                    if (callback) callback()
                }
            } else {
                if (callback) callback()
            }
        })
    },
    loadFonts: function() {
        const { families, urls, custom, useStates = false } = this.props
        const t = this
        if (families && families.length || custom && custom.length) {

            this.loadFont({
                families: families, custom, urls: urls, callback: function() {
                    //console.log("fonts loaded", loadedFonts);
                    if (useStates) {
                        t.setState({
                            loadedFonts: loadedFonts,
                            loaded: true
                        })
                    }
                }
            })

        } else {

            //console.log("no fonts loaded", loadedFonts);
            if (useStates) {
                t.setState({
                    loadedFonts: loadedFonts,
                    loaded: true
                })
            }
        }
    },
    render: function() {

        const { useStates = false } = this.props
        const { loaded } = this.state
        if (useStates && !loaded) return null

        const { children } = this.props
        if (children) return children
        return null
    }
})
