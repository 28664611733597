import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'
import c from './player'

import CircularProgress from 'material-ui/CircularProgress'

const Charliesplayer = createReactClass({
    getInitialState: function() {
        this.refElements = {}
        return {
            ready: false,
            process: false
        }
    },
    getAppString: function() {
        const { post, width = '100%', height = '450', autosize = 'height', src = '' } = this.props
        const dataid = (post) ? post.dataid : null

        return `<script
            type="text/javascript"
            app="zoomselect"
            name="ccsplayer"
            src="${src}"
            width="${width}"
            height="${height}"
            autosize="${autosize}"
            founded="true"
            data-id="${dataid}"
        />`
    },
    setReady: function() {
        if (!this.state.ready) {
            const { construct } = this.props
            const { readycallback } = construct.props
            this.setState({
                ready: true,
                process: false
            })
            if (readycallback) readycallback()
        }
    },
    getData: function(callback) {
        const cs = this.state.script
        const fr = this.state.frame
        if (fr && fr.carco.getData) {
            fr.carco.getData(function(data) {
                if (callback) callback(data)
            })
        } else {
            if (callback) callback(null)
        }
        return null
    },
    getScript: function({ d }) {

        const cs = d.children[0]
        const user_attributes = {}

        if (cs) {
            Object.keys(cs.attributes).map(function(x) {
                let avalue = cs.getAttribute(cs.attributes[x].name)
                if (avalue && cs.attributes[x].name) {
                    user_attributes[cs.attributes[x].name] = cs.getAttribute(cs.attributes[x].name)
                }
            })
            if (!cs.carco) cs.carco = {}
            if (!cs.carco.paramsdata) cs.carco.paramsdata = {}
            cs.carco.paramsdata.user = user_attributes
        }
        return cs

    },
    componentDidMount: function() {
        const { post, editor } = this.props
        const d = this.refElements['div']
        const setReady = this.setReady
        d.innerHTML = this.getAppString()

        if (post && post.dataid) {

            const cs = this.getScript({ d })
            let fr = null

            if (cs) {
                fr = c.functions.ccsplayer.getPlayer(cs, function() {
                    setReady()
                }, editor)
            }

            this.setState({
                process: true,
                script: cs,
                frame: fr
            })

        }

    },
    setRef: function(a, e) {
        const { setRef } = this.props
        this.refElements[a] = e
        if (setRef) setRef(a, e)
    },
    getClassName() {
        const { style = s } = this.props
        return (this.state.ready) ? style.charliesplayer + ' ' + style.show : style.charliesplayer
    },
    render: function() {
        const { style = s } = this.props
        const setRef = this.setRef
        const state = this.state
        return (
            <div className={style.charliesplayerbg}>
                {(state.process && !state.ready) ?
                    <div className={style.processbox}>
                        <div className={style.process}>
                            <CircularProgress size={60} thickness={5} />
                        </div>
                    </div>
                    : null
                }
                <div ref={function(e) {
                    setRef('div', e)
                }} className={this.getClassName()}></div>
            </div>
        )
    }
})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <Charliesplayer {...this.props} ref={(setRef) ? function(e) {
                setRef('charliesplayerold', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})


