let trackerId = null

class GA {
    constructor(props) {
        this.props = { ...props }
    }
}

export class getGA extends GA {
    constructor(props) {
        super(props)
    }

    getId() {
        if (trackerId) return trackerId
        const { construct } = this.props
        return (construct.props && construct.props.id) ? construct.props.id : 'flatchooser_' + new Date().getTime()
    }

    getEnableGA() {
        const { construct } = this.props
        return (construct && construct.props && construct.props.enablega) ? construct.props.enablega : null
    }

    checkIfAnalyticsLoaded({ callback, wait }) {
        const checkIfAnalyticsLoaded = this.checkIfAnalyticsLoaded.bind(this)
        if (window.ga && window.ga.loaded) {
            if (callback) callback({ loaded: true })
        } else {
            if (wait) {
                setTimeout(function() {
                    checkIfAnalyticsLoaded({ callback, wait })
                }, 500)
            } else {
                if (callback) callback({ loaded: false })
            }
        }
    }

    getTracker({ trackerName }) {
        let foundTracker = false
        if (typeof window !== 'undefined') {
            if (window.ga && window.ga.getAll) {
                const trackers = window.ga.getAll()
                if (trackers && trackers.length) {
                    trackers.map(function(tracker) {
                        let no = tracker.get('name')
                        if (no && no == trackerName || no && typeof no == 'string' && no.match(trackerName) || no && no.name == trackerName) {
                            foundTracker = tracker
                        }
                    })
                }
            }
        }
        return foundTracker
    }

    addGA({ url }) {

        const enablega = this.getEnableGA()
        const gaID = this.getId()
        if (gaID) trackerId = gaID

        if (enablega) {

            const head = document.head || document.getElementsByTagName('head')[0]
            const checkIfAnalyticsLoaded = this.checkIfAnalyticsLoaded.bind(this)
            const getTracker = this.getTracker
            const waitForTracker = this.waitForTracker

            checkIfAnalyticsLoaded({
                callback: function({ loaded }) {

                    if (!loaded) {
                        const a = document.createElement('script')
                        a.src = 'https://www.google-analytics.com/analytics.js'
                        a.type = 'text/javascript'
                        a.setAttribute('async', '')
                        head.appendChild(a)
                    }

                    checkIfAnalyticsLoaded({
                        callback: function() {
                            let tracker = getTracker({ trackerName: gaID })
                            if (!tracker) {
                                window.ga('create', enablega, 'auto', { name: gaID })
                                tracker = getTracker({ trackerName: gaID })
                            }

                            tracker.send({
                                hitType: 'pageview',
                                page: url
                            })

                            //console.log(gaID, "send ga", url);

                        }, wait: true
                    })

                }
            })

        }
    }
}
