// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._6hPjg {\n    width: 100%;\n}\n\n.q9zw\\+ {\n    display: block;\n}\n\n._0Ug82 {\n    position: relative;\n    font-size: 12px;\n    line-height: 12px;\n    color: rgb(244, 67, 54);\n    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle": "_6hPjg",
	"togglecomponent": "q9zw+",
	"error": "_0Ug82"
};
module.exports = ___CSS_LOADER_EXPORT___;
